export default function UseTermsContent() {
    return <ol>
            <li>테스트1</li>
             <div>
                테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트
                테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트
                테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트
                테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트
                테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트
                테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트
                테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트
                테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트
                테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트
                테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트테스트
             </div>
            <li>테스트2</li>
            <li>테스트3</li>
            <li>테스트4</li>
        </ol>
}

import { useParams } from "react-router-dom";
import ScheduleAddPage from "../components/ScheduleAddPage";
import ServerAPI from "../ServerAPI";


export default function IotScheduleAddPage() {
    const {clientId, scheduleId} = useParams();
    return <ScheduleAddPage clientId={clientId!} scheduleId={scheduleId} getScheduleURL={ServerAPI.storeIotScheduleGet}
    addScheduleURL={ServerAPI.storeIotScheduleAdd} updateScheduleURL={ServerAPI.storeIotScheduleUpdate} />
}

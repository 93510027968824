////////////////////////////////////////////////////////////////
// wCommon
////////////////////////////////////////////////////////////////
class wCommon {
    static uintMaxValue = 4294967296;

    static /*string*/ bytesToHexString(/*byte[]*/ bytes, /*int*/ offset = 0, /*int*/ length = -1) {
        const /*string*/ hexChar = "0123456789ABCDEF";

        if (bytes == null) return null;
        if (length == -1) length = bytes.length - offset;

        const buffer = Array(length * 2);
        for (let i = 0; i < length; i++) {
            buffer[i * 2] = hexChar[bytes[offset + i] >> 4];
            buffer[i * 2 + 1] = hexChar[bytes[offset + i] & 0x0F];
        }

        return buffer.join("");
    }

    static /*int*/ getTimerMs() {
        return Date.now();
    }

    static ipUIntToIpAddress(/*uint*/ ipUInt) {
        return `${(ipUInt >> 24) & 0xFF}.${(ipUInt >> 16) & 0xFF}.${(ipUInt >> 8) & 0xFF}.${ipUInt & 0xFF}`;
    }

    static shuffle(array) {
        for (let i = 0; i < array.length - 1; i++) {
            const index = i + Math.floor(Math.random() * (array.length - i));
            if (index == i) continue;

            const temp = array[i];
            array[i] = array[index];
            array[index] = temp;
        }
        return array;
    }
}

////////////////////////////////////////////////////////////////
// wStream
////////////////////////////////////////////////////////////////
class wStream {
    /*int*/ position = 0;
    /*Stream*/ stream = new Uint8Array(256);

    /*long*/ getAvailableLengthS() {
        return this.stream.length - this.position;
    }

    /*byte[]*/ getBytes() {
        return this.stream.subarray(0, this.position);
    }

    /*void*/ set(/*byte[]*/ bytes, /*int*/ index = 0, /*int*/ length = -1) {
        this.length = length != -1 ? length : (bytes.length - index);
        this.position = 0;
        this.stream = new Uint8Array(bytes.slice(index, index + this.length));
    }

    ////////////////////////////////////////////////////////////////
    // Read
    ////////////////////////////////////////////////////////////////
    /*bool*/ readBool() {
        if (this.getAvailableLengthS() < 1) throw "wStream.readBool() length error.";
        return this.stream[this.position++] != 0;
    }

    /*bool?*/ readBoolNullable() {
        if (this.getAvailableLengthS() < 1) throw "wStream.readBoolNullable() length error.";
        const result = this.stream[this.position++];
        return result == 0x80 ? null : result != 0;
    }

    /*byte*/ readByte() {
        if (this.getAvailableLengthS() < 1) throw "wStream.readByte() length error.";
        return this.stream[this.position++];
    }

    /*byte?*/ readByteNullable() {
        return this.readBool() ? this.readByte() : null;
    }

    /*byte[]*/ readBytes(/*int*/ length) {
        if (length > this.getAvailableLengthS()) throw `wStream.readBytes(${length}) length error.`;

        const buffer = new Uint8Array(this.stream.subarray(this.position, this.position + length));
        this.position += length;

        return buffer;
    }

    /*byte[]*/ readBytesByteLength() {
        const length = this.readByte();
        if (length == 255) return null;
        else {
            if (length > this.getAvailableLengthS()) throw `wStream.readBytesByteLength(${length}) length error.`;

            const buffer = new Uint8Array(this.stream.subarray(this.position, this.position + length));
            this.position += length;

            return buffer;
        }
    }

    /*byte[]*/ readBytesUShortLength() {
        const length = this.readUShort();
        if (length == 65535) return null;
        else {
            if (length > this.getAvailableLengthS()) throw `wStream.readBytesUShortLength(${length}) length error.`;

            const buffer = new Uint8Array(this.stream.subarray(this.position, this.position + length));
            this.position += length;

            return buffer;
        }
    }

    /*byte[]*/ readBytesIntLength() {
        const length = this.readInt();
        if (length == 2147483647) return null;
        else {
            if (length > this.getAvailableLengthS()) throw `wStream.readBytesIntLength(${length}) length error.`;

            const buffer = new Uint8Array(this.stream.subarray(this.position, this.position + length));
            this.position += length;

            return buffer;
        }
    }

    /*char*/ readChar() {
        return this.readUShort();
    }

    /*char?*/ readCharNullable() {
        return this.readBool() ? this.readChar() : null;
    }

    /*double*/ readDouble() {
        if (this.getAvailableLengthS() < 8) throw "wStream.readDouble() length error.";

        const buffer = this.stream.subarray(this.position, this.position + 8);
        const float = new Float64Array(buffer);

        return float[0];
    }

    /*double?*/ readDoubleNullable() {
        return this.readBool() ? this.readDouble() : null;
    }

    /*float*/ readFloat() {
        if (this.getAvailableLengthS() < 4) throw "wStream.readFloat() length error.";

        const buffer = this.stream.subarray(this.position, this.position + 4);
        const float = new Float32Array(buffer);

        return float[0];
    }

    /*float?*/ readFloatNullable() {
        return this.readBool() ? this.readFloat() : null;
    }

    /*short*/ readShort() {
        if (this.getAvailableLengthS() < 2) throw "wStream.readShort() length error.";

        const result = (this.stream[this.position] << 8) | (this.stream[this.position + 1]);
        if (result & 0x8000) result = result - 0x10000;
        this.position += 2;

        return result;
    }

    /*short?*/ readShortNullable() {
        return this.readBool() ? this.readShort() : null;
    }

    /*ushort*/ readUShort() {
        if (this.getAvailableLengthS() < 2) throw "wStream.readUShort() length error.";

        const result = (this.stream[this.position] << 8) | (this.stream[this.position + 1]);
        this.position += 2;

        return result;
    }

    /*ushort?*/ readUShortNullable() {
        return this.readBool() ? this.readUShort() : null;
    }

    /*int*/ readInt() {
        if (this.getAvailableLengthS() < 4) throw "wStream.readUInt() length error.";

        const result = (this.stream[this.position] << 24) | (this.stream[this.position + 1] << 16) | (this.stream[this.position + 2] << 8) | this.stream[this.position + 3];
        if (result & 0x80000000) result = result - 0x100000000;
        this.position += 4;

        return result;
    }

    /*int?*/ readIntNullable() {
        return this.readBool() ? this.readInt() : null;
    }

    /*uint*/ readUInt() {
        if (this.getAvailableLengthS() < 4) throw "wStream.readUInt() length error.";

        const result = (this.stream[this.position] << 24) | (this.stream[this.position + 1] << 16) | (this.stream[this.position + 2] << 8) | this.stream[this.position + 3];
        this.position += 4;

        return result;
    }

    /*uint?*/ readUIntNullable() {
        return this.readBool() ? this.readUInt() : null;
    }

    /*long*/ readLong() {
        if (this.getAvailableLengthS() < 8) throw "wStream.readLong() length error.";

        const result = (this.stream[this.position] << 56) | (this.stream[this.position + 1] << 48) | (this.stream[this.position + 2] << 40) | (this.stream[this.position + 3] << 32) |
            (this.stream[this.position + 4] << 24) | (this.stream[this.position + 5] << 16) | (this.stream[this.position + 6] << 8) | this.stream[this.position + 7];
        if (result & 0x8000000000000000) result = result - 0x10000000000000000;
        this.position += 8;

        return result;
    }

    /*long?*/ readLongNullable() {
        return this.readBool() ? this.readLong() : null;
    }

    /*ulong*/ readULong() {
        if (this.getAvailableLengthS() < 8) throw "wStream.readULong() length error.";

        const result = (this.stream[this.position] << 56) | (this.stream[this.position + 1] << 48) | (this.stream[this.position + 2] << 40) | (this.stream[this.position + 3] << 32) |
            (this.stream[this.position + 4] << 24) | (this.stream[this.position + 5] << 16) | (this.stream[this.position + 6] << 8) | this.stream[this.position + 7];
        this.position += 8;

        return result;
    }

    /*ulong?*/ readULongNullable() {
        return this.readBool() ? this.readULong() : null;
    }

    /*string*/ readStringByteLength() {
        const length = this.readByte();
        if (length == 255) return null;
        else {
            if (length > this.getAvailableLengthS()) throw `wStream.readStringByteLength(${length}) length error.`;

            const buffer = new Uint8Array(this.stream.subarray(this.position, this.position + length));
            this.position += length;

            return new TextDecoder().decode(buffer);
        }
    }

    /*string*/ readStringUShortLength() {
        const length = this.readUShort();
        if (length == 65535) return null;
        else {
            if (length > this.getAvailableLengthS()) throw `wStream.readStringUShortLength(${length}) length error.`;

            const buffer = new Uint8Array(this.stream.subarray(this.position, this.position + length));
            this.position += length;

            return new TextDecoder().decode(buffer);
        }
    }

    /*string*/ readStringIntLength() {
        const length = this.readInt();
        if (length == 2147483647) return null;
        else {
            if (length > this.getAvailableLengthS()) throw `wStream.readStringIntLength(${length}) length error.`;

            const buffer = new Uint8Array(this.stream.subarray(this.position, this.position + length));
            this.position += length;

            return new TextDecoder().decode(buffer);
        }
    }

    ////////////////////////////////////////////////////////////////
    // Write
    ////////////////////////////////////////////////////////////////
    /*wStream*/ writeBool(/*bool*/ b) {
        return this.writeBytes([b ? 1 : 0]);
    }

    /*wStream*/ writeBoolNullable(/*bool?*/ b) {
        return this.writeBytes([b == null ? 0x80 : b ? 1 : 0]);
    }

    /*wStream*/ writeByte(/*byte*/ b) {
        return this.writeBytes([+b & 0xFF]);
    }

    /*wStream*/ writeByteNullable(/*byte?*/ b) {
        if (b == null) this.writeBool(false);
        else {
            this.writeBool(true);
            this.writeByte(b);
        }

        return this;
    }

    /*wStream*/ writeBytes(/*byte[]*/ b, /*int*/ offset = 0, /*int*/ length = -1) {
        if (length <= -1) length = b.length - offset;

        const availableLength = this.getAvailableLengthS();
        if (availableLength < length) {
            const newSize = Math.ceil((length + this.position) / 1024) * 1024;
            const newStream = new Uint8Array(newSize);
            newStream.set(this.stream.subarray(0, this.position));
            this.stream = newStream;
        }

        this.stream.set(b.slice(offset, offset + length), this.position);
        this.position += length;

        return this;
    }

    /*wStream*/ writeBytesByteLength(/*byte[]*/ bytes, /*int*/ length = -1) {
        if (bytes == null) this.writeByte(255);
        else {
            if (length <= -1) length = bytes.length;
            if (length >= 255) throw `wStream.writeBytesByteLength(${length}) length error.`;
            this.writeByte(length);
            this.writeBytes(bytes.slice(0, length));
        }

        return this;
    }

    /*wStream*/ writeBytesUShortLength(/*byte[]*/ bytes, /*int*/ length = -1) {
        if (bytes == null) this.writeUShort(65535);
        else {
            if (length <= -1) length = bytes.length;
            if (length >= 65535) throw `wStream.writeBytesUShortLength(${length}) length error.`;
            this.writeUShort(length);
            this.writeBytes(bytes.slice(0, length));
        }

        return this;
    }

    /*wStream*/ writeBytesIntLength(/*byte[]*/ bytes, /*int*/ length = -1) {
        if (bytes == null) this.writeInt(2147483647);
        else {
            if (length <= -1) length = bytes.length;
            if (length >= 2147483647) throw `wStream.writeBytesIntLength(${length}) length error.`;
            this.writeInt(length);
            this.writeBytes(bytes.slice(0, length));
        }

        return this;
    }

    /*wStream*/ writeChar(/*char*/ c) {
        this.writeUShort(c);

        return this;
    }

    /*wStream*/ writeCharNullable(/*char?*/ c) {
        if (c == null) this.writeBool(false);
        else {
            this.writeBool(true);
            this.writeUShort(c);
        }

        return this;
    }

    /*wStream*/ writeDouble(/*double*/ d) {
        const buffer = new Uint8Array(8);
        const float = new Float64Array(buffer);
        float[0] = +d;

        return this.writeBytes(Array.from(buffer).reverse());
    }

    /*wStream*/ writeDoubleNullable(/*double?*/ d) {
        if (d == null) this.writeBool(false);
        else {
            this.writeBool(true);
            this.writeDouble(d);
        }

        return this;
    }

    /*wStream*/ writeFloat(/*float*/ f) {
        const buffer = new Uint8Array(4);
        const float = new Float32Array(buffer);
        float[0] = +f;

        return this.writeBytes(Array.from(buffer).reverse());
    }

    /*wStream*/ writeFloatNullable(/*float?*/ f) {
        if (f == null) this.writeBool(false);
        else {
            this.writeBool(true);
            this.writeFloat(f);
        }

        return this;
    }

    /*wStream*/ writeShort(/*short*/ s) {
        s = +s;
        return this.writeUShort(s >= 0 ? s : s + 0x10000);
    }

    /*wStream*/ writeShortNullable(/*short?*/ s) {
        if (s == null) this.writeBool(false);
        else {
            this.writeBool(true);
            this.writeShort(s);
        }

        return this;
    }

    /*wStream*/ writeUShort(/*ushort*/ s) {
        s = +s & 0xFFFF;
        return this.writeBytes([(s >> 8) & 0xFF, s & 0xFF]);
    }

    /*wStream*/ writeUShortNullable(/*ushort?*/ s) {
        if (s == null) this.writeBool(false);
        else {
            this.writeBool(true);
            this.writeUShort(s);
        }

        return this;
    }

    /*wStream*/ writeInt(/*int*/ i) {
        i = +i;
        return this.writeUInt(i >= 0 ? i : i + 0x100000000);
    }

    /*wStream*/ writeIntNullable(/*int?*/ i) {
        if (i == null) this.writeBool(false);
        else {
            this.writeBool(true);
            this.writeInt(i);
        }

        return this;
    }

    /*wStream*/ writeUInt(/*uint*/ i) {
        i = +i & 0xFFFFFFFF;
        return this.writeBytes([(i >> 24) & 0xFF, (i >> 16) & 0xFF, (i >> 8) & 0xFF, i & 0xFF]);
    }

    /*wStream*/ writeUIntNullable(/*uint?*/ i) {
        if (i == null) this.writeBool(false);
        else {
            this.writeBool(true);
            this.writeUInt(i);
        }

        return this;
    }

    /*wStream*/ writeLong(/*long*/ l) {
        return this.writeUInt(l >= 0 ? l : l + 0x10000000000000000);
    }

    /*wStream*/ writeLongNullable(/*long?*/ l) {
        if (l == null) this.writeBool(false);
        else {
            this.writeBool(true);
            this.writeLong(l);
        }

        return this;
    }

    /*wStream*/ writeULong(/*ulong*/ l) {
        l = +l & 0xFFFFFFFFFFFFFFFF;
        return this.writeBytes([(l >> 56) & 0xFF, (l >> 48) & 0xFF, (l >> 40) & 0xFF, (l >> 32) & 0xFF, (l >> 24) & 0xFF, (l >> 16) & 0xFF, (l >> 8) & 0xFF, l & 0xFF]);
    }

    /*wStream*/ writeULongNullable(/*ulong?*/ l) {
        if (l == null) this.writeBool(false);
        else {
            this.writeBool(true);
            this.writeULong(l);
        }

        return this;
    }

    /*wStream*/ writeStringByteLength(/*string*/ s) {
        if (s == null) this.writeByte(255);
        else {
            const b = new TextEncoder().encode(s);
            if (b.length >= 255) throw `wStream.writeStringByteLength(${b.length}) length error.`;
            this.writeByte(b.length);
            this.writeBytes(b);
        }

        return this;
    }

    /*wStream*/ writeStringUShortLength(/*string*/ s) {
        if (s == null) this.writeUShort(65535);
        else {
            const b = new TextEncoder().encode(s);
            if (b.length >= 65535) throw `wStream.writeStringUShortLength(${b.length}) length error.`;
            this.writeUShort(b.length);
            this.writeBytes(b);
        }

        return this;
    }

    /*wStream*/ writeStringIntLength(/*string*/ s) {
        if (s == null) this.writeInt(2147483647);
        else {
            const b = new TextEncoder().encode(s);
            if (b.length >= 2147483647) throw `wStream.writeStringIntLength(${b.length}) length error.`;
            this.writeInt(b.length);
            this.writeBytes(b);
        }

        return this;
    }
}

////////////////////////////////////////////////////////////////
// wPacket
////////////////////////////////////////////////////////////////
class wPacket extends wStream {
    static /*int*/ #headerSize = 6; // version + id + length

    /*byte[]*/ sign;
    /*byte*/ version;
    /*byte*/ id;
    /*int*/ length;

    constructor(/*byte[]*/ sign, /*byte*/ version, /*byte*/ id) {
        super();
        if (sign != null) {
            super.writeBytes(sign);
            super.writeByte(version);
            super.writeByte(id);
            super.writeInt(-1);

            this.sign = sign;
            this.version = version;
            this.id = id;
        }
    }

    #_constructor(/*byte[]*/ bytes, /*int*/ index, /*int*/ length) {
        super.set(bytes, index, length);
    }

    static /*wPacket*/ createFromBytes(/*byte[]*/ sign, /*byte[]*/ bytes, /*int*/ index = 0, /*int*/ length = -1) {
        if (length == -1) length = bytes.length - index;
        if (length <= 0 || length < sign.length + wPacket.#headerSize) return null;
        for (let i = 0; i < sign.length; i++) if (bytes[index + i] != sign[i]) throw "Packet sign is not correct.";

        const buffer = bytes.slice(index + sign.length + 2, index + sign.length + 2 + 4);
        const packetLength = (buffer[0] << 24) | (buffer[1] << 16) | (buffer[2] << 8) | buffer[3];
        if (length < packetLength) return null;

        const packet = new wPacket();
        packet.#_constructor(bytes, index, packetLength);
        packet.sign = packet.readBytes(sign.length);
        packet.version = packet.readByte();
        packet.id = packet.readByte();
        packet.length = packet.readInt();

        return packet;
    }

    /*byte[]*/ getBytes() {
        const bytes = super.getBytes();
        const offset = this.sign.length + wPacket.#headerSize - 4;
        bytes[offset + 0] = (bytes.length >> 24) & 0xFF;
        bytes[offset + 1] = (bytes.length >> 16) & 0xFF;
        bytes[offset + 2] = (bytes.length >> 8) & 0xFF;
        bytes[offset + 3] = bytes.length & 0xFF;

        return bytes;
    }

    /*long*/ getAvailableLengthP() {
        return this.length - super.position;
    }
}

////////////////////////////////////////////////////////////////
// wWebSocket
////////////////////////////////////////////////////////////////
class wWebSocket {
    /*WebSocket*/ #webSocket = null;

    /*void*/ close(/*bool*/ wait = true) {
        this.#webSocket?.close();
        this.#webSocket = null;
    }

    /*void*/ beginConnect(/*string*/ url) {
        if (this.#webSocket != null) return;
        const webSocket = new WebSocket(url);
        webSocket.binaryType = "arraybuffer";
        webSocket.onopen = (event) => { try { this.onConnected(); } catch { this.close(); } };
        webSocket.onclose = (event) => { try { this.onClosed(); } catch { } };
        webSocket.onmessage = (event) => { try { if (event.data instanceof ArrayBuffer) this.onReceived(new Uint8Array(event.data)); } catch { this.close(); } };
        webSocket.onerror = (event) => { try { this.onClosed(); } catch { } };
        this.#webSocket = webSocket;
    }

    /*void*/ beginSend(/*byte[]*/ buffer) { this.#webSocket?.send(buffer); }

    /*void*/ onClosed() { }

    /*void*/ onConnected() { }

    /*void*/ onReceived() { }
}

////////////////////////////////////////////////////////////////
// wVirtualNetwork
////////////////////////////////////////////////////////////////
class wVirtualNetworkFeature {
    static None = 0x00;
    static MainRouter = 0x01;
    static SubRouter = 0x02;
}

class wVirtualNetworkId {
    /*string*/ id = null;

    constructor(/*string*/ id) {
        if (arguments[1] !== true) throw "wVirtualNetworkId.constructor() is private!";
        this.id = id;
    }

    static /*wVirtualNetworkId?*/ create(/*string?*/ id) {
        return id == null || id.length == 0 || id.length > 127 ? null : new wVirtualNetworkId(id, true);
    }

    static /*wVirtualNetworkId*/ generate() {
        let id = "";
        id += Math.floor(Math.random() * 0x100000000).toString(16).toUpperCase().padStart(8, "X");
        id += Math.floor(Math.random() * 0x100000000).toString(16).toUpperCase().padStart(8, "X");

        return this.create(id);
    }

    ////////////////////////////////////////////////////////////////
    // .etc
    ////////////////////////////////////////////////////////////////
    /*bool*/ isSet() {
        return this.id != null;
    }

    toString() {
        return this.id;
    }
}

class wVirtualNetworkIdPair {
    /*wVirtualNetworkId?*/ groupId;
    /*wVirtualNetworkId?*/ socketId;

    constructor(/*wVirtualNetworkId?*/ groupId = null, /*wVirtualNetworkId?*/ socketId = null) {
        if (groupId !== null && !(groupId instanceof wVirtualNetworkId)) throw "wVirtualNetworkIdPair.constructor() groupId type is not wVirtualNetworkId!";
        if (socketId !== null && !(socketId instanceof wVirtualNetworkId)) throw "wVirtualNetworkIdPair.constructor() socketId type is not wVirtualNetworkId!";
        this.groupId = groupId;
        this.socketId = socketId;
    }

    ////////////////////////////////////////////////////////////////
    // .etc
    ////////////////////////////////////////////////////////////////
    /*bool*/ isSet() {
        return this.groupId != null && this.socketId != null;
    }

    toString() {
        return `[${this.groupId?.toString()}] ${this.socketId?.toString()}`;
    }
}

class wVirtualNetworkPacket {
    static /*byte[]*/ createIdPairNotify(/*byte[]*/ packetSign, /*wVirtualNetworkIdPair*/ idPair, /*wVirtualNetworkFeature*/ feature, /*bool*/ isLocal, /*wVirtualNetworkIdPair?*/ yourIdPair) {
        const sendPacket = new wPacket(packetSign, 1, wVirtualNetworkPacketId.IdPairNotify);
        sendPacket.writeStringByteLength(idPair.groupId.id);
        sendPacket.writeStringByteLength(idPair.socketId.id);
        sendPacket.writeByte(feature);
        sendPacket.writeBool(isLocal);
        sendPacket.writeStringByteLength(yourIdPair?.groupId?.id);
        sendPacket.writeStringByteLength(yourIdPair?.socketId?.id);
        return sendPacket.getBytes();
    }

    static /*byte[]*/ createIdPairRequest(/*byte[]*/ packetSign, /*bool*/ isLocal, /*wVirtualNetworkId[]*/ mySocketIds) {
        const sendPacket = new wPacket(packetSign, 1, wVirtualNetworkPacketId.IdPairRequest);
        sendPacket.writeBool(isLocal);
        sendPacket.writeInt(mySocketIds.Length);
        for (const mySocketId of mySocketIds) sendPacket.writeStringByteLength(mySocketId.id);
        return sendPacket.getBytes();
    }

    static /*byte[]*/ createNop(/*byte[]*/ packetSign) {
        const sendPacket = new wPacket(packetSign, 1, wVirtualNetworkPacketId.Nop);
        return sendPacket.getBytes();
    }

    static /*byte[]*/ createPayload(/*byte[]*/ packetSign, /*wVirtualNetworkIdPair*/ srcIdPair, /*wVirtualNetworkId*/ dstGroupId, /*List<wVirtualNetworkId>?*/ dstSocketIds, /*byte*/ ttl, /*byte[]?*/ payload, /*bool*/ isTcpProxy = false, /*ushort*/ srcTcpPort = 0, /*ushort*/ dstTcpPort = 0, /*uint*/ randomKey = 0) {
        const sendPacket = new wPacket(packetSign, 1, wVirtualNetworkPacketId.Payload);
        sendPacket.writeStringByteLength(srcIdPair.groupId.id);
        sendPacket.writeStringByteLength(srcIdPair.socketId.id);
        sendPacket.writeStringByteLength(dstGroupId.id);
        if (dstSocketIds == null) sendPacket.writeInt(-1);
        else {
            sendPacket.writeInt(dstSocketIds.length);
            for (const dstSocketId of dstSocketIds) sendPacket.writeStringByteLength(dstSocketId.id);
        }
        sendPacket.writeByte(ttl);
        sendPacket.writeBytesIntLength(payload);
        if (isTcpProxy) {
            sendPacket.writeBool(true);
            sendPacket.writeUShort(srcTcpPort);
            sendPacket.writeUShort(dstTcpPort);
            sendPacket.writeUInt(randomKey);
        }
        else sendPacket.writeBool(false);
        return sendPacket.getBytes();
    }

    // TODO: 테스트
    static /*byte[]*/ createSocketCloseEventSet(/*byte[]*/ packetSign, /*List<wVirtualNetworkIdPair>*/ idPairs) {
        const sendPacket = new wPacket(packetSign, 1, wVirtualNetworkPacketId.SocketCloseEventSet);
        if (idPairs == null) sendPacket.writeInt(0);
        else {
            sendPacket.writeInt(idPairs.length);
            for (const idPair of idPairs) {
                sendPacket.writeStringByteLength(idPair.groupId.id);
                sendPacket.writeStringByteLength(idPair.socketId.id);
            }
        }
        return sendPacket.getBytes();
    }

    static /*wVirtualNetworkPayload?*/ parsePayload(/*wPacket*/ packet, /*wVirtualNetworkFeature*/ srcFeature) {
        const srcGroupId = wVirtualNetworkId.create(packet.readStringByteLength());
        const srcSocketId = wVirtualNetworkId.create(packet.readStringByteLength());
        const srcIdPair = new wVirtualNetworkIdPair(srcGroupId, srcSocketId);
        if (!srcIdPair.isSet()) return null;
        const dstGroupId = wVirtualNetworkId.create(packet.readStringByteLength());
        if (dstGroupId?.isSet() !== true) return null;
        const dstSocketIdCount = packet.readInt();
        /*List<wVirtualNetworkId>*/ let dstSocketIds;
        if (dstSocketIdCount < 0) dstSocketIds = null;
        else {
            dstSocketIds = [];
            for (let i = 0; i < dstSocketIdCount; i++) {
                var dstSocketId = wVirtualNetworkId.create(packet.readStringByteLength());
                if (dstSocketId != null) dstSocketIds.push(dstSocketId);
            }
            if (dstSocketIds.length == 0) return null;
        }
        const ttl = packet.readByte();
        const payload = packet.readBytesIntLength();
        const isTcpProxy = packet.readBool();
        const /*ushort*/ srcTcpPort = 0;
        const /*ushort*/ dstTcpPort = 0;
        const /*uint*/ randomKey = 0;
        if (isTcpProxy) {
            srcTcpPort = packet.readUShort();
            dstTcpPort = packet.readUShort();
            randomKey = packet.readUInt();
        }

        const vnPayload = new wVirtualNetworkPayload(srcIdPair, srcFeature, dstGroupId);
        vnPayload.dstSocketIds = dstSocketIds;
        vnPayload.ttl = ttl;
        vnPayload.payload = payload;
        vnPayload.isTcpProxy = isTcpProxy;
        vnPayload.srcTcpPort = srcTcpPort;
        vnPayload.dstTcpPort = dstTcpPort;
        vnPayload.randomKey = randomKey;

        return vnPayload;
    }
}

class wVirtualNetworkPacketId {
    static Nop = 0x00;
    static IdPairNotify = 0x01;
    static IdPairRequest = 0x02;
    static IsMe = 0x03;
    static OpenedIpEndPoint = 0x04;
    static Payload = 0x05;
    static PeerRequest = 0x06;
    static PeerResponse = 0x07;
    static SharedData = 0x08;
    static SocketCloseEventSet = 0x09;
    static SocketCloseEventTriggered = 0x0A;
}

class wVirtualNetworkPayload {
    /*wVirtualNetworkIdPair*/ srcIdPair;
    /*wVirtualNetworkFeature*/ srcFeature;
    /*wVirtualNetworkId*/ dstGroupId;
    /*List<wVirtualNetworkId>?*/ dstSocketIds;
    /*byte*/ ttl;
    /*byte[]*/ payload;
    /*bool*/ isTcpProxy;
    /*ushort*/ srcTcpPort;
    /*ushort*/ dstTcpPort;
    /*uint*/ randomKey;

    constructor(/*wVirtualNetworkIdPair*/ srcIdPair, /*wVirtualNetworkFeature*/ srcFeature, /*wVirtualNetworkId*/ dstGroupId) {
        this.srcIdPair = srcIdPair;
        this.srcFeature = srcFeature;
        this.dstGroupId = dstGroupId;
    }
}

class wVirtualNetworkNode {
    /*wDomainSet*/ #domainSet;
    /*int*/ #domainSetNext;
    /*wVirtualNetworkFeature*/ feature;
    /*wVirtualNetworkIdPair*/ idPair;
    #intervalHandle = 0;
    /*int*/ #lastConnectToMainRoutersTime;
    /*Action<string>*/ #logger;
    /*byte[][]*/ #macs;
    /*wVirtualNetworkId*/ #mainRouterGroupId;
    /*IPEndPoint*/ openedIpEndPoint;
    /*byte[]*/ packetSign;
    /*int*/ timer;
    /*string*/ #webPath;
    /*ushort*/ #webPort;
    /*string*/ #webProtocol;
    /*WebSocket*/ webSocket;

    constructor(/*byte[]*/ packetSign, /*string*/ mainRouterGroupId, /*string*/ webProtocol, /*string[]*/ domains, /*ushort*/ webPort, /*string*/ webPath, /*Action<string>?*/ logger) {
        if (packetSign.length == 0 || !mainRouterGroupId || domains.length == 0) throw "wVirtualNetworkNode.constructor() arguments!";

        this.#domainSet = wCommon.shuffle(domains.map(i => { const index = i.indexOf(":"); return index == -1 ? i : i.substr(0, index); }));
        this.#domainSetNext = 0;
        this.feature = wVirtualNetworkFeature.None;
        this.#logger = logger ?? (str => console.log(str));
        this.#macs = [];
        if ((this.#mainRouterGroupId = wVirtualNetworkId.create(mainRouterGroupId)) == null) throw "wVirtualNetworkNode.constructor() arguments!";
        this.packetSign = packetSign;
        this.#webPath = webPath;
        this.#webProtocol = webProtocol;
        this.#webPort = webPort;
    }

    ////////////////////////////////////////////////////////////////
    // Thread
    ////////////////////////////////////////////////////////////////
    /*void*/ start(/*string?*/ groupId, /*string?*/ socketId) {
        this.idPair = new wVirtualNetworkIdPair(wVirtualNetworkId.create(groupId), wVirtualNetworkId.create(socketId));
        if (!this.idPair.isSet) throw "wVirtualNetworkNode.start() arguments!";
        this.#lastConnectToMainRoutersTime = 0;
        this.openedIpEndPoint = null;
        this.webSocket = null;

        this.log("[VN] Node is started!");

        this.#intervalHandle = setInterval(() => this.#run(), 1000);
    }

    /*void*/ stop() {
        clearInterval(this.#intervalHandle);
        this.#end();
    }

    /*void*/ #run() {
        this.timer = wCommon.getTimerMs();

        // 메인 라우터에 연결
        this.#connectToMainRouters();

        // NOP 보내기
        this.webSocket?.sendNop();
    }

    /*void*/ #end() {
        this.webSocket?.close(false);
        //while (webSocket != null) Thread.Sleep(1);

        this.log("[VN] Node is stopped!");
    }

    ////////////////////////////////////////////////////////////////
    // Events
    ////////////////////////////////////////////////////////////////
    /*void*/ onReceivedPayload(/*wVirtualNetworkPayload*/ payload) { }

    ////////////////////////////////////////////////////////////////
    // Methods
    ////////////////////////////////////////////////////////////////
    log(message) {
        this.#logger.call(this.#logger, message);
    }

    isConnectedToMainRouter() {
        const webSocket = this.webSocket;
        return webSocket != null && webSocket.isConnected && webSocket.feature == wVirtualNetworkFeature.MainRouter && webSocket.idPair.isSet();
    }

    // Payload 보내기
    /*bool*/ sendPayloadToMany(/*wVirtualNetworkId*/ groupId, /*wVirtualNetworkId*/ socketIds, /*byte[]?*/ payload) { return this.webSocket?.sendPayload(this.idPair, groupId, socketIds, 5, payload); }

    // Payload 보내기
    /*bool*/ sendPayloadToOne(/*wVirtualNetworkId*/ groupId, /*wVirtualNetworkId*/ socketId, /*byte[]?*/ payload) { return this.webSocket?.sendPayload(this.idPair, groupId, [socketId], 5, payload); }

    // Payload 보내기 (나와 연결된 메인 라우터 하나에게만 보내기)
    /*bool*/ sendPayloadToMyOneMainRouter(/*byte[]?*/ payload) { return this.webSocket?.sendPayload(this.idPair, this.webSocket.idPair.groupId, [this.webSocket.idPair.socketId], 1, payload) == true; }

    // Payload 보내기 (특정 Group 내의 서브 라우터들 모두에게 브로드캐스트)
    /*bool*/ sendPayloadToOtherSubRouters(/*wVirtualNetworkId*/ groupId, /*byte[]?*/ payload) { return this.webSocket?.sendPayload(this.idPair, groupId, null, 4, payload); }

    ////////////////////////////////////////////////////////////////
    // .etc
    ////////////////////////////////////////////////////////////////
    // 메인 라우터에 연결
    /*void*/ #connectToMainRouters() {
        if (this.webSocket != null) return;
        if (!this.idPair.isSet()) return;
        if (this.#lastConnectToMainRoutersTime != 0 && this.timer - this.#lastConnectToMainRoutersTime < 1000) return;
        this.#lastConnectToMainRoutersTime = this.timer;

        this.webSocket = new wVirtualNetworkWebSocket();
        this.webSocket.domain = this.#domainSet[this.#domainSetNext = (this.#domainSetNext + 1) % this.#domainSet.length];
        this.webSocket.feature = wVirtualNetworkFeature.MainRouter;
        this.webSocket.idPair = new wVirtualNetworkIdPair(wVirtualNetworkId.create(this.#mainRouterGroupId), wVirtualNetworkId.create(this.webSocket.domain));
        this.webSocket.isConnected = false;
        this.webSocket.isIdPairNotifyReceived = false;
        this.webSocket.isIdPairNotifySent = false;
        this.webSocket.lastSendTime = this.timer;
        this.webSocket.vnn = this;
        this.webSocket.beginConnect(`${this.#webProtocol}://${this.webSocket.domain}:${this.#webPort}${this.#webPath ?? "/"}`);
        this.log(`[VN] Connecting... ${this.webSocket}`);
    }
}

class wVirtualNetworkWebSocket extends wWebSocket {
    /*string*/ domain;
    /*wVirtualNetworkFeature*/ feature;
    /*wVirtualNetworkIdPair*/ idPair;
    /*bool*/ isConnected;
    /*bool*/ isIdPairNotifyReceived;
    /*bool*/ isIdPairNotifySent;
    /*bool*/ isLocal;
    /*int*/ lastSendTime;
    /*wVirtualNetworkNode*/ vnn;

    /*void*/ onClosed() {
        if (this.vnn.webSocket === this) {
            this.vnn.webSocket = null;

            this.vnn.log(`[VN] Disconnected. ${this}`);
        }
    }

    /*void*/ onConnected() {
        // IdPair 보내기
        this.#sendIdPairNotify();

        this.isConnected = true;

        this.vnn.log(`[VN] Connected. ${this}`);
    }

    /*void*/ onReceived(recvBytes) {
        const recvPacket = wPacket.createFromBytes(this.vnn.packetSign, recvBytes);
        if (recvPacket == null) return;

        switch (recvPacket.id) {
            case wVirtualNetworkPacketId.Nop: // NOP
                break;
            case wVirtualNetworkPacketId.OpenedIpEndPoint: // 나의 외부망 IP & Port 받기
                {
                    const openedIp = recvPacket.readUInt();
                    const openedPort = recvPacket.readUShort();
                    this.vnn.openedIpEndPoint = `${wCommon.ipUIntToIpAddress(openedIp)}:${openedPort}`;
                    this.vnn.log(`[VN] OpenedIpEndPoint is ${this.vnn.openedIpEndPoint}`);
                }
                break;
            case wVirtualNetworkPacketId.IdPairNotify: // IdPair 받기
                {
                    if (this.isIdPairNotifyReceived || !this.vnn.idPair.isSet()) {
                        this.close(false);
                        break;
                    }
                    else this.isIdPairNotifyReceived = true;

                    var groupId = wVirtualNetworkId.create(recvPacket.readStringByteLength());
                    var socketId = wVirtualNetworkId.create(recvPacket.readStringByteLength());
                    this.feature = recvPacket.readByte();
                    var isLocal = recvPacket.readBool();
                    var yourGroupId = wVirtualNetworkId.create(recvPacket.readStringByteLength());
                    var yourSocketId = wVirtualNetworkId.create(recvPacket.readStringByteLength());
                    var yourIdPair = new wVirtualNetworkIdPair(yourGroupId, yourSocketId);
                    if (groupId == null || this.idPair.groupId != null && this.idPair.groupId.id != groupId.id || // 내가 연결하고자 한 상대방이 맞는지 확인
                        socketId == null || this.idPair.socketId != null && this.idPair.socketId.id != socketId.id || // 내가 연결하고자 한 상대방이 맞는지 확인
                        groupId == this.vnn.idPair.groupId && socketId.id == this.vnn.idPair.socketId.id) // 나 자신에게 연결한 것은 아닌지
                    {
                        this.close(false);
                        break;
                    }
                    this.idPair.groupId = groupId;
                    this.idPair.socketId = socketId;
                    if (isLocal) this.isLocal = true;

                    // IdPair 보내기
                    if (!this.isIdPairNotifySent) this.#sendIdPairNotify();
                }
                break;
            case wVirtualNetworkPacketId.Payload: // Payload 받기
                {
                    if (!this.isIdPairNotifyReceived || !this.idPair.isSet()) {
                        this.close(false);
                        break;
                    }

                    const payload = wVirtualNetworkPacket.parsePayload(recvPacket, this.feature);
                    if (payload == null) break;

                    //this.vnn.log(`[VN] Payload(${payload.payload?.length} B) ${payload.srcIdPair} -> [${payload.dstGroupId}] ${payload.dstSocketIds.map(i => i.id).join(", ")}`);

                    if (payload.dstGroupId.id == this.vnn.idPair.groupId.id) { // 나와 같은 그룹
                        if (payload.dstSocketIds != null) {
                            for (const dstSocketId of payload.dstSocketIds) {
                                if (dstSocketId.id == this.vnn.idPair.socketId.id) {
                                    if (!payload.isTcpProxy) {
                                        // 나에게 온 Payload
                                        try { this.vnn.onReceivedPayload(payload); } catch { }
                                    }
                                    else {
                                        // 나에게 온 TcpProxy
                                    }
                                }
                            }
                        }
                    }
                }
                break;
        }
    }

    // IdPair 보내기
    /*void*/ #sendIdPairNotify() {
        this.beginSend(wVirtualNetworkPacket.createIdPairNotify(this.vnn.packetSign, this.vnn.idPair, this.vnn.feature, false));
        this.isIdPairNotifySent = true;
        this.lastSendTime = this.vnn.timer;
    }

    // NOP 보내기
    /*void*/ sendNop() {
        if (!this.isConnected) return false;
        if (this.lastSendTime != 0 && this.vnn.timer - this.lastSendTime < 30000) return;
        this.beginSend(wVirtualNetworkPacket.createNop(this.vnn.packetSign));
        this.lastSendTime = this.vnn.timer;
    }

    // Payload 보내기
    /*bool*/ sendPayload(/*wVirtualNetworkIdPair*/ srcIdPair, /*wVirtualNetworkId*/ dstGroupId, /*List<wVirtualNetworkId>?*/ dstSocketIds, /*byte*/ ttl, /*byte[]?*/ payload, /*bool*/ isTcpProxy = false, /*ushort*/ srcTcpPort = 0, /*ushort*/ dstTcpPort = 0, /*uint*/ randomKey = 0) {
        if (!this.isConnected) return false;
        this.beginSend(wVirtualNetworkPacket.createPayload(this.vnn.packetSign, srcIdPair, dstGroupId, dstSocketIds, ttl, payload, isTcpProxy, srcTcpPort, dstTcpPort, randomKey));
        this.lastSendTime = this.vnn.timer;
        return true;
    }

    toString() {
        return this.idPair.toString();
    }
}

export {wVirtualNetworkNode, wPacket, wVirtualNetworkId, wStream};
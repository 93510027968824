export default function NaverButton(props : {className? : string, onClick? : () => void}){

    return <button type="button" className={`btn ${props.className || ""}`} onClick={() => props.onClick && props.onClick()} style={{background : "#03C75A"}}>
    <div className="d-flex justify-content-center align-items-center">
        <div className="me-2">
<img src="/img/naverIcon.png" className="pb-1"  style={{width : "1.1rem", marginLeft : "0.15rem"}}></img>
</div>
<div className="fw-bold" style={{color : "white"}}>네이버 로그인</div>
</div>
</button>
}
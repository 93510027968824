import { ReactElement, useContext, useEffect, useState } from "react";
import Authorization from "../utils/Authorization";
import { claimCheck } from "./Accounts.Models";
import AuthenticationContext from "./AuthenticationContext";

function Authorized(props: authorizedProps) {
    const [isAuthorized, setIsAuthorized] = useState(true);
    const { claims, update } = useContext(AuthenticationContext);

    useEffect(() => {
        if (props.role || props.claims) {
            let authorized = false;
            if (props.role) {
                authorized = Authorization.hasOneOfRoles(claims, props.role);
            }
            if(!authorized && props.claims){
                for (let i = 0; i < props.claims.length; i++) {
                    if (Authorization.hasClaim(claims, props.claims[i].name, props.claims[i].value)) {
                        authorized = true;
                        break;
                    }
                }
            }
            setIsAuthorized(authorized);
        } else {
            setIsAuthorized(Authorization.isAuthorized(claims));
        }
    }, [claims, props.role]);

    return (
        <>
            {isAuthorized ? props.authorized : props.notAuthorized}
        </>
    )
}

interface authorizedProps {
    authorized: ReactElement;
    notAuthorized?: ReactElement;
    role?: string[];
    claims?: claimCheck[];
}



export default Authorized;
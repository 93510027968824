import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../AppContext";
import ComponentToLoad from "../components/ComponentToLoad";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton"
import ScheduleInfoComponent from "./ScheduleInfoComponent";
import RouteConfig from "../route-config";
import Dialog from "../utils/Dialog"
import HttpRequest from "../utils/HttpRequest";
import { ScheduleTime } from "./Component.Models";

export default function ScheduleListPage(props : {clientId: string, title:string, scheduleRegisterPageURL : string,  getScheduleListURL : string, addScheduleURL : string, deleteScheduleURL : string, updateScheduleOnURL : string}) {
    const { selectedStoreId, socketManager } = useContext(AppContext);
    const navigate = useNavigate();
    const [schedules, setSchedules] = useState<ScheduleTime[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        updateScheduleList();
    }, [selectedStoreId]);


    async function updateScheduleList() {
        if(!selectedStoreId) return;
        setIsLoading(true);
        setErrors([]);
        try {

            const scheduleList = await HttpRequest.get<ScheduleTime[]>(`${props.getScheduleListURL}/${selectedStoreId}`, {params : {clientId : props.clientId}});
            setSchedules(scheduleList);
        }
        catch (error) {
            console.log(error);
            if(schedules)
              setSchedules(undefined);
            if (error?.response?.data) {
                setErrors(error.response.data);
            }
            else {
                setErrors(["서버연결에 실패했습니다."])
            }
        }
        setIsLoading(false);
    }

    function onDelete(scheduleId: string, setLoading : (value : boolean) => void) {
        Dialog.showDialog("해당 스케줄을 삭제하시겠습니까?", async (result)=> {
            if(result){
                setLoading(true);
                try {
                    if(!selectedStoreId) {
                        Dialog.showError("선택된 매장정보가 없습니다.")
                        return;
                    }
                    await HttpRequest.delete(props.deleteScheduleURL, {params : {storeId : selectedStoreId, clientId : props.clientId,  id : scheduleId}});
                    Dialog.showConfirmation("삭제가 완료되었습니다.");
                    socketManager?.sendStoreUpdate(selectedStoreId);
                    updateScheduleList();
                }
                catch(error) {
                    let msg = "에러가 발생했습니다.\n"
                    if(error?.response?.data){
                        msg += error.response.data;
                    }
                    Dialog.showError(msg);
                }
                setLoading(false);
            }
        });
    }

    async function onSwitch(scheduleId: string, isOn: boolean, setLoading: (value: boolean) => void) {
        setLoading(true);
        try {
            if (!selectedStoreId) {
                Dialog.showError("선택된 매장정보가 없습니다.")
                return;
            }
            await HttpRequest.put(`${props.updateScheduleOnURL}/${selectedStoreId}/${props.clientId}`, { _id: scheduleId, isOn : isOn });
            updateScheduleList();
        }
        catch (error) {
            let msg = "에러가 발생했습니다.\n"
            if (error?.response?.data) {
                msg += error.response.data;
            }
            Dialog.showError(msg);
        }
        setLoading(false);
    }

    return <div>
        <h3 className="mb-3">{props.title}</h3>
        <div className="mt-3">
            {selectedStoreId ?
                <>
                    <OkButton className="btn-sm mb-2" onClick={() => {
                        navigate(`${props.scheduleRegisterPageURL}/${props.clientId}`);
                    }}>새 스케줄 등록</OkButton>

                    <ComponentToLoad className="mt-2" isLoading={isLoading}>
                        {schedules ?
                            <div>
                                <div>
                                    총 {schedules.length}개 스케줄이 등록되어 있습니다.
                                </div>
                                <div className="bg-gainsboro rounded p-2 d-flex flex-wrap">
                                    {schedules.map(s =>
                                      <ScheduleInfoComponent key={s._id} clientId={props.clientId} scheduleRegisterPageURL={props.scheduleRegisterPageURL} schedule={s} onDelete={onDelete} onSwitch={onSwitch} />
                                    )}
                                </div>
                            </div>
                            :
                            <DisplayErrors errors={errors} />}
                    </ComponentToLoad>
                </> :
                <div>
                    선택된 매장이 없습니다. 매장을 선택해주세요.
                </div>
            }
        </div>
    </div>
}

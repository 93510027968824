class StringValidation{
    static empty(value : string): boolean {
        if(!value) return true;
        let trimed = value.trim();
        if(!trimed) return true;
        return false;
    }

    static allNumber(value : string) : boolean {
        return /^[\d]*$/.test(value);
    }

    static maxLength(value : string, len : number) : boolean {
        return value.length <= len;
    }

    static validIpAddress(value : string | undefined) : boolean {
        if(!value) return true;
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value))
          return true;
        return false;
    }

}

export default StringValidation
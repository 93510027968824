function HeaderBar(props : HeaderBarProps){
return <div className={`p-3 mb-3 fw-bold bg-gainsboro border-top border-bottom ${props.className || ""}`}>
{props.display}
</div>;

}

export default HeaderBar;

interface HeaderBarProps {
    display : string,
    className?: string
}
import { useContext, useEffect, useMemo, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import OkButton from "../components/OkButton";
import SubButton from "../components/SubButton";
import AppContext from "../AppContext";
import Dialog from "../utils/Dialog";
import ComponentToLoad from "../components/ComponentToLoad";
import { useNavigate } from "react-router-dom";
import RouteConfig from "../route-config";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import ServerAPI from "../ServerAPI";
import HttpRequest from "../utils/HttpRequest";

function StoreSelectionHeader() {
    const {stores, updateStores, selectedStoreId, updateSelectedStoreId, defaultStoreId, updateDefaultStoreId} = useContext(AppContext);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIsRefreshing(true);
        updateStores(() => {
            setIsRefreshing(false);
        }, msg => {
            setIsRefreshing(false);
        });
    }, []);

    function defaultSelectedStoreChecked() {
        if(defaultStoreId)
            return defaultStoreId === selectedStoreId;
        return false;
    }

    async function onChangeDefaultSelectedStore(checked: boolean) {
        try {
            let defaultStoreId = checked ? selectedStoreId : "";
            await HttpRequest.put(ServerAPI.accountDefaultStoreSet, "", {params : {defaultStoreId : defaultStoreId}});
            updateDefaultStoreId(defaultStoreId);
        }
        catch (error) {
            let message = "기본선택매장설정에 실패했습니다.";
            if (error?.response?.data) {
                message = `${message}\n\n${error?.response?.data}`;
            }
            else {
                message = `${message}\n\n서버연결에 실패했습니다.`;
            }
            Dialog.showError(message);
        }
    }

    return <div className="row g-0">
        <div className="col-lg-8">
            <select disabled={isRefreshing} value={selectedStoreId} className="form-select mb-2" onChange={(e) => updateSelectedStoreId(e.currentTarget.value)}>
                <option value="">매장을 선택해주세요.</option>
                {
                    stores.map(store => 
                      <option key={store._id} value={store._id}>{store.name}</option>  
                    )
                }
            </select>
            <Typeahead className="mb-2" disabled={isRefreshing}
    id="storeTypeAhead"
        options={stores}
        onChange={stores => {
            if(stores.length > 0) {
              updateSelectedStoreId(stores[0]._id);
            }
        }}
        caseSensitive={false}
        placeholder="선택할 매장을 검색해주세요."
        labelKey={_ => ""}
        emptyLabel={
            <div className="text-danger">
                일치하는 매장이 없습니다.
            </div>
        }
        minLength={1}
        filterBy={['name']}
        renderMenuItemChildren={option => (
            <>
                <div className="fw-bold">{
                    option.name}</div>
                <div className="overflow-auto">{
                    option.addressFull}</div>
            </>
        )}
    >
    </Typeahead>
    <div className="d-flex align-items-center mb-2">
    <OkButton className="btn-sm me-2" onClick={() => navigate(RouteConfig.storeAddPath)}>매장등록</OkButton>
    <OkButton disabled={isRefreshing} className="btn-sm" onClick={async () => {
        setIsRefreshing(true);
        updateStores(() => {
            Dialog.showConfirmation("매장 목록 갱신이 완료되었습니다.");
            setIsRefreshing(false);
        }, msg => {
            Dialog.showError(msg);
            setIsRefreshing(false);
        });

    }}>매장목록 갱신</OkButton>
    <ComponentToLoad className="mx-2" smallIcon={true} isLoading={isRefreshing}>
        <></>
    </ComponentToLoad>
    {selectedStoreId &&
    <div className="form-check">
        <input className="form-check-input" checked={defaultSelectedStoreChecked()} type="checkbox" id="defaultStore"  
           onChange={e => {
              onChangeDefaultSelectedStore(e.currentTarget.checked);
           }}
        />
        <label htmlFor="defaultStore">기본선택매장</label>
    </div>
     }
    </div>
        </div>
  
    </div>
}

export default StoreSelectionHeader;
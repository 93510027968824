import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CancelButton from "../components/CancelButton";
import OkButton from "../components/OkButton";
import { app } from "../utils/AppCommnunication";

export default function VideoCallPage() {
    const [storeName, setStoreName] = useState<string|null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const storeIdRef = useRef<string|null>(null);
    const clientIdRef = useRef<string | null>(null);

    useEffect(() => {
        getInfoFromURL();
        const handler = app.addListener(onAppMessage);
        window.addEventListener("beforeunload", onBeforeUnload);
        return () => {
            app.removeListener(handler);
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, []);

    function onBeforeUnload(event : any) {
        alert("aaa");
        event.preventDevalut();
        event.returnValue = '';
    }

    function getInfoFromURL() {
        const searchParams = new URLSearchParams(location.search);
        const storeName =searchParams.get('name');
        const storeId = searchParams.get('storeId');
        const clientId = searchParams.get('clientId');
        const deviceId = searchParams.get('deviceId');
        if(storeName && storeId && clientId && deviceId){
        setStoreName(searchParams.get('name'));
        storeIdRef.current = storeId;
        clientIdRef.current = clientId;
        }
    }

    function onAppMessage(type: string, data: any) {
        if (type === "callReject")
            navigate(-1);
    }

    function onReject(){
        app.send("callReject");
        navigate(-1);
    }

    function onConnect(){
        app.send("callConnect");
    }


    return <div>
        {storeName ?
        <>
        <h4 className="mb-3">[{storeName}] 고객으로부터 연락이 왔습니다. </h4>
        <div className="row g-0">
            <div className="col-lg-5">
        <div className="d-flex justify-content-between p-4">
            <button type="button" className="border-0 bg-transparent" onClick={onConnect} >
                <img src="/img/callconnect.png" width={70}></img>
                <div>연결</div>
                </button>
                <button type="button" className="border-0 bg-transparent" onClick={onReject} >
                <img src="/img/callend.png" width={70}></img>
                <div>종료</div>
                </button>
            </div>
            </div>
            </div>
            </>
        :
        <div className="text-danger">잘못된 정보입니다.</div>
    }
    </div>;
}

import { ErrorMessage, useFormikContext } from "formik";

function CheckboxField(props : checkboxProps){
    const {values, handleBlur, handleChange} = useFormikContext<any>();
    return <div className={props.className || ''}> 
    <div className="form-check">
        <input className="form-check-input" type="checkbox" id={props.field} disabled={props.disabled} name={props.field} checked={values[props.field]} onBlur={handleBlur} 
           onChange={e => {
              handleChange(e);
              if(props.onChange){
                props.onChange(e.currentTarget.checked);
              }
           }}
        />
        <label htmlFor={props.field}>{props.displayName}</label>
    </div>
    <div className="ms-4">
    <ErrorMessage name={props.field}>{msg =>
            <div className="text-danger">{msg}</div>
        }</ErrorMessage>
        </div>
    </div>
    
}

interface checkboxProps{
   className? : string,
   field : string,
   displayName : string,
   disabled? : boolean,
   onChange?(value:boolean) : void,
}



export default CheckboxField;
import { EmailInfo, loginDTO } from "../accounts/Accounts.Models";

class EmailValidation {

    static emailSites = ["naver.com", "hanmail.net", "daum.net", "nate.com", "gmail.com", "hotmail.com"];

    static isValidEmail(email: string): boolean {
        return /^[\w\d]+@[\w\d]+\.[\w\d]+(\.[\w\d]+)?$/.test(email);
    }

    static parseEmail(email : string): EmailInfo | undefined {
        if(!email) return;
        const splitEmail = email.split('@');
        let emailId = "";
        let emailFull = "";
        let emailSiteSelection = "";
        let emailSite = "";
        if(splitEmail.length === 2) {
            emailId = splitEmail[0];
            if(EmailValidation.emailSites.includes(splitEmail[1])){
                emailSiteSelection = splitEmail[1];
                emailFull = email;
            }
            else {
                emailSiteSelection ="0";
                emailSite = splitEmail[1];
                emailFull = email;
            }
            return {
                emailId : emailId,
                emailFull : emailFull,
                emailSiteSelection : emailSiteSelection,
                emailSite : emailSite,
            };
        }
        return;
    }

}

export default EmailValidation;
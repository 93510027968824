class Dialog {
    static showDialog(msg : string, onAnswer : (result : boolean) => void, title : string = "") : void {
         if(window.confirm)
            onAnswer(window.confirm(msg));
         else
            onAnswer(false);
    }
    static showError(msg : string){
        if(window.alert)
            window.alert(msg);
    }

    static showConfirmation(msg : string){
        this.showError(msg);
    }
}

export default Dialog
import { claim, Device } from "../accounts/Accounts.Models";
import { PermissionEntry } from "../companies/Companies.Models";
import ServerAPI from "../ServerAPI";
import EmailValidation from "./EmailValidation";
import HttpRequest from "./HttpRequest";
import JWTHandler from "./JWTHandler";

class Authorization {

    static async checkExpiration(claims : claim[], update: (claims: claim[]) => void, device: Device | undefined) {
        if(!this.isAuthorized(claims))return;
        const isExpired = await JWTHandler.tokenExpired(60000);
        if(isExpired)
           this.logout(update, device);
    }

    static isAuthorized(claims: claim[]): boolean {
        var claim = claims.find(claim => claim.name === 'email');
        if (!claim) return false;
        if (typeof claim.value === 'string')
            return EmailValidation.isValidEmail(claim.value);
        else
            return false;
    }

    static getId(claims: claim[]): string {
        var claim = claims.find(claim => claim.name === 'email');
        if (!claim) return "";
        if (typeof claim.value === 'string')
            return claim.value;
        else
            return "";
    }

    static hasRole(claims: claim[], role: string): boolean {
        return this.hasClaim(claims, 'role', role);
    }

    static hasOneOfRoles(claims: claim[], roles: string[]): boolean {
        for(let i=0; i<roles.length; i++){
           if(this.hasRole(claims, roles[i])) return true;
        }
        return false;
    }

    static hasClaim(claims: claim[], name: string, value: string): boolean {
        var claim = claims.find(claim => claim.name === name);
        if (!claim) return false;
        if (typeof claim.value === 'string')
            return claim.value === value;
        else if (Array.isArray(claim.value)) 
            return claim.value.includes(value);
        return false;
    }

    static async logout(update: (claims: claim[]) => void, device: Device | undefined) {
        if (device?.pushToken) {
            try {
                await HttpRequest.delete(`${ServerAPI.accountDeviceDelete}`, { params: { pushToken: device.pushToken } });
            }
            catch (e) { console.log(e) };
        }
        JWTHandler.removeToken();
        update([]);
    }

    static getRoleName(claims: claim[]): string {
        if (this.hasRole(claims, "admin"))
            return "관리자";
        return "";
    }

    static getTypeName(type : number) : string {
        if(type===0)
           return "관리업체";
        else if(type===1)
            return "관리업체 관리자";
        else if(type===2)
            return "관리업체 직원";
        else if(type===3)
            return "매장 관리자";
        else if(type===4)
            return "매장 직원";
        else
            throw "알 수 없는 계정종류";
    }

    static isCompanyRelatedType(type : number) : boolean {
        return type ===0 || type === 1 || type ===2;
    }

    static isCompanyType(type : number) : boolean {
        return type ===0;
    }

    static hasPermission(permission : PermissionEntry[] | undefined, name : string, value : string) : boolean{
        return permission?.find(p => p.name === name)?.values.find(v => v === value) !== undefined;
    }
}

export default Authorization;
import { Form, Formik } from "formik";
import { useState } from "react";
import DisplayErrors from "../components/DisplayErrors";
import { accountPasswordChangeDTO } from "./Accounts.Models";
import * as Yup from 'yup'
import TextField from "../forms/TextField";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import OkButton from "../components/OkButton";
import HttpRequest from "../utils/HttpRequest";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";


export default function AccountCancelPage() {
    const [errors, setErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);      
    const initialDTO : accountPasswordChangeDTO = {
        email : "",
        oldPassword : "",
        password : "",
        passwordConfirmed : "",
    }

    function onUpdate(info: accountPasswordChangeDTO) {
        Dialog.showDialog("계정 탈퇴를 하시겠습니까?", async (result: boolean) => {
            if (result) {
                setIsLoading(true);
                try {
                    setErrors([]);
                    await HttpRequest.delete(`${ServerAPI.accountCancel}`,  {params : {email : info.email, password : info.password}});
                    Dialog.showConfirmation("계정 탈퇴가 완료되었습니다.");
                }
                catch (error) {
                    if (error?.response?.data) {
                        setErrors(error.response.data);
                    }
                    else {
                        setErrors(["서버연결에 실패했습니다."])
                    }
                }
                setIsLoading(false);
            }

        });
    }

    return <div className='row'>
    <div className='col-lg-5'>
        <h3 className="mb-3">NSM 매니저 계정 탈퇴</h3> 
        <div className="mb-3">
        NSM 매니저 앱에 가입된 계정탈퇴를 신청합니다. 계정탈퇴 시 계정에 관련된 모든 계정정보도 같이 삭제되고 더이상 해당 아이디로 앱을 사용하실 수 없으며 앱을 사용하기 위해서는 재가입이 필요합니다.
        </div>
            <Formik initialValues={initialDTO} onSubmit={onUpdate}
                validationSchema={
                    Yup.object({
                        email: Yup.string().required('이메일을 입력해주세요').max(64, "이메일 전체길이가 64글자를 초과할 수 없습니다."),
                        password: Yup.string().required('비밀번호를 입력해주세요.').min(8, "비밀번호는 8글자 이상 입력해주세요.")
                            .max(32, "비밀번호는 32글자 이하로 입력해주세요.")
                            .test("noSpace", "비밀번호에 공백이 포함되어 있습니다.",
                                value => (value !== undefined && !/[\s]/.test(value)))
                            .test("noAlphabet", "비밀번호에 영어가 포함되어있지 않습니다.",
                                value => (value !== undefined && /[a-zA-Z]/.test(value)))
                            .test("noNumber", "비밀번호에 숫자가 포함되어있지 않습니다.",
                                value => (value !== undefined && /[\d]/.test(value)))
                            .test("noSpecialCharacter", "비밀번호에 특수문자가 포함되어있지 않습니다.",
                                value => (value !== undefined && /[!@#$%^&*)(+=._-]/.test(value))),
                        passwordConfirmed: Yup.string().required('비밀번호 확인을 해주세요.')
                            .test("checkPassword", "비밀번호가 일치하지 않습니다.",
                                function (value) {
                                    if (!value) return false;
                                    const { password } = this.parent;
                                    return value.trim() === password.trim();
                                }),
                    })
                }>
        {(formikProps) => 
            <Form>
                <TextField field="email" display="계정 (이메일)" className="mb-3"/>
            <TextField type="password" field='password' display='비밀번호' className="mb-3" />
    
            <TextField type="password" field='passwordConfirmed' display='비밀번호 확인' className="mb-1" />
        <DisplayErrors errors={errors} />


                <ComponentToLoadSmallCenter isLoading={isLoading} className="mt-4">
                <OkButton type="submit" className="w-100" disabled={isLoading}>탈퇴하기</OkButton>
                </ComponentToLoadSmallCenter>
            </Form>
        }
    </Formik>

   
        </div>
        </div>
}
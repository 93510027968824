import { Switch } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../AppContext";
import CancelButton from "../components/CancelButton";
import CollapseIcon from "../components/CollapseIcon";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import ServerConnectionComponent from "../stores/ServerConnectionComponent";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import { PacketCommands } from "../utils/SocketManager";
import stringUtil from "../utils/StringUtil";
import { IrisClient, IrisClientState } from "./Iris.models";

export default function IrisClientComponent(props: IrisClientProps) {
    const { selectedStoreId, updateStores, socketManager } = useContext(AppContext);
    const navigate = useNavigate();
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLockLoading, setIsLockLoading] = useState(false);
    const [ageVerificationLoading, setAgeVerificationLoading] = useState(false);
    const [isUnlockingDoor, setIsUnlockingDoor] = useState(false);
    const [client, setClient] = useState(props.client ? { ...props.client } : null);
    const clientRef = useRef(props.client ? { ...props.client } : null);
    const connectionCount = useRef(0);
    const timeoutCount = 3;
    const timeOutIdRefForOpeningDoor = useRef<NodeJS.Timeout>();

    useEffect(() => {
        setClient(props.client ? { ...props.client } : null);
        clientRef.current = props.client ? { ...props.client } : null;
        let timer: any;
        if (clientRef.current) {
            socketManager?.registerCommandCallBack(PacketCommands.Manager_Iris_State, onReceive);
            socketManager?.registerCommandCallBack(PacketCommands.Manager_Iris_Open_Door, onReceiveOpenDoorResult);
            timer = setInterval(() => {
                if (connectionCount.current < timeoutCount) {
                    connectionCount.current++;
                }
                else {
                    if (clientRef.current!.isConnected) {
                        clientRef.current!.isConnected = false;
                        setClient({ ...clientRef.current! });
                    }
                }
                socketManager?.sendIrisState(selectedStoreId, clientRef.current!._id);
            }, 1000);
        }
        return () => {
            if (timer) {
                clearInterval(timer);
                socketManager?.deregisterCommandCallBack(PacketCommands.Manager_Iris_State);
                socketManager?.deregisterCommandCallBack(PacketCommands.Manager_Iris_Open_Door);
            }
        }
    }, [selectedStoreId, props.client]);

    async function onAgeVerificationChange(enabled: boolean) {
        setAgeVerificationLoading(true);
        try {
            if (!selectedStoreId) {
                Dialog.showError("선택된 매장이 없습니다.");
                return;
            }
            if (client?.isConnected !== true) {
                Dialog.showError("인증기기가 연결되어있지 않습니다.");
                return;
            }
            await HttpRequest.put(`${ServerAPI.storeIrisClientUpdateAgeVerificationOn}/${selectedStoreId}`, { _id: props.client?._id, ageVerificationOn: enabled });
            clientRef.current!.ageVerificationOn = enabled;
            setClient({ ...clientRef.current! });
            socketManager?.sendStoreUpdate(selectedStoreId);
        }
        catch (error) {
            let message = "서버연결에 실패했습니다.";
            if (error?.response?.data) {
                message = error.response.data;
            }
            Dialog.showError(message);
        }
        finally {
            setAgeVerificationLoading(false);
        }
    }

    function onReceive(data: any) {
        const state: IrisClientState = data.state;
        connectionCount.current = 0;
        if (client === null || clientRef.current === null) return;
        clientRef.current!.isConnected = state.isConnected;
        clientRef.current!.version = state.version;
        setClient({
            ...clientRef.current!
        });
    }

    function onReceiveOpenDoorResult(result: boolean) {
        clearTimeout(timeOutIdRefForOpeningDoor.current);
        setIsUnlockingDoor(false);
        if (result) {
            Dialog.showConfirmation("일시잠금해제가 완료되었습니다.");
        }
        else
            Dialog.showConfirmation("일시잠금해제가 실패했습니다.");
    }

    function onDelete(clientId: string) {
        if (!selectedStoreId) {
            Dialog.showError("선택된 매장정보가 없습니다.");
            return;
        }
        Dialog.showDialog("해당 기기를 삭제하시겠습니까?\n\n※ 삭제 이후에는 해당 기기 제어가 불가능합니다.", async (result) => {
            if (!result) return;
            setDeleteLoading(true);
            try {
                await HttpRequest.delete(ServerAPI.storeIrisClientDelete, { params: { storeId: selectedStoreId, id: clientId } });
                Dialog.showConfirmation("삭제가 완료되었습니다.");
                socketManager?.sendStoreUpdate(selectedStoreId);
                updateStores();
            }
            catch (error) {
                let msg = "에러가 발생했습니다.\n"
                if (error?.response?.data) {
                    msg += error.response.data;
                }
                Dialog.showError(msg);
            }
            setDeleteLoading(false);
        });
    }

    async function onEnabled(enabled: boolean) {
        setIsLoading(true);
        try {
            if (!selectedStoreId) {
                Dialog.showError("선택된 매장이 없습니다.");
            }
            if (client?.isConnected !== true) {
                Dialog.showError("인증기기가 연결되어있지 않습니다.");
                return;
            }
            await HttpRequest.put(`${ServerAPI.storeIrisClientUpdateIsScheduleOn}/${selectedStoreId}`, { _id: props.client?._id, isScheduleOn: enabled });
            clientRef.current!.isScheduleOn = enabled;
            setClient({ ...clientRef.current! });
            socketManager?.sendStoreUpdate(selectedStoreId);
        }
        catch (error) {
            let message = "서버연결에 실패했습니다.";
            if (error?.response?.data) {
                message = error.response.data;
            }
            Dialog.showError(message);
        }
        finally {
            setIsLoading(false);
        }
    }

    async function onLockEnabled(enabled: boolean) {
        setIsLockLoading(true);
        try {
            if (!selectedStoreId) {
                Dialog.showError("선택된 매장이 없습니다.");
                return;
            }
            if (client?.isConnected !== true) {
                Dialog.showError("인증기기가 연결되어있지 않습니다.");
                return;
            }
            await HttpRequest.put(`${ServerAPI.storeIrisClientUpdateLockDoor}/${selectedStoreId}`, { _id: props.client?._id, lockDoor: enabled });
            clientRef.current!.lockDoor = enabled;
            setClient({ ...clientRef.current! });
            socketManager?.sendStoreUpdate(selectedStoreId);
        }
        catch (error) {
            let message = "서버연결에 실패했습니다.";
            if (error?.response?.data) {
                message = error.response.data;
            }
            Dialog.showError(message);
        }
        finally {
            setIsLockLoading(false);
        }
    }

    function onUnlockDoorTemporarily() {
        if (!selectedStoreId || !client?.isConnected) return;
        Dialog.showDialog("잠금을 일시적으로 해제하시겠습니까?\n\n※ 일정시간 후 다시 원상태로 돌아갑니다.", result => {
            setIsUnlockingDoor(true);
            socketManager?.requestIrisOpenDoor(selectedStoreId, client._id);
            timeOutIdRefForOpeningDoor.current = setTimeout(() => {
                Dialog.showError("기기응답이 없어 일시잠금해제에 실패했습니다.");
                setIsUnlockingDoor(false);
            }, 3000);
        });

    }

    return <div className={"row g-0 " + props.className || ""}>
        <div className="col-lg-8 border px-3 pb-3 pt-1">
            <div className="d-flex align-items-center">
                <div className="fw-bold">자동출입인증</div>
                {client &&
                    <ComponentToLoadSmallCenter isLoading={isLoading} className="ms-2">
                        <div className="d-flex align-items-center" >
                            <Switch checked={client.isScheduleOn === true} onChange={(e, b) => onEnabled(b)} />
                            {!client.isScheduleOn &&
                                <div className="badge bg-primary">수동모드</div>
                            }
                        </div>
                    </ComponentToLoadSmallCenter>
                }
            </div>

            {!client &&
                <div>
                    <OkButton className="btn-sm" onClick={() => navigate(RouteConfig.irisPath)} >인증기기 등록</OkButton>
                </div>
            }

            {client && (client.isScheduleOn !== true ?
                <>
                    <div className="text-primary mb-1">
                        ※ 수동으로 문잠금을 설정하거나 해제합니다.
                    </div>
                    <div className="d-flex align-items-center">
                        <div>문잠금 설정</div>
                        <ComponentToLoadSmallCenter isLoading={isLockLoading} className="ms-2">
                            <div className="d-flex align-items-center" >
                                <Switch checked={client.lockDoor === true} onChange={(e, b) => onLockEnabled(b)} />
                                {client.lockDoor === true ? <div className="badge bg-success">잠김</div>
                                    : <div className="badge bg-danger">열림</div>
                                }
                            </div>
                        </ComponentToLoadSmallCenter>
                    </div>
                </>
                :
                <>
                    <div className="text-primary mb-1">
                        ※ 설정한 스케줄시간에는 인증시에만 문잠금이 해제됩니다.
                    </div>
                    <div className="d-flex align-items-center">
                        <OkButton className="btn-sm me-1" onClick={() => navigate(`${RouteConfig.irisSchedulePath}/${client._id}`)}>
                            스케줄조정
                        </OkButton>
                        <ComponentToLoadSmallCenter isLoading={isUnlockingDoor}>
                            <OkButton className="btn-sm" disabled={client?.isConnected !== true} onClick={() => onUnlockDoorTemporarily()}>
                                일시잠금해제
                            </OkButton>
                        </ComponentToLoadSmallCenter>
                    </div>
                    <div className="d-flex align-items-center">
                        <div>성인인증적용</div>
                        <ComponentToLoadSmallCenter isLoading={ageVerificationLoading} className={`ms-2 ${ageVerificationLoading && "mt-1"}`}>
                            <div className="d-flex align-items-center" >
                                <Switch checked={client.ageVerificationOn === true} onChange={(e, b) => onAgeVerificationChange(b)} />
                                {client.ageVerificationOn === true &&
                                    <div>
                                        ({stringUtil.toHourMinutesFromMinutes(client.ageVerificationStartMinutes ?? 0) + "~"
                                            + stringUtil.toHourMinutesFromMinutes(client.ageVerificationEndMinutes ?? 0)})
                                    </div>
                                }
                            </div>
                        </ComponentToLoadSmallCenter>
                    </div>
                    {client.ageVerificationOn === true &&
                        <>
                            <OkButton className="btn-sm" onClick={() => navigate(`${RouteConfig.irisAgeVerificationTimePath}/${client!._id}`)}>
                                적용시간설정
                            </OkButton>
                        </>
                    }
                </>
            )
            }

            <div className="mb-1 mt-2 p-2 border border-dark rounded bg-floralwhite">
                <div className="row g-0 align-items-center mb-1">
                    <div className="col-auto" >인증기기정보</div> {client &&
                    <>
                        <CollapseIcon isOpen={isDetailOpen} setIsOpen={setIsDetailOpen} className="col-auto me-2" />
                        <div className={`${client?.isConnected? "col-md" : "col" } d-flex`}>
                        {client?.isConnected && <OkButton className="btn-sm me-1" onClick={() => navigate(RouteConfig.irisMemberListPath)} >회원목록</OkButton>}
                    <OkButton className="btn-sm" onClick={() => navigate(RouteConfig.irisLogPath)}>출입로그</OkButton>
                        </div>
                        
                        </>
                    }
                </div>
                {client !== null && isDetailOpen &&
                    <div className="border rounded p-2">
                        <ul className="mb-0">
                            <li>MAC 주소 : {stringUtil.toMacAddress(client._id)}</li>
                            <li>이름 : {client.name}</li>
                            <li>등록일시 : {stringUtil.toStringFullDateTimeNoSeconds(client.registerDate)}</li>
                            {client.isConnected === true &&
                                <li>버전 : {client.version}</li>
                            }

                        </ul>
                        <div className="d-flex align-items-center my-1 justify-content-center justify-content-md-start ms-0 ms-md-4">
                            <OkButton className="btn-sm me-1" onClick={() => navigate(`${RouteConfig.irisPath}/${client!._id}`)}>
                                이름변경
                            </OkButton>
                            <ComponentToLoadSmallCenter isLoading={deleteLoading}>
                                <CancelButton className="btn-sm" disabled={deleteLoading} onClick={() => onDelete(client!._id)}>
                                    기기삭제
                                </CancelButton>
                            </ComponentToLoadSmallCenter>
                        </div>
                    </div>
                }
                <ServerConnectionComponent isConnected={client !== null && client.isConnected === true} connectionElement={
                    <div>
                        인증기기가 연결되었습니다.
                    </div>

                } noConnectionElement={
                    client === null ?
                        <>
                            <div>등록된 출입인증기기가 없습니다.</div>
                        </>
                        :
                        <div>인증기기가 연결되지 않았습니다.</div>
                } />

            </div>


        </div>
    </div>
}

interface IrisClientProps {
    className?: string,
    client: IrisClient | null,
}
import { ErrorMessage, Field } from "formik";

function TextField(props: textFieldProps) {
    return <div className={props.className ?? ""}>
        {props.display &&
        <label htmlFor={props.field} className="form-label mb-0">{props.display}{props.required&&<span className="text-danger">*</span>}</label>
        }
        <Field placeholder={props.placeholder} type={props.type} disabled={props.disabled} name={props.field} id={props.field} className="form-control" />
        <ErrorMessage name={props.field}>{msg =>
            <div className="text-danger">{msg}</div>
        }</ErrorMessage>
    </div>
}

interface textFieldProps {
    className? : string,
    field : string,
    display? : string,
    type : "text" | "password",
    disabled : boolean,
    required? : boolean,
    placeholder? : string,
}

TextField.defaultProps = {
    type : "text",
    disabled : false,
}

export default TextField;
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Authorized from "../accounts/Authorized";
import CancelButton from "../components/CancelButton";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import OkButton from "../components/OkButton";
import SubButton from "../components/SubButton";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import stringUtil from "../utils/StringUtil";
import { Inquiry } from "./Settings.Models";

export default function InquiryComponent(props: { inquiry: Inquiry, updateList: () => void }) {
    const isWating = stringUtil.isNullOrWhiteSpace(props.inquiry.answer);
    const [wideView, setWideView] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isAnswerOpen, setIsAnswerOpen] = useState(false);
    const [answerWriteMode, setAnswerWriteMode] = useState(false);
    const [answerCount, setAnswerCount] = useState(0);
    const [answer, setAnswer] = useState("");
    const [isAnswerAdding, setIsAnswerAdding] = useState(false);
    const navigate = useNavigate();

    async function onAnswerAdd() {
        if (stringUtil.isNullOrWhiteSpace(answer)) {
            Dialog.showError("작성된 답변이 없습니다. 답변을 작성 후 다시 시도해주세요.")
            return;
        }
        Dialog.showDialog("해당 답변을 등록하시겠습니까?", async result => {
            if (!result) return;
            setIsAnswerAdding(true);
            try {
                await HttpRequest.put(`${ServerAPI.inquiryAnswer}/${props.inquiry._id}`, { answer: answer });
                Dialog.showConfirmation("답변등록이 완료되었습니다.");
                props.updateList()
            }
            catch (error) {
                let message = "작업에 실패했습니다.\n\n"
                if (Array.isArray(error?.response?.data)) {
                    for (let i = 0; i < error.reponse.data; i++)
                        message += `${error.response.data[i]}\n`;
                }
                else {
                    message += "서버연결에 실패했습니다.";
                }
                Dialog.showError(message);
            }
            setIsAnswerAdding(false);
        });
    }

    function onDelete() {
        Dialog.showDialog("해당 문의내역을 삭제하시겠습니까?", async result => {
            if (!result) return;
            setIsDeleting(true);
            try {
                await HttpRequest.delete(`${ServerAPI.inquiry}/${props.inquiry._id}`);
                Dialog.showConfirmation(`삭제가 완료되었습니다.`);
                props.updateList()
            }
            catch (error) {
                let message = "작업에 실패했습니다.\n\n"
                if (Array.isArray(error?.response?.data)) {
                    for (let i = 0; i < error.reponse.data; i++)
                        message += `${error.response.data[i]}\n`;
                }
                else {
                    message += "서버연결에 실패했습니다.";
                }
                Dialog.showError(message);
            }
            setIsDeleting(false);
        });
    }

    function onCheckAnswer() {
        if (isAnswerOpen)
            setIsAnswerOpen(false);
        else {
            setIsAnswerOpen(true);
            setAnswerWriteMode(false);
        }
    }

    function onEditAnswer() {
        if (isAnswerOpen) {
            setAnswer(props.inquiry.answer);
            setAnswerCount(props.inquiry.answer.length);
            setAnswerWriteMode(true);
        }
    }

    function onWriteAnswer() {
        setAnswer("");
        setAnswerCount(0);
        setIsAnswerOpen(true);
        setAnswerWriteMode(true);
    }

    return <div className="bg-gainsboro rounded p-3">
        {isWating ?
            <div className="badge bg-danger"> 답변대기 </div>
            :
            <div className="badge bg-success"> 답변완료 </div>
        }
        <div className="text-primary">{stringUtil.toStringFullDateTimeNoSeconds(props.inquiry.registerDate)} | {props.inquiry.email}</div>
        <div className="text-truncate" style={wideView ? { whiteSpace: "pre-line" } : {}}>
            {props.inquiry.contents}
        </div>
        {isAnswerOpen &&
            <div className="bg-floralwhite rounded-3 p-3 mt-2">
                {answerWriteMode ?
                    <Authorized role={["admin"]} authorized={
                        <>
                            <textarea value={answer} onInput={e => setAnswerCount(e.currentTarget.value.length)} onChange={e => setAnswer(e.currentTarget.value)} maxLength={500} className="form-control" placeholder="500자 이내로 답변을 작성해주세요."
                                rows={5}></textarea>
                            <div className="d-flex justify-content-between">
                                <div className="mt-2 d-flex">
                                    <ComponentToLoadSmallCenter className="me-2" isLoading={isAnswerAdding}>
                                        <SubButton className="btn-sm" onClick={onAnswerAdd}>답변등록</SubButton>
                                    </ComponentToLoadSmallCenter>
                                    <CancelButton className="btn-sm" onClick={() => setIsAnswerOpen(false)}>취소</CancelButton>
                                </div>
                                <div>
                                    {answerCount}/500
                                </div>
                            </div>
                        </>
                    } />
                    :
                    <div style={{ whiteSpace: "pre-line" }}>
                        {props.inquiry.answer}
                        <Authorized role={["admin"]} authorized={
                            <div className="mt-2">
                                <SubButton onClick={onEditAnswer}>답변수정</SubButton>
                            </div>
                        } />

                    </div>
                }
            </div>
        }
        <div className="row g-0 mt-2">
            <div className="col-auto pe-1">
                <OkButton className="btn-sm" onClick={() => { setWideView(!wideView) }}>{wideView ? "접어보기" : "펼쳐보기"}</OkButton>
            </div>
            {isWating ?
                <>
                    <Authorized role={["admin"]} authorized={
                        <div className="col-auto px-1">
                            <OkButton className="btn-sm" onClick={onWriteAnswer} disabled={isAnswerOpen} >답변쓰기</OkButton>
                        </div>
                    } />

                    <div className="col-auto px-1">
                        <OkButton className="btn-sm" onClick={() => navigate(`${RouteConfig.inquiryAddPath}/${props.inquiry._id}`)}>수정</OkButton>
                    </div>
                    <div className="col-auto px-1">
                        <ComponentToLoadSmallCenter isLoading={isDeleting}>
                            <CancelButton className="btn-sm" onClick={onDelete}>삭제</CancelButton>
                        </ComponentToLoadSmallCenter>
                    </div>
                </> :
                <div className="col-auto px-1">
                    <OkButton className="btn-sm" onClick={onCheckAnswer}>{isAnswerOpen ? "답변닫기" : "답변보기"}</OkButton>
                </div>
            }
        </div>
    </div>
}
import { ErrorMessage, useFormikContext } from "formik";
import { ReactNode, useEffect } from "react";

function SelectionField(props: SelectionFieldProps) {
    const {handleBlur, values, handleChange}= useFormikContext<any>();
    
    return <div className={props.className || ''}>
        {props.displayName&&<label className="form-label">{props.displayName}</label>}
        <select name={props.field} disabled={props.disabled} className='form-select' onBlur={handleBlur} value={values[props.field]} onChange={handleChange}>
            {props.children}
        </select>
        <ErrorMessage name={props.field}>
            {msg => <div className="text-danger">{msg}</div>}
        </ErrorMessage>
    </div>

}

interface SelectionFieldProps {
    className?: string,
    field: string,
    displayName?: string,
    children : ReactNode,
    disabled? : boolean,
}

SelectionField.defaultProps = {
    disabled : false,
};

export default SelectionField;
import { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext from "../AppContext";
import ComponentToLoad from "../components/ComponentToLoad";
import DisplayErrors from "../components/DisplayErrors";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import JWTHandler from "../utils/JWTHandler";
import { accountCreateDTO, SocialLoginResult } from "./Accounts.Models";
import AuthenticationContext from "./AuthenticationContext";
import SocialLogin from "./SocialLogin";

export default function KakaoLoginPage() {
    return <SocialLogin type="kakao" />
}
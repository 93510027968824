import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CancelButton from "../components/CancelButton";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import Dialog from "../utils/Dialog";
import stringUtil from "../utils/StringUtil";
import { cctvClient } from "./CCTV.Models";

export default function CCTVStateComponent(props : Props) {
    
    const [deleteLoading, setDeleteLoading] = useState(false);
    const navigate = useNavigate();

    
    return  <div className="bg-gainsboro p-4 mb-3 rounded-4">
        <div className="row g-0 justify-content-between align-items-center">
        <div className="col text-break pe-2">
        <h4 className="text-violet fw-bold mb-0">
            {props.cctv.name}
            </h4>
        </div>
        <div className="col-auto d-flex justify-content-end">
      <OkButton className="btn-sm me-1" onClick={() => {
       navigate(`${RouteConfig.cctvAddUpdatePath}/${props.cctv._id}`);
      }}>정보 수정</OkButton>
      <ComponentToLoadSmallCenter isLoading={deleteLoading} >
      <CancelButton className="btn-sm" disabled={deleteLoading} onClick={() =>
                   {
                       Dialog.showDialog("해당 CCTV를 삭제하시겠습니까?", async (result) => {
                          if(result) {
                            setDeleteLoading(true);
                            await props.onDelete(props.cctv._id);
                            setDeleteLoading(false);
                          }
                       });
                   }}>삭제</CancelButton>
        </ComponentToLoadSmallCenter>
      </div>

    </div>
        <ul className="my-2">
    <li>등록일시 : {stringUtil.toStringFullDateTimeNoSeconds(props.cctv.registerDate)}</li>
    <li>IP 및 포트 : {props.cctv.ip}:{props.cctv.port}</li>
    </ul>

    
    <div className="d-flex mt-2">
    <OkButton className="btn-sm me-1" onClick={() => {
       navigate(`${RouteConfig.cctvViewPath}/${props.cctv._id}`);
      }}>실시간 영상보기</OkButton>
      <OkButton className="btn-sm" onClick={() => {
       navigate(`${RouteConfig.cctvPlayListPath}/${props.cctv._id}`);
      }}>녹화영상목록</OkButton>
    </div>
    </div>
}

interface Props {
    cctv : cctvClient,
    onDelete(id : string) : Promise<void>,
}

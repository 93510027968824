import { Card, CardContent, Switch, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CancelButton from "./CancelButton";
import ComponentToLoadSmallCenter from "./ComponentToLoadSmallCenter";
import OkButton from "./OkButton";
import stringUtil from "../utils/StringUtil";
import { ScheduleTime } from "./Component.Models";

export default function ScheduleInfoComponent(props: ScheduleInfoProps) {
    const navigate = useNavigate();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isOnLoading, setIsOnLoading] = useState(false);
    return <Card sx={{ width: 230 }} className="m-1">
        <CardContent>
            <div>
                <div className="d-flex align-items-center">
                    <ComponentToLoadSmallCenter isLoading={isOnLoading}>
                    <Switch checked={props.schedule.isOn} edge='start' onChange={(e, v) => props.onSwitch(props.schedule._id, v, setIsOnLoading)}/>
                    </ComponentToLoadSmallCenter>
                    <span>스케줄 {props.schedule.isOn ? "적용" : "해제"}됨</span>
                </div>                                                                                                                                             </div>
            <Typography color="text.secondary">
                시작요일
            </Typography>
            <Typography variant="body1" className="mb-1" component="div">
                {stringUtil.toDaysString(props.schedule.days)}
            </Typography>
            <Typography color="text.secondary">
                적용시간
            </Typography>
            <Typography variant="body1" className="mb-2" component="div">
                {stringUtil.toScheduleTimeString(props.schedule.startTime, props.schedule.endTime)}
            </Typography>
            <div className="d-flex">
                <OkButton className="btn-sm me-2" onClick={() => navigate(`${props.scheduleRegisterPageURL}/${props.clientId}/${props.schedule._id}`)}>수정</OkButton>
                <ComponentToLoadSmallCenter isLoading={isDeleting}>
                    <CancelButton className="btn-sm" disabled={isDeleting} onClick={() => props.onDelete(props.schedule._id, setIsDeleting)}>삭제</CancelButton>
                </ComponentToLoadSmallCenter>
            </div>
        </CardContent>
    </Card>
}

interface ScheduleInfoProps {
    clientId : string,
    scheduleRegisterPageURL : string,
    schedule: ScheduleTime,
    onDelete(scheduleId : string, setLoading : (value : boolean)=> void) : void,
    onSwitch(scheduleId : string, isOn : boolean, setLoading : (value : boolean)=> void) : void,
}
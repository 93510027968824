import { cctvVideo } from "../cctv/CCTV.Models";
import stringUtil from "./StringUtil";

class StoreUtil {

    CCTV = 0;
    DoorSensor = 1;
    IrisRecognizer = 2;
    IrReceiver = 3;
    IrTransmitter = 4;
    MoviePlayer = 5;
    TemperatureSensor = 6;

    hasDoorSensorFeature(feature : number[] | null) : boolean {
        if(feature === null) return false;
        return feature.includes(this.DoorSensor);
    }

    hasIrisRecognizerFeature(feature : number[] | null) : boolean {
        if(feature === null) return false;
        return feature.includes(this.IrisRecognizer);
    }

    hasIoTFeature(feature : number[] | null) : boolean {
        return this.hasDoorSensorFeature(feature) || this.hasAirConditionerFeature(feature);
    }

    hasCCTVFeature(feature : number[] | null) : boolean {
        if(feature === null) return false;
        return feature.includes(this.CCTV);
    }

    hasAirConditionerFeature(feature : number[] | null) : boolean {
        if(feature === null) return false;
        return feature.includes(this.IrReceiver) && feature.includes(this.IrTransmitter) && feature.includes(this.TemperatureSensor);
    }

    featureToString(features: number[]) {
        let featureString = "";
        if(features.includes(this.CCTV)){
            featureString = this.getStoreFeatureName(this.CCTV);
        }
        if (features.includes(this.DoorSensor)){
            if (featureString)
                featureString += ", ";
            featureString += this.getStoreFeatureName(this.DoorSensor);
        }
        if (features.includes(this.IrisRecognizer)){
            if (featureString)
                featureString += ", ";
            featureString += this.getStoreFeatureName(this.IrisRecognizer);
        }
        if (storeUtil.hasAirConditionerFeature(features)) {
            if (featureString)
                featureString += ", ";
            featureString += "냉난방기제어";
        }
        else {
            if (features.includes(this.IrReceiver)) {
                if (featureString)
                    featureString += ", ";
                featureString += "적외선 수신기";
            }
            if (features.includes(this.IrTransmitter)) {
                if (featureString)
                    featureString += ", ";
                featureString += "적외선 송신기";
            }
            if (features.includes(this.TemperatureSensor)) {
                if (featureString)
                    featureString += ", ";
                featureString += "온도센서";
            }
        }
        if (features.includes(this.MoviePlayer)){
            if (featureString)
                featureString += ", ";
            featureString += this.getStoreFeatureName(this.MoviePlayer);
        }
        return featureString;
    }

    getAvailableTemperature() : number[] {
        const numbers = [];
        for(let i =18; i<=30; i++){
            numbers.push(i);
        }
        return numbers;
    }

    getCctvVideoFromFileName(fileName : string) : cctvVideo | undefined {
        try {
            let rest = fileName;
            let type : "regular" | "intrusion" | "motion" | "traverse" | "face" | "tampering" = "regular";
            let splits = fileName.split("\\");
            if(splits.length > 1) {
                rest = splits[1];
                if(splits[0] === "Intrusion") 
                    type = "intrusion";
                else if(splits[0] === "MotionDetection")
                    type = "motion";
                else if(splits[0] === "TraversePlane")
                    type = "traverse";
                else if(splits[0] === "FaceDetection")
                    type = "face";
                else if(splits[0] === "TamperingDetection")
                    type = "tampering";
                else 
                    throw "unknown type";
            }
            splits = rest.split(" ");
            const yearMonthDates = splits[0].split("-");
            const hourMinuteSeconds = splits[1].split("_");
            const startYear = Number.parseInt(yearMonthDates[0]);
            const startMonth = Number.parseInt(yearMonthDates[1]);
            const startDate = Number.parseInt(yearMonthDates[2]);
            const startHour = Number.parseInt(hourMinuteSeconds[0]);
            const startMinute = Number.parseInt(hourMinuteSeconds[1]);
            const startSecond = Number.parseInt(hourMinuteSeconds[2]);
            const displayName = `${startYear}-${stringUtil.padZero(startMonth.toString(), 2)}-${stringUtil.padZero(startDate.toString(), 2)} 
            ${stringUtil.padZero(startHour.toString(), 2)}:${stringUtil.padZero(startMinute.toString(), 2)}:${stringUtil.padZero(startSecond.toString(), 2)}.mp4`;
            return {
                originalName : fileName,
                type : type,
                startYear : startYear,
                startMonth : startMonth,
                startDate : startDate,
                startHour : startHour,
                startMinute : startMinute,
                startSecond : startSecond,
                displayName : displayName,
            };
        }
        catch(e) {
            console.log(e);
            return;
        }
    }

    getStoreFeatureName(feature : number) {
        switch(feature){
           case this.CCTV: return "CCTV";  
           case this.DoorSensor: return "도어센서";
           case this.IrisRecognizer: return "홍채출입인증";
           case this.IrReceiver: return "냉난방기제어";
           case this.IrTransmitter: return "냉난방기제어";
           case this.MoviePlayer: return "OTT 플레이어";
           case this.TemperatureSensor: return "냉난방기제어";
           default: return "알수없음";
        }
    }


    isAirConditioner(features : boolean[]){
        if(!features || features.length <= this.TemperatureSensor ) return false;
        return features[this.IrReceiver] && features[this.IrTransmitter] && features[this.TemperatureSensor];
    }
}

export const storeUtil = new StoreUtil();
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ComponentToLoad from "../components/ComponentToLoad";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import ArrayUtil from "../utils/ArrayUtil";
import HttpRequest from "../utils/HttpRequest";
import { Company } from "./Companies.Models";
import CompanyInfoComponent from "./CompanyInfoComponent";

export default function CompanyPage(){
    const [companies, setCompanies] = useState<Company[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        updateCompanyList();        
    }, []); 

    async function updateCompanyList(){
        setIsLoading(true);
        setErrors([]);
        try {
            const companies = await HttpRequest.get<Company[]>(`${ServerAPI.accountGetAccesibleCompaniesPath}`);
            ArrayUtil.sortAscending(companies, "name");
            for(let i = 0; i<companies.length; i++)
                if(companies[i].employees)
                    ArrayUtil.sortAscending(companies[i].employees!, "name");
            setCompanies(companies);
        }
        catch (error) {
            console.log(error);
            if(companies) setCompanies(undefined);
            if (error?.response?.data) {
                setErrors(error.response.data);
            }
            else {
                setErrors(["서버연결에 실패했습니다."])
            }
        }
        setIsLoading(false);
    }
    
    return <div>
    <h3 className='mb-3'>업체 목록</h3>
    <OkButton className="btn-sm mb-3" onClick={() => navigate(RouteConfig.companyAddPath)}>새 업체 등록</OkButton>
    <ComponentToLoad isLoading={isLoading}>
        {companies ?
        <div className="row g-0">
            <div className="col-lg-8">
            <div>총 {companies.length}개 업체가 등록되어 있습니다.</div>
            {companies.map(c => 
                <CompanyInfoComponent company={c} key={c._id} updateCompanyList={updateCompanyList}/>
            )}
            </div>
            </div>
            :
            <DisplayErrors errors={errors}/>
        }

    </ComponentToLoad>
    </div>
}
function OkButton(props : ButtonProps){

    return <button className={`btn btn-primary ${props.className || ""}`} type={props.type} 
            onClick={props.onClick}
             disabled={props.disabled}>{props.children}</button>
}

interface ButtonProps{
    children : React.ReactNode,
    disabled : boolean,
    type : "button" | "submit"
    onClick?() : void,
    className?: string,
}

OkButton.defaultProps = {
    disabled : false,
    type : "button",
}

export default OkButton
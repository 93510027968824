import ComponentToLoad from "./ComponentToLoad"

export default function ComponentToLoadSmallCenter(props : ComponentToLoadSmallCenterProps){

    return <ComponentToLoad isLoading={props.isLoading} children={props.children} className={props.className} 
    loadingCenter={true} smallIcon={true}/>
}

interface ComponentToLoadSmallCenterProps{
    children : React.ReactNode,
    isLoading : boolean,
    className? : string,
}
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import {useNavigate, useParams } from "react-router-dom";
import AppContext from "../AppContext";
import ComponentToLoad from "../components/ComponentToLoad";
import Dialog from "../utils/Dialog";
import * as Yup from 'yup';
import DisplayErrors from "../components/DisplayErrors";
import TextField from "../forms/TextField";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import OkButton from "../components/OkButton";
import ServerAPI from "../ServerAPI";
import HttpRequest from "../utils/HttpRequest";
import RouteConfig from "../route-config";
import { IrisClient } from "./Iris.models";


export default function IrisInfoPage() {
    const {updateStores, socketManager} = useContext(AppContext);
    const {id} = useParams();
    const {selectedStoreId} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [client, setClient] = useState<IrisClient>({
        _id : "",
        name : "",
        features : [],
        registerDate : 0
        
    });
    const navigate = useNavigate();

    useEffect(() => {
        getClientInfo(id);
    }, [selectedStoreId, id]);

    async function getClientInfo(id : string | undefined) {
        setIsLoading(true)
        if(selectedStoreId && id){
        try {
            const client = await HttpRequest.get<IrisClient>(ServerAPI.storeGetIrisClient, {params : {storeId : selectedStoreId, id : id}});
            setClient(client);
        }
        catch(error) {
            var message = "기기정보를 가져오는데 실패했습니다.\n";
            if(error?.response?.data){
                message += error.response.data;
            }
            Dialog.showError(message);
        }
        }
        setIsLoading(false);
    }

    function onSubmit(client: IrisClient) {
        Dialog.showDialog(`해당 기기를 ${id ? "수정" : "등록"}하시겠습니까?`, async (result) => {
            if (result) {
                setIsUpdating(true);
                try {
                    setErrors([]);
                    const path = id ? ServerAPI.storeIrisClientUpdate : ServerAPI.storeIrisClientCreate;
                    await HttpRequest.post(`${path}/${selectedStoreId}`, client);
                    Dialog.showConfirmation(`기기 ${id ? "수정" : "등록"}이 완료되었습니다.`);
                    socketManager?.sendStoreUpdate(selectedStoreId);
                    await updateStores();
                    navigate(RouteConfig.homePath);
                }
                catch (error) {
                    if (error?.response?.data) {
                        setErrors(error.response.data);
                    }
                    else {
                        setErrors(["서버연결에 실패했습니다."])
                    }
                }
                setIsUpdating(false);
            }
        });
    }


    return  <div className='row'>
    <div className='col-lg-5'>
    <h3 className="mb-3">{id ? "출입인증기기 수정" : "출입인증기기 등록"} </h3>
    { selectedStoreId ?
    <ComponentToLoad isLoading={isLoading}>
    <>
    <Formik initialValues={client} onSubmit={onSubmit} enableReinitialize
    validationSchema={
        Yup.object({
        name: Yup.string().required('이름을 입력해주세요.').max(32, "이름 길이가 32글자를 초과합니다."),
        _id : Yup.string().required('MAC주소를 입력해주세요').length(12, "12글자 주소를 입력해주세요."),
       })
    }>
        {(formikProps) => 
            <Form>
                <TextField field="_id" display="MAC주소" disabled={id !== undefined} placeholder="':'없이 입력해주세요.(12자리)" className="mb-3"/>
                <TextField field="name" display="이름" className="mb-4"/>
                <ComponentToLoadSmallCenter isLoading={isUpdating}>
                <OkButton type="submit" className="w-100" disabled={isUpdating}>{id ? "수정하기" : "등록하기"}</OkButton>
                </ComponentToLoadSmallCenter>
            </Form>
        }
    </Formik>
    <DisplayErrors errors={errors} />
    </>
    </ComponentToLoad>
    :
    <div>
            선택된 매장이 없습니다. 매장을 선택해주세요.
            </div>
}
</div> 
    </div>
}

import { useNavigate } from 'react-router-dom';
import RouteConfig from '../route-config';
import ServerAPI from '../ServerAPI';
import Dialog from '../utils/Dialog';
import EmailValidation from '../utils/EmailValidation';
import HttpRequest from '../utils/HttpRequest';
import { accountCreateDTO } from './Accounts.Models';
import RegisterForm from './RegisterForm';

export default function RegisterPage() {
    const navigate = useNavigate();
    const initialAccount: accountCreateDTO = {
        emailId: "",
        emailSite: "",
        emailSiteSelection: "-1",
        emailFull: "",
        checkedEmail: "",
        idCheckButtonDisabled: true,
        password: "",
        passwordConfirmed: "",
        name: "",
        phoneNumber: "",
        allAgreed: false,
        useTermsAgreed: false,
        privateTermsAgreed: false,
        marketingAgreed: false,
    };

    async function onAccountCreate(account: accountCreateDTO) : Promise<void> {
        return new Promise((resolve, reject) => {
        if (!EmailValidation.isValidEmail(account.checkedEmail)) {
            Dialog.showError("아이디 중복확인을 해주세요.");
            resolve();
            return;
        }

        Dialog.showDialog("입력하신 정보로 회원가입을 하시겠습니까?", async (result) => {
            if (result) {
                try {
                    var accountDTO = {
                        email: account.checkedEmail,
                        password: account.password,
                        phoneNumber: account.phoneNumber,
                        name: account.name,
                        userTermsAgreed: account.useTermsAgreed,
                        privateTermsAgreed: account.privateTermsAgreed,
                        marketingAgreed: account.marketingAgreed,
                    };
                    const id = await HttpRequest.post<string>(ServerAPI.accountCreatePath, accountDTO);
                    Dialog.showConfirmation("회원가입이 완료되었습니다. 이메일 인증 후 로그인이 가능합니다.");
                    navigate(`${RouteConfig.accountEmailVerificationPath}/${id}`);
                    resolve();
                }
                catch (error) {
                    reject(error);
                }
            }
            resolve();
        });
    });
    }

    return (
        <div className='row'>
            <div className='col-lg-5'>
                <h3 className="mb-3">회원가입</h3>
                <RegisterForm initialValue={initialAccount} onSubmit={onAccountCreate} isSocial={false}  />
            </div>
        </div>
    );
}
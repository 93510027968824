import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DisplayErrors from "../components/DisplayErrors";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import AppContext from "../AppContext";
import OkButton from "../components/OkButton";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import { Slider } from "@mui/material";
import stringUtil from "../utils/StringUtil";
import { IrisClient } from "./Iris.models";

export default function IrisAgeVerificationTimePage() {
    const { id } = useParams();
    const { selectedStoreId, socketManager, stores } = useContext(AppContext);
    const [client, setClient] = useState<IrisClient>();
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getClientInfo(id);
    }, [selectedStoreId, stores]);

    async function getClientInfo(id: string | undefined) {
        try {
            setIsLoading(true);
            setErrors([]);
            if (!id || !selectedStoreId) return;
            const client = await HttpRequest.get<IrisClient>(ServerAPI.storeGetIrisClient, { params: { storeId: selectedStoreId, id: id } });
            setClient(client);
        }
        catch (error) {
            var message = "정보를 가져오는데 실패했습니다.\n";
            if (error?.response?.data) {
                message += error.response.data;
            }
            setClient(undefined);
            setErrors([message]);
        }
        finally {
            setIsLoading(false);
        }
    }

    function onSubmit(client: IrisClient) {
        Dialog.showDialog(`해당 시간을 적용하시겠습니까?`, async (result) => {
            if (result) {
                const store = stores.find(s => s._id === selectedStoreId);
                if (!store || store.irisClient === null) return;
                setIsUpdating(true);
                try {
                    setErrors([]);
                    const path = ServerAPI.storeIrisClientUpdateAgeVerificationTime;
                    await HttpRequest.put(`${path}/${selectedStoreId}`, { _id: client._id, ageVerificationStartMinutes: client.ageVerificationStartMinutes, ageVerificationEndMinutes: client.ageVerificationEndMinutes });
                    store.irisClient.ageVerificationStartMinutes = client.ageVerificationStartMinutes;
                    store.irisClient.ageVerificationEndMinutes = client.ageVerificationEndMinutes;
                    Dialog.showConfirmation(`성인인증 적용시간 설정이 완료되었습니다.`);
                    socketManager?.sendStoreUpdate(selectedStoreId);
                    navigate(-1);
                }
                catch (error) {
                    if (error?.response?.data) {
                        setErrors(error.response.data);
                    }
                    else {
                        setErrors(["서버연결에 실패했습니다."])
                    }
                }
                setIsUpdating(false);
            }
        });
    }
    function toSliderText(index: number) {
        return stringUtil.toHourMinutesFromMinutes(index);
    }

    return <div className='row'>
        <div className='col-lg-5'>
            <h3 className="mb-3">성인인증 적용시간</h3>
            {selectedStoreId ?
                <ComponentToLoad isLoading={isLoading}>
                    {client ?
                        <>
                            <Formik initialValues={client} onSubmit={onSubmit}>
                                {(formikProps) =>
                                    <Form>
                                        <div>적용시작시간</div>
                                        <div className="mx-2">
                                            <Slider min={0} max={1440} step={10} valueLabelDisplay="auto" valueLabelFormat={toSliderText} value={formikProps.values.ageVerificationStartMinutes ?? 0} onChange={(e, v) => formikProps.setFieldValue("ageVerificationStartMinutes", v)}  ></Slider>
                                        </div>
                                        <div>적용종료시간</div>
                                        <div className="mx-2">
                                            <Slider min={0} max={1440} step={10} valueLabelDisplay="auto" valueLabelFormat={toSliderText} value={formikProps.values.ageVerificationEndMinutes ?? 0} onChange={(e, v) => formikProps.setFieldValue("ageVerificationEndMinutes", v)}></Slider>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <span className="me-2">
                                                ※ 적용시간 :
                                            </span>
                                            <div className="text-primary fw-bold">
                                                {stringUtil.toScheduleTimeString(formikProps.values.ageVerificationStartMinutes ?? 0, formikProps.values.ageVerificationEndMinutes ?? 0)}
                                            </div>
                                        </div>
                                        <ComponentToLoadSmallCenter isLoading={isUpdating} className="mt-4">
                                            <OkButton type="submit" className="w-100" disabled={isUpdating}>설정하기</OkButton>
                                        </ComponentToLoadSmallCenter>
                                    </Form>
                                }
                            </Formik>
                            <DisplayErrors className="mt-2" errors={errors} />
                        </>
                        :
                        <DisplayErrors className="mt-2" errors={errors} />
                    }
                </ComponentToLoad>
                :
                <div>
                    선택된 매장이 없습니다. 매장을 선택해주세요.
                </div>
            }
        </div>
    </div>
}

import React, { useContext, useEffect, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { NavLink, useNavigate } from 'react-router-dom';
import { setLocale } from 'yup';
import AuthenticationContext from '../accounts/AuthenticationContext';
import Authorized from '../accounts/Authorized';
import RouteConfig from '../route-config';
import Authorization from '../utils/Authorization';
import './SideBar.css';
import SubButton from '../components/SubButton';
import SideBarCollapseMenu from './SideBarCollapseMenu';
import AppContext from '../AppContext';
import Dialog from '../utils/Dialog';


function SideBar(props : {versionName : string | undefined}) {
    const {claims, update} = useContext(AuthenticationContext);
    const {device} = useContext(AppContext);
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);
    const roleName = Authorization.getRoleName(claims);

    function onLogout(){
        Dialog.showDialog("현재 계정에서 로그아웃하시겠습니까?", (result)=> {
            if(result){
                setOpen(false); 
                Authorization.logout(update, device);
            }
        });
    }

    return (
        <Menu isOpen={isOpen} onOpen={()=> setOpen(true)} onClose={() => setOpen(false)}>
            <div className='d-flex mb-3 align-items-center'>
                <img src="/logo192.png" style={{width : "40px"}} className="me-2"/>
                <div>
                    <div>NSM 매니저 (v{props.versionName})</div>
                 </div>
            </div>
            <Authorized
            authorized={
                <>
            <div>
               로그인 계정 {roleName ? `(${roleName})` : ""} : {Authorization.getId(claims)}
            </div>
            <div className='d-flex mt-2'>
                <SubButton className='me-2' onClick={()=>{onLogout()}}>로그아웃</SubButton>
                <SubButton onClick={()=>window.location.reload()}>새로고침</SubButton>
            </div>
            

            <div className='mt-4'>
            <div className='main-link'>
            <NavLink to='/' onClick={() => setOpen(false)}>홈</NavLink>
            </div> 
            <div className='main-link'>
            <NavLink to={RouteConfig.companyPath} onClick={() => setOpen(false)}>업체 및 직원관리</NavLink>
            </div>
            <div className='mb-3'> 
               <SideBarCollapseMenu display='내 정보수정'>
               <div className='main-link-second mt-2'>
                <NavLink to={RouteConfig.accountUpdatePath} onClick={() => setOpen(false)}>정보수정</NavLink>
                </div>
                <div className='main-link-second'>
                <NavLink to={RouteConfig.accountPasswordChangePath} onClick={() => setOpen(false)}>비밀번호변경</NavLink>
                </div>
               </SideBarCollapseMenu>
                
            </div>
            
            <div className='main-link'>
            <NavLink to={RouteConfig.notificationPath}  onClick={() => setOpen(false)}>알림설정</NavLink>
            </div>
            <div className='main-link'>
            <NavLink to={RouteConfig.notificationHistoryPath}  onClick={() => setOpen(false)}>알림내역</NavLink>
            </div>
            <div className='main-link'>
            <NavLink to={RouteConfig.inquiryPath} onClick={() => setOpen(false)}>문의하기</NavLink>
            </div>
            </div>
            </>
            } 
            notAuthorized={
                <>
                <div>
               로그인이 필요합니다.
            </div>
            <div className='row g-0 mt-2'>
                <div className='col'>
                <SubButton  className='w-100' onClick={() => {setOpen(false); navigate(RouteConfig.accountLoginPath);}}>로그인</SubButton>
                </div>
                <div className='col ps-2'>
                <SubButton className='w-100' onClick={() => {setOpen(false); navigate(RouteConfig.accountRegisterPath);}}>회원가입</SubButton>
                </div>
                
            </div>
            <div className='mt-2'>
            <SubButton className='w-100' onClick={()=>window.location.reload()}>새로고침</SubButton>
            </div>

                </>
            }
            />
        </Menu>
      );
}

export default SideBar;
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Authorized from "../accounts/Authorized";
import CancelButton from "../components/CancelButton";
import MainButton from "../components/MainButton";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import ServerMainState from "../stores/ServerMainState";
import { StoreInfo } from "../stores/Store.Models";
import AppContext from "../AppContext";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import CollapseIcon from "../components/CollapseIcon";
import IrisClientComponent from "../iris/IrisClientComponent";
import Authorization from "../utils/Authorization";
import { storeUtil } from "../utils/StoreUtil";
import { app } from "../utils/AppCommnunication";

function Home() {
    const { stores, selectedStoreId, updateStores, updateSelectedStoreId, device } = useContext(AppContext);
    const [store, setStore] = useState<StoreInfo>();
    const [isLoading, setIsLoading] = useState(false);
    const [isDetailMenuOpen, setIsDetailMenuOpen] = useState(false);
    const navigate = useNavigate();
    const eWeLinkPackageName = "com.coolkit";

    useEffect(() => {
        const handler = app.addListener(onAppMessage)
        return () => {app.removeListener(handler)}
    }, [])

    useEffect(() => {
        setStore(stores.find(s => s._id === selectedStoreId));
    }, [stores, selectedStoreId]);

    async function deleteStore() {
        try {
            if (!selectedStoreId) {
                Dialog.showError("선택된 매장정보가 없습니다.");
                return;
            }
            await HttpRequest.delete(`${ServerAPI.storeDelete}/${selectedStoreId}`);
            Dialog.showConfirmation("매장삭제가 완료되었습니다.");
            updateSelectedStoreId("");
            updateStores();
        }
        catch (error) {
            let msg = "에러가 발생했습니다.\n"
            if (error?.response?.data) {
                msg += error.response.data;
            }
            Dialog.showError(msg);
        }
    }

    function onAppMessage(type: string, data: any) {
        try {
          if (type === "packageIsInstalled") {
            if(data) {
             Dialog.showDialog("[eWeLink] 앱을 실행하시겠습니까?", result => {
                if(result)
                    app.send("packageRun", eWeLinkPackageName);
             });
            }
            else {
                Dialog.showDialog("[eWeLink] 앱이 설치되어있지않아 플레이스토어로 이동합니다. 계속 진행하시겠습니까?", result => {
                    if(result)
                        app.send("packageInstall", eWeLinkPackageName);
                 });
            }
          }
          else if (type === "packageInstall") {
            if(!data) {
                Dialog.showError("플레이스토어 실행에 실패했습니다. 앱을 직접 설치해주세요.")
            }
          }
          else if (type === "packageRun") {
            if(!data) {
                Dialog.showError("앱을 실행하는데 실패했습니다. 앱이 설치되어있는지 확인해주세요.")
            }
          }
        }
        catch (e) { console.log(e) }
      }
    

    function onExecuteApp() {
        if(!app.isApp() || device?.platform !== "android") return
        app.send("packageIsInstalled", eWeLinkPackageName);
    }

    return store ? (
        <div>
            <div className="row g-0 mb-3">
                <div className="col-lg-8 border p-3">
                    <div className="d-flex align-items-center">
                        <h4 className="mb-0">
                            {store.name}
                        </h4>
                        <CollapseIcon isOpen={isDetailMenuOpen} setIsOpen={setIsDetailMenuOpen} />
                    </div>

                    {isDetailMenuOpen && <>
                        <div>
                            {store.addressFull} (연락처 : {store.phoneNumber})
                        </div>
                        <div className="d-flex mt-2">
                            {Authorization.hasPermission(store.permission, "store", "update") &&
                                <OkButton className="btn-sm" onClick={() => navigate(RouteConfig.storeUpdatePath)}>
                                    매장정보 수정
                                </OkButton>
                            }
                            {
                                Authorization.hasPermission(store.permission, "store", "delete") &&
                                <ComponentToLoadSmallCenter isLoading={isLoading} className="mx-2">
                                    <CancelButton disabled={isLoading} className="btn-sm" onClick={() => {
                                        Dialog.showDialog("해당 매장을 삭제하시겠습니까?", async (result) => {
                                            if (!result) return false;
                                            setIsLoading(true);
                                            await deleteStore();
                                            setIsLoading(false);
                                        });
                                    }}>
                                        매장삭제
                                    </CancelButton>
                                </ComponentToLoadSmallCenter>
                            }
                        </div>
                    </>}

                    <ServerMainState className="mt-2" servers={store.servers} />
                </div>
            </div>
            {storeUtil.hasIrisRecognizerFeature(store.features) &&
                <IrisClientComponent className="mb-3" client={store.irisClient} />}{/*
            <div className="row g-0 mb-3">
    <div className="col-lg-8 border px-3 pb-3 pt-1">
            <div className="fw-bold">자동출입인증</div>
            <div className="text-danger">해당 매장에 지원하지 않는 기능입니다.</div>
            </div>
            </div>
            */
            }
            <div className="row g-0">
                <div className="col-lg-8 row g-0">
                    <div className="col-6 col-lg-3 p-1">
                        <MainButton onClick={() => navigate(RouteConfig.storeServerListPath)}>서버 목록</MainButton>
                    </div>
                    <div className="col-6 col-lg-3 p-1">
                        <MainButton onClick={() => navigate(RouteConfig.storeLogPath)}>매장로그 확인</MainButton>
                    </div>
                    <div className="col-6 col-lg-3 p-1">
                        <MainButton disabled={!storeUtil.hasIoTFeature(store.features)} onClick={() => navigate(RouteConfig.iotListPath)} >IoT 기기 목록</MainButton>
                    </div>
                    <div className="col-6 col-lg-3 p-1">
                        <MainButton disabled={!storeUtil.hasCCTVFeature(store.features)} onClick={() => navigate(RouteConfig.cctvListPath)}>CCTV 관리</MainButton>
                    </div>
                    <div className="col-6 col-lg-3 p-1">
                        <MainButton disabled={!storeUtil.hasIrisRecognizerFeature(store.features)} onClick={() => navigate(RouteConfig.storeCallDeviceSetupPage)} >영상통화 설정</MainButton>
                    </div>
                    { app.isApp() && device?.platform === "android" &&
                    <div className="col-6 col-lg-3 p-1">
                        <MainButton onClick={() => onExecuteApp()} >eWeLink 앱실행</MainButton>
                    </div>
                    }
                </div>
            </div>
        </div>)
        :
        <div>
            매장 정보가 없습니다. 매장등록을 하거나 등록된 매장을 선택해주세요.
        </div>
}

export default Home;
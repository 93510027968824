import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CollapseIcon from "../components/CollapseIcon";
import OkButton from "../components/OkButton";
import SubButton from "../components/SubButton";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import Authorization from "../utils/Authorization";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import { Company } from "./Companies.Models";
import EmployeeInfoComponent from "./EmployeeInfoComponent";

export default function CompanyInfoComponent(props : CompanyInfoComponentProps) {
    const navigate = useNavigate();
    const [employeeListOpen, setEmployeeListOpen] = useState(false);

    async function onEmployeeDelete(companyId : string, email : string){
        try {
            await HttpRequest.delete(`${ServerAPI.accountEmployeeDelete}`, {params : {companyId : companyId, employeeEmail : email}});
            Dialog.showConfirmation("직원삭제가 완료되었습니다.");
            await props.updateCompanyList();
        }
        catch(error) {
            var message = "작업에 실패했습니다.\n";
            if(error?.response?.data){
                message += error.response.data;
            }
            Dialog.showError(message);
        }
       }

    return  <div className="bg-gainsboro p-4 mb-3 rounded-4">
        <h4 className="text-violet fw-bold">{props.company.name}</h4>
        <div>
                        {props.company.addressMain} {props.company.addressDetail} ({props.company.addressZipcode})
                    </div>
        <ul className="mt-2">
    <li>소속업체 : {props.company.parentCompanyName || "없음"}</li>
    <li>연락처 : {props.company.phone}</li>
    </ul>
        <div className="mt-2 p-2 border border-dark rounded bg-floralwhite">
            <div className="d-flex align-items-center mb-1">
            <div >소속직원목록 ({props.company.employees?.length ?? 0})</div>
            <CollapseIcon isOpen={employeeListOpen} setIsOpen={setEmployeeListOpen} />
            </div>
            { employeeListOpen &&
            <div className="row g-0 mb-2" style={{ 'fontSize': '0.9rem' }}>
                {props.company.employees?.map( e=>
                <div className="col-6" key={e.email}>
                  <EmployeeInfoComponent company={props.company} employee={e}  className="p-1" onDelete={async (email) => await onEmployeeDelete(props.company._id, email) } />
                </div>
                )
                }
            </div>
            }
        </div>
    <div className="d-flex mt-2">
      <OkButton className="btn-sm me-2" onClick={() => {
       navigate(`${RouteConfig.companyAddPath}/${props.company._id}`);
      }}>업체정보수정</OkButton>
      {Authorization.hasPermission(props.company.permission, "employee", "add") &&
      <OkButton className="btn-sm" onClick={() => {
       navigate(`${RouteConfig.employeeAddPath}/${props.company._id}`);
      }}>직원추가</OkButton>
     }
    </div>
    </div>
}

interface CompanyInfoComponentProps {
    company : Company,
    updateCompanyList() : Promise<void>
}
import "./PrivateTermsContent.css";

export default function PrivateTermsContent() {
    return <div className="termBody">
    <p>주식회사 엔에스엠(이하 "회사"라 함)은 회사가 운영하는 웹 및 모바일 어플리케이션 NSM 매니저 (이하 '앱'라 함)를 이용하는 이용자(서비스를 이용하는 회원 과 비회원, 이하 "이용자"라 함)들의 개인정보를 매우 중요하게 생각하며, 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하고, 관련 법령에 의거한 개인정보 취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.</p>
    <h3>1. 개인정보의 수집 및 이용</h3>
    <ol>
        <li>
            가. "회사"는 회원가입 및 회원인증 후 로그인 시 본인확인, 원활한 고객상담, 각종 서비스의 제공을 위해 최초 회원가입 및 로그인 당시 아래와 같은 개인정보를 수집하고 있습니다.
            <ol >
                <li>① 이메일 주소, 비밀번호, 이름, 연락처 : 본인여부 확인, 약관 및 서비스 변경 등의 고지 및 고객문의처리를 위한 연락 목적</li>
                <li>② 모바일 Push ID : 사용자 구분과 푸시 알림을 위한 목적</li>
            </ol>
        </li>
        <li>나.	"회원"이 제공한 모든 정보는 상기 목적에 필요한 용도 이외로는 사용되지 않으며, 수집정보의 범위나 사용목적, 용도가 변경될 시에는 반드시 "회원"에게 사전 동의를 구합니다.</li>
        <li>
            다. "회사"는 다음과 같은 방법으로 개인정보를 수집합니다.
            <ol >
                <li>① "회사"가 제공하는 서비스 프로그램을 실행 또는 사용함으로써 자동으로 수집</li>
                <li>② 서비스 가입이나 사용 중 이용자의 자발적 제공을 통한 수집</li>
            </ol>
        </li>
    </ol>

    <h3>2. 개인정보 제3자 제공 </h3>
    <ol >
        <li>
            가.	"회사"는 "이용자"들의 개인정보를 「1. 개인정보의 수집 및 이용」에서 고지한 범위 내에서 사용하며, "이용자"의 사전 동의 없이 동 범위를 초과하여 이용하거나 원칙적으로 "이용자"의 개인정보를 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
            <ol>
                <li>① "이용자"들이 사전에 공개 또는 제3자 제공에 동의한 경우</li>
                <li>② '앱'의 이용약관을 위배하거나 '앱'의 '서비스'를 이용하여 타인에게 피해를 주거나 미풍양속을 해치는 위법행위를 한 사람 등에게 법적인 조치를 취하기 위해 개인 정보를 공개해야 한다고 판단되는 경우</li>
                <li>③ 법령의 규정에 의거하거나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독당국의 요구가 있는 경우 </li>
            </ol>
        </li>
    </ol>

    <h3>3. 개인정보의 취급위탁  </h3>
    <ol >
        <li>가. '회사'는 원활하고 향상된 서비스를 위하여 개인정보 취급을 타인에게 위탁할 수 있습니다.</li>
        <li>나. '회사'는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다. 또한 위탁 처리하는 정보는 원활한 '서비스'를 제공하기 위하여 필요한 최소한의 정보에 국한됩니다. </li>
        <li>
            수탁업체 및 위탁 목적
            <ol>
                <li>- 경찰청 : 사기피해 신고여부 확인 </li>
            </ol>
        </li>
    </ol>

    <h3>4.	개인정보의 보유 및 이용기간</h3>
    <ol >
        <li>가.	"이용자"의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.</li>
        <li>
            <ol >
                <li>회사 내부 방침에 의한 정보보유 사유</li>
                <li>- 부정이용기록 보존 이유 : 부정 이용 방지 보존 기간 : 5년  </li>
                <li>- 사기예방을 위해 필요한 기록 보존 이유 : 사기 예방 보존 기간 : 3년  </li>
            </ol>
        </li>
        <li>
            나.	관련법령에 의한 정보보유 사유 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
            <ol >
                <li>- 계약 또는 청약철회 등에 관한 기록 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 보존 기간 : 5년 </li>
                <li>- 대금결제 및 재화 등의 공급에 관한 기록 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 보존 기간 : 5년 </li>
                <li>- 소비자의 불만 또는 분쟁처리에 관한 기록 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률 보존 기간 : 3년</li>
                <li>- 본인확인에 관한 기록 보존 이유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률 보존 기간 : 6개월</li>
                <li>- 방문에 관한 기록 보존 이유 : 통신비밀보호법 보존 기간 : 3개월  </li>
            </ol>
        </li>
    </ol>

    <h3>5. 개인정보 파기절차 및 방법</h3>
    "이용자"의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. "회사"의 개인정보 파기절차 및 방법은 다음과 같습니다.
    <ol >
        <li>
            가.	파기절차
            <ol >
                <li>① 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다. </li>
                <li>② 별도로 옮겨진 개인정보는 법률에 의한 경우를 제외하고는 다른 목적으로 이용되지 않습니다.  </li>
            </ol>
        </li>
        <li>
            나.	파기방법
            <ol >
                <li>① 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. </li>
                <li>② 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.   </li>
            </ol>
        </li>
    </ol>

    <h3>6.	개인정보의 기술적/관리적 보호 대책  </h3>
    "회사"는 "이용자"들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
    <ol >
        <li>
            가.	해킹 등에 대비한 대책
            <ol >
                <li>"회사"는 해킹이나 컴퓨터 바이러스 등에 의해 "회원"의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 "이용자"들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다. </li>
            </ol>
        </li>
        <li>
            나.	취급 직원의 최소화 및 교육
            <ol >
                <li>"회사"의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 회사는 개인정보취급방침의 준수를 항상 강조하고 있습니다. </li>
            </ol>
        </li>
        <li>
            다.	정보보호전담기구의 운영
            <ol >
                <li>사내 개인정보보호전담기구 등을 통하여 "회사"는 개인정보취급방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자’ 본인의 부주의나 인터넷상의 문제로 개인 정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.</li>
            </ol>
        </li>
    </ol>

    <h3>7.	이용자의 권리</h3>
    ‘회원'은 언제든지 본인의 개인정보를 열람 및 정정 삭제할 수 있습니다. '회원'이 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 본인에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.

    <h3 className="mt-3">8.	이용자의 의무 </h3>
    '회원'은 스스로 개인정보를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한 개인정보가 유출되지 않도록 주의를 필요로 하고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해야 합니다. 만약 이 같은 책임을 다하지 못하고 타인의 정보를 훼손할 시에는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 등에 의해 처벌받을 수 있습니다.

    <h3 className="mt-3">9.	쿠키(Cookie)의 운용 및 거부 </h3>
    쿠키의 사용 목적
    <ol >
        <li>가.	'회사'는 개인 맞춤 서비스를 제공하기 위해서 '이용자'에 대한 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 '앱' 서버가 이용자의 브라우저에게 전송하는 소량의 정보로서 '이용자' 컴퓨터의 하드디스크에 저장됩니다.</li>
        <li>나.	회사는 쿠키의 사용을 통해서만 가능한 특정된 맞춤형 서비스를 제공할 수 있습니다. </li>
        <li>다.	"회사"는 회원을 식별하고 회원의 로그인 상태를 유지하기 위해 쿠키를 사용할 수 있습니다. </li>
    </ol>
    쿠키의 설치/운용 및 거부
    <ol >
        <li>가.	'이용자'는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 '이용자'는 웹브라우저에서 옵션을 조정함으로써 모든 쿠키를 허용/거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 할 수 있습니다.</li>
        <li>나.	쿠키의 저장을 거부할 경우에는 개인 맞춤서비스 등 '회사'가 제공하는 일부 서비스는 이용이 어려울 수 있습니다.</li>
    </ol>
    <h3>10.	링크 사이트에 대한 책임</h3>
    ‘회사'는 '이용자'에게 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 이 경우 '회사'는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 진실성, 유용성 등에 대해서는 당사자간 귀책사항으로 '회사'는 어떠한 책임도 지지 않으며, 어떠한 보증도 하지 않습니다. 또한 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "개인정보취급방침"이 적용되지 않으므로 새로 방문한 사이트의 정책을 반드시 검토해 보시기 바랍니다.
    <h3 className="mt-3">11.	고지의 의무</h3>
    현 개인정보취급방침은 정부의 정책 또는 회사의 필요에 따라 변경될 수 있으며, 내용의 추가 삭제 및 수정이 있을 시에는 사전 공지합니다.  사전공지가 곤란할 경우 지체없이 공지하며, 이 정책은 공지한 날로부터 시행됩니다.<br /><br />
    공고일자  2023. 2. 1<br />
    시행일자  2023. 2. 1<br />
</div>
}

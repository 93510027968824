import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../AppContext";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import { StoreInfo } from "../stores/Store.Models";
import Dialog from "../utils/Dialog";
import { PacketCommands } from "../utils/SocketManager";
import { storeUtil } from "../utils/StoreUtil";
import stringUtil from "../utils/StringUtil";
import { cctvVideo } from "./CCTV.Models";

export default function CCTVPlayListPage() {
    const { id } = useParams();
    const { selectedStoreId, socketManager, stores, isSocketConnected } = useContext(AppContext);
    const [store, setStore] = useState<StoreInfo | undefined>(stores.find(s => s._id === selectedStoreId));
    const [isServerLoading, setIsServerLoading] = useState(false);
    const isServerLoadingRef = useRef(false);
    const [isLoading, setIsLoading] = useState(true);
    const isLoadingRef = useRef(true);
    const [isPlayLoading, setIsPlayLoading] = useState(false);
    const isPlayLoadingRef = useRef(false);
    const serverIdRef = useRef<string>("");
    const [selectedServerId, setSelectedServerId] = useState<string>("");
    const totalCCTVVideoListRef = useRef<cctvVideo[]>();
    const [cctvVideoList, setCctvVideoList] = useState<cctvVideo[]>();
    const [type, setType] = useState<string>("all");
    const [keyword, setKeyword] = useState<string>("");
    const [channel, setChannel] = useState(1);
    const channelRef = useRef(1);
    const [channelCount, setChannelCount] = useState(1);
    const channelKey = "playChannel" + id;

    useEffect(() => {
        if(!id) return;
        try{
          const savedChannel = localStorage.getItem(channelKey);
          if(!savedChannel) return;
          channelRef.current = Number.parseInt(savedChannel);
          setChannel(channelRef.current);
        }
        catch(e) {}
    }, []);

    useEffect(() => {
        const newStore = stores.find(s => s._id === selectedStoreId);
        setStore(newStore);
        socketManager?.registerCommandCallBack(PacketCommands.Manager_CCTVInfo, onReceiveCCTVInfo);
        socketManager?.registerCommandCallBack(PacketCommands.Manager_CCTVPlaybackListResponse, onReceiveCCTVList);
        socketManager?.registerCommandCallBack(PacketCommands.Manager_CCTVPlayResponse, onReceiveCCTVPlay);
        initialize(newStore);
        return () => {
            socketManager?.deregisterCommandCallBack(PacketCommands.Manager_CCTVInfo);
            socketManager?.deregisterCommandCallBack(PacketCommands.Manager_CCTVPlaybackListResponse);
            socketManager?.deregisterCommandCallBack(PacketCommands.Manager_CCTVPlayResponse);
        }
    }, [isSocketConnected, selectedStoreId, stores]);

    async function initialize(newStore: StoreInfo | undefined) {
        if (!isSocketConnected || !newStore || !storeUtil.hasCCTVFeature(newStore.features) || !socketManager || !selectedStoreId || !id) return
        if (isServerLoadingRef.current) return;
        isServerLoadingRef.current = true;
        setIsLoading(isServerLoadingRef.current);
        // Unless connection completes in 3 sec, regarded as disconnected;
        setTimeout(() => {
            if (isServerLoadingRef.current) {
                isServerLoadingRef.current = false;
                setIsLoading(isServerLoadingRef.current);
            }
        }, 3000);
        socketManager?.requestCCTVInfo(selectedStoreId, id);
    }

    function getChannelOptions(): JSX.Element[] {
        const options: JSX.Element[] = [];
        for (let i = 0; i < channelCount; i++) {
            options.push(<option key={i + 1} value={i + 1}> 카메라{stringUtil.padZero((i + 1).toString(), 2)} </option>)
        }
        return options;
    }

    function getCCTVList(serverSocketId: string, clientId: string | undefined) {
        if (!selectedStoreId || !serverSocketId || !clientId || !socketManager) return;
        isLoadingRef.current = true;
        setIsLoading(isLoadingRef.current);
        // Unless connection completes in 3 sec, regarded as disconnected;
        setTimeout(() => {
            if (isLoadingRef.current) {
                setCctvVideoList(undefined);
                isLoadingRef.current = false;
                setIsLoading(isLoadingRef.current);
            }
        }, 3000);
        socketManager.requestCCTVList(selectedStoreId, serverSocketId, clientId, channelRef.current);
    }

    function getTypeName(type: string): string {
        if (type === "regular")
            return "상시녹화";
        else if (type === "intrusion")
            return "영역침범";
        else if (type === "traverse")
            return "경계선통과";
        else if (type === "motion")
            return "모션탐지";
        else if (type === "face")
            return "얼굴탐지";
        else if (type === "tampering")
            return "영상가림감지";
        else if (type === "all")
            return "전체";
        else
            return "unknown";
    }

    function getTypeFilteredCCTVList(newType: string): cctvVideo[] {
        if (!totalCCTVVideoListRef.current) return [];
        if (newType === "all")
            return totalCCTVVideoListRef.current.filter(n => true);
        else
            return totalCCTVVideoListRef.current.filter(n => n.type === newType);
    }

    function onChannelChange(newChannelString: string) {
        try {
            const newChannel = Number.parseInt(newChannelString);
            if (newChannel < 1 || newChannel > channelCount) return;
            channelRef.current = newChannel;
            setChannel(channelRef.current);
            try{
                localStorage.setItem(channelKey, channelRef.current.toString());
              }
              catch(e) {}
            getCCTVList(selectedServerId, id);
        }
        catch (e) {
            console.log(e);
        }
    }

    function onFilterType(newType: string) {
        if (!totalCCTVVideoListRef.current) return;
        setKeyword("");
        setCctvVideoList(getTypeFilteredCCTVList(newType));
        setType(newType);
    }

    function onKeywordChange(newKeyword: string) {
        const newKeywordTrimmed = newKeyword.trim();
        setKeyword(newKeywordTrimmed);
        const filteredCCTVVideoList = getTypeFilteredCCTVList(type);
        if (filteredCCTVVideoList.length === 0) return;
        if (newKeywordTrimmed.length === 0)
            setCctvVideoList(filteredCCTVVideoList);
        else
            setCctvVideoList(filteredCCTVVideoList.filter(c => c.displayName.includes(newKeywordTrimmed)));
    }

    function onPlay(fileName: string) {
        const currentServer = store?.servers?.find(s => socketManager?.getServerId(s._id).toString() === selectedServerId);
        if (!currentServer || stringUtil.isNullOrWhiteSpace(currentServer.webIp) || stringUtil.isNullOrWhiteSpace(currentServer.webPort?.toString())) {
            Dialog.showError("선택된 서버에 웹 IP 및 포트정보가 없습니다. 서버수정을 통해 해당 정보를 등록해주세요.");
            return;
        }
        if (!selectedStoreId || !selectedServerId || !socketManager || !id) return;
        isPlayLoadingRef.current = true;
        setIsPlayLoading(isPlayLoadingRef.current);
        // Unless connection completes in 3 sec, regarded as disconnected;
        setTimeout(() => {
            if (isPlayLoadingRef.current) {
                isPlayLoadingRef.current = false;
                setIsPlayLoading(isPlayLoadingRef.current);
                Dialog.showError("서버에서 영상재생정보를 가져오는데 실패했습니다.");
            }
        }, 3000);
        socketManager.requestCCTVPlay(selectedStoreId, selectedServerId, id, channel, fileName);

    }

    function onReceiveCCTVInfo(data: any) {
        // prevent duplicate server response
        if (!id || !isServerLoadingRef.current || serverIdRef.current) return;
        const serverIdPair = data.serverIdPair;
        const clientId = data.clientId;
        const channelCount = data.channelCount;
        if (clientId !== id || channelCount < 1) return;
        if(channelRef.current > channelCount){
            channelRef.current = channelCount;
            setChannel(channelRef.current);
        }
        serverIdRef.current = serverIdPair.socketId.toString();
        setSelectedServerId(serverIdRef.current);
        isServerLoadingRef.current = false;
        setIsServerLoading(isServerLoadingRef.current);
        setChannelCount(channelCount);
        getCCTVList(serverIdRef.current, id);
    }

    function onReceiveCCTVList(cctvList: cctvVideo[]) {
        if (!isLoadingRef.current) return;
        isLoadingRef.current = false;
        setIsLoading(isLoadingRef.current);
        totalCCTVVideoListRef.current = cctvList;
        onFilterType(type);
    }

    function onReceiveCCTVPlay(data: any) {
        if (!isPlayLoadingRef.current) return;
        isPlayLoadingRef.current = false;
        setIsPlayLoading(isPlayLoadingRef.current);
        const serverSocketId: string = data.serverSocketId;
        const path: string = data.path;
        const server = store?.servers?.find(s => socketManager?.getServerId(s._id).toString() === serverSocketId);
        if (!server || !server.webIp || !server.webPort) return;
        const url = `http://${window.location.hostname}:${process.env.REACT_APP_HTTP_PORT}${RouteConfig.cctvPlayPath}?host=${encodeURI(server.webIp + ":" + server.webPort.toString())}&path=${encodeURI(path)}`;
        window.location.href = url;
    }

    function onServerChange(serverSocketId: string) {
        setSelectedServerId(serverSocketId);
        getCCTVList(serverSocketId, id);
    }

    return <div>
        <h4 className="mb-3">CCTV 녹화영상목록</h4>
        {store ? (storeUtil.hasCCTVFeature(store.features) ?
            <>
                <div className="row g-0">
                    <div className="col-md-5">
                        <ComponentToLoad isLoading={isServerLoading}>
                            <>
                                <div className="fw-bold">
                                    영상서버 선택
                                </div>
                                <select className="form-select mb-1" value={selectedServerId} onChange={(e) => onServerChange(e.currentTarget.value)}>
                                    <option value="">서버를 선택해주세요.</option>
                                    {store.servers?.map(s => <option key={s._id} value={socketManager?.getServerId(s._id)}>{s.name}</option>)}
                                </select>
                                {!isLoading &&
                                    <div className="d-flex justify-content-start">
                                        <OkButton className="btn-sm" disabled={isLoading} onClick={() => getCCTVList(selectedServerId, id)} >영상목록갱신</OkButton>
                                    </div>
                                }
                                 { channelCount > 1 && 
                                 <>
                                <div className="fw-bold mt-2">
                                    채널 선택
                                </div>
                                <select className="form-select" value={channel} onChange={e => onChannelChange(e.currentTarget.value)}>
                                    {
                                        getChannelOptions()
                                    }
                                </select>
                                </>
                                }
                                <ComponentToLoad isLoading={isLoading} className="mt-3">
                                    {cctvVideoList ?
                                        <>
                                            <div className="fw-bold">
                                                녹화 이벤트
                                            </div>
                                            <select className="form-select" value={type} onChange={(e) => onFilterType(e.currentTarget.value)}>
                                                <option value="all">전체</option>
                                                {["regular", "motion", "tampering", "intrusion", "traverse"].map(t =>
                                                    <option key={t} value={t}>{getTypeName(t)} </option>
                                                )}
                                            </select>
                                            <div className="mb-3 fw-bold">※ {`[${getTypeName(type)}]`} {cctvVideoList.length}개 영상이 있습니다.</div>
                                            <input value={keyword} onInput={(e) => onKeywordChange(e.currentTarget.value)} className="form-control mb-1" placeholder="파일이름검색 ('2022-12-25', '12-25' 등)"></input>
                                            <div style={{ "height": "500px" }} className="overflow-auto">
                                                {cctvVideoList.map(c => <div key={c.originalName} className="py-2 px-4 bg-gainsboro my-1 rounded">
                                                    <div className={`badge ${c.type === "regular" ? "bg-success" : c.type === "intrusion" ? "bg-danger" : "bg-warning"}`}>{getTypeName(c.type)}</div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {c.displayName} <ComponentToLoadSmallCenter isLoading={isPlayLoading}> <OkButton className="btn-sm" onClick={() => onPlay(c.originalName)}>재생</OkButton></ComponentToLoadSmallCenter>
                                                    </div>
                                                </div>

                                                )}
                                            </div>
                                        </>
                                        :
                                        <div className="text-danger">
                                            해당 서버에서 CCTV 영상 목록을 불러올수 없습니다. CCTV가 연결되어있는지 확인해주세요.
                                        </div>

                                    }

                                </ComponentToLoad>
                            </>
                        </ComponentToLoad>

                    </div>
                </div>
            </>
            :
            <div className="text-danger">
                해당매장에 지원하지 않는 기능입니다.
            </div>)
            :
            <div>
                선택된 매장이 없습니다. 매장을 선택해주세요.
            </div>
        }
    </div>
}


import { Switch } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext from "../AppContext";
import Loading from "../components/Loading";
import OkButton from "../components/OkButton";
import TimeEllapse from "../components/TimeEllapse";
import RouteConfig from "../route-config";
import { app } from "../utils/AppCommnunication";
import { PacketCommands } from "../utils/SocketManager";
import stringUtil from "../utils/StringUtil";
import { webRTCUtil } from "../utils/WebRTCUtil";

export default function VideoCallConnectPage() {
    const {isSocketConnected, socketManager, device} = useContext(AppContext);
    const [storeName, setStoreName] = useState<string|null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [speakerOn, setSpeakerOn] = useState(true);
    const isLoadingRef = useRef(false);
    const videoElement = useRef<HTMLVideoElement>(null);
    const pcRef = useRef<RTCPeerConnection>();
    const navigate = useNavigate();
    const location = useLocation();
    const storeIdRef = useRef<string|null>(null);
    const serverIdRef = useRef<string | null>(null);
    const clientIdRef = useRef<string | null>(null);
    const deviceIdRef = useRef<string | null>(null);
    const iceCandidatesRef = useRef<RTCIceCandidate[]>([]);
    const connectionTimerRef = useRef<NodeJS.Timer>();
    const currentTracksRef = useRef<MediaStreamTrack[]>();

    useEffect(() => {
        getInfoFromURL();
    }, []);

    useEffect(()=>{
        socketManager?.registerCommandCallBack(PacketCommands.Manager_IrisVideoCallOfferRequest, onReceiveOffer);
        socketManager?.registerCommandCallBack(PacketCommands.Manager_IrisVideoCallEnd, onReceiveCallEnd);
        initializeConnection();
        return () => {
        socketManager?.deregisterCommandCallBack(PacketCommands.Manager_IrisVideoCallOfferRequest);
        socketManager?.deregisterCommandCallBack(PacketCommands.Manager_IrisVideoCallEnd);
        }
    }, [isSocketConnected]);

    function getInfoFromURL() {
        const searchParams = new URLSearchParams(location.search);
        const storeName = searchParams.get('name');
        const storeId = searchParams.get('storeId');
        const serverId = searchParams.get('serverId');
        const clientId = searchParams.get('clientId');
        const deviceId = searchParams.get('deviceId');
        if (storeName && storeId && clientId && serverId && deviceId) {
            setStoreName(searchParams.get('name'));
            storeIdRef.current = storeId;
            serverIdRef.current = serverId;
            clientIdRef.current = clientId;
            deviceIdRef.current = deviceId;
        }
    }

    function stopTracks(){
        if(currentTracksRef.current) {
            currentTracksRef.current.forEach(track => track.stop());
            currentTracksRef.current = undefined;
         }
    }

    function initializeConnection(){
        if(!isSocketConnected || !storeIdRef.current || !serverIdRef.current || !clientIdRef.current) return;
        if(isLoadingRef.current) return;
        isLoadingRef.current = true;
        setIsLoading(isLoadingRef.current);

        if(pcRef.current) {
            pcRef.current.close();
            iceCandidatesRef.current = [];
        }
        stopTracks();
        
        pcRef.current = webRTCUtil.getNewPeerConnection(onConnected, onDisconnected);
        pcRef.current.onicecandidate = e => {
            if(e.candidate)
               iceCandidatesRef.current.push(e.candidate);     
        };

        pcRef.current.ontrack = function(event) {
            if(videoElement.current ) {
                videoElement.current.srcObject = event.streams[0];
            }
        };
        
        navigator.mediaDevices.getUserMedia({
            video : false,
            audio : { echoCancellation : true},
        }).catch(r => {
            alert(r);
            alert("마이크 정보를 가져오는데 실패했습니다.");
        }).then(stream => {
            currentTracksRef.current = stream?.getTracks();
            if(!currentTracksRef.current || currentTracksRef.current.length === 0 ){
                alert("마이크 정보를 가져오는데 실패했습니다.");
                return;
                }
           //pcRef.current.addTransceiver('video');
            //pcRef.current.addTransceiver('audio');
            
            currentTracksRef.current.forEach(track => pcRef.current?.addTrack(track) );
            socketManager?.sendIrisVideoCallOffer(storeIdRef.current!, serverIdRef.current!, clientIdRef.current!, deviceIdRef.current!);
        });
    }

    function onCallEndClick(){
        socketManager?.sendIrisVideoCallEnd(storeIdRef.current!, serverIdRef.current!, clientIdRef.current!, deviceIdRef.current!);
        onDisconnected(4);
    }

    function onConnected() {
        socketManager?.sendIrisVideoCallConnected(storeIdRef.current!, serverIdRef.current!, clientIdRef.current!, deviceIdRef.current!);
        setIsLoading(false);
        onSpeakerChange(speakerOn);
        connectionTimerRef.current = setInterval(() => {
           socketManager?.sendIrisVideoCallConnectionState(storeIdRef.current!, serverIdRef.current!, clientIdRef.current!, deviceIdRef.current!);
        }, 1000);
    }

    function onDisconnected(endType? : number){
        if(!pcRef.current) return;
        stopTracks();
        pcRef.current?.close();
        pcRef.current = undefined;
        if(connectionTimerRef.current){
            clearInterval(connectionTimerRef.current);
            connectionTimerRef.current = undefined;
        }
        if(!endType) endType = -1;
        app.send("callEnd", endType);
        if(window.history.length >=2)
            navigate(-2);
        else 
            navigate(RouteConfig.homePath, {replace : true});
    }

    function onSpeakerChange(isOn : boolean){
        if(device?.platform === "ios") return;
        app.send("speakerphone", isOn);
        setSpeakerOn(isOn);
    }


    async function onReceiveCallEnd(data : {clientId : string, state : number}){
        if(!pcRef.current) return;
        if(data.clientId !== clientIdRef.current) return;
        onDisconnected(data.state);
    }

    async function onReceiveOffer(data : any ){
        if(!isLoadingRef.current || !pcRef.current) return;
        const clientId = data.clientId;
        if(clientId !== clientIdRef.current) return;
        const offer = data.offer;
        const candidates = data.candidates; 
        await pcRef.current.setRemoteDescription(offer);
        const answer = await pcRef.current.createAnswer();
        await pcRef.current.setLocalDescription(answer);
        for(let i in candidates){
            pcRef.current.addIceCandidate(candidates[i]);
        }
        const answerString = stringUtil.btoa(JSON.stringify(pcRef.current.currentLocalDescription));
        setTimeout(() => {
            const candidatesString = stringUtil.btoa(JSON.stringify(iceCandidatesRef.current));
            socketManager?.sendIrisVideoCallAnswer(storeIdRef.current!, serverIdRef.current!, clientId, deviceIdRef.current!, answerString, candidatesString);
        }, 500);
    }

    return <div>
        {storeName ?
        <>
        <h4 className="mb-3">[{storeName}] 
        {isLoading ? <span className="ms-2">고객과 연결 중입니다. 잠시만 기다려주세요.</span> : <span className="ms-2">고객과 연결되었습니다. (<TimeEllapse />) </span>  }
        </h4> 
        <div className="d-flex align-items-center mb-3">
        <OkButton onClick={() => onCallEndClick()} className="me-3"> 통화종료</OkButton>
        {device?.platform !== "ios" && <>
        <div>스피커폰</div>
        <Switch checked={speakerOn} onChange={(e, checked)=> onSpeakerChange(checked)}/>
        </>
}
         {isLoading && <div className="ms-2" ><Loading/> </div>}
         </div>
        <video ref={videoElement}  className="border w-100" autoPlay controls playsInline></video>
        </>
        :
        <div className="text-danger">잘못된 정보입니다.</div>
    }

    </div>;
}

import { ErrorMessage, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DisplayErrors from "../components/DisplayErrors";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import AppContext from "../AppContext";
import * as Yup from 'yup'
import OkButton from "../components/OkButton";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import { Checkbox, Slider } from "@mui/material";
import stringUtil from "../utils/StringUtil";
import { ScheduleTime } from "./Component.Models";


export default function ScheduleAddPage(props : {clientId: string, scheduleId? : string, getScheduleURL : string, addScheduleURL : string, updateScheduleURL : string}) {
    const {selectedStoreId, socketManager} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [schedule, setSchedule] = useState<ScheduleTime>({
        _id : "",
        startTime : 0,
        endTime : 1440,
        days : 127,
        isOn : false,
    });
    const navigate = useNavigate();

    const daysDisplay = [
        {name : "일", value : 0x01},
        {name : "월", value : 0x02},
        {name : "화", value : 0x04},
        {name : "수", value : 0x08},
        {name : "목", value : 0x10},
        {name : "금", value : 0x20},
        {name : "토", value : 0x40},
    ];

    useEffect(() => {
        getScheduleInfo(props.scheduleId);
    }, [selectedStoreId, props.scheduleId]);

    async function getScheduleInfo(scheduleId : string | undefined) {
        setIsLoading(true)
        if(selectedStoreId && scheduleId){
        try {
            const schedule = await HttpRequest.get<ScheduleTime>(props.getScheduleURL, {params : {storeId : selectedStoreId, clientId : props.clientId, scheduleId : scheduleId}});
            setSchedule(schedule);
        }
        catch(error) {
            var message = "정보를 가져오는데 실패했습니다.\n";
            if(error?.response?.data){
                message += error.response.data;
            }
            Dialog.showError(message);
        }
        }
        setIsLoading(false);
    }

    function onSubmit(schedule : ScheduleTime) {
        Dialog.showDialog(`해당 스케줄을 ${props.scheduleId ? "수정" : "등록"}하시겠습니까?`, async (result)=>{
            if(result) {
                setIsUpdating(true);
            try {
                setErrors([]);
                const path = props.scheduleId ? props.updateScheduleURL : props.addScheduleURL;
                await HttpRequest.post(`${path}/${selectedStoreId}/${props.clientId}`, schedule);
                Dialog.showConfirmation(`스케줄 ${props.scheduleId ? "수정" : "등록"}이 완료되었습니다.`);
                socketManager?.sendStoreUpdate(selectedStoreId);
                navigate(-1);
            }
            catch(error) {
                if(error?.response?.data){
                    setErrors(error.response.data);
                }
                else{
                    setErrors(["서버연결에 실패했습니다."])
                }
            }
            setIsUpdating(false);
        }
        });
    }
    function toSliderText(index : number){
        return stringUtil.toHourMinutesFromMinutes(index);
    }

    return  <div className='row'>
    <div className='col-lg-5'>
    <h3 className="mb-3">{props.scheduleId ? "스케줄 수정" : "스케줄 등록"} </h3>
    { selectedStoreId ?
    <ComponentToLoad isLoading={isLoading}>
    <>
    <Formik initialValues={schedule} onSubmit={onSubmit} enableReinitialize
    validationSchema={
        Yup.object({
        days: Yup.number().min(1, "시작요일을 선택해주세요.").max(127, "요일 선택값이 올바르지않습니다."),
       })
    }>
        {(formikProps) => 
            <Form>
                <div>시작요일선택</div>
                <ErrorMessage name="days">{msg =>
            <div className="text-danger">{msg}</div>
        }</ErrorMessage>
                <div className="d-flex justify-content-center mb-2">
                {daysDisplay.map((v, index)=> 
                <Checkbox key={index} checked={(formikProps.values.days & v.value) > 0 } onBlur={formikProps.handleBlur} name="days" onChange={(e, checked)=> {
                    if(checked) {
                        formikProps.setFieldValue("days", formikProps.values.days | v.value);
                    }
                    else{
                        formikProps.setFieldValue("days", formikProps.values.days & ~v.value);
                    }
                }}  icon={<div className="border border-secondary rounded p-1">{v.name}</div>} checkedIcon={<div className="border rounded p-1 bg-primary text-white">{v.name}</div>} />
                )}
                </div>
                <div>적용시작시간</div>
                <div className="mx-2">
                <Slider min={0} max={1440} step={10} valueLabelDisplay="auto" valueLabelFormat={toSliderText} value={formikProps.values.startTime} onChange={(e, v) => formikProps.setFieldValue("startTime", v)}  ></Slider>
                </div>
                <div>적용종료시간</div>
                <div className="mx-2">
                <Slider min={0} max={1440} step={10} valueLabelDisplay="auto" valueLabelFormat={toSliderText} value={formikProps.values.endTime} onChange={(e, v) => formikProps.setFieldValue("endTime", v)}></Slider>
                </div>
                    <div className="d-flex align-items-center">
                        <span className="me-2">
                    ※ 적용시간 : 
                    </span>
                    <div className="text-primary fw-bold">
                         {stringUtil.toScheduleTimeString(formikProps.values.startTime, formikProps.values.endTime)}
                    </div>
                    </div>
                <ComponentToLoadSmallCenter isLoading={isUpdating} className="mt-4">
                <OkButton type="submit" className="w-100" disabled={isUpdating}>{props.scheduleId ? "수정하기" : "등록하기"}</OkButton>
                </ComponentToLoadSmallCenter>
            </Form>
        }
    </Formik>
    <DisplayErrors className="mt-2" errors={errors} />
    </>
    </ComponentToLoad>
    :
    <div>
            선택된 매장이 없습니다. 매장을 선택해주세요.
            </div>
}
</div> 
    </div>
}

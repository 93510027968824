import { Chip } from "@mui/material";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { number } from "yup/lib/locale";
import AppContext from "../AppContext";
import CancelButton from "../components/CancelButton";
import CollapseIcon from "../components/CollapseIcon";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import ConnectionComponent from "../stores/ServerConnectionComponent";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import { SocketManager } from "../utils/SocketManager";
import { storeUtil } from "../utils/StoreUtil";
import stringUtil from "../utils/StringUtil";
import DoorSensorStateComponent from "./DoorSensorStateComponent";
import { IoTClient } from "./Iots.Models";

function IotStateComponent(props: IotStateComponentProps) {
    const { socketManager } = useContext(AppContext);
    const [isConnected, setIsConnected] = useState(props.iot.isConnected ?? false);
    const [sendLoading, setSendLoading] = useState(false);
    const connectionCountRef = useRef(0);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [modeLoading, setModeLoading] = useState(false);
    const [temperatureLoading, setTemperatureLoading] = useState(false);
    const [powerLoading, setPowerLoading] = useState(false);
    const [powerModeLoading, setPowerModeLoading] = useState(false);
    const [temperatureRangeLoading, setTemperatureRangeLoading] = useState(false);
    const [fanSpeedLoading, setFanSpeedLoading] = useState(false);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [airConPower, setAirConPower] = useState((props.iot.power ? 1 : 0).toString());
    const [airConPowerMode, setAirConPowerMode] = useState((props.iot.powerMode ?? 0).toString());
    const [airConCoolMode, setAirConCoolMode] = useState((props.iot.mode ?? 0).toString());
    const [airConFanSpeed, setAirConFanSpeed] = useState((props.iot.fanSpeed ?? -1).toString());
    const [airConTemperature, setAirConTemperature] = useState((props.iot.temperature ?? 0).toString());
    const [airConTemperatureRange, setAirConTemperatureRange] = useState((props.iot.temperatureRange ?? 1).toString());
    const navigate = useNavigate();

    useEffect(() => {
        let interval: any;
        if (props.iot.isConnected) {
            setIsConnected(true);
            setSendLoading(false);
            connectionCountRef.current = 0;
        }
        else {
            setSendLoading(true);
            interval = setInterval(() => {
                if (connectionCountRef.current > 1) {
                    clearInterval(interval);
                    setIsConnected(false);
                }
                else {
                    connectionCountRef.current++;
                }
            }, 1000);
        }
        return () => { clearInterval(interval) }

    }, [props.iot.isConnected]);


    function getPowerModeGuide(mode: string): JSX.Element {
        let settings = "";
        if(airConCoolMode === "0")
            settings += "냉난방모드";
        if(airConTemperature === "0"){
            if(settings)
               settings += ", "
            settings += "온도";
        }
        if(airConFanSpeed === "-1"){
            if(settings)
               settings += ", "
            settings += "풍량";
        } 
        if(settings && (mode !== "0" || airConPower !== "0"))
            return <span className="text-danger"> {`※ ${settings} 설정 후 해당기능 이용가능합니다.`}</span>;

        if (mode === "1")
            return <span>※ 정해진 스케줄 시간에만 전원을 켭니다. </span>;
        else if (mode === "2") {
            if (airConCoolMode === "0")
                return <span className="text-danger"> ※ 냉난방 설정 후 해당기능 이용가능합니다. </span>;
            else if (airConTemperature === "0")
                return <span className="text-danger"> ※ 온도 설정 후 해당기능 이용가능합니다. </span>;
            else if (airConCoolMode === "1") {
                let temp = 0;
                let diff = 0;
                try {
                    temp = Number.parseInt(airConTemperature);
                    diff = Number.parseInt(airConTemperatureRange);
                }
                catch (e) { }
                return <span>{` ※ ${temp + diff}도 이상에서 전원이 켜지고, ${temp - diff}도 이하에서 전원이 꺼집니다.`} </span>;

            }
            else if (airConCoolMode === "2") {
                let temp = 0;
                let diff = 0;
                try {
                    temp = Number.parseInt(airConTemperature);
                    diff = Number.parseInt(airConTemperatureRange);
                }
                catch (e) { }
                return <span>{` ※ ${temp - diff}도 이하에서 전원이 켜지고, ${temp + diff}도 이상에서 전원이 꺼집니다.`} </span>;
            }
            return <span> ※ 온도 설정 후 해당기능 이용가능합니다. </span>;
        }
        else
            return <span> ※ 수동으로 리모콘 신호를 보냅니다. </span>;
    }

    function getSendButtonDisabled(): boolean{
        return airConPowerMode !== "0" || (airConPower === "1" && (airConCoolMode === "0" || airConTemperature === "0" || airConFanSpeed === "-1"))
    }

    async function onChangeFanSpeed(fanSpeed: string) {
        setFanSpeedLoading(true);
        sendLoadingSet();
        try {
            const fanSpeedNumber = Number.parseInt(fanSpeed);
            await HttpRequest.put(`${ServerAPI.storeIotUpdateIRFanSpeed}/${props.storeId}`, { _id: props.iot._id, fanSpeed: fanSpeedNumber });
            setAirConFanSpeed(fanSpeed);
            socketManager?.sendStoreUpdate(props.storeId);
        }
        catch (error) {
            let message = "서버연결에 실패했습니다.";
            if (error?.response?.data) {
                message = error.response.data;
            }
            Dialog.showError(message);
        }
        finally {
            setFanSpeedLoading(false);
        }
    }

    async function onChangeMode(mode: string) {
        setModeLoading(true);
        sendLoadingSet();
        try {
            const modeNumber = Number.parseInt(mode);
            await HttpRequest.put(`${ServerAPI.storeIotUpdateIRMode}/${props.storeId}`, { _id: props.iot._id, mode: modeNumber });
            setAirConCoolMode(mode);
            socketManager?.sendStoreUpdate(props.storeId);
        }
        catch (error) {
            let message = "서버연결에 실패했습니다.";
            if (error?.response?.data) {
                message = error.response.data;
            }
            Dialog.showError(message);
        }
        finally {
            setModeLoading(false);
        }
    }

    async function onChangePower(power: string) {
        setPowerLoading(true);
        sendLoadingSet();
        try {
            let powerBoolean = false;
            if (power === "0")
                powerBoolean = false;
            else if (power === "1")
                powerBoolean = true;
            else
                return;
            
            await HttpRequest.put(`${ServerAPI.storeIotUpdateIRPower}/${props.storeId}`, { _id: props.iot._id, power: powerBoolean });
            setAirConPower(power);
            socketManager?.sendStoreUpdate(props.storeId);
        }
        catch (error) {
            let message = "서버연결에 실패했습니다.";
            if (error?.response?.data) {
                message = error.response.data;
            }
            Dialog.showError(message);
        }
        finally {
            setPowerLoading(false);
        }
    }

    async function onChangePowerMode(mode: string) {
        setPowerModeLoading(true);
        sendLoadingSet();
        try {
            const modeNumber = Number.parseInt(mode);
            await HttpRequest.put(`${ServerAPI.storeIotUpdateIRPowerMode}/${props.storeId}`, { _id: props.iot._id, powerMode: modeNumber });
            setAirConPowerMode(mode);
            socketManager?.sendStoreUpdate(props.storeId);
        }
        catch (error) {
            let message = "서버연결에 실패했습니다.";
            if (error?.response?.data) {
                message = error.response.data;
            }
            Dialog.showError(message);
        }
        finally {
            setPowerModeLoading(false);
        }
    }

    async function onChangeTemperature(temperature: string) {
        setTemperatureLoading(true);
        sendLoadingSet();
        try {
            const temperatureNumber = Number.parseInt(temperature);
            await HttpRequest.put(`${ServerAPI.storeIotUpdateIRTemperature}/${props.storeId}`, { _id: props.iot._id, temperature: temperatureNumber });
            setAirConTemperature(temperature);
            socketManager?.sendStoreUpdate(props.storeId);
        }
        catch (error) {
            let message = "서버연결에 실패했습니다.";
            if (error?.response?.data) {
                message = error.response.data;
            }
            Dialog.showError(message);
        }
        finally {
            setTemperatureLoading(false);
        }
    }

    async function onChangeTemperatureRange(range: string) {
        setTemperatureRangeLoading(true);
        try {
            const rangeNumber = Number.parseInt(range);
            await HttpRequest.put(`${ServerAPI.storeIotUpdateIRTemperatureRange}/${props.storeId}`, { _id: props.iot._id, temperatureRange: rangeNumber });
            setAirConTemperatureRange(range);
            socketManager?.sendStoreUpdate(props.storeId);
        }
        catch (error) {
            let message = "서버연결에 실패했습니다.";
            if (error?.response?.data) {
                message = error.response.data;
            }
            Dialog.showError(message);
        }
        finally {
            setTemperatureRangeLoading(false);
        }
    }

    function onSendClick() {
        try {
            let powerOn = false;
            if(airConPower === "1")
                powerOn = true;
            let mode = 0;
            let temperature = 18;
            let fanSpeed = 1;
            if(powerOn){
                mode = Number.parseInt(airConCoolMode) - 1;
                temperature = Number.parseInt(airConTemperature);
                fanSpeed = Number.parseInt(airConFanSpeed);
            }
            socketManager?.sendIRTransmitterRequest(props.storeId, props.iot._id, powerOn, mode, temperature, fanSpeed);
            Dialog.showConfirmation(`리모콘 신호가 전달되었습니다.`);
        }
        catch (e) {
            Dialog.showError("에러가 발생했습니다.\n\n" + e);
        }
    }

    function onUpdateClick() {
        try {
            socketManager?.sendIoTUpdate(props.storeId, props.iot._id);
            Dialog.showConfirmation(`업데이트 신호가 전달되었습니다. 업데이트 중에는 연결이 잠시 끊길 수 있습니다.`);
        }
        catch (e) {
            Dialog.showError("에러가 발생했습니다.\n\n" + e);
        }
    }

    function sendLoadingSet(){
        setSendLoading(true);
        setTimeout(()=> setSendLoading(!(props.iot.isConnected ?? false)), 1500);
    }

    return <div className="bg-gainsboro p-3 mb-3 rounded-4">
        <div className="row g-0 align-items-center">
            <h4 className="col-auto text-violet fw-bold mb-0">{props.iot.name || `기기 ${props.iot._id}`}</h4>
            <CollapseIcon className="col-auto ms-2" isOpen={isDetailOpen} setIsOpen={setIsDetailOpen} />
        </div>
        <div className="mt-1 fw-bold">
            ※ 지원기능 : {storeUtil.featureToString(props.iot.features)}
        </div>
        {isDetailOpen &&
            <div className="mt-1 border border-dark rounded p-2 bg-floralwhite">
                <ul className="mb-0">
                    <li>등록일시 : {stringUtil.toStringFullDateTimeNoSeconds(props.iot.registerDate)}</li>
                    <li>MAC 주소 : {props.iot._id}</li>
                    <li>버전 : {props.iot.version}</li>
                </ul>
                <div className="d-flex mt-1 ms-3">
                    <OkButton className="btn-sm me-2" onClick={() => {
                        navigate(`${RouteConfig.iotUpdatePath}/${props.iot._id}`);
                    }}>이름변경</OkButton>
                    <ComponentToLoadSmallCenter isLoading={deleteLoading} >
                        <CancelButton className="btn-sm me-2" disabled={deleteLoading} onClick={() => {
                            Dialog.showDialog("해당 기기를 삭제하시겠습니까?", async (result) => {
                                if (result) {
                                    setDeleteLoading(true);
                                    await props.onDelete(props.iot._id);
                                    setDeleteLoading(false);
                                }
                            });
                        }}>기기삭제</CancelButton>
                    </ComponentToLoadSmallCenter>
                    <OkButton className="btn-sm" onClick={() => onUpdateClick()}>업데이트</OkButton>
                </div>
            </div>
        }
        <ConnectionComponent className="mt-1" isConnected={props.serverConnected && isConnected === true}
            noConnectionElement={<div className="text-danger">응답없음 </div>}
            connectionElement={<>연결 됨.</>}
        />

        {props.iot.doorSensorNames && props.iot.doorSensorNames.length > 0 &&
            <div className="mt-2 p-2 border border-dark rounded bg-floralwhite">
                <div className="mb-1 text-blue">도어센서목록</div>
                <div className="d-flex flex-wrap" style={{ 'fontSize': '0.9rem' }}>
                    {props.iot.doorSensorNames.map((v, index) =>
                        <DoorSensorStateComponent state={(props.serverConnected && isConnected === true && props.iot.doorSensorStates && props.iot.doorSensorStates.length > index) ? props.iot.doorSensorStates[index] : 0}
                            key={index} name={props.iot.doorSensorNames![index] || `도어센서${index + 1}`} />
                    )}
                </div>
            </div>
        }

        {
            props.serverConnected && isConnected && storeUtil.hasAirConditionerFeature(props.iot.features) &&
            <>
                <div className="mt-2 p-3 border border-dark rounded bg-floralwhite">
                    <Chip className="mb-2" label={`현재온도 : ${props.iot.currentTemperature}도`} variant="outlined" color="info" />
                    <div className="row g-0 align-items-center justify-content-between">
                    <div className="col">
                            <div className="row g-0 align-items-center justify-content-between">
                                <div className="col-4 d-flex justify-content-end">
                                    <div className="me-2">
                                        조작모드
                                    </div>
                                </div>
                                <div className="col-8 row g-0">
                                    <ComponentToLoadSmallCenter isLoading={powerModeLoading}>
                                        <select className="form-select text-center" value={airConPowerMode} onChange={e => onChangePowerMode(e.currentTarget.value)}>
                                            <option value="0">수동</option>
                                            <option value="1">자동(스케줄적용)</option>
                                            <option value="2">자동(현재온도적용)</option>
                                        </select>
                                    </ComponentToLoadSmallCenter>
                                </div>
                            </div>
                    
                        </div>
                        <div className="col-md">
                            {
                                airConPowerMode === "0" &&
                        <div className="row g-0 align-items-center mt-2 mt-md-0">
                                <div className="col-4 d-flex justify-content-end">
                                    <div className="me-2">
                                        전원설정
                                    </div>
                                </div>
                                <div className="col-8 row g-0">
                                    <ComponentToLoadSmallCenter isLoading={powerLoading}>
                                        <select className="form-select text-center" value={airConPower} onChange={e => onChangePower(e.currentTarget.value)}>
                                           <option value="0">전원끄기</option>
                                            <option value="1">전원켜기</option>
                                        </select>
                                    </ComponentToLoadSmallCenter>
                                </div>
                            </div>
                            }

                        </div>
                    </div>
                    <div className="text-primary ms-2 ms-md-5">
                                {getPowerModeGuide(airConPowerMode)}
                            </div>
                    <div className="row g-0 align-items-center justify-content-between mt-2">
                        <div className="col">
                            <div className="row g-0 align-items-center">
                                <div className="col-4 d-flex justify-content-end">
                                    <div className="me-2">
                                        냉난방모드
                                    </div>
                                </div>
                                <div className="col-8 row g-0 ">
                                    <ComponentToLoadSmallCenter isLoading={modeLoading}>
                                        <select className="form-select text-center w-100" value={airConCoolMode} onChange={e => onChangeMode(e.target.value)} >
                                            <option value="0" className="text-center">선택없음</option>
                                            <option value="1">냉방</option>
                                            <option value="2">난방</option>
                                        </select>
                                    </ComponentToLoadSmallCenter>
                                </div>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="row g-0 align-items-center mt-2 mt-md-0">
                                <div className="col-4 d-flex justify-content-end">
                                    <div className="me-2">
                                        온도
                                    </div>
                                </div>
                                <div className="col-8 row g-0">
                                    <ComponentToLoadSmallCenter isLoading={temperatureLoading}>
                                        <select className="form-select text-center" value={airConTemperature} onChange={e => onChangeTemperature(e.currentTarget.value)}>
                                            <option value="0">선택없음</option>
                                            {storeUtil.getAvailableTemperature().map(n =>
                                                <option value={n} key={n}>{n}도</option>
                                            )}
                                        </select>
                                    </ComponentToLoadSmallCenter>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-0 mt-2">
                        <div className="col">
                        <div className="row g-0 align-items-center">
                                <div className="col-4 d-flex justify-content-end">
                                    <div className="me-2">
                                        풍량
                                    </div>
                                </div>
                                <div className="col-8 row g-0 ">
                                    <ComponentToLoadSmallCenter isLoading={fanSpeedLoading}>
                                        <select className="form-select text-center w-100" value={airConFanSpeed} onChange={e => onChangeFanSpeed(e.target.value)} >
                                            <option value="-1" className="text-center">선택없음</option>
                                            <option value="1">1단</option>
                                            <option value="2">2단</option>
                                            <option value="3">3단</option>
                                            <option value="4">4단</option>
                                        </select>
                                    </ComponentToLoadSmallCenter>
                                </div>
                            </div>
                        </div>
                        <div className="col-md mt-1 mt-md-0">
                            {airConPowerMode === "1" ?
                                <div className="row g-0 h-100 ms-2 align-items-center justify-content-end justify-content-md-start my-2 my-md-0">
                                    <div className="col" >
                                        <OkButton className="btn-sm w-100" onClick={() => navigate(`${RouteConfig.iotSchedulePath}/${props.iot._id}`)} >스케줄조정</OkButton>
                                    </div>
                                </div>
                                : airConPowerMode === "2" ?
                                    <div className="row g-0 align-items-center mt-2 mt-md-0">
                                        <div className="col-4 d-flex justify-content-end">
                                            <div className="me-2">
                                                허용범위
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <ComponentToLoadSmallCenter isLoading={temperatureRangeLoading}>
                                                <select className="form-select text-center" value={airConTemperatureRange} onChange={e => onChangeTemperatureRange(e.currentTarget.value)}>
                                                    <option value="1">(+/-) 1도</option>
                                                    <option value="2">(+/-) 2도</option>
                                                    <option value="3">(+/-) 3도</option>
                                                    <option value="4">(+/-) 4도</option>
                                                    <option value="5">(+/-) 5도</option>
                                                </select>
                                            </ComponentToLoadSmallCenter>

                                        </div>
                                    </div>
                                    :
                                    <div className="row g-0 h-100 ms-2 align-items-center my-2 my-md-0">
                                        <ComponentToLoadSmallCenter className="col"  isLoading={sendLoading}>
                                        <OkButton className="btn-sm w-100" onClick={() => onSendClick()} disabled={getSendButtonDisabled()}>리모콘 신호보내기</OkButton>
                                        </ComponentToLoadSmallCenter>
                                    </div>

                            }
                        </div>
                    </div>
                </div>
            </>
        }
    </div>
}

interface IotStateComponentProps {
    iot: IoTClient,
    onDelete(iotId: string): Promise<void>,
    serverConnected: boolean,
    storeId: string
}

export default IotStateComponent;
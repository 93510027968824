import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../AppContext";
import CancelButton from "../components/CancelButton";
import CollapseIcon from "../components/CollapseIcon";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton"
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import { StoreInfo } from "../stores/Store.Models";
import Dialog from "../utils/Dialog"
import HttpRequest from "../utils/HttpRequest";
import { CallDevice, MyDevice } from "./VideoCall.Models";

export default function CallDeviceSetupPage() {
    const { selectedStoreId, stores} = useContext(AppContext);
    const [store, setStore] = useState<StoreInfo>();
    const navigate = useNavigate();
    const [callDevices, setCallDevices] = useState<CallDevice[]>();
    const [myDevices, setMyDevices] = useState<MyDevice[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [isMyDeviceListOpen, setIsMyDeviceListOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        setStore(stores.find(s => s._id === selectedStoreId));
        updateCallDeviceList();
    }, [stores, selectedStoreId]);


    async function updateCallDeviceList() {
        if (!selectedStoreId) return;
        setIsLoading(true);
        setErrors([]);
        try {
            const storeAndMyDevices = await HttpRequest.get<StoreAndMyDevices>(`${ServerAPI.storeVideoCallGetDeviceList}/${selectedStoreId}`);
            for(let i = 0; i<storeAndMyDevices.myDevices.length; i++) {
                const storeDevice = storeAndMyDevices.storeDevices.find(s => s.deviceId === storeAndMyDevices.myDevices[i]._id);
                if(storeDevice) {
                    storeAndMyDevices.myDevices[i].isRegistered = true;
                    if(storeAndMyDevices.myDevices[i].deviceName !== storeDevice.deviceName)
                    storeAndMyDevices.myDevices[i].deviceName = `[${storeDevice.deviceName}] ${storeAndMyDevices.myDevices[i].deviceName}`;
                }

            }
            setCallDevices(storeAndMyDevices.storeDevices);
            setMyDevices(storeAndMyDevices.myDevices);
        }
        catch (error) {
            setCallDevices(undefined);
            setMyDevices(undefined);
            if (error?.response?.data) {
                setErrors(error.response.data);
            }
            else {
                setErrors(["서버연결에 실패했습니다."])
            }
        }
        setIsLoading(false);
    }

    async function onDelete(id: string | undefined) {
        if(!id || !selectedStoreId) return;
        try {
            await HttpRequest.delete(`${ServerAPI.storeVideoCallDeleteDevice}`, { params: { storeId: selectedStoreId, id: id } });
            Dialog.showConfirmation("삭제가 완료되었습니다.");
            updateCallDeviceList();
        }
        catch (error) {
            let msg = "에러가 발생했습니다.\n"
            if (error?.response?.data) {
                msg += error.response.data;
            }
            Dialog.showError(msg);
        }
    }

    return <div>
        <h3 >영상통화 설정</h3>
        <div className="text-primary">※ 홍채인증기기로부터 영상통화를 연결할 기기등록 및 우선순위를 설정합니다.</div>
        <div className="row mt-3">
            <div className="col-lg-8">
            {store ?
                <>
                    <OkButton className="btn-sm mb-2" onClick={() => {
                        setIsMyDeviceListOpen(!isMyDeviceListOpen);
                    }}>
                        <div className="d-flex align-items-center">
                        내 기기 등록 <CollapseIcon isOpen={isMyDeviceListOpen} setIsOpen={setIsMyDeviceListOpen} className="text-light"></CollapseIcon> 
                        </div>
                        </OkButton>
                    {isMyDeviceListOpen &&<ComponentToLoad isLoading={isLoading}>
                        <div className="border border-dark rounded p-2">
                            {myDevices?
                            <>
                            <div> 총 {myDevices.filter(s => s.isRegistered !== true).length}개 기기 등록이 가능합니다.</div>
                            <div className="row g-0 row-cols-1 row-cols-md-2">
                            {myDevices.map(s => 
                            <div className="col p-1" key={s._id!}>
                            <div className="rounded bg-gainsboro p-2 h-100">
                                {s.isRegistered ?
                                <div className="text-danger">※ 이미 등록된 기기입니다.</div>
                                :
                                <OkButton className="btn-sm" onClick={()=>navigate(`${RouteConfig.storeCallDeviceAddPage}/${s._id}`)}>등록</OkButton>
                                }
                                <div className="mt-1">{s.deviceName} ({s.platform})</div>
                                </div>
                                </div>
                            )
                            }
                            </div>
                            </>
                            :<DisplayErrors errors={errors} />
                    }
                        </div>
                    </ComponentToLoad>
                    }
                    <ComponentToLoad className="mt-2" isLoading={isLoading}>
                        {callDevices ?
                            <div >
                                <div className="fw-bold">
                                    총 {callDevices.length}개 기기가 등록되어 있습니다.
                                </div>
                                {callDevices.map(s =>
                                    <div key={s._id} className="bg-gainsboro rounded p-3 mt-1">
                                        {s.isDeleted &&
                                        <div className="text-danger fw-font">
                                            ※ 삭제되었거나 사용자가 변경된 기기로 연결불가능합니다.
                                        </div>
                                        }
                                        <div className="row g-0 align-items-center">
                                            <div className="col-auto badge bg-success me-2">우선순위 : {s.priority} </div>
                                            <div className="col-auto text-violet fw-bold">
                                                {s.deviceName} </div>
                                                <div className="col-md d-flex justify-content-md-end my-2 my-md-0">
                                            <OkButton className="btn-sm me-1" onClick={() => {
                                                navigate(`${RouteConfig.storeCallDeviceUpdatePage}/${s._id!}`);
                                            }}>정보 수정</OkButton>
                                            <ComponentToLoadSmallCenter isLoading={deleteLoading} >
                                                <CancelButton className="btn-sm" disabled={deleteLoading} onClick={() => {
                                                       Dialog.showDialog("해당 기기를 삭제하시겠습니까?", async (result) => {
                                                          if(result) {
                                                            setDeleteLoading(true);
                                                            await onDelete(s._id);
                                                            setDeleteLoading(false);
                                                          }
                                                       });
                                                }}>삭제</CancelButton>
                                            </ComponentToLoadSmallCenter>
                                        </div>
                                        </div>
                                        <ul className="mb-1">
                                            <li>소속계정 : {s.email}</li>
                                            <li>대기시간 : {s.interval}초</li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            :
                            <DisplayErrors errors={errors} />}
                    </ComponentToLoad>
                </>
                :
                <div>
                    선택된 매장이 없습니다. 매장을 선택해주세요.
                </div>
            }
            </div>
        </div>
    </div>
}

interface StoreAndMyDevices {
    storeDevices : CallDevice[],
    myDevices : MyDevice[],
}

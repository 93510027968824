import { Pagination } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../AppContext";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import DateSelection from "../components/DateSelection";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton"
import { StoreInfo } from "../stores/Store.Models";
import { dateUtil } from "../utils/DateUtil";
import Dialog from "../utils/Dialog";
import { PacketCommands } from "../utils/SocketManager";
import stringUtil from "../utils/StringUtil";
import { IrisLog} from "./Iris.models";

export default function IrisLogPage() {
    const { selectedStoreId, socketManager, stores, isSocketConnected } = useContext(AppContext);
    const [store, setStore] = useState<StoreInfo | undefined>(stores.find(s => s._id === selectedStoreId));
    const [logs, setLogs] = useState<IrisLog[]>();
    const [isLoading, setIsLoading] = useState(false);
    const isLoadingRef = useRef(false);
    const [errors, setErrors] = useState<string[]>([]);
    const startDateInit = new Date();
    startDateInit.setDate(startDateInit.getDate()-7);
    const [startDate, setStartDate] = useState<Date>(startDateInit);
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);
    const timeOutIdRefForUpdatingList = useRef<NodeJS.Timeout>();
    const serverIdPairRef = useRef<any>();
    const startDateParameterRef = useRef<string>();
    const endDateParameterRef = useRef<string>();
    const pageParameterRef = useRef<number>();
    const maxCountPerPage = 10;

    useEffect(() => {
        socketManager?.registerCommandCallBack(PacketCommands.Manager_Iris_State, onReceiveState);
        socketManager?.registerCommandCallBack(PacketCommands.Manager_IrisLog, onReceiveLog);
        setStore(stores.find(s => s._id === selectedStoreId));
        updateList(page);
        return () => {
            socketManager?.deregisterCommandCallBack(PacketCommands.Manager_Iris_Get_Member_List)
            socketManager?.deregisterCommandCallBack(PacketCommands.Manager_IrisLog)
        }
    }, [stores, selectedStoreId, isSocketConnected]);

    function getPageCount() : number{
        let pageCount = Math.trunc(count / maxCountPerPage);
        if(count % maxCountPerPage !== 0) pageCount++;
        return pageCount;
    }


    function getResultName(type: number) {
        switch (type) {
            case 0:
                return "인증성공";
            case 2:
                return "인증실패:활성감지실패";
            case 3:
                return "인증실패:비활성사용자";
            case 4:
                return "인증실패:시간초과";
            case 5:
                return "인증실패:성인인증데이터없음";
            case 6:
                return "인증실패:성인인증실패";
            default:
                return "인증실패";
        }

    }


    function updateList(pageNum : number) {
        if (!store || !store.irisClient) return;
        if (startDate.getTime() > endDate.getTime()) {
            Dialog.showError("종료날짜가 시작날짜보다 이전입니다.");
            return;
        }
        else if (!dateUtil.isInOneMonth(startDate, endDate)) {
            Dialog.showError("검색기간이 1개월을 초과할 수 없습니다.");
            return;
        }
        isLoadingRef.current = true;
        serverIdPairRef.current = undefined;
        setIsLoading(isLoadingRef.current);
        setErrors([]);
        pageParameterRef.current = pageNum;
        startDateParameterRef.current = new Date(startDate.toDateString()).toISOString();
        const endTimeStamp = new Date(endDate.toDateString());
        endTimeStamp.setDate(endTimeStamp.getDate() + 1);
        endDateParameterRef.current = endTimeStamp.toISOString();
        socketManager?.sendIrisState(store._id, store.irisClient._id);
        timeOutIdRefForUpdatingList.current = setTimeout(() => {
            if (isLoadingRef.current) {
                isLoadingRef.current = false;
                serverIdPairRef.current = undefined;
                setIsLoading(isLoadingRef.current);
                setLogs(undefined);
                setCount(0);
                setPage(1);
                setErrors(["기기 응답이 없어 작업에 실패했습니다."]);
            }
        }, 3000);
    }

    function onReceiveLog(data: { clientId: string, count : number, logList: IrisLog[] }) {
        if (!store?.irisClient || store.irisClient._id !== data.clientId) return;
        if (isLoadingRef.current) {
            isLoadingRef.current = false;
            setIsLoading(isLoadingRef.current);
            setCount(data.count);
            setLogs(data.logList);
        }
    }

    function onReceiveState(data: any) {
        if (!isLoadingRef.current || serverIdPairRef.current || !store?.irisClient?._id || startDateParameterRef.current === undefined 
            || endDateParameterRef.current === undefined || pageParameterRef.current === undefined) return;
        serverIdPairRef.current = data.serverIdPair;
        socketManager?.sendIrisLog(serverIdPairRef.current, store?.irisClient._id, startDateParameterRef.current, endDateParameterRef.current, pageParameterRef.current);
    }

    function onPageChange(newPage : number){
        setPage(newPage);
        updateList(newPage);
    }

    return <div>
        <h3>출입로그 {store?.irisClient && <> ({store.irisClient.name}) </>}</h3>
        <div className="mt-3">
            {store ? store.irisClient ?
                <div className="row g-0">
                    <div className="col-lg-6" >
                        <div className="border border-primary p-3 rounded" onKeyDown={e => e.key === "Enter" && updateList(1)}>
                            <DateSelection startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} defaultDateRange="2" />
                            <ComponentToLoadSmallCenter className="my-2" isLoading={isLoading}>
                                <OkButton disabled={isLoading} className="w-100" onClick={() => {
                                    updateList(1)
                                }}>검색</OkButton>
                            </ComponentToLoadSmallCenter>
                        </div>

                        <ComponentToLoad className="mt-2" isLoading={isLoading}>
                            {
                                logs ?
                                    <>
                                        <div className="fw-bold mt-2">
                                            총 {count}개의 로그가 검색되었습니다.
                                        </div>
                                  
                                        <div className="row g-0">
                                            {count> 0 &&
                                            <div className="d-flex justify-content-center my-2">
                                                <Pagination page={page} onChange={(e, v) => onPageChange(v)} count={getPageCount()} siblingCount={0} color="primary" />
                                            </div>
}
                                            {logs.map(c => <div key={c.timeStamp} className="py-2 px-4 bg-gainsboro my-1 rounded">
                                            <div className="row g-0 align-items-center">
                                            <div className="col-auto me-2"> {stringUtil.toStringFullDateTime(new Date(c.timeStamp))} </div>
                                                            <div className="col-auto">
                                                                <div className={`badge ${c.result === 0 ? "bg-success" : "bg-danger"}`}>{getResultName(c.result)}</div>
                                                            </div>
                                                            
                                                        </div>
                                                <div className="d-flex mt-2">
                                                {c.faceImg && <img style={{ width: "60px" }} className="me-2" src={`data:image/jpeg;base64, ${c.faceImg}`} alt="이미지" />}
                                                    <div>
                                                        {c.name &&
                                                            <div >
                                                                {c.name}
                                                            </div>
                                                        }
                                                        {c.birthDay &&
                                                            <div>
                                                                {c.birthDay}
                                                            </div>
                                                        }
                                                        {c.phone &&
                                                            <div>
                                                                {c.phone}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            )}
                                        </div>
                                    </>
                                    :
                                    <DisplayErrors errors={errors} />}
                        </ComponentToLoad>
                    </div>
                </div> :
                <div className="text-danger">
                    해당매장에 지원하지 않는 기능입니다.
                </div>
                :
                <div>
                    선택된 매장이 없습니다. 매장을 선택해주세요.
                </div>
            }
        </div>
    </div>
}

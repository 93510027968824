import { Pagination } from "@mui/material";
import { Form, Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../AppContext";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import DateSelection from "../components/DateSelection";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton"
import SelectionField from "../forms/SelectionField";
import ServerAPI from "../ServerAPI";
import { StoreLog, StoreLogSearch, StoreLogSearchOutput } from "../stores/Store.Models";
import { dateUtil } from "../utils/DateUtil";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import { logUtil } from "../utils/LogUtil";
import { storeUtil } from "../utils/StoreUtil";
import stringUtil from "../utils/StringUtil";

export default function StoreLogPage() {
    const { selectedStoreId } = useContext(AppContext);
    const startDateInit = new Date();
    startDateInit.setDate(startDateInit.getDate()-7);
    const [logSearch, setLogSearch] = useState<StoreLogSearch>({
        startDate : startDateInit,
        endDate : new Date(),
        type : "0"
    });
    
    const [logs, setLogs] = useState<StoreLog[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<Date>(startDateInit);
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);
    const maxCountPerPage = 10;

    useEffect(() => {
        onLogSearch(logSearch, page);
    }, [selectedStoreId]);

    function getLogMessage(log : StoreLog) : JSX.Element {
        let email = <></>;
        let message = <li className="text-danger">알수없는 메세지</li>;
        if(log.email){
            email = <li>수행ID : {log.email} </li>
        }
        if(log.data) {
        switch(log.type){
            case logUtil.StoreUpdate:
                if(Array.isArray(log.data)){
                    message = <>
                        {log.data.map(f => <li key={f.name}>{logUtil.getStoreFieldDisplayName(f.name)} : {logUtil.getFieldUpdateMessage(f)} </li>)}
                    </>
                }
                break;
            case logUtil.StoreFeatureChange:
                    message = 
                        <li>기능 : [{storeUtil.featureToString(log.data.oldFeature)}] → [{storeUtil.featureToString(log.data.newFeature)}]</li>
                    
                break;
            case logUtil.ClientAdd:
            case logUtil.ClientDelete:
                    message = <>
                        <li>이름 : {log.data.name || `기기 ${log.data._id}`}</li>
                        <li>종류 : {storeUtil.featureToString(log.data.features)}</li>
                    </>;
                break;
            case logUtil.ClientUpdate:
                if(Array.isArray(log.data.fieldChanges)){
                    message = <>
                        <li>이름 : {log.data.name ?? `기기 ${log.data._id}`}</li>
                        {log.data.fieldChanges.map((f : any) => <li key={f.name}>{logUtil.getClientFieldDisplayName(f.name)} : {logUtil.getClientFieldUpdateMessage(f)} </li>)}
                    </>;
                }
                break;
            case logUtil.ServerAdd:
            case logUtil.ServerDelete:
                    message = 
                        <li>이름 : {log.data.name}</li>;
                break;
            case logUtil.ServerUpdate:
                if(Array.isArray(log.data.fieldChanges)){
                    message = <>
                        <li>이름 : {log.data.name}</li>
                        {log.data.fieldChanges.map((f : any) => <li key={f.name}>{logUtil.getServerFieldDisplayName(f.name)} : {logUtil.getFieldUpdateMessage(f)} </li>)}
                    </>;
                }
                break;

        }
        }
        return <ul>
            {email}
            {message}
        </ul>;
    }

    function getPageCount() : number{
        let pageCount = Math.trunc(count / maxCountPerPage);
        if(count % maxCountPerPage !== 0) pageCount++;
        return pageCount;
    }



    function onPageChange(newPage : number){
        setPage(newPage);
        onLogSearch(logSearch, newPage);
    }

    async function onLogSearch(logSearch: StoreLogSearch, pageNumber : number){
        if(!selectedStoreId) return;
        if (startDate.getTime() > endDate.getTime()) {
            Dialog.showError("종료날짜가 시작날짜보다 이전입니다.");
            return;
        }
        else if (!dateUtil.isInOneMonth(startDate, endDate)) {
            Dialog.showError("검색기간이 1개월을 초과할 수 없습니다.");
            return;
        }
        setIsLoading(true);
        setErrors([]);
        try {
            const startDateString = dateUtil.toDateISOStringWithoutTime(logSearch.startDate);
            const endDate1DayAfter = new Date(logSearch.endDate.toDateString());
            endDate1DayAfter.setDate(endDate1DayAfter.getDate() + 1);
            const endDateString = endDate1DayAfter.toISOString();
            const typeNumber = Number.parseInt(logSearch.type);
            const result = await HttpRequest.get<StoreLogSearchOutput>(`${ServerAPI.storeLog}/${selectedStoreId}`, {params : {startDate : startDateString, endDate : endDateString, type : typeNumber, page : pageNumber}});
            setLogs(result.logs);
            setCount(result.count);
            setPage(pageNumber);
        }
        catch(error) {
            setLogs(undefined);
            setCount(0);
            setPage(1);
            if(Array.isArray(error?.response?.data)){
                setErrors(error.response.data);
            }
            else{
                setErrors(["검색에 실패했습니다."])
            }
        }
        finally{
            setIsLoading(false);
        }
    }

    return <div>
        <h3>매장로그</h3>
        <div className="mt-3">
            {selectedStoreId ?
                <div className="row g-0">
                    <div className="col-lg-6" >
                        <div className="border border-primary p-3 rounded">
                        <Formik initialValues={logSearch} onSubmit={v => onLogSearch(v,1)}>
        {(formikProps) => 
            <Form>
                <DateSelection startDate={formikProps.values.startDate} endDate={formikProps.values.endDate} setStartDate={(date: Date) => formikProps.setFieldValue("startDate", date)} setEndDate={(date: Date) => formikProps.setFieldValue("endDate", date)} defaultDateRange="2" />
                            <SelectionField field="type" displayName="로그종류" >
                                <>
                                <option value="0">전체</option>
                                {logUtil.getStoreLogTypes().map(t => <option key={t} value={t}>{logUtil.getTypeName(t)}</option>)}
                                </>
                            </SelectionField>
                            <ComponentToLoadSmallCenter className="mb-2 mt-3" isLoading={isLoading}>
                                <OkButton disabled={isLoading} type="submit" className="w-100" >검색</OkButton>
                            </ComponentToLoadSmallCenter>
                </Form>
}
</Formik>
                            
                        </div>
                        <ComponentToLoad className="mt-2" isLoading={isLoading}>
                            {
                                logs ?
                                    <>
                                        <div className="fw-bold mt-2">
                                            총 {count}개의 로그가 검색되었습니다.
                                        </div>
                                  
                                        <div className="row g-0">
                                            {count> 0 &&
                                            <div className="d-flex justify-content-center my-2">
                                                <Pagination page={page} onChange={(e, v) => onPageChange(v)} count={getPageCount()} siblingCount={0} color="primary" />
                                            </div>
}
                                            {logs.map(c => <div key={c.registerDate} className="py-2 px-4 bg-gainsboro my-1 rounded">
                                            <div className="row g-0 align-items-center">
                                            <div className="col-auto me-2 fw-bold"> {stringUtil.toStringFullDateTime(new Date(c.registerDate))} </div>
                                                            <div className="col-auto">
                                                                <div className={`badge bg-success`}>{logUtil.getTypeName(c.type)}</div>
                                                            </div>
                                                            
                                                        </div>
                                                <div className="d-flex mt-2">
                                                {getLogMessage(c)
                                                    }
                                                </div>
                                            </div>

                                                    )}
                                        </div>
                                    </>
                                    :
                                    <DisplayErrors errors={errors} />}
                        </ComponentToLoad>
                    </div>
                </div> :
                <div>
                    선택된 매장이 없습니다. 매장을 선택해주세요.
                </div>
            }
        </div>
    </div>
}

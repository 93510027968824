import { useEffect, useRef, useState } from "react";

function Timer(props : TimerProps) {
    const [minutes, setMinutes] = useState(props.minutes);
    const [seconds, setSeconds] = useState(props.seconds);
    const minutesRef = useRef(props.minutes);
    const secondsRef = useRef(props.seconds)
    useEffect(() => {
        setMinutes(props.minutes);
        minutesRef.current = props.minutes;
        setSeconds(props.seconds);
        secondsRef.current = props.seconds;
        const timer = setInterval(()=> {
            if(secondsRef.current>0)
            {
                secondsRef.current = secondsRef.current -1;
                setSeconds(secondsRef.current);
            }
            else if(minutesRef.current>0){
                minutesRef.current = minutesRef.current-1;
                setMinutes(minutesRef.current);
                secondsRef.current = 59;
                setSeconds(secondsRef.current);
            }
            else {
                clearInterval(timer);
                props.onTimeOut();
            }

        }, 1000);
        return ()=>clearInterval(timer);
    }, [props.reset]);

    return <span>{minutes}분 {seconds< 10 ? `0${seconds}` : seconds}초</span>
}

interface TimerProps {
    minutes : number,
    seconds : number,
    reset? : boolean,
    onTimeOut() : void,
}

export default Timer;
import { Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import Events from "../utils/Events";
import { DeviceInfo, WifiInfo } from "./Iots.Models";
import * as Yup from 'yup'
import SelectionField from "../forms/SelectionField";
import TextField from "../forms/TextField";
import OkButton from "../components/OkButton";
import { isArrowFunction } from "typescript";
import Loading from "../components/Loading";
import Timer from "../components/Timer";
import Dialog from "../utils/Dialog";
import { Route, useNavigate } from "react-router-dom";
import RouteConfig from "../route-config";
import AppContext from "../AppContext";
import { app } from "../utils/AppCommnunication";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import SubButton from "../components/SubButton";
import PasswordShowHide from "../components/PasswordShowHide";


function IotConnectionPage() {
    const [isWebView, setIsWebView] = useState(true);
    const [wifiSSIDs, setWIFISSIDs] = useState<string[]>([]);
    const [wifiInfo, setWifiInfo] = useState<WifiInfo>({ssid : "", password : ""});
    const [loadingWifiList, setLoadingWifiList] = useState(false);
    const [findingDevice , setFindingDevice] = useState(false)
    const [passwordShow, setPasswordShow] = useState(false);
    const [devices, setDevices] = useState<DeviceInfo[]>();
    const devicesRef = useRef<DeviceInfo[]>();
    const {selectedStoreId, socketManager, device} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        const handler = app.addListener(onAppHandler);
        requestWifiSSIDList();
        return () => {
            socketManager?.sendStoreUpdate(selectedStoreId);
            app.removeListener(handler);
        }
    }, []);

    function requestWifiSSIDList(){
        if(!app.isApp()) {
            setIsWebView(false);
            return;
        }
        setLoadingWifiList(true);
        app.send('wifilist');
    }

    function requestSmartConfig(wifi : WifiInfo){
        if(!app.isApp()) {
            setIsWebView(false);
            return;
        }
        app.send('smartconfig', {storeId : selectedStoreId, wifi : wifi});
    }

    function requestWifiCheck(wifi : WifiInfo){
        if(!app.isApp()) {
            setIsWebView(false);
            return;
        }
        if(wifi.password)
          wifi.password = wifi.password.trim();
        app.send('wificheck', wifi);
    }

    function onAppHandler(type : string, data : any) {
        if(type === "wifilist") {
             setLoadingWifiList(false);
             const wifiInfo = data;
             if(device?.platform === "ios" && wifiInfo.currentSSID)
                wifiInfo.SSIDs.push(wifiInfo.currentSSID);
             setWIFISSIDs(wifiInfo.SSIDs);
             if(!wifiInfo.ssid) {
                setWifiInfo({... wifiInfo, ssid : wifiInfo.currentSSID});
             }
        }
        else if(type === "wifidifferent") {
            Dialog.showError("해당 와이파이에 현재 연결되어있지 않습니다. 해당 와이파이로 연결 후 다시 시도해주세요.");
        }
        else if(type === "wifisame") {
            setFindingDevice(true);
            setDevices([]);
            devicesRef.current = [];
            requestSmartConfig(data);
        }
        else if(type === "smartconfig") {
            const deviceInfo = data;
            if(devicesRef.current?.find(d => d.bssid === deviceInfo.bssid))
                return;
            if(devicesRef.current) {
                devicesRef.current = [...devicesRef.current];
                devicesRef.current.push(deviceInfo);
            }
            else {
                devicesRef.current = [deviceInfo];
            }
            setDevices(devicesRef.current);
        }
    }

    function onConnect(values: WifiInfo, formikHelpers: FormikHelpers<WifiInfo>){
        requestWifiCheck(values);
    }

    function finishFindingDevice(){
        setFindingDevice(false);
        if(devicesRef.current?.length && devicesRef.current.length > 0) {
            Dialog.showConfirmation(`총 ${devicesRef.current.length}개 기기가 새로 등록되었습니다. 목록을 확인해주세요.`);
            navigate(-1);
        }
    }

    return <div className="row">
        <div className="col-lg-5">
        <h4>새 기기 등록</h4>
        {isWebView ?
        <div>
            <Formik initialValues={wifiInfo} onSubmit={onConnect} enableReinitialize
    validationSchema={
        Yup.object({
        ssid : Yup.string().required("와이파이를 선택해주세요."),
        password: Yup.string().required("와이파이 비밀번호를 입력해주세요."),
       })
    }>
        {(formikProps) => 
            <Form>
                <div className="mb-3">
                <div className="d-flex justify-content-start my-1 align-items-center">
                <div className="me-2">기기에 연결할 와이파이 선택</div>
                <ComponentToLoadSmallCenter isLoading={loadingWifiList}>
                    <SubButton className="btn-sm" onClick={() => requestWifiSSIDList() }>목록갱신</SubButton>
                    </ComponentToLoadSmallCenter>
                    </div>
                <SelectionField field="ssid" displayName="" disabled={device?.platform === "ios"}>
                    <>
                     <option key="" value="">{device?.platform === "ios" ? "연결된 와이파이가 없습니다." : "와이파이를 선택해주세요."}</option>
                     {wifiSSIDs.map(v => <option key={v} value={v}>{v}</option>)}
                     </>
                    </SelectionField>
                    
                    {device?.platform === "ios" ?
                    <>
                    <div className="text-primary" >
                        ※ 아이폰의 경우 연결된 와이파이만 목록에 표시됩니다.
                        </div> 
                        <div className="text-primary" >
                        ※ 연결되지않은 경우 2.4GHz 와이파이 연결 후 갱신버튼을 눌러주세요. (5.0GHz 작동불가) 
                        </div>
                        </> 
                        :
                        <div className="text-primary" >
                        ※ 연결가능한 2.4GHz만 표시됩니다. (5.0GHz 작동불가)
                        </div>
}
                        </div>
                        <div className="d-flex align-items-center">
                            <label className="form-label mb-0 me-2">와이파이 비밀번호</label>
                            <PasswordShowHide passwordShow={passwordShow} setPasswordShow={setPasswordShow}/>

                        </div>
                <TextField field="password" type={passwordShow ? "text" : "password"} display="" className="mb-3"/>
                <OkButton type="submit" className="w-100" disabled={findingDevice}>기기탐색</OkButton>
            </Form>
        }
    </Formik>
    <div className="mt-4">
        
        {findingDevice&&<div className="d-flex align-items-center"> 
        <Loading/> <div className="ms-2"> 
         <div>
        연결가능한 기기를 탐색중입니다. (
            <Timer minutes={0} seconds={30} onTimeOut={finishFindingDevice} />) 
            </div>
            <div>
        탐색시간이 완료될때까지 기다려주세요. 
            </div>
            </div>
            </div> }
        {devices&& <div className="mt-3">
            <h4>등록된 기기목록 ({devices.length}개)</h4>
            <div className="text-primary" >
             ※ 탐색 시간 내 탐색된 기기는 자동으로 등록됩니다. 
            </div>
            <div className="mt-2">
                {devices.map((d, index) => 
                    <div key={d.bssid} className="p-3 bg-gainsboro mb-2">
                        <div className="d-flex align-items-center">
                        <div className="fw-bold">기기 {index+1} </div>
                        </div>
                        <ul className="mt-2">
                            <li>BSSID (MAC 주소) : {d.bssid}</li>
                            <li>IP주소 : {d.ip}</li>
                        </ul>
                    </div>
                )}
                </div>
            
            </div>}
    </div>
    </div>
        :
         <div className="text-danger">
            앱에서만 정상작동합니다. 앱을 이용해주세요.
            </div>
            }
        </div>
    </div>

}

export default IotConnectionPage;
import { Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DisplayErrors from "../components/DisplayErrors";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import AppContext from "../AppContext";
import * as Yup from 'yup'
import StringValidation from "../utils/StringValidation";
import TextField from "../forms/TextField";
import OkButton from "../components/OkButton";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import { StoreInfo } from "../stores/Store.Models";
import { storeUtil } from "../utils/StoreUtil";
import { cctvClient } from "./CCTV.Models";
import stringUtil from "../utils/StringUtil";
import SelectionField from "../forms/SelectionField";


export default function CCTVPage() {
    const { id } = useParams();
    const { selectedStoreId, stores, socketManager } = useContext(AppContext);
    const [store, setStore] = useState(stores.find(s => s._id === selectedStoreId));
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [cctv, setCCTV] = useState<cctvClient>({
        _id: "",
        name: "",
        ip: "",
        port: 8000,
        cctvId: "admin",
        cctvPassword: "",
        cctvPasswordConfirmed: "",
        retentionPeriod : 30,
        features: [],
        registerDate: 0,
    });
    const navigate = useNavigate();
    const passwordInfo = "※ 비밀번호는 8~16자리가 허용되며 대문자, 소문자, 숫자 및 특수문자(!\"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ 공란)를 사용할 수 있습니다. 언급한 유형 가운데 최소 두 가지 유형을 포함해야 합니다.";

    useEffect(() => {
        setStore(stores.find(s => s._id === selectedStoreId));
        getInfo(id);
    }, [selectedStoreId, stores, id]);

    function getRetentionPeriodOptions() : JSX.Element {
        const options : JSX.Element[] = [];
        for(let i = 1; i<=30; i++){
            options.push(<option value={i} key={i}>{i}일</option>)
        }
        return <>{options}</>
    }


    async function getInfo(id: string | undefined) {
        setIsLoading(true)
        if (selectedStoreId && id) {
            try {
                const cctvGet = await HttpRequest.get<cctvClient>(ServerAPI.storeCCTVGet, { params: { storeId: selectedStoreId, id: id } });
                cctvGet.cctvPasswordConfirmed = "";
                if(!cctvGet.retentionPeriod) cctvGet.retentionPeriod = 30;
                setCCTV(cctvGet);
            }
            catch (error) {
                var message = "CCTV 정보를 가져오는데 실패했습니다.\n";
                if (error?.response?.data) {
                    message += error.response.data;
                }
                Dialog.showError(message);
            }
        }
        setIsLoading(false);
    }

    function onSubmit(cctv: cctvClient) {
        Dialog.showDialog(`해당 CCTV를 ${id ? "수정" : "등록"}하시겠습니까?`, async (result) => {
            if (result) {
                setIsUpdating(true);
                try {
                    setErrors([]);
                    const path = id ? ServerAPI.storeCCTVUpdate : ServerAPI.storeCCTVCreate;
                    await HttpRequest.post(`${path}/${selectedStoreId}`, cctv);
                    Dialog.showConfirmation(`CCTV ${id ? "수정" : "등록"}이 완료되었습니다.`);
                    socketManager?.sendStoreUpdate(selectedStoreId);
                    navigate(-1);
                }
                catch (error) {
                    if (error?.response?.data) {
                        setErrors(error.response.data);
                    }
                    else {
                        setErrors(["서버연결에 실패했습니다."])
                    }
                }
                setIsUpdating(false);
            }
        });
    }


    return <div className='row'>
        <div className='col-lg-5'>
            <h3 className="mb-3">{id ? "CCTV 수정" : "CCTV 등록"} </h3>
            {store ? storeUtil.hasCCTVFeature(store.features) ?
                <ComponentToLoad isLoading={isLoading}>
                    <>
                        <Formik initialValues={cctv} onSubmit={onSubmit} enableReinitialize
                            validationSchema={
                                Yup.object({
                                    name: Yup.string().required('이름을 입력해주세요.').max(32, "이름 길이가 32글자를 초과합니다."),
                                    ip: Yup.string().test("validIPAddress", "IP주소 형식이 올바르지 않습니다.",
                                        value => StringValidation.validIpAddress(value)),
                                    port: Yup.number().required("포트를 입력해주세요.").typeError("숫자를 입력해주세요").min(1, "포토번호가 올바르지 않습니다.").max(65535, "포트번호가 올바르지 않습니다."),
                                    cctvId: Yup.string().required("아이디를 입력해주세요.").max(32, "아이디길이가 32글자를 초과합니다."),
                                    cctvPassword: Yup.string().required('비밀번호를 입력해주세요.').min(8, "비밀번호는 8~16자리가 허용됩니다.").max(16, "비밀번호는 8~16자리가 허용됩니다.")
                                        .test("checkPassword", "비밀번호 형식이 올바르지 않습니다.",
                                            function (value) {
                                                if (!value) return false;
                                                return stringUtil.isValidCCTVPasswordFormat(value);
                                            }),
                                    cctvPasswordConfirmed: Yup.string().required('비밀번호 확인을 해주세요.').test("confirmPassword", "비밀번호가 일치하지 않습니다.",
                                            function (value) {
                                                if (!value) return false;
                                                const { cctvPassword } = this.parent;
                                                if(!cctvPassword) return false;
                                                return value.trim() === cctvPassword.trim();
                                            }),
                                    retentionPeriod: Yup.number().required("저장기간을 선택해주세요").min(1, "저장기간은 1~30일까지 선택해주세요.").max(30, "저장기간을 1~30일까지 선택해주세요."),
                                })
                            }>
                            {(formikProps) =>
                                <Form>
                                    <TextField field="name" display="이름" className="mb-3" />
                                    <TextField field="ip" display="IP주소" className="mb-3" placeholder="xxx.xxx.xxx.xxx" />
                                    <TextField field="port" display="접속포트" className="mb-3" />
                                    <TextField field="cctvId" display="아이디" className="mb-3" disabled={true} />
                                    <TextField field="cctvPassword" display="비밀번호" type="password" />
                                    <div className="text-primary mb-3">
                                        {passwordInfo}
                                    </div>
                                    <TextField field="cctvPasswordConfirmed" display="비밀번호확인" className="mb-3" type="password" />
                                    <SelectionField field="retentionPeriod" displayName="녹화영상 저장기간" className="mb-4">
                                        {getRetentionPeriodOptions()}
                                    </SelectionField>

                                    <ComponentToLoadSmallCenter isLoading={isUpdating}>
                                        <OkButton type="submit" className="w-100" disabled={isUpdating}>{id ? "수정하기" : "등록하기"}</OkButton>
                                    </ComponentToLoadSmallCenter>
                                </Form>
                            }
                        </Formik>
                        <DisplayErrors errors={errors} />
                    </>
                </ComponentToLoad>
                :
                <div className="text-danger">
                    해당매장에 지원하지 않는 기능입니다.
                </div>
                :
                <div>
                    선택된 매장이 없습니다. 매장을 선택해주세요.
                </div>
            }
        </div>
    </div>
}

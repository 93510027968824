import { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext from "../AppContext";
import ComponentToLoad from "../components/ComponentToLoad";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import { app } from "../utils/AppCommnunication";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import JWTHandler from "../utils/JWTHandler";
import stringUtil from "../utils/StringUtil";
import { accountCreateDTO, SocialLoginResult } from "./Accounts.Models";
import AuthenticationContext from "./AuthenticationContext";
import RegisterForm from "./RegisterForm";

export default function SocialLogin(props: {type : "naver" | "kakao" | "apple"}) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const hashParams = new URLSearchParams(location.hash);
    const { device } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const isLoadingRef = useRef(false);
    const navigate = useNavigate();
    const { update } = useContext(AuthenticationContext);
    const [token, setToken] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [name, setName] = useState<string>("");
    const initialAccount: accountCreateDTO = {
        emailId: "",
        emailSite: "",
        emailSiteSelection: "-1",
        emailFull: "",
        checkedEmail: "",
        idCheckButtonDisabled: true,
        password: "",
        passwordConfirmed: "",
        name: "",
        phoneNumber: "",
        allAgreed: false,
        useTermsAgreed: false,
        privateTermsAgreed: false,
        marketingAgreed: false,
    };
    const autoLoginRef = useRef(false);

    useEffect(() => {
        login();
    }, [device]);

    async function loginConnect(email: string, token: string) {
        isLoadingRef.current = true;
        setIsLoading(isLoadingRef.current);
        try {
            var loginDTO = {
                email: email,
                token: token,
                autoLogin: autoLoginRef.current,
                device: device || null,
                socialLoginType: props.type === "kakao" ? 1 : props.type === "naver" ? 2 : 3,
            };
            await HttpRequest.post<SocialLoginResult>(ServerAPI.accountLoginConnectSocialPath, loginDTO);
            Dialog.showConfirmation("연동이 완료되어 자동으로 로그인됩니다.");
            const newClaims = await JWTHandler.getClaims()
            update(newClaims);
            navigate('/', { replace: true });

        }
        catch (error) {
            let message = "로그인에 실패했습니다.\n\n";
            if (Array.isArray(error?.response?.data)) {
                for (let i = 0; i < error.response.data.length; i++)
                    message += `- ${error.response.data[i]}\n`
            }
            else {
                message += "- 서버연결에 실패했습니다.";
            }
            Dialog.showError(message);
            navigate(-1);
        }
        finally {
            isLoadingRef.current = false;
            setIsLoading(isLoadingRef.current);
        }
    }

    async function getAppleLoginResult() {
        const token = hashParams.get("#token");
        autoLoginRef.current = searchParams.get("autoLogin") == "true";
        setAppleName();
        if (!token) {
            let message = "로그인 정보를 찾을 수 없습니다.";
            Dialog.showError(message);
            navigate(-1);
            return;
        }
        const loginDTO = {
            token: token,
            autoLogin: autoLoginRef.current,
            device: device || null,
        };
        return await HttpRequest.post<SocialLoginResult>(ServerAPI.accountLoginApplePath, loginDTO);
    }

    async function getKakaoLoginResult() {
        const code = searchParams.get("code");
        autoLoginRef.current = searchParams.get("state") == "true";
        if (!code) {
            const error = searchParams.get("error");
            const error_description = searchParams.get("error_description");
            let message = "로그인 정보를 찾을 수 없습니다.\n\n";
            let errorMessage = "";
            if (error) {
                errorMessage = `- ${error}: `;
            }
            if (error_description) {
                errorMessage += error_description;
            }
            if (errorMessage)
                message += errorMessage;
            Dialog.showError(message);
            navigate(-1);
            return;
        }

        const loginDTO = {
            code: code,
            redirectUri: RouteConfig.getFullURL(),
            autoLogin: autoLoginRef.current,
            device: device || null,
        };
        return await HttpRequest.post<SocialLoginResult>(ServerAPI.accountLoginKakaoPath, loginDTO);
    }

    async function getNaverLoginResult() {
        const accessToken = hashParams.get("#access_token");
            autoLoginRef.current = searchParams.get("autoLogin") == "true";
            if (!accessToken) {
                const error = searchParams.get("error");
                const error_description = searchParams.get("error_description");
                let message = "로그인 정보를 찾을 수 없습니다.\n\n";
                let errorMessage = "";
                if (error) {
                    errorMessage = `- ${error}: `;
                }
                if (error_description) {
                    errorMessage += error_description;
                }
                if (errorMessage)
                    message += errorMessage;
                Dialog.showError(message);
                navigate(-1);
                return;
            }
            const loginDTO = {
                token: accessToken,
                autoLogin: autoLoginRef.current,
                device: device || null,
            };
            return await HttpRequest.post<SocialLoginResult>(ServerAPI.accountLoginNaverPath, loginDTO);
    }


    async function login() {
        if(app.isApp() && !device ) return;
        if (isLoadingRef.current) return;
        isLoadingRef.current = true;
        setIsLoading(isLoadingRef.current);
        try {
            let result : SocialLoginResult | undefined = undefined;
            if(props.type === "apple")
                result = await getAppleLoginResult();
            else if(props.type ==="kakao")
                result = await getKakaoLoginResult();
            else 
                result = await getNaverLoginResult();
            
            if(!result) return;
            if (result.result === 1) {
                const newClaims = await JWTHandler.getClaims()
                update(newClaims);
                navigate('/', { replace: true });
            }
            else if (result.result === 2) {
                setToken(result.token);
                setEmail(result.email);
            }
            else if (result.result === 3) {
                Dialog.showDialog(`"${result.email}" 이메일로 가입된 계정이 이미 있습니다. 해당 계정에 ${props.type === "kakao" ? "카카오" : "네이버"}로그인을 연동하시겠습니까?`, (dresult) => {
                    if (dresult) {
                        loginConnect(result!.email, result!.token);
                    }
                    else {
                        navigate(-1);
                    }
                });
            }
        }
        catch (error) {
            let message = "로그인에 실패했습니다.\n\n";
            if (Array.isArray(error?.response?.data)) {
                for (let i = 0; i < error.response.data.length; i++)
                    message += `- ${error.response.data[i]}\n`
            }
            else {
                message += "- 서버연결에 실패했습니다.";
            }
            Dialog.showError(message);
            navigate(-1);
        }
        finally {
            isLoadingRef.current = false;
            setIsLoading(isLoadingRef.current);
        }
    }

    async function onAccountCreate(account: accountCreateDTO): Promise<void> {
        return new Promise((resolve, reject) => {
            Dialog.showDialog("입력하신 정보로 회원가입을 하시겠습니까?", async (result) => {
                if (result) {
                    try {
                        const accountDTO = {
                            email: email,
                            socialLoginType: props.type === "kakao" ? 1 : props.type === "naver" ? 2 : 3,
                            token: token,
                            autoLogin: autoLoginRef.current,
                            phoneNumber: account.phoneNumber,
                            name: account.name,
                            userTermsAgreed: account.useTermsAgreed,
                            privateTermsAgreed: account.privateTermsAgreed,
                            marketingAgreed: account.marketingAgreed,
                            device: device || null,
                        };
                        await HttpRequest.post<string>(ServerAPI.accountCreateSocialPath, accountDTO);
                        Dialog.showConfirmation("회원가입이 완료되었습니다.");
                        const newClaims = await JWTHandler.getClaims()
                        update(newClaims);
                        navigate('/', { replace: true });
                    }
                    catch (error) {
                        reject(error);
                    }
                }
                resolve();
            });
        });
    }

    function setAppleName(){
        const firstName = searchParams.get("fName") || "";
        const lastName = searchParams.get("lName") || "";
        if(firstName || lastName){
            if(stringUtil.includeKorean(firstName) || stringUtil.includeKorean(lastName))
                setName(`${lastName}${firstName}`);
            else
               setName(`${firstName} ${lastName}`);
        }
    }

    return <div className="row g-0">
        <div className="col-lg-5">
            <h3 className="mb-3">{token ? "회원가입" : "로그인 확인 중"}</h3>
            {token && email && <div className="text-primary">※ 첫 방문시 회원가입 후 로그인이 가능합니다.</div>}
            <ComponentToLoad isLoading={isLoading}>
                {token && email && <div>
                    <RegisterForm initialValue={initialAccount} onSubmit={onAccountCreate} email={email} name={name} isSocial={true}></RegisterForm>
                </div>}
            </ComponentToLoad>
        </div>
    </div>
}
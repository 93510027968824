import { Field, FieldArray, Form, Formik, FormikHelpers } from "formik";
import { storeCreateDTO } from "./Store.Models";
import * as Yup from 'yup'
import SelectionField from "../forms/SelectionField";
import TextField from "../forms/TextField";
import AddressField from "../forms/AddressField";
import { CompanyQueryDTO } from "../accounts/Accounts.Models";
import { useEffect, useState } from "react";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import OkButton from "../components/OkButton";
import HttpRequest from "../utils/HttpRequest";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import { useNavigate } from "react-router-dom";
import RouteConfig from "../route-config";
import { storeUtil } from "../utils/StoreUtil";

function StoreForm(props : StoreFormProps){
    const [companies, setCompanies] = useState<CompanyQueryDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(()=> {
        getCompanies();
    }, [])

    async function getCompanies() {
        setIsLoading(true);
        try {
            const companies = await HttpRequest.get<CompanyQueryDTO[]>(ServerAPI.storeGetAccessibleCompaniesForStoreAdd);
            companies.sort((a : CompanyQueryDTO, b :CompanyQueryDTO) => {
                if(a.name > b.name) return 1;
                else if(a.name < b.name) return -1;
                else return 0;
            });
            setCompanies(companies);
        }
        catch(error) {
            setCompanies([]);
            var message = "소속업체 목록을 가져오는데 실패했습니다.\n";
            if(error?.response?.data){
                message += error.response.data;
            }
            Dialog.showError(message);
        }
        setIsLoading(false);
    }


    return  <Formik initialValues={props.store} onSubmit={props.onSubmit} enableReinitialize
    validationSchema={
        Yup.object({
        companyId : Yup.string().required("소속업체를 선택해주세요."),
        name: Yup.string().required('매장명을 입력해주세요.').max(64, "매장명 길이가 64글자를 초과합니다."),
        phoneNumber : Yup.string().required('매장 연락처를 입력해주세요.').max(32, "매장연락처 길이가 32글자를 초과합니다."),
        addressMain : Yup.string().required('우편번호 찾기를 해주세요.').max(50, "주소가 50글자를 초과할수 없습니다."),
        addressZipcode : Yup.string().required('우편번호 찾기를 해주세요.').max(10, "우편번호가 10글자를 초과할수 없습니다."),
        addressDetail : Yup.string().required('상세주소를 입력해주세요.').max(50, "상세주소가 50글자를 초과할수 없습니다."),
       })
    }>
        {(formikProps) => 
            <Form>
                <label className="form-label">소속업체</label>
                <ComponentToLoadSmallCenter isLoading={isLoading}>
                <SelectionField  field="companyId" displayName="">
                    <>
                     <option key="" value="">소속업체를 선택해주세요.</option>
                     {companies.map(v => <option key={v._id} value={v._id}>{v.name} </option>)}
                     </>
                    </SelectionField> 
                    </ComponentToLoadSmallCenter>
                    <div className="text-primary">
                        ※ 업체가 보이지 않는경우 해당업체에 매장등록 권한이 있는지 확인해주세요.
                    </div>
                    <OkButton className="btn-sm mb-3 mt-2" onClick={() => navigate(RouteConfig.companyAddPath)}>업체등록</OkButton>
                <TextField field="name" display="매장명" className="mb-3"/>
                <TextField field="phoneNumber" display="매장 연락처" className="mb-3"/>
                <AddressField className="mb-3" display="매장주소" mainAddressFieldName="addressMain"
                addressDetailFieldName="addressDetail" zipCodeFieldName="addressZipcode" />
                    <div>매장에 적용할 기능 선택</div>
                    <div className="text-primary mb-2">※ 적용기능에 따라 사용할수 있는 메뉴와 과금이 달라집니다.</div>
                <FieldArray name="features"
                             render={arrayHelpers => 
                               formikProps.values.features && formikProps.values.features.length > 0 &&
                               <div className="d-flex flex-wrap">
                                <div className="form-check mx-1">
                                <Field className="form-check-input"  id={`features.0`} name={`features.0`} type="checkbox"/>
                                <label htmlFor={`features.0`}>{storeUtil.getStoreFeatureName(0)}</label>
                                </div>
                                <div className="form-check mx-1">
                                <Field className="form-check-input"  id={`features.1`} name={`features.1`} type="checkbox"/>
                                <label htmlFor={`features.1`}>{storeUtil.getStoreFeatureName(1)}</label>
                                </div>
                                <div className="form-check mx-1">
                                <Field className="form-check-input"  id={`features.2`} name={`features.2`} type="checkbox"/>
                                <label htmlFor={`features.2`}>{storeUtil.getStoreFeatureName(2)}</label>
                                </div>
                                <div className="form-check mx-1">
                                    <input type={"checkbox"} className="form-check-input" checked={storeUtil.isAirConditioner(formikProps.values.features)} onChange={e => {
                                            formikProps.setFieldValue("features.3", e.currentTarget.checked);
                                            formikProps.setFieldValue("features.4", e.currentTarget.checked);
                                            formikProps.setFieldValue("features.6", e.currentTarget.checked);
                                    }} />
                                <label htmlFor={`features.3`}>{storeUtil.getStoreFeatureName(3)}</label>
                               </div>
                                <div className="form-check mx-1">
                                <Field className="form-check-input"  id={`features.5`} name={`features.5`} type="checkbox"/>
                                <label htmlFor={`features.5`}>{storeUtil.getStoreFeatureName(5)}</label>
                                </div>
                               </div>
                            } />
                            <ComponentToLoadSmallCenter isLoading={formikProps.isSubmitting}>
                <OkButton type="submit" className="w-100 mt-3" disabled={formikProps.isSubmitting}>{props.buttonDisplay}</OkButton>
                </ComponentToLoadSmallCenter>
            </Form>
        }
    </Formik>
}

interface StoreFormProps {
    store : storeCreateDTO,
    onSubmit(store :storeCreateDTO, actions: FormikHelpers<storeCreateDTO>) : void,
    buttonDisplay : string,
}

export default StoreForm;
import { Pagination } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../AppContext";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton"
import { StoreInfo } from "../stores/Store.Models";
import { PacketCommands } from "../utils/SocketManager";
import { IrisClientState, IrisMember, IrisMemberInfo } from "./Iris.models";

export default function IrisMemberListPage() {
    const { selectedStoreId, socketManager, stores, isSocketConnected } = useContext(AppContext);
    const [store, setStore] = useState<StoreInfo | undefined>(stores.find(s => s._id === selectedStoreId));
    const [members, setMembers] = useState<IrisMember[]>();
    const [isLoading, setIsLoading] = useState(true);
    const isLoadingRef = useRef(true);
    const [errors, setErrors] = useState<string[]>([]);
    const [page, setPage] = useState<number>(1);
    const [userCount, setUserCount] = useState<number>(0);
    const [name, setName] = useState("");
    const timeOutIdRefForUpdatingList = useRef<NodeJS.Timeout>();
    const maxCountPerPage = 10;
    const serverIdPairRef = useRef<any>();
    const pageParameterRef = useRef<number>();
    const nameParameterRef = useRef<string>();

    useEffect(() => {
        socketManager?.registerCommandCallBack(PacketCommands.Manager_Iris_State, onReceiveState);
        socketManager?.registerCommandCallBack(PacketCommands.Manager_Iris_Get_Member_List, onReceive);
        setStore(stores.find(s => s._id === selectedStoreId));
        updateList(page, "");
        return () => {
            socketManager?.deregisterCommandCallBack(PacketCommands.Manager_Iris_Get_Member_List)
            socketManager?.deregisterCommandCallBack(PacketCommands.Manager_Iris_State)
        }
    }, [stores, selectedStoreId, isSocketConnected]);

    function getPageCount() : number{
        let pageCount = Math.trunc(userCount / maxCountPerPage);
        if(userCount % maxCountPerPage !== 0) pageCount++;
        return pageCount;
    }

    async function updateList(pageNum : number, nameQuery: string) {
        if (!store || !store.irisClient) return;
        isLoadingRef.current = true;
        serverIdPairRef.current = undefined;
        setIsLoading(isLoadingRef.current);
        setErrors([]);
        const trimmedName = nameQuery.trim();
        pageParameterRef.current = pageNum;
        nameParameterRef.current = trimmedName;
        socketManager?.sendIrisState(store._id, store.irisClient._id);
        timeOutIdRefForUpdatingList.current = setTimeout(() => {
            if (isLoadingRef.current) {
                isLoadingRef.current = false;
                serverIdPairRef.current = undefined;
                setIsLoading(isLoadingRef.current);
                setMembers(undefined);
                setUserCount(0);
                setPage(1);
                setErrors(["기기 응답이 없어 회원목록을 가져오는데 실패했습니다."]);
            }
        }, 3000);
    }

    function onNameSearch(){
        setPage(1);
        updateList(1, name);
    }

    function onPageChange(newPage : number){
        setPage(newPage);
        updateList(newPage, name);
    }

    function onReceive(memberInfo: IrisMemberInfo) {
        if (!store?.irisClient || store.irisClient._id !== memberInfo.clientId) return;
        if (isLoadingRef.current) {
            isLoadingRef.current = false;
            setIsLoading(isLoadingRef.current);
            setUserCount(memberInfo.memberCount);
            setMembers(memberInfo.memberList);
        }
    }

    function onReceiveState(data : any) {
        if(!isLoadingRef.current || serverIdPairRef.current || !store?.irisClient?._id || pageParameterRef.current === undefined || nameParameterRef.current === undefined) return;
        const state : IrisClientState = data.state; 
        if(!state.isConnected)return;
        serverIdPairRef.current = data.serverIdPair;
        socketManager?.sendIrisGetMemberList(serverIdPairRef.current, store?.irisClient._id,  pageParameterRef.current, nameParameterRef.current);
    }

    return <div>
         <h3>회원목록 {store?.irisClient && <> ({store.irisClient.name}) </>}</h3>
        <div className="mt-3">
            {store ? store.irisClient ?
                <div className="row g-0">
                    <div className="col-lg-6" >
                        <div  className="border border-primary p-3 rounded">
                            <h5>회원이름검색</h5>
                            <input type="text" className="form-control" placeholder="회원이름을 검색해주세요." value={name} 
                            onChange={e => setName(e.currentTarget.value)} 
                            onKeyDown={e => e.key === "Enter" && onNameSearch()}
                            />
                              <div className="text-primary">※ 빈칸으로 검색 시 전체회원이 검색됩니다. </div>
                              <ComponentToLoadSmallCenter className="my-2" isLoading={isLoading}>
                        <OkButton className="w-100 " disabled={isLoading} onClick={() => {
                        onNameSearch()
                    }}>검색</OkButton>
                    </ComponentToLoadSmallCenter>
                        </div>
                    
                    <div className="fw-bold mt-2">
                        총 {userCount}명의 회원이 검색되었습니다.
                    </div>
                    <div className="row g-0">
                            <div className="d-flex justify-content-center">
                            <Pagination className="mt-2" page={page} onChange={(e, v) => onPageChange(v)} count={getPageCount()} siblingCount={0} color="primary" />
                            </div>
                    </div>
                    <ComponentToLoad className="mt-2" isLoading={isLoading}>
                        {
                            members ?
                                <div className="row g-0">

                                    {members.map((s, index) =>
                                        <div key={index} className="p-1 col-lg-6">
                                            <div className="bg-gainsboro rounded p-2">
                                            <div className="d-flex">
                                                <img src={`data:image/jpeg;base64, ${s.faceImg}`} alt="이미지" />
                                                <ul className="mb-0">
                                                    <li>이름 : {s.name} </li>
                                                    <li>연락처 : {s.phoneNum} </li>
                                                    <li>생년월일 : {s.birthDay} </li>
                                                </ul>

                                            </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                :
                                <DisplayErrors errors={errors} />}
                    </ComponentToLoad>
                    </div>
                </div> :
                <div className="text-danger">
                    해당매장에 지원하지 않는 기능입니다.
                </div>
                :
                <div>
                    선택된 매장이 없습니다. 매장을 선택해주세요.
                </div>
            }
        </div>

    </div>
}

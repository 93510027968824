import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DisplayErrors from "../components/DisplayErrors";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import AppContext from "../AppContext";
import ComponentToLoad from "../components/ComponentToLoad";
import { CallDevice } from "./VideoCall.Models";
import CallDeviceForm from "./CallDeviceForm";

export default function CallDeviceUpdatePage() {
    const { deviceId } = useParams();
    const { selectedStoreId, stores } = useContext(AppContext);
    const [store, setStore] = useState(stores.find(s => s._id === selectedStoreId));
    const [callDevice, setCallDevice] = useState<CallDevice>();
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);
    const navigate = useNavigate();
    

    useEffect(() => {
        setStore(stores.find(s => s._id === selectedStoreId));
        getInfo(deviceId);
    }, [selectedStoreId, stores, deviceId]);


    async function getInfo(id: string | undefined) {
        setIsLoading(true)
        if (selectedStoreId && id) {
            setErrors([]);
            try {
                const device = await HttpRequest.get<CallDevice>(`${ServerAPI.storeVideoCallGetDevice}`, {params : {storeId : selectedStoreId, id : id}});
                setCallDevice(device);
            }
            catch (error) {
                var message = "정보를 가져오는데 실패했습니다.\n";
                if (error?.response?.data) {
                    message += error.response.data;
                }
                setCallDevice(undefined);
                setErrors([message]);
            }
        }
        setIsLoading(false);
    }

    function onSubmit(callDevice: CallDevice) : Promise<void> {
        return new Promise(resolve => {
        Dialog.showDialog(`해당 기기를 수정하시겠습니까?`, async (result) => {
            if (result) {
                try {
                    setErrors([]);
                    await HttpRequest.put(`${ServerAPI.storeVideoCallUpdateDevice}/${selectedStoreId}`, callDevice);
                    Dialog.showConfirmation("수정이 완료되었습니다.");
                    navigate(-1);
                }
                catch (error) {
                    if (error?.response?.data) {
                        setErrors(error.response.data);
                    }
                    else {
                        setErrors(["서버연결에 실패했습니다."])
                    }
                }
            }
            resolve();
        });
        });
    }


    return <div className='row'>
        <div className='col-lg-5'>
            <h3 className="mb-3">영상통화 기기 수정 </h3>
            {store ? 
                <ComponentToLoad isLoading={isLoading}>
                    {callDevice ?
                    <>
                    <CallDeviceForm callDevice={callDevice} isAdd={false} onSubmit={async (callDevice) => await onSubmit(callDevice)} />
                        <DisplayErrors errors={errors} />
                    </>
                    :
                    <DisplayErrors errors={errors} />
}
                </ComponentToLoad>
                :
                <div>
                    선택된 매장이 없습니다. 매장을 선택해주세요.
                </div>
            }
        </div>
    </div>
}

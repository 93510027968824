import { useEffect, useRef, useState } from "react"
import stringUtil from "../utils/StringUtil";

export default function TimeEllapse(){
    const [second, setSecond] = useState(0);
    const [minute, setMinute] = useState(0);
    const secondRef = useRef(0)
    const minuteRef = useRef(0);
    
    useEffect(() => {
        const timer = setInterval(()=> {
            if(secondRef.current<59)
            {
                secondRef.current += 1;
                setSecond(secondRef.current);
            }
            else {
                minuteRef.current += 1;
                setMinute(minuteRef.current);
                secondRef.current = 0;
                setSecond(secondRef.current);
            }
        }, 1000);
        return ()=>clearInterval(timer);
    }, []);

    return <span>
        {stringUtil.padZero(minute.toString(), 2)}:{stringUtil.padZero(second.toString(), 2)}
    </span>

}
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../AppContext";
import { PacketCommands } from "../utils/SocketManager";
import ConnectionComponent from "./ServerConnectionComponent";
import { Server } from "./Store.Models";

function ServerMainState(props : ServerMainStateProps){
    const {selectedStoreId, socketManager, stores} = useContext(AppContext);
    const connectionCount = useRef(0);
    const [isConnected, setIsConnected] = useState(false);
    const timeoutCount = 3;
    
    useEffect(() => {
        setIsConnected(false);
        props.servers.forEach(s => 
        socketManager?.registerServerCallBack(s._id, PacketCommands.Manager_Server_State_Simple, onReceive)
        );
        const timer = setInterval(()=> {
            if(connectionCount.current < timeoutCount ){
                connectionCount.current++;

            }
            else {
                setIsConnected(false);
            }

            props.servers.forEach(s => 
                socketManager?.requestServerStateSimple(selectedStoreId, s._id)
            );

            
        }, 1000);

        return () => {
            clearInterval(timer);
            props.servers.forEach(s => 
                socketManager?.deregisterServerCallBack(s._id, PacketCommands.Manager_Server_State_Simple)
                );
        }
    }, [selectedStoreId, props.servers, stores]); 

    function onReceive(){
        connectionCount.current = 0;
        if(!isConnected) {
            setIsConnected(true);  
        } 
    }

    return <div className={`d-flex align-items-center ${props.className ? props.className : ""}`}>
        <ConnectionComponent isConnected={isConnected}
         noConnectionElement={
            <div>
    <div className="text-danger">연결된 서버가 없습니다.</div>
    <div>아래 서버목록 버튼을 통해 서버등록을 하거나 연결 상태를 확인하세요.</div>
    </div>
         }
         connectionElement={
            <>서버가 연결되었습니다.</>
         }
         
         />
</div>
}

interface ServerMainStateProps {
    className? : string,
    servers : Server[],
}

export default ServerMainState;
import jwtDecode from "jwt-decode";
import { claim } from "../accounts/Accounts.Models";
import {app} from "./AppCommnunication";

class JWTHandler {
    static tokenKey = "token";
    static tokenWaitList: tokenWait[]= [];

    static saveToken(token : string) {
        if(app.isApp()){
            app.send("tokenSet", token);
        }
        else
            localStorage.setItem(this.tokenKey, token);
    }

    static getToken() : Promise<string|null>{
        return new Promise((resolve) => {
            if(app.isApp()){
                const timerId = setTimeout(() => {
                    const timerIdString = timerId.toString();
                    const tokenWait = this.tokenWaitList.find(t=> t.id === timerIdString);
                    if(!tokenWait)return;
                    this.tokenWaitList = this.tokenWaitList.filter(t => t.id !== timerIdString); 
                    tokenWait.resolve(null);
                }, 2000);
                const timerIdString = timerId.toString();
                this.tokenWaitList.push({id : timerIdString, resolve : resolve});
                app.send("tokenGet", timerIdString);
            }
            else 
                resolve(localStorage.getItem(this.tokenKey));
        });
    }

    static async tokenExpired(remainingTimeMS : number){
        if(remainingTimeMS < 0) return true;
        const token = await this.getToken();
        if (!token){
            return true;
        }
        const tokenDecoded : any = jwtDecode(token);
        if(!tokenDecoded.exp) return true;
        return (tokenDecoded.exp * 1000 - new Date().getTime()) <=remainingTimeMS; 
    }

    static async getClaims(): Promise<claim[]>{
        const token = await this.getToken();
        if (!token){
            return [];
        }
        const tokenDecoded : any = jwtDecode(token);
        if(!tokenDecoded.exp) return [];
        if(tokenDecoded.exp * 1000 < new Date().getTime()){
            this.removeToken();
            return [];
        }
    
        const claims: claim[] = [];
        for (const property in tokenDecoded){
            claims.push({name: property, value: tokenDecoded[property]});
        }
    
        return claims;
    }

    static removeToken(){
        if(app.isApp())
            app.send("tokenRemove");
        else
            localStorage.removeItem(this.tokenKey);
    }

    static onTokenGet(id: string, token: string) {
        const tokenWait = this.tokenWaitList.find(t => t.id === id);
        if (!tokenWait) return;
        this.tokenWaitList = this.tokenWaitList.filter(t => t.id !== id);
        tokenWait.resolve(token);
    }
}

interface tokenWait {
    id : string,
    resolve : (value: string | PromiseLike<string | null> | null) => void
}

export default JWTHandler;
import { preProcessFile } from "typescript";

class ServerAPI {
    static getServerDomains() : string[] {
        const domain = process.env.REACT_APP_SERVER_DOMAINS;
        const domains = domain?.split(';');
        if(!domains) return [];
        return domains;
    }

    static getServerPort() : string {
        const port = process.env.REACT_APP_SERVER_PORT; 
        if(!port) return "";
        return port;
    }

    static getServerTCPDomains() : string[] {
        const domain = process.env.REACT_APP_SERVER_TCP_DOMAINS;
        const domains = domain?.split(';');
        if(!domains) return [];
        return domains;
    }

    static getServerTCPPort() : string {
        const port = process.env.REACT_APP_SERVER_TCP_PORT; 
        if(!port) return "";
        return port;
    }

    static accountCreatePath = `/accounts/create`;
    static accountCreateSocialPath = `/accounts/createSocial`;
    static accountCancel = `/accounts/cancel`;
    static accountDeviceDelete = `/accounts/deviceDelete`;
    static accountDeviceGet = `/accounts/deviceGet`;
    static accountLoginPath = `/accounts/login`;
    static accountLoginApplePath = `/accounts/loginapple`;
    static accountLoginKakaoPath = `/accounts/loginkakao`;
    static accountLoginNaverPath = `/accounts/loginNaver`;
    static accountLoginConnectSocialPath = `/accounts/loginConnectSocial`;
    static accountUpdate = `/accounts/update`;
    static accountPasswordChange = `/accounts/updatePassword`;
    static accountGetInfoPath = `/accounts/getInfo`;
    static accountIdCheckPath = `/accounts/idavailable`;
    static accountRequestEmailVerificationPath = `/accounts/requestEmailVerification`;
    static accountCheckEmailVerificationPath = `/accounts/checkEmailVerification`;
    static accountApprovePath = `/accounts/approve`;
    static accountGetCompaniesByNamePath = `/accounts/getCompaniesByName`;
    static accountCompanyCreatePath = `/accounts/companyCreate`;
    static accountCompanyUpdatePath = `/accounts/companyUpdate`;
    static accountDefaultStoreGet = `/accounts/defaultStoreGet`;
    static accountDefaultStoreSet = `/accounts/defaultStoreSet`;
    static accountGetCompanyInfoForUpdate = `/accounts/getCompanyInfoForUpdate`;
    static accountGetAccesibleCompaniesPath = `/accounts/getAccessibleCompanies`;
    static accountGetAffiliatedCompanyPath = `/accounts/getAffiliatedCompany`;
    static accountGetNotificationLogs = `/accounts/getNotificationLogs`;
    static accountGetNotificationSettings = `/accounts/getNotificationSettings`;
    static accountUpdateNotificationSettings = `/accounts/updateNotificationSettings`;
    static accountEmployeeAdd = `/accounts/employeeAdd`;
    static accountEmployeeDelete = `/accounts/employeeDelete`;
    static accountGetPermission = `/accounts/getPermission`;
    static accountPermissionUpdate = `/accounts/permissionUpdate`;
    static inquiry = `/inquiry`;
    static inquiryAnswer = `/inquiry/answer`;
    static storeCCTVCreate = `/store/cctvCreate`;
    static storeCCTVDelete = `/store/cctvDelete`;
    static storeCCTVGet = `/store/cctvGet`;
    static storeCCTVGetList = `/store/cctvGetList`;
    static storeCCTVUpdate = `/store/cctvUpdate`;
    static storeGetStoresByNamePath = `/store/getStoresByName`;
    static storeGetAccessibleStores = `/store/getAccessibleStores`;
    static storeGetAccessibleCompaniesForStoreAdd = `/store/getAccessibleCompaniesForStoreAdd`;
    static storeCreate = `/store/create`;
    static storeUpdate = `/store/update`;
    static storeDelete = `/store/delete`;
    static storeGetStoreInfoForUpdate = `/store/getStoreInfoForUpdate`;
    static storeLog = `/store/log`;
    static storeServerCreate = `/store/serverCreate`;
    static storeIrisClientCreate = `/store/irisClientCreate`;
    static storeIrisClientUpdate = `/store/irisClientUpdate`;
    static storeIrisClientUpdateIsScheduleOn = `/store/irisClientUpdateIsScheduleOn`;
    static storeIrisClientUpdateIndividualScheduleOn = `/store/irisClientUpdateIndividualScheduleOn`;
    static storeIrisClientUpdateLockDoor = `/store/irisClientUpdateLockDoor`;
    static storeIrisClientUpdateAgeVerificationOn = `/store/irisClientUpdateAgeVerificationOn`;
    static storeIrisClientGetSchedules = `/store/irisClientGetSchedules`;
    static storeIrisClientGetSchedule = `/store/irisClientGetSchedule`;
    static storeIrisClientAddSchedule = `/store/irisClientAddSchedule`;
    static storeIrisClientDeleteSchedule = `/store/irisClientDeleteSchedule`;
    static storeIrisClientUpdateSchedule = `/store/irisClientUpdateSchedule`;
    static storeIrisClientUpdateAgeVerificationTime = `/store/irisClientUpdateAgeVerificationTime`;
    static storeIrisClientDelete = `/store/irisClientDelete`;
    static storeGetIrisClient = `/store/getIrisClient`;
    static storeServerUpdate = `/store/serverUpdate`;
    static storeServerDelete = `/store/serverDelete`;
    static storeGetServers = `/store/getServers`;
    static storeGetServer = `/store/getServer`;
    static storeGetIots = `/store/getIots`;
    static storeGetIot = `/store/getIot`;
    static storeIotUpdate = `/store/iotUpdate`;
    static storeIotDelete = `/store/iotDelete`;
    static storeIotScheduleAdd = `/store/iotScheduleAdd`;
    static storeIotScheduleDelete = `/store/iotScheduleDelete`;
    static storeIotScheduleUpdate = `/store/iotScheduleUpdate`;
    static storeIotScheduleUpdateIndividualOn = `/store/iotScheduleUpdateIndividualOn`;
    static storeIotScheduleGet = `/store/iotScheduleGet`;
    static storeIotScheduleGetList = `/store/iotScheduleGetList`;
    static storeIotUpdateIRFanSpeed = `/store/iotUpdateIRFanSpeed`;
    static storeIotUpdateIRMode = `/store/iotUpdateIRMode`;
    static storeIotUpdateIRPower = `/store/iotUpdateIRPower`;
    static storeIotUpdateIRPowerMode = `/store/iotUpdateIRPowerMode`;
    static storeIotUpdateIRTemperature = `/store/iotUpdateIRTemperature`;
    static storeIotUpdateIRTemperatureRange = `/store/iotUpdateIRTemperatureRange`;
    static storeVideoCallAddDevice = `/store/videoCallAddDevice`;
    static storeVideoCallDeleteDevice = `/store/videoCallDeleteDevice`;
    static storeVideoCallGetDevice = `/store/videoCallGetDevice`;
    static storeVideoCallGetDeviceList = `/store/videoCallGetDeviceList`;
    static storeVideoCallUpdateDevice = `/store/videoCallUpdateDevice`;
    static versionCodePath = "/home/managerVersionCode"
}

export default ServerAPI;
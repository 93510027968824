class DateUtil {
    isInOneMonth(startDate: Date, endDate: Date) {
        const startDateYear = startDate.getFullYear();
        const endDateYear = endDate.getFullYear();
        const approximatedDate = (endDate.getDate() + endDate.getMonth() * 31) -
            (startDate.getDate() + startDate.getMonth() * 31) + ((endDateYear - startDateYear) * 12 * 31);
        return approximatedDate >= 0 && approximatedDate <= 31;
    }

    toDateISOStringWithoutTime(date: Date) {
        return new Date(date.toDateString()).toISOString();
    }
}

export const dateUtil = new DateUtil();
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import OkButton from "../components/OkButton";

export default function CCTVPlayPage(){
    const [videoURL, setVideoURL] = useState<string>();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setVideoURL(getPlayURL());
    }, [])

    function getPlayURL() : string{
        const searchParams = new URLSearchParams(location.search);
        const host = searchParams.get('host');
        const path = searchParams.get('path');
        if(!host || !path) return "";
        return `http://${host}${path}`;
    }
    return <div>
        <h4>영상재생</h4>
        <OkButton onClick={() => navigate(-1)} className="mb-3">돌아가기</OkButton>
        {videoURL?
        <video className="w-100" src={videoURL} autoPlay muted controls crossOrigin="anonymous" playsInline/>
        :
        <div className="text-danger">영상 재생정보가 없습니다.</div>
        }
    </div>
}
import { Form, Formik } from "formik";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import OkButton from "../components/OkButton";
import CheckboxField from "../forms/CheckboxField";
import EmailField from "../forms/EmailField";
import TextField from "../forms/TextField";
import RouteConfig from "../route-config";
import EmailValidation from "../utils/EmailValidation";
import { AppleLoginInfo, EmailInfo, loginDTO } from './Accounts.Models';
import * as Yup from 'yup'
import ServerAPI from "../ServerAPI";
import { useContext, useEffect, useRef, useState } from "react";
import DisplayErrors from "../components/DisplayErrors";
import AuthenticationContext from "./AuthenticationContext";
import JWTHandler from "../utils/JWTHandler";
import HttpRequest from "../utils/HttpRequest";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import AppContext from "../AppContext";
import KakaoButton from "../components/KakaoButton";
import NaverButton from "../components/NaverButton";
import { app } from "../utils/AppCommnunication";
import Dialog from "../utils/Dialog";
import AppleButton from "../components/AppleButton";


function LoginPage(){
    const {id} = useParams();
    const [errors, setErrors] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {device } = useContext(AppContext);
    const {update} = useContext(AuthenticationContext);
    const navigate = useNavigate();
    const autoLoginRef = useRef(false);
    const naverLoginButtonRef = useRef<any>(null);
    const [naverLoginLoading, setNaverLoginLoading] = useState(false);
    const [appleLoginLoading, setAppleLoginLoading] = useState(false);

    let emailInfo : EmailInfo = {
        emailId : "",
       emailFull : "",
        emailSite : "",
       emailSiteSelection : "-1"
    }
    if(id) {
        let newEmailInfo = EmailValidation.parseEmail(id);
        if(newEmailInfo) emailInfo = newEmailInfo;
    }
    const loginDTO : loginDTO = {
        ...emailInfo,
        password : "",
        autoLogin : false,
    }

    useEffect(() => {
        const handler = app.addListener(onAppMessage);
        return () => {
            app.removeListener(handler);
          };
    }, [device]);

    function onAppMessage(type: string, data: any) {
        try {
          if (type === "naverLogin") {
            setNaverLoginLoading(false);
            if(data.success){
                const callbackURL = `${RouteConfig.accountNaverLoginPath}?autoLogin=${autoLoginRef.current}#access_token=${encodeURIComponent(data.token)}`;
                navigate(callbackURL);
            }
            else {
                Dialog.showError("네이버로그인 연결에 실패했습니다.");
            }
          }
          else if (type === "appleLogin") {
            setAppleLoginLoading(false);
            if(data.isSuccess){
                if(data.firstName || data.lastName)
                    navigate(`${RouteConfig.accountAppleLoginPath}?autoLogin=${autoLoginRef.current}&fName=${encodeURIComponent(data.firstName)}&lName=${encodeURIComponent(data.lastName)}#token=${encodeURIComponent(data.token)}`);
                else
                    navigate(`${RouteConfig.accountAppleLoginPath}?autoLogin=${autoLoginRef.current}#token=${encodeURIComponent(data.token)}`);
            }
            else {
                Dialog.showError("애플로그인 연결에 실패했습니다.");
            }
          }
        }
        catch (e) { console.log(e) }
      }


    async function onLogin(userInfo : loginDTO){
        setIsSubmitting(true);
        try {
            setErrors([]);
            var userDTO = {
                email : userInfo.emailFull.trim(),
                password : userInfo.password,
                autoLogin : userInfo.autoLogin,
                device : device ?? null,
            };
            
            await HttpRequest.post(ServerAPI.accountLoginPath, userDTO);
            const newClaims = await JWTHandler.getClaims()
            update(newClaims);
            navigate('/');
        }
        catch(error) {
            if(error?.response?.data){
                setErrors(error.response.data);
            }
            else{
                setErrors(["서버연결에 실패했습니다."])
            }
        }
        setIsSubmitting(false);
    }

    function onAppleLogin(e : React.MouseEvent<Element, MouseEvent>){
        setAppleLoginLoading(true);
        if(app.isApp() && device?.platform === "ios"){
            e.stopPropagation();
            app.send("appleLogin");
        }
    }

    function onKakaoLogin(){
        if(!window.Kakao) return;
        window.Kakao.Auth.authorize({
            redirectUri : `${RouteConfig.getFullBaseURL()}${RouteConfig.accountKakaoLoginPath}`,
            state : `${autoLoginRef.current}`,
        });
    }

    function onNaverLogin(){
        if(!window.naver  || !naverLoginButtonRef.current) return;
        if(app.isApp()){
            setNaverLoginLoading(true);
            app.send("naverLogin");

        }
        else {
        const callbackURL = `${RouteConfig.getFullBaseURL()}${RouteConfig.accountNaverLoginPath}?autoLogin=${autoLoginRef.current}&`;
        const naverLogin = new window.naver.LoginWithNaverId(
          {
            clientId: "KfE8pk9xigBNyZMB68oq",
            callbackUrl: callbackURL, 
            isPopup: false,
          }
        );
        naverLogin.init();
        naverLoginButtonRef.current.click();
        }
    }



    return  <div className='row'>
    <div className='col-lg-5'>
    <h3 className="mb-3">로그인</h3>
                <Formik initialValues={loginDTO} onSubmit={async value => await onLogin(value)} 
                validationSchema={Yup.object({
                    emailId: Yup.string().required('이메일을 입력해주세요.').max(32, "이메일길이가 32글자를 초과합니다."),
                    emailSiteSelection: Yup.string().test("isValidEmail", "이메일 형식이 올바르지 않습니다.",
                        function (siteSelection) {
                            if (!siteSelection) return false;
                            const { emailFull } = this.parent;
                            return EmailValidation.isValidEmail(emailFull);
                        }).max(64, "이메일 전체길이가 64자를 초과할 수 없습니다."),
                    password: Yup.string().required('비밀번호를 입력해주세요.').max(32, "비밀번호는 32글자 이하로 입력해주세요.")
                })
                }>
                    {(formikProps) => 
                        <Form>
                            <EmailField
                                emailIdInputField={'emailId'} emailSiteInputField={'emailSite'} emailSiteSelectionField={'emailSiteSelection'} emailFullField={'emailFull'} />
                                <TextField type="password" field='password' display='비밀번호' className='mt-2' />
                               <DisplayErrors errors={errors} />
                                <div className="d-flex mt-2">
                                    <CheckboxField displayName="자동로그인" field="autoLogin" onChange={v => {autoLoginRef.current = v}} />
                                </div>
                                <div className='mt-3'>
                                    <ComponentToLoadSmallCenter isLoading={isSubmitting}>
                                    <OkButton disabled={isSubmitting} type='submit' className='w-100 btn-height'>로그인</OkButton>
                                    </ComponentToLoadSmallCenter>
                                    <div className="mt-2">
                                    <KakaoButton className="w-100 btn-height" onClick={onKakaoLogin}/>
                                    </div>
                                    <ComponentToLoadSmallCenter className="mt-2" isLoading={naverLoginLoading}>
                                    <NaverButton  className="w-100 btn-height" onClick={onNaverLogin}/>
                                    </ComponentToLoadSmallCenter>
                                    <div id="naverIdLogin_loginButton" ref={naverLoginButtonRef}  className="mt-2">
                                    </div>
                                    <div className="mt-2">
                                        {appleLoginLoading ?
                                        <ComponentToLoadSmallCenter isLoading={appleLoginLoading}><></></ComponentToLoadSmallCenter>
                                        : 
                                        <AppleButton className="btn-height-two" onClick={(e) => onAppleLogin(e)} autoLogin={formikProps.values.autoLogin} />
                                        }
                                        </div>
                                    </div>

                                <div className="d-flex mt-2">
                                    <NavLink to={RouteConfig.accountRegisterPath} className="me-2">회원가입</NavLink>
                                    <NavLink to={RouteConfig.accountEmailVerificationPath}>이메일인증</NavLink>
                                </div>
                        
                        </Form>
                    }
                </Formik>
        </div>
        </div>
}
export default LoginPage;
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CollapseIcon from "../components/CollapseIcon";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton";
import SubButton from "../components/SubButton";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import ArrayUtil from "../utils/ArrayUtil";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import stringUtil from "../utils/StringUtil";
import InquiryComponent from "./InquiryComponent";
import { Inquiry } from "./Settings.Models";

export default function InquiryPage() {
    const inquiriesRef= useRef<Inquiry[]>();
    const [selectedInquiries, setSelectedInquiries] = useState<Inquiry[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);
    const [type, setType] = useState<string>("0")
    const navigate = useNavigate();

    useEffect(() => {
        updateInquiryList();
    }, []);

    async function updateInquiryList() {
        setIsLoading(true);
        setErrors([]);
        try {
            const inquiries = await HttpRequest.get<Inquiry[]>(`${ServerAPI.inquiry}`);
            ArrayUtil.sortDescending(inquiries, "registerDate");
            inquiriesRef.current = inquiries;
            onChangeType(type);
        }
        catch (error) {
            inquiriesRef.current = undefined
            setSelectedInquiries(undefined);
            if (error?.response?.data) {
                setErrors(error.response.data);
            }
            else {
                setErrors(["서버연결에 실패했습니다."])
            }
        }
        setIsLoading(false);
    }
    function getTypeName(type:string){
        if(type === "0") return "전체";
        else if(type === "1") return "답변대기";
        else if(type === "2") return "답변완료";
        return "";
    }

    function onChangeType(newType : string){
        setType(newType);
        if(inquiriesRef.current){
            if(newType === "0")
                setSelectedInquiries(inquiriesRef.current);
            else if(newType === "1")
                setSelectedInquiries(inquiriesRef.current.filter(i => stringUtil.isNullOrWhiteSpace(i.answer)));
            else if(newType === "2")
                setSelectedInquiries(inquiriesRef.current.filter(i => !stringUtil.isNullOrWhiteSpace(i.answer)));
        }
        else{
            setSelectedInquiries(undefined);
        }
    }

    return <div>
        <h3 className='mb-3'>문의 내역</h3>
        <div className="row g-0">
            <div className="col-lg-8">
                <div >
                    <OkButton onClick={() => navigate(RouteConfig.inquiryAddPath)}>
                        새 문의 등록
                    </OkButton>
                </div>
             
                <ComponentToLoad isLoading={isLoading} className="mt-2">
                    {selectedInquiries ?
                        <>
                            <select className="form-select mb-2" value={type} onChange={(e) => onChangeType(e.currentTarget.value)}>
                                 {["0", "1", "2"].map(i => <option key={i} value={i}>{getTypeName(i)}</option>)}
                            </select>
                            <div className="fw-bold">[{getTypeName(type)}] 총 {selectedInquiries.length}개 문의내역이 있습니다.</div>

                            {selectedInquiries.map(c =>
                                <div key={c._id} className="my-1">
                                    <InquiryComponent inquiry={c} updateList={()=> updateInquiryList()} />
                                </div>
                            )}
                        </>
                        :
                        <DisplayErrors errors={errors} />
                    }

                </ComponentToLoad>
            </div>
        </div>
    </div>
}
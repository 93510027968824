import { Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import ComponentToLoad from "../components/ComponentToLoad";
import DisplayErrors from "../components/DisplayErrors";
import { accountUpdateDTO } from "./Accounts.Models";
import * as Yup from 'yup'
import TextField from "../forms/TextField";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import OkButton from "../components/OkButton";
import HttpRequest from "../utils/HttpRequest";
import ServerAPI from "../ServerAPI";
import CheckboxField from "../forms/CheckboxField";
import Dialog from "../utils/Dialog";
import Authorization from "../utils/Authorization";
import JWTHandler from "../utils/JWTHandler";
import AuthenticationContext from "./AuthenticationContext";


export default function AccountUpdatePage() {
    const {claims} = useContext(AuthenticationContext);
    const [errors, setErrors] = useState<string[]>([]);
    const [account, setAccount] = useState<accountUpdateDTO>();
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isSocial, setIsSocial] = useState(false);
    const [validationObj, setValidationObj] = useState<any>({});

    useEffect(() => {
        getAccountInfo();
    }, []);

    useEffect(() => {
        const isSocial = Authorization.hasClaim(claims, "socialLogin", "1") || Authorization.hasClaim(claims, "socialLogin", "1"); 
        setIsSocial(isSocial);
        setValidationObj(getValidationObj(isSocial));
    }, [claims])

    async function getAccountInfo() {
        setIsLoading(true);
        try {
            setErrors([]);
            const accountInfo = await HttpRequest.get<accountUpdateDTO>(`${ServerAPI.accountGetInfoPath}`);
            setAccount(accountInfo);
        }
        catch(error) {
            var message = "에러가 발생했습니다.\n";
            if(error?.response?.data){
                message += error.response.data;
            }
            setAccount(undefined);
            setErrors([message]);
        }
        setIsLoading(false);
    }

    function getValidationObj(social : boolean){
        let validationObj = Yup.object({
            email : Yup.string().required('이메일을 입력해주세요').max(64, "이메일 전체길이가 64글자를 초과할 수 없습니다."),
            name: Yup.string().required('이름을 입력해주세요.').max(32, "이름길이가 32글자를 초과할 수 없습니다."),
            phoneNumber: Yup.string().required('연락처를 입력해주세요.').max(32, "연락처길이가 32글자를 초과할 수 없습니다."),
       });
       if(!social)
          validationObj = validationObj.concat(Yup.object({
            password: Yup.string().required('비밀번호를 입력해주세요.').max(32, "비밀번호는 32글자 이하로 입력해주세요.")
       }));
       return validationObj;
    }

    function onUpdate(account: accountUpdateDTO, actions: FormikHelpers<accountUpdateDTO>) {
        Dialog.showDialog("내 정보를 수정하시겠습니까?", async (result: boolean) => {
            if (result) {
                setIsUpdating(true);
                try {
                    setErrors([]);
                    await HttpRequest.put(`${ServerAPI.accountUpdate}`, account);
                    Dialog.showConfirmation("정보수정이 완료되었습니다.");
                }
                catch (error) {
                    if (error?.response?.data) {
                        setErrors(error.response.data);
                    }
                    else {
                        setErrors(["서버연결에 실패했습니다."])
                    }
                }
                setIsUpdating(false);
            }
        });
    }

    return <div className='row'>
    <div className='col-lg-5'>
        <h3 className="mb-3">내 정보 수정</h3>
        <ComponentToLoad isLoading={isLoading}>
            {account ?
            <Formik initialValues={account} onSubmit={onUpdate } enableReinitialize
    validationSchema={validationObj}
    >
        {(formikProps) => 
            <Form>
                <TextField field="email" display="계정 (이메일)" className="mb-3" disabled={true}/>
                <TextField field='name' display="이름" className="mb-3" />
                <TextField field='phoneNumber' display="연락처"/>
                <CheckboxField field="marketingAgreed" className='mb-3' displayName='[선택] 마케팅 활용 및 광고성 정보 수신동의'
                            />
                            {!isSocial &&
            <TextField type="password" field='password' display='비밀번호확인' className="mb-4" />
                            }
        <DisplayErrors errors={errors}/>
                <ComponentToLoadSmallCenter isLoading={isUpdating}>
                <OkButton type="submit" className="w-100" disabled={isUpdating}>수정하기</OkButton>
                </ComponentToLoadSmallCenter>
            </Form>
        }
    </Formik>
    :
        <DisplayErrors errors={errors} />
    }
        </ComponentToLoad>
        </div>
        </div>
}
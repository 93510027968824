import axios, { AxiosResponse } from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DisplayErrors from "../components/DisplayErrors";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import AppContext from "../AppContext";
import * as Yup from 'yup'
import StringValidation from "../utils/StringValidation";
import TextField from "../forms/TextField";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import { Company } from "./Companies.Models";
import AddressField from "../forms/AddressField";
import CompanyTypeAhead from "../components/CompanyTypeAhead";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import ComponentToLoad from "../components/ComponentToLoad";


export default function CompanyAddPage() {
    const { companyId } = useParams();
    const [errors, setErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isInfoLoading, setIsInfoLoading] = useState(true);
    const [company, setCompany] = useState<Company>({
        _id: "",
        name: "",
        parentCompanyId: "",
        addressMain : "",
        addressDetail : "",
        addressZipcode : "",
        phone : "",
        registerDate : 0,
    });
    const navigate = useNavigate();

    useEffect(() => {
        getCompanyInfo();
    }, []);

    async function getCompanyInfo() {
        setIsInfoLoading(true);
        if (companyId) {

        try {
            const company = await HttpRequest.get<Company>(`${ServerAPI.accountGetCompanyInfoForUpdate}/${companyId}`);
            setCompany(company);
        }
        catch(error) {
            var message = "업체정보를 가져오는데 실패했습니다.\n";
            if(error?.response?.data){
                message += error.response.data;
            }
            Dialog.showError(message);
        }
       }
        setIsInfoLoading(false);
    }

    function onSubmit(company: Company, actions: FormikHelpers<Company>) {
        Dialog.showDialog(`해당 업체를 ${companyId ? "수정" : "등록"}하시겠습니까?`, async (result) => {
            if (result) {
                setIsLoading(true);
                try {
                    setErrors([]);
                    const apiPath = companyId ? ServerAPI.accountCompanyUpdatePath : ServerAPI.accountCompanyCreatePath;
                    await HttpRequest.post(`${apiPath}`, company);
                    Dialog.showConfirmation(`업체 ${companyId ? "수정" : "등록"}이 완료되었습니다.`);
                    navigate(RouteConfig.companyPath);
                }
                catch (error) {
                    if (error?.response?.data) {
                        setErrors(error.response.data);
                    }
                    else {
                        setErrors(["서버연결에 실패했습니다."])
                    }
                }
                setIsLoading(false);
            }
        });
    }


    return <div className='row'>
        <div className='col-lg-5'>
            <h3 className="mb-3">{companyId ? "업체 수정" : "업체 등록"} </h3>
            <>
            <ComponentToLoad isLoading={isInfoLoading}>
                <Formik initialValues={company} onSubmit={onSubmit} enableReinitialize
                    validationSchema={
                        Yup.object({
                            name: Yup.string().required('업체명을 입력해주세요.').max(64, "업체명 길이가 64글자를 초과합니다."),
                            parentCompanyId : Yup.string().required('소속업체를 선택해주세요.'),
                            phone: Yup.string().required('업체 연락처를 입력해주세요.').max(32, "업체연락처 길이가 32글자를 초과합니다."),
                            addressMain: Yup.string().required('우편번호 찾기를 해주세요.').max(50, "주소가 50글자를 초과할수 없습니다."),
                            addressZipcode: Yup.string().required('우편번호 찾기를 해주세요.').max(10, "우편번호가 10글자를 초과할수 없습니다."),
                            addressDetail: Yup.string().required('상세주소를 입력해주세요.').max(50, "상세주소가 50글자를 초과할수 없습니다."),
                        })
                    }>
                    {(formikProps) =>
                        <Form>
                             <div className='mb-4'>
                                {companyId ?
                                <TextField field="parentCompanyName" display='소속업체' disabled={true} />
                                : <>
                                        <label className='form-label mb-0'>소속업체 선택</label>
                                        <div className='text-primary' >
                                                ※ 소속업체가 없을 시 'NSM'을 검색해 선택해주세요.
                                            </div>
                                        <CompanyTypeAhead fieldName="parentCompanyId" />
                                        </>
                                }
                                        </div>

                            <TextField field='name' display='업체명' className='mb-3' />
                            <TextField field='phone' display='업체연락처' className='mb-3' />
                            <AddressField className="mb-4" display='업체주소' mainAddressFieldName='addressMain'
                                    zipCodeFieldName='addressZipcode' addressDetailFieldName='addressDetail' />
                                    <ComponentToLoadSmallCenter isLoading={isLoading}>
                            <OkButton type="submit" className="w-100" disabled={isLoading}>{companyId ? "수정하기" : "등록하기"}</OkButton>
                            </ComponentToLoadSmallCenter>
                        </Form>
                    }
                </Formik>
               </ComponentToLoad>
                <DisplayErrors errors={errors} />
            </>
        </div>
    </div>
}

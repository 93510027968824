import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CollapseIcon(props : CollapseIconProps) {
    return <div className={`ms-1 text-primary ${props.className ?? ""}`} 
    style={{'fontSize' : '1.2em', 'cursor' : 'pointer'}} 
    onClick={() => {props.setIsOpen(!props.isOpen)}}>{props.isOpen ? <FontAwesomeIcon icon="square-minus" /> : <FontAwesomeIcon icon="square-plus" />}</div>
}

interface CollapseIconProps {
    isOpen : boolean,
    setIsOpen(isOpen : boolean) : void,
    className? : string,
}
import axios, { AxiosResponse } from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DisplayErrors from "../components/DisplayErrors";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import { Server, storeCreateDTO } from "./Store.Models";
import AppContext from "../AppContext";
import * as Yup from 'yup'
import StringValidation from "../utils/StringValidation";
import TextField from "../forms/TextField";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import stringUtil from "../utils/StringUtil";


function ServerPage() {
    const {serverId} = useParams();
    const {selectedStoreId, updateStores} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [server, setServer] = useState<Server>({
        _id : 0,
        name : "",
        bindIp : "",
        webIp : "",
        webPort : null,
        webPortString : "",
    });
    const navigate = useNavigate();

    useEffect(() => {
        getServerInfo(serverId);
    }, [selectedStoreId, serverId]);

    async function getServerInfo(serverId : string | undefined) {
        setIsLoading(true)
        if(selectedStoreId && serverId){
        try {
            const server = await HttpRequest.get<Server>(ServerAPI.storeGetServer, {params : {storeId : selectedStoreId, serverId : serverId}});
            if(server.webIp ===null) server.webIp = "";
            if(server.webPort ===null) server.webPortString = "";
            else server.webPortString = server.webPort.toString();
            setServer(server);
        }
        catch(error) {
            var message = "서버정보를 가져오는데 실패했습니다.\n";
            if(error?.response?.data){
                message += error.response.data;
            }
            Dialog.showError(message);
        }
        }
        setIsLoading(false);
    }

    function onSubmit(server : Server, actions : FormikHelpers<Server>) {
        Dialog.showDialog(`해당 서버를 ${serverId ? "수정" : "등록"}하시겠습니까?`, async (result)=>{
            if(result) {
                setIsUpdating(true);
            try {
                setErrors([]);
                if(stringUtil.isNullOrWhiteSpace(server.webPortString))
                     server.webPort = null;
                else
                    server.webPort = Number.parseInt(server.webPortString!);
                const serverPath = serverId ? ServerAPI.storeServerUpdate : ServerAPI.storeServerCreate;
                await HttpRequest.post(`${serverPath}/${selectedStoreId}`, server);
                Dialog.showConfirmation(`서버 ${serverId ? "수정" : "등록"}이 완료되었습니다.`);
                updateStores();
                navigate(-1);
            }
            catch(error) {
                if(error?.response?.data){
                    setErrors(error.response.data);
                }
                else{
                    setErrors(["서버연결에 실패했습니다."])
                }
            }
            setIsUpdating(false);
        }
        });
    }


    return  <div className='row'>
    <div className='col-lg-5'>
    <h3 className="mb-3">{serverId ? "서버 수정" : "서버 등록"} </h3>
    { selectedStoreId ?
    <ComponentToLoad isLoading={isLoading}>
    <>
    <Formik initialValues={server} onSubmit={onSubmit} enableReinitialize
    validationSchema={
        Yup.object({
        name: Yup.string().required('서버이름 입력해주세요.').max(32, "서버이름 길이가 32글자를 초과합니다."),
        bindIp : Yup.string().test("validIPAddress", "IP주소 형식이 올바르지 않습니다.",
        value => StringValidation.validIpAddress(value)),
        webIp : Yup.string().test("validIPAddress", "IP주소 형식이 올바르지 않습니다.",
        value => StringValidation.validIpAddress(value)),
        webPortString: Yup.number().typeError("숫자를 입력해주세요").min(1, "포토번호가 올바르지 않습니다.").max(65535, "포트번호가 올바르지 않습니다."),
       })
       
    }>
        {(formikProps) => 
            <Form>
                <TextField field="name" display="서버이름" className="mb-3" required={true}/>
                <TextField field="bindIp" display="바인딩 IP주소 (선택사항)" placeholder="xxx.xxx.xxx.xxx"/>
                <div className="text-primary mb-4">
                    ※ 입력할 경우 해당 IP주소로만 서버가 연결됩니다.
                </div>
                <TextField field="webIp" display="웹 IP주소 (선택사항)" placeholder="xxx.xxx.xxx.xxx"/>
                <div>
                <div className="text-primary">
                    ※ CCTV 녹화영상 재생기능 사용 시 필수입력사항입니다.
                </div>
                <div className="text-primary mb-4">
                    ※ 외부에서 접속가능한 IP주소를 입력해주세요.
                </div>
                </div>
                <TextField field="webPortString" display="웹 포트 (선택사항)" />
                <div className="text-primary mb-4">
                    ※ 외부에서 접속가능한 포트번호를 입력해주세요. (공유기 사용 시 포트포워딩 필요)
                </div>
                <ComponentToLoadSmallCenter isLoading={isUpdating}>
                <OkButton type="submit" className="w-100" disabled={isUpdating}>{serverId ? "수정하기" : "등록하기"}</OkButton>
                </ComponentToLoadSmallCenter>
            </Form>
        }
    </Formik>
    <DisplayErrors errors={errors} />
    </>
    </ComponentToLoad>
    :
    <div>
            선택된 매장이 없습니다. 매장을 선택해주세요.
            </div>
}
</div> 
    </div>
}

export default ServerPage;
import { useParams } from "react-router-dom";
import ScheduleListPage from "../components/ScheduleListPage";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";

export default function IotScheduleListPage() {
    const {clientId} = useParams();
    return <ScheduleListPage title="냉난방기 전원제어 스케줄 목록" scheduleRegisterPageURL={RouteConfig.iotScheduleAddPath} 
    getScheduleListURL={ServerAPI.storeIotScheduleGetList} addScheduleURL={ServerAPI.storeIotScheduleAdd} 
    clientId={clientId!} 
    deleteScheduleURL={ServerAPI.storeIotScheduleDelete}
    updateScheduleOnURL={ServerAPI.storeIotScheduleUpdateIndividualOn} />
}

import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/ko'
import { useEffect, useState } from "react";

export default function MyDateRangePicker(props : MyDateRangePickerProps){
    const [startDate, setStartDate] = useState<Dayjs|null>( dayjs(props.startDate));
    const [endDate, setEndDate] = useState<Dayjs|null>( dayjs(props.endDate));

    useEffect(() => {
      setStartDate(dayjs(props.startDate));
      setEndDate(dayjs(props.endDate));
    }, [props.startDate, props.endDate])

    return <div className={props.className ?? ""}>
    <LocalizationProvider  dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
        <div className="d-flex align-items-center justify-content-center" >
    <DatePicker
    disabled={props.disabled}
    label="시작날짜"
      value={startDate}
      onChange={(newDate) => {
        setStartDate(newDate);
        if(newDate && props.onStartDateChange)
           props.onStartDateChange(newDate.toDate());
      }}
      renderInput={(params) => 
        <>
       <TextField {...params} size="small">  </TextField>
       </>
    }
    />
     <div className="mx-1">~</div>
    <DatePicker
    disabled={props.disabled}
    label="종료날짜"
      value={endDate}
      onChange={(newDate) => {
        setEndDate(newDate);
        if(newDate && props.onEndDateChange)
            props.onEndDateChange(newDate.toDate());
      }}
      renderInput={(params) => 
        <>
       <TextField {...params} size="small">  </TextField>
       </>
    }
    />
    </div>
  </LocalizationProvider>
  </div>
}

interface MyDateRangePickerProps {
    startDate : Date,
    endDate : Date,
    onStartDateChange?(startDate : Date) : void,
    onEndDateChange?(startDate : Date) : void,
    disabled? : boolean,
    className? : string,
}

MyDateRangePicker.defaultProps = {
  disabled : false
}
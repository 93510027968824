import axios, { AxiosResponse } from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DisplayErrors from "../components/DisplayErrors";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import AppContext from "../AppContext";
import * as Yup from 'yup'
import StringValidation from "../utils/StringValidation";
import TextField from "../forms/TextField";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import { Company, EmployeeAddDTO } from "./Companies.Models";
import AddressField from "../forms/AddressField";
import CompanyTypeAhead from "../components/CompanyTypeAhead";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import ComponentToLoad from "../components/ComponentToLoad";


export default function EmployeeAddPage() {
    const { companyId } = useParams();
    const [errors, setErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isInfoLoading, setIsInfoLoading] = useState(true);
    const [employee, setEmployee] = useState<EmployeeAddDTO>({
        companyName: "",
        email : "",
    });
    const navigate = useNavigate();

    useEffect(() => {
        getCompanyInfo();
    }, []);

    async function getCompanyInfo() {
        setErrors([]);
        setIsInfoLoading(true);
        if (companyId) {

        try {
            const company = await HttpRequest.get<Company>(`${ServerAPI.accountGetCompanyInfoForUpdate}/${companyId}`);
            setEmployee({... employee, companyName : company.name});
        }
        catch(error) {
            var message = "업체정보를 가져오는데 실패했습니다.\n";
            if(error?.response?.data){
                message += error.response.data;
            }
            Dialog.showError(message);
        }
       }
        setIsInfoLoading(false);
    }

    function onSubmit(employee: EmployeeAddDTO) {
        Dialog.showDialog(`직원을 새로 추가하시겠습니까?`, async (result) => {
            if (result) {
                setIsLoading(true);
                try {
                    setErrors([]);
                    await HttpRequest.post(ServerAPI.accountEmployeeAdd, {companyId : companyId, email : employee.email});
                    Dialog.showConfirmation(`직원 추가가 완료되었습니다.`);
                    navigate(RouteConfig.companyPath);
                }
                catch (error) {
                    if (error?.response?.data) {
                        setErrors(error.response.data);
                    }
                    else {
                        setErrors(["서버연결에 실패했습니다."])
                    }
                }
                setIsLoading(false);
            }
        });
    }


    return <div className='row'>
        <div className='col-lg-5'>
            <h3 className="mb-3">직원추가</h3>
            <>
            <ComponentToLoad isLoading={isInfoLoading}>
                <Formik initialValues={employee} onSubmit={onSubmit} enableReinitialize
                    validationSchema={
                        Yup.object({
                            email: Yup.string().required('계정을 입력해주세요.').max(64, "계정 길이가 64글자를 초과합니다."),
                        })
                    }>
                    {(formikProps) =>
                        <Form>
                            <TextField field='companyName' display='소속업체명' className='mb-3' disabled={true}/>
                            <TextField field='email' display='직원계정(이메일)' className='mb-3' />
                                    <ComponentToLoadSmallCenter isLoading={isLoading}>
                            <OkButton type="submit" className="w-100" disabled={isLoading}>추가하기</OkButton>
                            </ComponentToLoadSmallCenter>
                        </Form>
                    }
                </Formik>
               </ComponentToLoad>
                <DisplayErrors errors={errors} />
            </>
        </div>
    </div>
}

import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DisplayErrors from "../components/DisplayErrors";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import AppContext from "../AppContext";
import * as Yup from 'yup'
import TextField from "../forms/TextField";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import { IoTClient } from "./Iots.Models";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";


function IotUpdatePage() {
    const [errors, setErrors] = useState<string[]>([]);
    const { id} = useParams();
    const [iot, setIot] = useState<IoTClient>();
    const [isLoading, setIsLoading] = useState(true);
    const { selectedStoreId, socketManager } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        getIotInfo(id);
    }, [selectedStoreId, id]);

    async function getIotInfo(id : string | undefined) {
        setIsLoading(true);
        try {
            if(!id) return;
            if (!selectedStoreId) {
                setErrors(["선택된 매장이 없습니다."]);
                setIot(undefined);
                return;
            }
            setErrors([]);
            const iotClient = await HttpRequest.get<IoTClient>(ServerAPI.storeGetIot, { params: {storeId : selectedStoreId, id: id} });
            setIot(iotClient);
        }
        catch (error) {
            var message = "에러가 발생했습니다.\n";
            if (error?.response?.data) {
                message += error.response.data;
            }
            setErrors([message]);
            setIot(undefined);
        }
        setIsLoading(false);
    }

    function onUpdateClient(client : IoTClient, actions : FormikHelpers<IoTClient>) {
        Dialog.showDialog("해당 IoT기기의 이름을 변경하시겠습니까?", async (result) => {
            if(result) {
                try {
                    if(!selectedStoreId) {
                        setErrors(["선택된 매장이 없습니다."]);
                        setIot(undefined);
                        return;
                    }
                    setErrors([]);
                    await HttpRequest.put(`${ServerAPI.storeIotUpdate}/${selectedStoreId}`, client);
                    Dialog.showConfirmation("수정이 완료되었습니다.");
                    socketManager?.sendStoreUpdate(selectedStoreId);
                    navigate(RouteConfig.iotListPath);
                }
                catch(error) {
                    if(error?.response?.data){
                        setErrors(error.response.data);
                    }
                    else{
                        setErrors(["서버연결에 실패했습니다."]);
                    }
                }
            }
            actions.setSubmitting(false);

        });
        

    }

    return <div className='row'>
        <div className='col-lg-5'>
            <h3 className="mb-3">IoT 이름 변경 </h3>
            <ComponentToLoad isLoading={isLoading} >
            {selectedStoreId ?<> {iot&& 
            
                <Formik initialValues={iot} enableReinitialize onSubmit={onUpdateClient}
                validationSchema={
                    Yup.object({
                    name: Yup.string().max(20, "기기이름 길이가 20글자를 초과합니다."),
                    doorSensorNames : Yup.array().of(Yup.string().max(20, "도어센서 이름이 20글자를 초과합니다."))
                   })
                }
                    >
                    {(formikProps) =>
                        <Form>
                          <TextField field="_id" display="MAC 주소" disabled={true} className="mb-3"/>
                          <TextField field="name" display="기기이름" className="mb-3" placeholder="새 이름을 입력해주세요."/>
                          <FieldArray name="doorSensorNames"
                             render={arrayHelpers => 
                               formikProps.values.doorSensorNames && formikProps.values.doorSensorNames.length > 0 &&
                               formikProps.values.doorSensorNames.map((n, index) => 
                               <div key={index} className="mb-3">
                                <TextField field={`doorSensorNames.${index}`} display={`도어센서${index+1} 이름`} />

                               </div>
                               )
                            } />
                            <ComponentToLoadSmallCenter isLoading={formikProps.isSubmitting}>
                          <OkButton type="submit" className="w-100" disabled={formikProps.isSubmitting}>수정하기</OkButton>
                          </ComponentToLoadSmallCenter>

                        </Form>
                    }
                </Formik>
                }
                <DisplayErrors errors={errors} />
                </>
                :
                <div>
                    선택된 매장이 없습니다. 매장을 선택해주세요.
                </div>
            }
            </ComponentToLoad>
        </div>
    </div>
}

export default IotUpdatePage;
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useState } from "react";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton";
import { accountCreateDTO } from "./Accounts.Models";
import * as Yup from 'yup'
import EmailValidation from "../utils/EmailValidation";
import HeaderBar from "../components/HeaderBar";
import EmailField from "../forms/EmailField";
import IdDuplicationCheck from "./IdDuplicationCheck";
import HttpRequest from "../utils/HttpRequest";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import { useNavigate } from "react-router-dom";
import RouteConfig from "../route-config";
import TextField from "../forms/TextField";
import CheckboxField from "../forms/CheckboxField";
import DialogComponent from "../components/DialogComponent";
import UseTermsContent from "./UseTermsContent";
import PrivateTermsContent from "./PrivateTermsContent";


export default function RegisterForm(props : {initialValue : accountCreateDTO, 
                                               onSubmit : (newAccount : accountCreateDTO)=> Promise<void>, 
                                              email? : string, name? : string, isSocial : boolean}){
    const [account, setAccount] = useState<accountCreateDTO>(props.initialValue);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [useTermsOpen, setUseTermsOpen] = useState(false);
    const [privateTermsOpen, setPrivateTermsOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let newAccount = account;
        if(props.email){
            const emailInfo = EmailValidation.parseEmail(props.email);
            if(emailInfo) {
                newAccount = {...account, emailId : emailInfo.emailId, emailFull : emailInfo.emailFull, 
                    emailSiteSelection : emailInfo.emailSiteSelection, emailSite : emailInfo.emailSite};
            }
        }
        if(props.name){
            newAccount = {...newAccount, name : props.name};
        }
        setAccount(newAccount);
    }, []);

    async function onSubmit(newAccount : accountCreateDTO) {
        setIsSubmitting(true);
        try {
            setErrors([]);
            await props.onSubmit(newAccount);
        }
        catch (error) {
            if (error?.response.data) {
                setErrors(error.response.data);
            }
            else {
                setErrors(["서버연결에 실패했습니다."])
            }
        }
        finally{
        setIsSubmitting(false);
        }

    }

    function disableIdCheckButton(emailId: string, currentEmailFull: string, checkedEmailFull: string): boolean {
        if (emailId.length > 32 || !EmailValidation.isValidEmail(currentEmailFull))
            return true;
        else if (currentEmailFull.trim() === checkedEmailFull.trim())
            return true;
        else return false;
    }

    function termsAgreedChange(formikProps: FormikProps<accountCreateDTO>) {
        const fields = ["useTermsAgreed", "privateTermsAgreed", "marketingAgreed"];
        if (fields.every(s => formikProps.values[s as keyof typeof formikProps.values]) && !formikProps.values.allAgreed)
            formikProps.setFieldValue("allAgreed", true);
        else if (fields.some(s => !formikProps.values[s as keyof typeof formikProps.values]) && formikProps.values.allAgreed)
            formikProps.setFieldValue("allAgreed", false);
    }

    let validationObj = Yup.object({
        emailId: Yup.string().required('이메일을 입력해주세요.').max(32, "이메일길이가 32글자를 초과할 수 없습니다."),
        emailSiteSelection: Yup.string().test("isValidEmail", "이메일 형식이 올바르지 않습니다.",
            function (siteSelection) {
                if (!siteSelection) return false;
                const { emailFull } = this.parent;
                return EmailValidation.isValidEmail(emailFull);
            }).max(64, "이메일 전체길이가 64자를 초과할 수 없습니다."),
        
        name: Yup.string().required('이름을 입력해주세요.').max(32, "이름길이가 32글자를 초과할 수 없습니다."),
        phoneNumber: Yup.string().required('연락처를 입력해주세요.').max(32, "연락처길이가 32글자를 초과할 수 없습니다."),
        useTermsAgreed: Yup.bool().isTrue("약관에 동의해주세요."),
        privateTermsAgreed: Yup.bool().isTrue("약관에 동의해주세요."),
    });

    if(!props.isSocial){
        validationObj = validationObj.concat(Yup.object({
            password: Yup.string().required('비밀번호를 입력해주세요.').min(8, "비밀번호는 8글자 이상 입력해주세요.")
            .max(32, "비밀번호는 32글자 이하로 입력해주세요.")
            .test("noSpace", "비밀번호에 공백이 포함되어 있습니다.",
                value => (value !== undefined && !/[\s]/.test(value)))
            .test("noAlphabet", "비밀번호에 영어가 포함되어있지 않습니다.",
                value => (value !== undefined && /[a-zA-Z]/.test(value)))
            .test("noNumber", "비밀번호에 숫자가 포함되어있지 않습니다.",
                value => (value !== undefined && /[\d]/.test(value)))
            .test("noSpecialCharacter", "비밀번호에 특수문자가 포함되어있지 않습니다.",
                value => (value !== undefined && /[!@#$%^&*)(+=._-]/.test(value))),
        passwordConfirmed: Yup.string().required('비밀번호 확인을 해주세요.')
            .test("checkPassword", "비밀번호가 일치하지 않습니다.",
                function (value) {
                    if (!value) return false;
                    const { password } = this.parent;
                    if(!password) return false;
                    return value.trim() === password.trim();
                }),
        }));
    }
    

    return <Formik initialValues={account} onSubmit={onSubmit} enableReinitialize
    validationSchema={validationObj}
>
    {(formikProps) =>
        <Form>
            <HeaderBar display='계정정보' />
            {props.isSocial && formikProps.values.emailFull ?
            <>
            <label>아이디(이메일)</label>
            <input className="form-control" value={formikProps.values.emailFull} disabled={true}/>
            </>
            :
            <>
            <EmailField
                onEmailChanged={email => {
                    const disabled = disableIdCheckButton(formikProps.values.emailId, email, formikProps.values.checkedEmail);
                    formikProps.setFieldValue("idCheckButtonDisabled", disabled);
                }}
                emailIdInputField={'emailId'} emailSiteInputField={'emailSite'} emailSiteSelectionField={'emailSiteSelection'} emailFullField={'emailFull'} />
            <div className='text-primary'>
                ※ 회원가입 후 이메일 인증을 해야 서비스 이용이 가능합니다.
            </div>
            <IdDuplicationCheck className='mt-2' onCheckButtonClick={async () => {
                if (formikProps.errors.emailId || formikProps.errors.emailSiteSelection) return;

                try {
                    const isAvailable = await HttpRequest.get<boolean>(ServerAPI.accountIdCheckPath, { params: { email: formikProps.values.emailFull } });
                    if (isAvailable === true) {
                        formikProps.values.checkedEmail = formikProps.values.emailFull;
                        formikProps.setFieldValue("idCheckButtonDisabled", true);
                    }
                    else {
                        Dialog.showDialog(`아이디 "${formikProps.values.emailFull}"가 이미 존재합니다. 해당 아이디로 로그인하시겠습니까?`, (result) => {
                            result && navigate(`${RouteConfig.accountLoginPath}\\${formikProps.values.emailFull}`);
                        });
                    }
                }
                catch (error) {
                    if (error?.response?.data) {
                        Dialog.showError(error.response.data);
                    }
                }
            }} />
            </>
        }
        
{!props.isSocial &&
<>
            <TextField type="password" field='password' display='비밀번호' className='mt-3' />
            <div className="text-primary">
                ※ 비밀번호는 8자리이상 영문+숫자+특수문자로 입력해주세요.
            </div>

            <TextField type="password" field='passwordConfirmed' display='비밀번호 확인' className="mt-3" />
            </>
                                              }
            <HeaderBar display='개인정보' className="mt-4" />
            <TextField field='name' display="이름" />
            <TextField field='phoneNumber' display="연락처" className='mt-3 mb-4' />

            <HeaderBar display='약관 동의' />
            <CheckboxField field="allAgreed" displayName='모두 동의합니다.' className="mt-2"
                onChange={(value) => {
                    if (value) {
                        formikProps.setFieldValue("useTermsAgreed", true);
                        formikProps.setFieldValue("privateTermsAgreed", true);
                        formikProps.setFieldValue("marketingAgreed", true);
                    }
                    else {
                        formikProps.setFieldValue("useTermsAgreed", false);
                        formikProps.setFieldValue("privateTermsAgreed", false);
                        formikProps.setFieldValue("marketingAgreed", false);
                    }
                }}
            />
            <div className='d-flex'>
                <CheckboxField field="useTermsAgreed" displayName='[필수] 이용약관 동의'
                    onChange={(value) => {
                        formikProps.values.useTermsAgreed = value;
                        termsAgreedChange(formikProps);
                    }} />
                <a href='#' className='ms-2' onClick={(e) => {
                    e.preventDefault();
                    setUseTermsOpen(true);
                }}>자세히보기</a>
                <DialogComponent title='이용약관' isOpen={useTermsOpen} onClose={() => setUseTermsOpen(false)} onOkButtonClick={() => setUseTermsOpen(false)} >
                    <UseTermsContent />
                </DialogComponent>
            </div>
            <div className='d-flex'>
                <CheckboxField field="privateTermsAgreed" displayName='[필수] 개인정보 처리방침 동의'
                    onChange={(value) => {
                        formikProps.values.privateTermsAgreed = value;
                        termsAgreedChange(formikProps);
                    }} />
                <a href='#' className='ms-2' onClick={(e) => {
                    e.preventDefault();
                    setPrivateTermsOpen(true);
                }}>자세히보기</a>
                <DialogComponent title='개인정보 처리방침' isOpen={privateTermsOpen} onClose={() => setPrivateTermsOpen(false)} onOkButtonClick={() => setPrivateTermsOpen(false)} >
                    <PrivateTermsContent/>
                </DialogComponent>
            </div>
            <CheckboxField field="marketingAgreed" displayName='[선택] 마케팅 활용 및 광고성 정보 수신동의'
                onChange={(value) => {
                    formikProps.values.marketingAgreed = value;
                    termsAgreedChange(formikProps);
                }}
            />
            <DisplayErrors className='mt-2' errors={errors} />
            <div className='mt-3'>
                <ComponentToLoadSmallCenter isLoading={isSubmitting}>
                    <OkButton type='submit' className='w-100' disabled={isSubmitting}>회원가입</OkButton>
                </ComponentToLoadSmallCenter>
            </div>
        </Form>
    }
</Formik>
}
import PhoneVerification from "../accounts/PhoneVerification";

class WebRTCUtil {
    getNewPeerConnection(onConnected? : () => void, onDisconnected? : () => void) {
        const pc = new RTCPeerConnection({'iceServers': [
            {
              'urls': ['stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302']
            }
          ]});
          pc.onconnectionstatechange = e => {
             console.log("WebRTC connection state : ", pc.connectionState);
             if(pc.connectionState === "connected" && onConnected)
                 onConnected();
            else if(pc.connectionState === "disconnected" && onDisconnected)
                 onDisconnected();
          }
          return pc;
    }
}

export const webRTCUtil = new WebRTCUtil();
import { FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import DisplayErrors from "../components/DisplayErrors";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import { storeCreateDTO } from "./Store.Models";
import AppContext from "../AppContext";
import StoreForm from "./StoreForm";
import { useNavigate } from "react-router-dom";

function StoreAddPage() {
    const [errors, setErrors] = useState<string[]>([]);
    const [store, setStore] = useState<storeCreateDTO>(
        getDefaultStore()
    );
    const {updateStores} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        getDefaultOwnerCompany();
    }, []);

    function getDefaultStore() : storeCreateDTO{
        return {
            name : "",
            companyId : "",
            phoneNumber : "",
            addressMain : "",
            addressDetail : "",
            addressZipcode : "",
            features : [true, true, true, true, true, false, true]
        }
    }

    async function getDefaultOwnerCompany() {
        try {
            const companyId = await HttpRequest.get<string>(ServerAPI.accountGetAffiliatedCompanyPath);
            setStore({...store, companyId : companyId });
        }
        catch(error) {
            console.log(error)
            var message = "소속 업체를 가져오는데 실패했습니다.\n";
            if(error?.response.data){
                message += error.response.data;
            }
        }
    }

    async function onCreateStore(store : storeCreateDTO, actions : FormikHelpers<storeCreateDTO>) {
        try {
            setErrors([]);
            await HttpRequest.post(ServerAPI.storeCreate, store);
            Dialog.showConfirmation("매장 등록이 완료되었습니다.");
            updateStores();
            navigate(-1);
        }
        catch(error) {
            if(error?.response.data){
                setErrors(error.response.data);
            }
            else{
                setErrors(["서버연결에 실패했습니다."])
            }
        }
        actions.setSubmitting(false);

    }


    return  <div className='row'>
    <div className='col-lg-5'>
    <h3 className="mb-3">새 매장 등록</h3>
    <StoreForm store={store} buttonDisplay="등록하기" onSubmit={(store, actions) => Dialog.showDialog("입력하신 정보로 매장 등록을 진행하시겠습니까?", async(result) => result&&await onCreateStore(store, actions)) } />
    <DisplayErrors errors={errors} />
    </div>
    </div>
}

export default StoreAddPage;
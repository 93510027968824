import axios from "axios";
import jwtDecode from "jwt-decode";
import JWTHandler from "./JWTHandler";

class HttpInterceptors {
    static configureInterceptors() {
        axios.defaults.timeout = 5000;
        axios.interceptors.request.use(async (config) => {
            const token = await JWTHandler.getToken();
            if (token && config.headers) {
                config.headers.Authorization = `bearer ${token}`;
            }

            return config;
        }, error => Promise.reject(error));
        axios.interceptors.response.use(response => {
            if (response.status === 200 && response.headers['access-key']) {
                JWTHandler.saveToken(response.headers['access-key']);
            }
            return response;
        }, error => {
            if(error.response?.status === 401 || error.response?.status === 403){
                error.response.data = ["권한이 없습니다."];
            }
            else if(error.response?.status !== 400) {
                if(error.response?.data)
                    error.response.data = ["서버 에러가 발생했습니다."];
            }
            return Promise.reject(error);
        });
    }

}

export default HttpInterceptors;
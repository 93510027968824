export default function DoorSensorStateComponent(props: DoorSensorStateProps) {
    function getStateStyle() {
        if(props.state === 1) 
            return "text-bg-success";
        else if(props.state === 2) 
            return "text-bg-danger";
        else 
            return "text-bg-secondary";
    }

    function getStateText() {
        if(props.state === 0) 
            return "상태없음";
        else if(props.state === 1) 
            return "닫힘";
        else if(props.state === 2)
            return "열림";
        else return "에러";
    }

    return <div className="border border-primary rounded p-1 mx-1 my-1">
        <div>{props.name}</div>
        <div className={"badge " + getStateStyle()}>{getStateText()}</div>
    </div>
}

interface DoorSensorStateProps {
    name: string,
    state: number
}
export default function Loading(props : LoadingProps){
    let style = {};
    if(props.width && props.height){
        style = {width : props.width, height : props.height};
    }
    else if(props.width)
        style = {width : props.width};
    else if(props.height)
        style = {width : props.height};

    return (
        <div  className={"spinner-border" + (props.className ?? "")} role="status" style={style}>
          <span className="visually-hidden">Loading...</span>
          {// <img alt="loading" src='/img/loading.gif' style={style} className={props.className ?? ""}/> 
    }
       </div>
       
    );
}

interface LoadingProps {
    width? : string,
    height? : string,
    className? : string,
}
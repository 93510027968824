import { ErrorMessage, useFormikContext } from "formik";
import { accountCreateDTO } from "../accounts/Accounts.Models";
import EmailValidation from "../utils/EmailValidation";

function EmailField(props: emailFieldProps) {
    const { values, handleBlur, setFieldValue } = useFormikContext<any>();

    const emailSites = [
        { display: "선택", value: "-1" }
    ];
    EmailValidation.emailSites.forEach(v => emailSites.push({display : v, value : v}));
    emailSites.push({ display: "직접입력", value: "0" });

    function getFullEmail(account: accountCreateDTO): string {
        if (account.emailSiteSelection === '-1') return `${account.emailId.trim()}`;
        else if (account.emailSiteSelection === '0')
            return `${account.emailId.trim()}@${account.emailSite.trim()}`;
        else
            return `${account.emailId.trim()}@${account.emailSiteSelection.trim()}`;
    }

    function updateEmailFields(fieldName: string, value: string) {
        values[fieldName] = value;
        const newEmail = getFullEmail(values);
        values[props.emailFullField] = newEmail;
        setFieldValue(fieldName, value);
        if(props.onEmailChanged)
           props.onEmailChanged(newEmail);
    }


    return <div className={props.className}>
        <label htmlFor={props.emailIdInputField} className='form-label'>아이디(이메일)</label>
        <div className='row g-1 align-items-center'>
            <div className='col'>
                <input id={props.emailIdInputField} name={props.emailIdInputField} className="form-control" onBlur={handleBlur} value={values[props.emailIdInputField]} onChange={e => {
                    updateEmailFields(props.emailIdInputField, e.currentTarget.value);
                }} />
            </div>
            <div className='col-auto'>
                @
            </div>
            {values.emailSiteSelection === "0" &&
                <div className='col'>
                    <input name={props.emailSiteInputField} className="form-control" onBlur={handleBlur} value={values[props.emailSiteInputField]}
                        onChange={e => {
                            updateEmailFields(props.emailSiteInputField, e.currentTarget.value);
                        }} />
                </div>
            }
            <div className={values.emailSiteSelection === "0" ?  'col-lg' : 'col'}>
                <select name={props.emailSiteSelectionField} className="form-select" onBlur={handleBlur} value={values[props.emailSiteSelectionField]} onChange={e => {
                    updateEmailFields(props.emailSiteSelectionField, e.currentTarget.value);
                }}>
                    {emailSites.map(site =>
                        <option value={site.value} key={site.value}>{site.display}</option>
                    )}
                </select>
            </div>
        </div>
        <ErrorMessage name={props.emailIdInputField}>
            {msg => <div className="text-danger">{msg}</div>}
        </ErrorMessage>
        <ErrorMessage name={props.emailSiteSelectionField}>
            {msg => <div className="text-danger">{msg}</div>}
        </ErrorMessage>
       
    </div>
}

interface emailFieldProps {
    emailIdInputField: string,
    emailSiteInputField: string,
    emailSiteSelectionField: string,
    emailFullField: string,
    className?: string
    onEmailChanged?(email : string) : void
}

export default EmailField;


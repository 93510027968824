import { Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import ComponentToLoad from "../components/ComponentToLoad";
import DisplayErrors from "../components/DisplayErrors";
import { accountPasswordChangeDTO, accountUpdateDTO } from "./Accounts.Models";
import * as Yup from 'yup'
import TextField from "../forms/TextField";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import OkButton from "../components/OkButton";
import HttpRequest from "../utils/HttpRequest";
import ServerAPI from "../ServerAPI";
import CheckboxField from "../forms/CheckboxField";
import Dialog from "../utils/Dialog";
import AuthenticationContext from "./AuthenticationContext";
import Authorization from "../utils/Authorization";
import CancelButton from "../components/CancelButton";
import { useNavigate } from "react-router-dom";


export default function PasswordChangePage() {
    const [errors, setErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const {claims} = useContext(AuthenticationContext);
    const [isSocial, setIsSocial] = useState(false);
    const email = Authorization.getId(claims);    
    const navigate = useNavigate();   
    const initialDTO : accountPasswordChangeDTO = {
        email : email,
        oldPassword : "",
        password : "",
        passwordConfirmed : "",
    }

    useEffect(() => {
        const isSocial = Authorization.hasClaim(claims, "socialLogin", "1") || Authorization.hasClaim(claims, "socialLogin", "1"); 
        setIsSocial(isSocial);
    }, [claims])

    function onUpdate(passwordInfo: accountPasswordChangeDTO) {
        Dialog.showDialog("비밀번호를 변경하시겠습니까?", async (result: boolean) => {
            if (result) {
                setIsLoading(true);
                try {
                    setErrors([]);
                    await HttpRequest.put(`${ServerAPI.accountPasswordChange}`, passwordInfo);
                    Dialog.showConfirmation("비밀번호가 변경되었습니다.");
                }
                catch (error) {
                    if (error?.response?.data) {
                        setErrors(error.response.data);
                    }
                    else {
                        setErrors(["서버연결에 실패했습니다."])
                    }
                }
                setIsLoading(false);
            }

        });
    }

    return <div className='row'>
    <div className='col-lg-5'>
        <h3 className="mb-3">비밀번호 변경</h3>
        {isSocial ? 
        <>
        <div className="text-danger mb-3">
            ※ 카카오 및 네이버 로그인을 이용하신 경우 비밀번호 설정 및 변경이 불가능합니다.
            </div>
            <CancelButton onClick={() => navigate(-1)} >돌아가기</CancelButton>
            </>
        :
            <Formik initialValues={initialDTO} onSubmit={onUpdate}
                validationSchema={
                    Yup.object({
                        email: Yup.string().required('이메일을 입력해주세요').max(64, "이메일 전체길이가 64글자를 초과할 수 없습니다."),
                        oldPassword: Yup.string().required('비밀번호를 입력해주세요.').max(32, "비밀번호는 32글자 이하로 입력해주세요."),
                        password: Yup.string().required('비밀번호를 입력해주세요.').min(8, "비밀번호는 8글자 이상 입력해주세요.")
                            .max(32, "비밀번호는 32글자 이하로 입력해주세요.")
                            .test("noSpace", "비밀번호에 공백이 포함되어 있습니다.",
                                value => (value !== undefined && !/[\s]/.test(value)))
                            .test("noAlphabet", "비밀번호에 영어가 포함되어있지 않습니다.",
                                value => (value !== undefined && /[a-zA-Z]/.test(value)))
                            .test("noNumber", "비밀번호에 숫자가 포함되어있지 않습니다.",
                                value => (value !== undefined && /[\d]/.test(value)))
                            .test("noSpecialCharacter", "비밀번호에 특수문자가 포함되어있지 않습니다.",
                                value => (value !== undefined && /[!@#$%^&*)(+=._-]/.test(value))),
                        passwordConfirmed: Yup.string().required('비밀번호 확인을 해주세요.')
                            .test("checkPassword", "비밀번호가 일치하지 않습니다.",
                                function (value) {
                                    if (!value) return false;
                                    const { password } = this.parent;
                                    return value.trim() === password.trim();
                                }),
                    })
                }>
        {(formikProps) => 
            <Form>
                <TextField field="email" display="계정 (이메일)" className="mb-3" disabled={true}/>
            <TextField type="password" field='oldPassword' display='현재 비밀번호' className='mb-3' />
            <TextField type="password" field='password' display='새 비밀번호'  />
            <div className="text-primary mb-3">
                                ※ 비밀번호는 8자리이상 영문+숫자+특수문자로 입력해주세요.
                            </div>
            <TextField type="password" field='passwordConfirmed' display='새 비밀번호 확인' className="mb-1" />
        <DisplayErrors errors={errors} />


                <ComponentToLoadSmallCenter isLoading={isLoading} className="mt-4">
                <OkButton type="submit" className="w-100" disabled={isLoading}>변경하기</OkButton>
                </ComponentToLoadSmallCenter>
            </Form>
        }
    </Formik>
}
   
        </div>
        </div>
}
import Events from "./Events";

class AppCommunication {
    isApp() : boolean {
        if(window.ReactNativeWebView) return true;
        return false;
    }
    send(type :string, data? : any) : void {
        if(data === undefined)
            window.ReactNativeWebView?.postMessage(JSON.stringify({type : type}));
        else
            window.ReactNativeWebView?.postMessage(JSON.stringify({type : type, data : data}));
    }

    addListener(handler : (type : string, data : any) => void) : (e:any) => void{
        const messageHandler = (e : any) => {
            onMessageHandler(e, handler);
        }
        (new Events()).addEventListener('message', messageHandler);
        return messageHandler;
    }
    
    removeListener(handler : (e : any) => void){
        (new Events()).removeEventListener('message', handler);
    }
}

function onMessageHandler(e: any, handler : (type : string, data : any) => void) {
    if(!e.data) return;
    const event = JSON.parse(e.data);
    if(!event || !event.type) return;
    handler(event.type, event.data);
}

export const app = new AppCommunication();
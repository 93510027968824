import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from 'yup'
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton";
import SelectionField from "../forms/SelectionField";
import TextField from "../forms/TextField";
import { CallDevice } from "./VideoCall.Models";


export default function CallDeviceForm(props : CallDeviceFormProps) {
    const [isUpdating, setIsUpdating] = useState(false);
    const prioirtyInfo = "※ 우선순위가 높은 (숫자가 낮은) 기기부터 먼저 연결되고 같을 경우 임의순서를 따릅니다.";
    const intervalInfo = "※ 대기시간이 지나면 다음 우선순위 기기로 연결이 넘어가거나 다음 기기가 없는 경우 통화가 종료됩니다.";

    async function onSubmit(callDevice: CallDevice){
        setIsUpdating(true);
        await props.onSubmit(callDevice);
        setIsUpdating(false);
    }

    return <>
        <Formik initialValues={props.callDevice} onSubmit={onSubmit}
            validationSchema={
                Yup.object({
                    deviceName: Yup.string().required('이름을 입력해주세요.').max(64, "이름 길이가 64글자를 초과합니다."),
                    priority: Yup.number().required("우선순위를 입력해주세요.").typeError("숫자를 입력해주세요").min(1, "1~1000사이 숫자를 입력해주세요.").max(1000, "1~1000사이 숫자를 입력해주세요."),
                    interval: Yup.number().test("checkInterval", "대기시간 값이 올바르지 않습니다.",
                            function (value) {
                                if (!value) return false;
                                return value === 30 || value === 60 || value === 90 || value === 120;
                            }),
                })
            }>
            {(formikProps) =>
                <Form>
                    <TextField field="deviceName" display="기기이름" className="mb-3" />
                    <TextField field="priority" display="우선순위 (1~1000)" />
                    <div className="text-primary mb-3">
                        {prioirtyInfo}
                    </div>
                    <SelectionField field="interval" displayName="대기시간">
                        <>
                        <option value={30}>30초</option>
                        <option value={60}>60초</option>
                        <option value={90}>90초</option>
                        <option value={120}>120초</option>
                        </>
                    </SelectionField>
                    <div className="text-primary mb-3">
                        {intervalInfo}
                    </div>
                    <ComponentToLoadSmallCenter isLoading={isUpdating}>
                        <OkButton type="submit" className="w-100" disabled={isUpdating}>{props.isAdd ? "등록하기" : "수정하기"}</OkButton>
                    </ComponentToLoadSmallCenter>
                </Form>
            }
        </Formik>
    </>
}

interface CallDeviceFormProps {
    callDevice : CallDevice,
    isAdd : boolean,
    onSubmit(callDevice : CallDevice) : Promise<void>
}
import React from "react";
import { Device } from "./accounts/Accounts.Models";
import { StoreInfo } from "./stores/Store.Models";
import { SocketManager } from "./utils/SocketManager";

const AppContext = React.createContext<{
    stores: StoreInfo[],
    updateStores(onSuccess? : () =>void, onFail? : (errorMsg :string ) => void) : void,
    selectedStoreId : string,
    updateSelectedStoreId(id : string) : void, 
    socketManager : SocketManager | undefined,
    isSocketConnected : boolean,
    device? : Device
    defaultStoreId : string,
    updateDefaultStoreId(id:string):void
}>({stores: [], updateStores: () => {}, selectedStoreId : "", updateSelectedStoreId : () => {}, socketManager : undefined, isSocketConnected : false, defaultStoreId : "", updateDefaultStoreId : ()=>{} });

export default AppContext;

import {zip, unzip} from 'gzip-js'
import internal from 'stream';

class StringUtil {
    // base64 decoding
    atob(valueToDecode : string) : string {
        return Buffer.from(valueToDecode, 'base64').toString();
    }

    // base64 encoding
    btoa(valueToEncode : string) : string {
      return Buffer.from(valueToEncode).toString("base64");
    }

    padZero(n : string, width : number) : string {
        return n.length >= width ? n : new Array(width - n.length +1).join('0') + n;
    }

    toStringFullDateTimeNoSeconds(totalMilliSeconds : number) : string{
        if(totalMilliSeconds === 0) return "정보없음";
        const date = new Date(totalMilliSeconds);
        const year = date.getFullYear();
        const month = this.padZero((date.getMonth()+1).toString(), 2); 
        const day = this.padZero(date.getDate().toString(), 2); 
        const hour = this.padZero(date.getHours().toString(), 2); 
        const minutes = this.padZero(date.getMinutes().toString(), 2); 
        return `${year}/${month}/${day} ${hour}:${minutes}`;
    }

    toStringFullDateTime(date : Date) : string{
        const year = date.getFullYear();
        const month = this.padZero((date.getMonth()+1).toString(), 2); 
        const day = this.padZero(date.getDate().toString(), 2); 
        const hour = this.padZero(date.getHours().toString(), 2); 
        const minutes = this.padZero(date.getMinutes().toString(), 2); 
        const seconds = this.padZero(date.getSeconds().toString(), 2);
        return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
    }

    compress(data : string) : Uint8Array {
        try{
            const zipped = zip((new TextEncoder()).encode(data));
            const byteArray = new Uint8Array(zipped.length);
            for(let i = 0; i<zipped.length; i++)
                byteArray[i] = zipped[i];
            return byteArray;
        }
        catch(e){
            console.log(e);
            return new Uint8Array();
        }
    }

    decompressToBytes(bytes : Uint8Array) : Uint8Array {
        try{
            const unzippedBytes = unzip(bytes);
            const byteArray = new Uint8Array(unzippedBytes.length);
            for(let i = 0; i<unzippedBytes.length; i++)
                byteArray[i] = unzippedBytes[i];
            return byteArray;
        }
        catch(e){
            console.log(e);
            return new Uint8Array();
        }
    }

    decompress(bytes : Uint8Array) : string {
        try{
            const unzippedBytes = unzip(bytes);
            const byteArray = new Uint8Array(unzippedBytes.length);
            for(let i = 0; i<unzippedBytes.length; i++)
                byteArray[i] = unzippedBytes[i];
            const resultString = (new TextDecoder()).decode(byteArray);
            return resultString;
        }
        catch(e){
            console.log(e);
            return "";
        }
    }

    toMacAddress(macAddr : string) : string {
        let newMacAddr = macAddr.trim().toUpperCase();
        if(newMacAddr.length != 12) return "";
        let returnAddr = "";
        for(let i=0; i<12; i++) {
            returnAddr += newMacAddr[i]
            if(i!== 11 && i%2 === 1)
               returnAddr += ":";
        }
        return returnAddr;
    }

    toHourMinutesFromMinutes(minutes : number) : string{
        if(minutes <0 || minutes > 1440) return "";
        const resultHour = this.padZero(Math.trunc(minutes/ 60).toString(), 2);
        const resultMinutes = this.padZero(Math.trunc(minutes%60).toString(), 2);
        return `${resultHour}:${resultMinutes}`;
    }

    toScheduleTimeString(startTime : number, endTime:number) : string {
        if(startTime<0 || startTime > 1440) return "";
        if(endTime<0 || endTime > 1440) return "";
        const startTimeString = this.toHourMinutesFromMinutes(startTime);
        const endTimeString = this.toHourMinutesFromMinutes(endTime);
        let duration = 0;
        if(endTime <= startTime)
            duration = endTime+1440-startTime;
        else
            duration = endTime -startTime;
        const durationHour = Math.trunc(duration /60);
        const durationMinutes = duration %60;
        return `${startTimeString}~${endTimeString} (${durationHour}시간${durationMinutes > 0 ? " " + durationMinutes +"분" : ""})`;
    }

    toDaysString(days : number) : string {
        let result = "";
        if((days & 0x01) !==0) result+="일";
        if((days & 0x02) !==0) result+=" 월";
        if((days & 0x04) !==0) result+=" 화";
        if((days & 0x08) !==0) result+=" 수";
        if((days & 0x10) !==0) result+=" 목";
        if((days & 0x20) !==0) result+=" 금";
        if((days & 0x40) !==0) result+=" 토";
        return result.trim();
    }

    includeKorean(value : string) : boolean {
        return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value);
    }

    isNullOrWhiteSpace(value : string|undefined|null) : boolean {
        return !value || value.trim().length === 0;
    }

    isValidCCTVPasswordFormat(password : string) : boolean {
        if(/[^a-zA-Z0-9!\"#$%&'()*+,-\./:;<=>?@\[\]^_` ]+/.test(password)) return false;
        let count = 0;
        if(/[a-z]/.test(password))
            count++;
        if(/[A-Z]/.test(password))
            count++;
        if(count>=2) return true;
        if(/[0-9]/.test(password)) 
            count++;
        if(count>=2) return true;
        if(/[!\"#$%&'()*+,-\./:;<=>?@\[\]^_` ]/.test(password)) 
            count++;
        if(count>=2) return true;
        return false;
    }
}
const stringUtil = new StringUtil();
export default stringUtil;
function DisplayErrors(props: displayErrorsProps){
    return (
        <>
        {props.errors && props.errors.length > 0 && <ul className={"text-danger " + props.className??""}>
                {props.errors.map((error, index) => <li key={index}>{error}</li>)}
            </ul>}
        </>
    )
}

export default DisplayErrors;

interface displayErrorsProps {
    errors?: string[],
    className? : string
}
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CancelButton from "../components/CancelButton";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import SubButton from "../components/SubButton";
import RouteConfig from "../route-config";
import Authorization from "../utils/Authorization";
import Dialog from "../utils/Dialog";
import { Company, Employee } from "./Companies.Models";

export default function EmployeeInfoComponent(props : EmployeeInfoComponentProps) {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    function onDelete() {
        Dialog.showDialog("해당 직원을 삭제하시겠습니까?", async (result :boolean) => {
            if(result){
                setIsLoading(true);
                await props.onDelete(props.employee.email);
                setIsLoading(false);
            }
        });

    }
    return <div className={props.className|| ""}> 
    <div className={"d-flex flex-column border border-primary rounded p-2 mx-1 my-1 text-break h-100 " + props.className?? ""}>
    <div>{props.employee.name}</div>
    <div>{props.employee.phoneNumber}</div>
    <div > {props.employee.email} </div>
    <div className="my-1">
        { props.employee.isSelf ?
        <SubButton className="w-100" onClick={() => navigate(`${RouteConfig.permissionCheckPath}/${props.company._id}`)}>권한보기</SubButton>
        : Authorization.hasPermission(props.company.permission, "employee", "permission")&&
        <SubButton className="w-100" onClick={() => navigate(`${RouteConfig.permissionCheckPath}/${props.company._id}/${props.employee.email}`)
    }>권한변경</SubButton>
}
</div>
    
    { Authorization.hasPermission(props.company.permission, "employee", "delete")&&
        <ComponentToLoadSmallCenter isLoading={isLoading}>
        <CancelButton className="btn-sm w-100" onClick={onDelete}>직원삭제</CancelButton>
        </ComponentToLoadSmallCenter>
}
</div>
</div>

}

interface EmployeeInfoComponentProps {
   className? : string,
   employee : Employee,
   company : Company,
   onDelete(email : string) : Promise<void>,
}
import { ReactElement, useEffect, useState } from "react";

function ConnectionComponent(props : ConnectionComponentProps){
    return <div className={`d-flex align-items-center ${props.className ? props.className : ""}`}>
    {!props.isConnected ?
    <> 
    <img src="/img/error.png" alt="error" style={{width : "30px", height : "30px"}} />
    {props.noConnectionElement}

    </>
:   <> 
<img src="/img/success.png" alt="connected" style={{width : "20px", height : "20px"}}/>
<div className="ms-1"> {props.connectionElement} </div>
</>
}
    </div>

}

interface ConnectionComponentProps {
    isConnected : boolean,
    className? : string,
    noConnectionElement : ReactElement
    connectionElement : ReactElement
}

export default ConnectionComponent;
import axios, { AxiosResponse } from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DisplayErrors from "../components/DisplayErrors";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import AppContext from "../AppContext";
import * as Yup from 'yup'
import StringValidation from "../utils/StringValidation";
import TextField from "../forms/TextField";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import { PermissionDTO, PermissionEntry, PermissionForm } from "./Companies.Models";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import ComponentToLoad from "../components/ComponentToLoad";
import CheckboxField from "../forms/CheckboxField";


export default function PermissionCheckPage() {
    const { companyId, email } = useParams();
    const [errors, setErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isInfoLoading, setIsInfoLoading] = useState(true);
    const [permission, setPermission] = useState<PermissionForm>();
    const navigate = useNavigate();

    useEffect(() => {
        getPermission();
    }, []);

    function toPermissionForm(permissionDTO : PermissionDTO) : PermissionForm{
        return {...permissionDTO,
        storeAdd : (permissionDTO.permission.find(p => p.name === "store")?.values.find(v=> v==="add") !== undefined), 
        storeUpdate : (permissionDTO.permission.find(p => p.name === "store")?.values.find(v=> v==="update") !== undefined), 
        storeDelete : (permissionDTO.permission.find(p => p.name === "store")?.values.find(v=> v==="delete") !== undefined), 
        serverAdd : (permissionDTO.permission.find(p => p.name === "server")?.values.find(v=> v==="add") !== undefined), 
        serverDelete : (permissionDTO.permission.find(p => p.name === "server")?.values.find(v=> v==="delete") !== undefined), 
        serverUpdate : (permissionDTO.permission.find(p => p.name === "server")?.values.find(v=> v==="update") !== undefined), 
        employeeAdd : (permissionDTO.permission.find(p => p.name === "employee")?.values.find(v=> v==="add") !== undefined), 
        employeePermission : ((permissionDTO.permission.find(p => p.name === "employee")?.values.find(v=> v==="permission")) !== undefined), 
        employeeDelete : (permissionDTO.permission.find(p => p.name === "employee")?.values.find(v=> v==="delete") !== undefined), 
        nameAndPhoneNumber : `${permissionDTO.name} (${permissionDTO.phoneNumber})`
        };
    }

    function toPermission(permissionForm: PermissionForm): PermissionDTO {
        const finalPermission: PermissionEntry[] = []
        const employeePermission: string[] = [];
        if (permissionForm.employeeAdd)
            employeePermission.push("add");
        if (permissionForm.employeePermission)
            employeePermission.push("permission");
        if (permissionForm.employeeDelete)
            employeePermission.push("delete");
        if (employeePermission.length > 0)
            finalPermission.push({ name: "employee", values: employeePermission });
        const storePermission: string[] = [];
        if (permissionForm.storeAdd)
        storePermission.push("add");
        if (permissionForm.storeUpdate)
        storePermission.push("update");
        if (permissionForm.storeDelete)
        storePermission.push("delete");
        if (storePermission.length > 0)
            finalPermission.push({ name: "store", values: storePermission });
        const serverPermission: string[] = [];
        if (permissionForm.serverAdd)
        serverPermission.push("add");
        if (permissionForm.serverUpdate)
        serverPermission.push("update");
        if (permissionForm.serverDelete)
        serverPermission.push("delete");
        if (serverPermission.length > 0)
            finalPermission.push({ name: "server", values: serverPermission });
        permissionForm.permission = finalPermission;
        return permissionForm;
    }

    async function getPermission() {
        setErrors([]);
        setIsInfoLoading(true);
        if (companyId) {

        try {
            let path = `${ServerAPI.accountGetPermission}/${companyId}`;
            if(email !== undefined)
                path += `/${email}`;
            const permissionDTO = await HttpRequest.get<PermissionDTO>(path);
            setPermission(toPermissionForm(permissionDTO));
        }
        catch(error) {
            let message = "권한정보를 가져오는데 실패했습니다.\n";
            if(error?.response?.data){
                message += error.response.data;
            }
            setErrors([message]);
        }
       }
        setIsInfoLoading(false);
    }

    function onSubmit(permission: PermissionForm) {
        Dialog.showDialog(`해당 직원의 권한을 변경시겠습니까?`, async (result) => {
            if (result) {
                setIsLoading(true);
                try {
                    setErrors([]);
                    await HttpRequest.put(ServerAPI.accountPermissionUpdate, toPermission(permission));
                    Dialog.showConfirmation(`권한변경이 완료되었습니다.`);
                    navigate(-1);
                }
                catch (error) {
                    if (error?.response?.data) {
                        setErrors(error.response.data);
                    }
                    else {
                        setErrors(["서버연결에 실패했습니다."])
                    }
                }
                setIsLoading(false);
            }
        });
    }


    return <div className='row'>
        <div className='col-lg-5'>
            <h3 className="mb-3">{email === undefined ? "권한보기" : "권한변경" }</h3>
            <>
            <ComponentToLoad isLoading={isInfoLoading}>
                {permission ?
                <Formik initialValues={permission} onSubmit={onSubmit} enableReinitialize
                    validationSchema={
                        Yup.object({
                            email: Yup.string().required('계정을 입력해주세요.').max(64, "계정 길이가 64글자를 초과합니다."),
                        })
                    }>
                    {(formikProps) =>
                        <Form>
                            <TextField field='nameAndPhoneNumber' display='이름 (연락처)' className='mb-3' disabled={true}/>

                            <TextField field='companyName' display='소속업체명' className='mb-3' disabled={true}/>
                            <div>
                                직원관리
                            </div>
                            <div className="d-flex justify-content-around mb-3"> 
                               <CheckboxField field="employeeAdd" disabled={email === undefined} displayName="직원추가" />
                               <CheckboxField field="employeePermission" disabled={email === undefined} displayName="권한변경" />
                               <CheckboxField field="employeeDelete" disabled={email === undefined} displayName="직원삭제" />
                            </div>
                            
                            <div>
                                매장관리
                            </div>
                            <div className="d-flex justify-content-around mb-3"> 
                               <CheckboxField field="storeAdd" disabled={email === undefined} displayName="매장등록" />
                               <CheckboxField field="storeUpdate" disabled={email === undefined} displayName="매장정보수정" />
                               <CheckboxField field="storeDelete" disabled={email === undefined} displayName="매장삭제" />
                            </div>
                            <div>
                                서버관리
                            </div>
                            <div className="d-flex justify-content-around mb-4"> 
                               <CheckboxField field="serverAdd" disabled={email === undefined} displayName="서버등록" />
                               <CheckboxField field="serverUpdate" disabled={email === undefined} displayName="서버정보수정" />
                               <CheckboxField field="serverDelete" disabled={email === undefined} displayName="서버삭제" />
                            </div>
                            {email === undefined ? 
                            <OkButton className="w-100" onClick={() => navigate(-1)}>돌아가기</OkButton>
                            :
                            <>                
                <DisplayErrors errors={errors} className="mt-3"/>
                                    <ComponentToLoadSmallCenter isLoading={isLoading}>
                            <OkButton type="submit" className="w-100" disabled={isLoading}>변경하기</OkButton>
                            </ComponentToLoadSmallCenter>
                              </>
                            }
                        </Form>
                    }
                </Formik>
                :
                <DisplayErrors errors={errors} />
                }
               </ComponentToLoad>
            </>
        </div>
    </div>
}

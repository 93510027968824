class Events {
    getReceiver(): Window | Document {
        if (navigator.userAgent
            .toLowerCase()
            .match(/\(ip.*applewebkit(?!.*(version|crios))/))
            return window;
        else
            return document;
    }

    addEventListener(eventType: string, eventListener: any) {
        const receiver = this.getReceiver();
        receiver.addEventListener(eventType, eventListener);
    }

    removeEventListener(eventType: string, eventListener: any) {
        const receiver = this.getReceiver();
        receiver.removeEventListener(eventType, eventListener);
    }
}

export default Events;
import axios, { AxiosResponse } from "axios";
import { ErrorMessage, Form, Formik, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DisplayErrors from "../components/DisplayErrors";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import AppContext from "../AppContext";
import * as Yup from 'yup'
import StringValidation from "../utils/StringValidation";
import TextField from "../forms/TextField";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import stringUtil from "../utils/StringUtil";
import { Inquiry } from "./Settings.Models";


export default function InquiryAddPage() {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [newContentsCount, setNewContentsCount] = useState(0);
    const [errors, setErrors] = useState<string[]>([]);
    const [inquiry, setInquiry] = useState<Inquiry>({
        _id : "",
        contents : "",
        registerDate : 0,
        answer : "",
        email : "",
    });
    const navigate = useNavigate();

    useEffect(() => {
        getInfo(id);
    }, [id]);

    async function getInfo(id : string | undefined) {
        setIsLoading(true)
        if(id){
        try {
            const inquiry = await HttpRequest.get<Inquiry>(`${ServerAPI.inquiry}/${id}`);
            setInquiry(inquiry);
            setNewContentsCount(inquiry.contents.length);
        }
        catch(error) {
            var message = "문의정보를 가져오는데 실패했습니다.\n";
            if(error?.response?.data){
                message += error.response.data;
            }
            Dialog.showError(message);
        }
        }
        setIsLoading(false);
    }

    function onSubmit(inquiry : Inquiry) {
        if (!inquiry.contents) {
            Dialog.showError("작성된 글이 없습니다. 문의글 작성 후 다시 시도해주세요.");
            return;
        }
        Dialog.showDialog(`해당 문의글을 ${id ? "수정" : "등록"}하시겠습니까?`, async (result) => {
            if(result) {
                setIsUpdating(true);
            try {
                setErrors([]);
                if(id){
                    await HttpRequest.put(`${ServerAPI.inquiry}/${id}`, {contents : inquiry.contents});
                }
                else {
                    await HttpRequest.post(ServerAPI.inquiry, {contents : inquiry.contents});
                }
                Dialog.showConfirmation(`문의글 ${id ? "수정" : "등록"}이 완료되었습니다.`);
                navigate(-1);
            }
            catch(error) {
                if(error?.response?.data){
                    setErrors(error.response.data);
                }
                else{
                    setErrors(["서버연결에 실패했습니다."])
                }
            }
            setIsUpdating(false);
        }
        });
    }


    return  <div className='row'>
    <div className='col-lg-5'>
    <h3 className="mb-3">{id ? "문의 수정" : "새 문의 등록"} </h3>
    <ComponentToLoad isLoading={isLoading}>
    <>
    <Formik initialValues={inquiry} onSubmit={onSubmit} enableReinitialize
    validationSchema={
        Yup.object({
        contents: Yup.string().required('문의내역을 입력해주세요.').max(500, "내용이 500글자를 초과합니다."),
       })
       
    }>
        {(formikProps) => 
            <Form>
                 <textarea name="contents" maxLength={500} className="form-control" placeholder="500자 이내로 문의사항을 작성해주세요." onInput={e => setNewContentsCount(e.currentTarget.value.length)}
                            value={formikProps.values.contents}
                            onBlur={formikProps.handleBlur}
                            onChange={e => formikProps.setFieldValue("contents", e.currentTarget.value)}
                            rows={5}></textarea>
                            <div className="d-flex justify-content-end">
                            <div>
                                {newContentsCount}/500
                            </div>
                        </div>
                <ErrorMessage name={"contents"}>{msg =>
            <div className="text-danger">{msg}</div>
        }</ErrorMessage>
                <ComponentToLoadSmallCenter className="mt-3" isLoading={isUpdating}>
                <OkButton type="submit" className="w-100" disabled={isUpdating}>{id ? "수정하기" : "등록하기"}</OkButton>
                </ComponentToLoadSmallCenter>
            </Form>
        }
    </Formik>
    <DisplayErrors errors={errors} />
    </>
    </ComponentToLoad>
</div> 
    </div>
}

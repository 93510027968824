class LogUtil {
    StoreUpdate = 9;
    StoreFeatureChange = 11;
    ClientAdd = 12;
    ClientUpdate = 13;
    ClientDelete = 14;
    ServerAdd = 15;
    ServerUpdate = 16;
    ServerDelete = 17;

    getClientFieldDisplayName(field : string){
        if(field === "name") return "이름 변경";
        else if(field === "doorSensorNames") return "도어센서 이름";
        else if(field === "ip") return "CCTV IP 주소";
        else if(field === "port") return "CCTV 포트";
        else if(field === "cctvId") return "CCTV 아이디";
        else if(field === "cctvPassword") return "CCTV 비밀번호";
        else if(field === "retentionPeriod") return "CCTV 저장기간";
        else return "알수없음";
    }

    getClientFieldUpdateMessage(fieldChange : FieldChange){
        if(fieldChange.name === "cctvPassword")
           return "민감한 정보로 변경사항 로깅없음";
        else 
           return this.getFieldUpdateMessage(fieldChange);
    }

    getFieldUpdateMessage(fieldChange : FieldChange){
        return `[${fieldChange.oldValue}] → [${fieldChange.newValue}]`;
    }

    getNotificationTypeName(type: number): string {
        switch (type) {
            case 1: return "매장등록";
            case 2: return "매장삭제";
            case 3: return "도어센서열림";
            case 4: return "도어센서끊김";
            case 5: return "통화연결요청";
            case 6: return "통화연결취소";
            case 7: return "새문의글";
            case 8: return "문의답변완료"
            case 9: return "리커버리관련"
            default: return "알수없음"
        }
    }

    getServerFieldDisplayName(field : string){
        if(field === "name") return "이름변경";
        else if(field === "bindIp") return "바인딩 IP주소";
        else return "알수없음";
    }

    getStoreFieldDisplayName(field : string){
        if(field === "name") return "이름";
        else if(field === "phoneNumber") return "연락처";
        else if(field === "addressMain") return "주소";
        else if(field === "addressDetail") return "상세주소";
        else if(field === "addressZipcode") return "우편번호";
        else return "알수없음";
    }

    getStoreLogTypes() {
        return [this.StoreUpdate, this.StoreFeatureChange, this.ServerAdd, this.ServerUpdate, this.ServerDelete, this.ClientAdd, this.ClientUpdate, this.ClientDelete];
    }

   

    getTypeName(type: number) {
        switch (type) {
            case this.StoreUpdate:
                return "매장정보갱신";
            case this.StoreFeatureChange:
                return "매장지원기능갱신"
            case this.ClientAdd:
                return "클라이언트추가";
            case this.ClientUpdate:
                return "클라이언트갱신";
            case this.ClientDelete:
                return "클라이언트삭제";
            case this.ServerAdd:
                return "서버추가";
            case this.ServerUpdate:
                return "서버갱신";
            case this.ServerDelete:
                return "서버삭제";
            default:
                return "알수없음";
        }
    }
}

interface FieldChange {
    name : string,
    oldValue : string,
    newValue : string,
}

export const logUtil = new LogUtil();
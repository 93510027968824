
//@ts-ignore
import AppleSignin from 'react-apple-signin-auth';
import { AppleLoginInfo } from '../accounts/Accounts.Models';
import RouteConfig from "../route-config";

export default function AppleButton(props : {autoLogin : boolean, className? : string, onClick? : (e : React.MouseEvent<Element, MouseEvent>)=> void}){
  const baseUrl = RouteConfig.getFullBaseURL();
  const state = `${baseUrl}${RouteConfig.accountAppleLoginPath};${props.autoLogin}`;   
  
  function getRedirectURI() : string {
    let host = "";
    if(baseUrl.includes("manager."))
        host = "https://nsmaker.co.kr";
    else if(baseUrl.includes("manager2."))
        host = "https://2.nsmaker.co.kr";
    else if(baseUrl.includes("manager3."))
        host = "https://3.nsmaker.co.kr";
    else if(baseUrl.includes("manager4."))
        host = "https://4.nsmaker.co.kr";
    else 
        host = process.env.REACT_APP_APPLE_REDIRECT_HOST || "https://nsmaker.co.kr";
    return `${host}/api/accounts/loginAppleRedirected`;
  }

  function onClick(e : React.MouseEvent<Element, MouseEvent>){
    if(props.onClick)
      props.onClick(e);
  }

    return <AppleSignin 
    authOptions={{
      clientId: 'com.nsmaker.manager.managerapp',
      scope: 'email name',
      redirectURI : getRedirectURI(),
      state: state,
      nonce: 'nonce',
      usePopup: false,
    }}
    uiType="dark"
    iconProps={{style : {marginLeft:"9px"} }}
    className={`apple-auth-btn w-100 ${props.className || ''}`}
    noDefaultStyle={false}
    buttonExtraChildren= {<div onClick={onClick}>Apple로 로그인</div>}
    onSuccess={(response : any) => {
      }
    } // default = undefined
    onError={(error : any) => {
    }} // default = undefined
  />
}
import { useParams } from "react-router-dom";
import ScheduleListPage from "../components/ScheduleListPage";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";

export default function IrisSchedulePage() {
    const {clientId} = useParams();
    return <ScheduleListPage title="자동인증 스케줄 목록" scheduleRegisterPageURL={RouteConfig.irisScheduleAddPath} 
    getScheduleListURL={ServerAPI.storeIrisClientGetSchedules} addScheduleURL={ServerAPI.storeIrisClientAddSchedule} clientId={clientId!} 
    deleteScheduleURL={ServerAPI.storeIrisClientDeleteSchedule}
    updateScheduleOnURL={ServerAPI.storeIrisClientUpdateIndividualScheduleOn} />
}

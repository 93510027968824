import axios, { AxiosResponse } from "axios";
import { FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import DisplayErrors from "../components/DisplayErrors";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import { storeCreateDTO } from "./Store.Models";
import AppContext from "../AppContext";
import StoreForm from "./StoreForm";
import ComponentToLoad from "../components/ComponentToLoad";

function StoreUpdatePage() {
    const [errors, setErrors] = useState<string[]>([]);
    const [store, setStore] = useState<storeCreateDTO>();
    const [isLoading, setIsLoading] = useState(true);
    const {updateStores, selectedStoreId} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        getStoreInfo();
    }, [selectedStoreId]);

    async function getStoreInfo() {
        setIsLoading(true);
        try {
            if(!selectedStoreId) {
                setErrors(["선택된 매장이 없습니다."]);
                setStore(undefined);
            }
            else {
            setErrors([]);
            const storeDTO = await HttpRequest.get<storeCreateDTO>(`${ServerAPI.storeGetStoreInfoForUpdate}/${selectedStoreId}`);
            setStore(storeDTO);
            }
        }
        catch(error) {
            var message = "에러가 발생했습니다.\n";
            if(error?.response?.data){
                message += error.response.data;
            }
            setErrors([message]);
            setStore(undefined);
        }
        setIsLoading(false);
    }

    async function onUpdateStore(store : storeCreateDTO, actions : FormikHelpers<storeCreateDTO>) {
        try {
            if(!selectedStoreId) {
                setErrors(["선택된 매장이 없습니다."]);
                setStore(undefined);
            }
            else {
            setErrors([]);
            await HttpRequest.put(`${ServerAPI.storeUpdate}/${selectedStoreId}`, store);
            Dialog.showConfirmation("매장정보 수정이 완료되었습니다.");
            updateStores();
            navigate(RouteConfig.homePath);
            }
        }
        catch(error) {
            if(error?.response?.data){
                setErrors(error.response.data);
            }
            else{
                setErrors(["서버연결에 실패했습니다."])
            }
        }
        actions.setSubmitting(false);

    }
    return <div className='row'>
    <div className='col-lg-5'>
    <h3 className="mb-3">매장 정보 수정</h3>
    <ComponentToLoad isLoading={isLoading}>
        {store && 
    <StoreForm store={store} buttonDisplay="수정하기" onSubmit={(store, actions) => Dialog.showDialog("입력하신 정보로 매장정보를 수정하시겠습니까?", async(result) => result&&await onUpdateStore(store, actions)) } />
        }
    <DisplayErrors errors={errors} />
    </ComponentToLoad>
    </div>
    </div>

}

export default StoreUpdatePage;
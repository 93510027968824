import { FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton";
import { NotificationSetting, NotificationSettings } from "./Settings.Models";
import ServerAPI from "../ServerAPI";
import HttpRequest from "../utils/HttpRequest";
import Dialog from "../utils/Dialog";
import { logUtil } from "../utils/LogUtil";

export default function NotificationPage() {
    const [errors, setErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [notificatoinSettings, setNotificationSettings] = useState<NotificationSettings>();

    useEffect(() => {
        getNotificationSettings();
    }, []);

    async function getNotificationSettings() {
        setIsLoading(true);
        try {
            setErrors([]);
            const settings = await HttpRequest.get<NotificationSetting[]>(ServerAPI.accountGetNotificationSettings);
            setNotificationSettings({
                settings: settings,
            });
        }
        catch (error) {
            var message = "에러가 발생했습니다.\n";
            if (error?.response?.data) {
                message += error.response.data;
            }
            setNotificationSettings(undefined);
            setErrors([message]);
        }
        setIsLoading(false);
    }

    function onUpdateSettings(value: NotificationSettings) {
        Dialog.showDialog("변경된 내용을 저장하시겠습니까?", async (result) => {
            if (result) {
                setIsUpdating(true);
                try {
                    setErrors([]);
                    await HttpRequest.put(ServerAPI.accountUpdateNotificationSettings, value.settings);
                    Dialog.showConfirmation("저장이 완료되었습니다.");
                }
                catch (error) {
                    if (error?.response?.data) {
                        setErrors(error.response.data);
                    }
                    else {
                        setErrors(["서버연결에 실패했습니다."])
                    }
                }
                setIsUpdating(false);
            }
        });
    }

    return <div className='row'>
        <div className='col-lg-5'>
            <h3 className="mb-3">알림설정</h3>
            <ComponentToLoad isLoading={isLoading}>
                {notificatoinSettings ?
                    <Formik initialValues={notificatoinSettings} enableReinitialize onSubmit={onUpdateSettings}
                    >
                        {(formikProps) =>
                            <Form>
                                <div className="border rounded-2 p-3 mb-3">
                                    <FieldArray name="settings"
                                        render={arrayHelpers =>
                                            formikProps.values.settings.map((n, index) =>
                                                <div key={n.type} className="my-1 d-flex justify-content-between">
                                                    <div>{logUtil.getNotificationTypeName(n.type)}</div>
                                                    <div><Switch checked={!n.disabled} onChange={(e, checked) => formikProps.setFieldValue(`settings.${index}.disabled`, !checked)} /> </div>

                                                </div>
                                            )
                                        } />
                                    <DisplayErrors errors={errors}  />
                                </div>
                                <ComponentToLoadSmallCenter isLoading={isUpdating}>
                                    <OkButton type="submit" className="w-100" disabled={isUpdating}>저장하기</OkButton>
                                </ComponentToLoadSmallCenter>
                            </Form>
                        }
                    </Formik>
                    :
                    <DisplayErrors errors={errors} />
                }
            </ComponentToLoad>
        </div>
    </div>
}
import { height } from "@mui/system";
import { rejects } from "assert";
import { resolve } from "node:path/win32";
import { useContext, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom";
import { isGeneratorFunction } from "util/types";
import { number } from "yup";
import PhoneVerification from "../accounts/PhoneVerification";
import AppContext from "../AppContext";
import ComponentToLoad from "../components/ComponentToLoad";
import OkButton from "../components/OkButton";
import { StoreInfo } from "../stores/Store.Models";
import Dialog from "../utils/Dialog";
import { PacketCommands } from "../utils/SocketManager";
import { storeUtil } from "../utils/StoreUtil";
import stringUtil from "../utils/StringUtil";
import { webRTCUtil } from "../utils/WebRTCUtil";

export default function CCTVViewPage() {
    const {id} = useParams();
    const { selectedStoreId, socketManager, stores, isSocketConnected } = useContext(AppContext);
    const [store, setStore] = useState<StoreInfo | undefined>(stores.find(s => s._id === selectedStoreId));
    const [isLoading, setIsLoading] = useState(true);
    const isLoadingRef = useRef(false);
    const [isConnected, setIsConnected] = useState(false);
    const pc = useRef<RTCPeerConnection>();
    const jsPluginRef = useRef<any>();
    const serverIdPairRef = useRef<any>();
    const [channel, setChannel] = useState(1);
    const channelRef = useRef(1);
    const [channelCount, setChannelCount] = useState(1);
    const [hasZeroChannel, setHasZeroChannel] = useState(false);
    const channelKey = "liveChannel" + id;

    useEffect(() => {
        if(!id) return;
        try{
          const savedChannel = localStorage.getItem(channelKey);
          if(!savedChannel) return;
          channelRef.current = Number.parseInt(savedChannel);
          setChannel(channelRef.current);
        }
        catch(e) {}
    }, []);
    useEffect(() => {
        const newStore = stores.find(s => s._id === selectedStoreId);
        setStore(newStore);
        socketManager?.registerCommandCallBack(PacketCommands.Manager_CCTVInfo, onReceiveCCTVInfo);
        socketManager?.registerCommandCallBack(PacketCommands.Manager_WebRTCOffer, onReceiveAnswer);
        socketManager?.registerCommandCallBack(PacketCommands.Manager_WebRTCCandidate, onReceiveCandidate);
        initialize(newStore);
        return ()=> {
            socketManager?.deregisterCommandCallBack(PacketCommands.Manager_CCTVInfo);
            socketManager?.deregisterCommandCallBack(PacketCommands.Manager_WebRTCOffer);
            socketManager?.deregisterCommandCallBack(PacketCommands.Manager_WebRTCCandidate);
            deinitialize();
        }
    }, [isSocketConnected, selectedStoreId, stores]);

    function deinitialize(){
        if(pc.current) {
            pc.current.close();
            pc.current = undefined;
         }
         if(jsPluginRef.current){
             jsPluginRef.current.JS_StopRealPlayAll(); jsPluginRef.current.JS_DestroyWorker();
         }
         serverIdPairRef.current = undefined;
    }

    async function initialize(newStore : StoreInfo | undefined) {
        if (!isSocketConnected || !newStore || !storeUtil.hasCCTVFeature(newStore.features)|| !socketManager || !selectedStoreId || !id) return
        if(isLoadingRef.current) return;
        isLoadingRef.current = true;
        setIsLoading(isLoadingRef.current);
        // Unless connection completes in 10 sec, regarded as disconnected;
        setTimeout(() => {
            if(isLoadingRef.current){
                isLoadingRef.current = false;
                serverIdPairRef.current = undefined;
                setIsLoading(isLoadingRef.current);
                setIsConnected(false);
            }
        }, 10000);
        socketManager?.requestCCTVInfo(selectedStoreId, id);
    }
    
    async function initializeCCTVConnection(){
        if (!store || !storeUtil.hasCCTVFeature(store.features)|| !socketManager || !selectedStoreId || !id) return
        try {
            pc.current = webRTCUtil.getNewPeerConnection(onWebRTCConnected, onWebRTCDisconnected);
            const dataChannel = pc.current.createDataChannel("dataChannel");
            dataChannel.onopen = (event) => {
                console.log("data channel opened");
                if (!id) return;
                dataChannel.send(`clientId${id},${channelRef.current}`);
            };
            pc.current.onicecandidate = e => {
                if (!isLoadingRef.current || !serverIdPairRef.current || !e.candidate) return;
                socketManager?.sendCandidate(serverIdPairRef.current, e.candidate.candidate, e.candidate.sdpMid!, e.candidate.sdpMLineIndex!);
                console.log("candidate sent : ", e.candidate);
            }
            console.log("Peer Connection initialized");
            play(dataChannel, async () => {
                    if (!pc.current) return;
                const offer = await pc.current.createOffer();
                if (!offer.sdp) return;
                await pc.current.setLocalDescription(offer);
                if (!pc.current.localDescription) return;
                if(isLoadingRef.current && serverIdPairRef.current) {
                    socketManager?.sendOffer(serverIdPairRef.current, id, pc.current.localDescription.sdp);
                }
            }).catch(e => { });
        }
        catch (e) {
            console.log(e);
        }
    }

    function onMessageReceived(){
        if(isLoadingRef.current){
            isLoadingRef.current = false;
            setIsLoading(isLoadingRef.current);
            setIsConnected(true);
        }
    }

    async function onReceiveAnswer(answer : string ){
        if(!pc.current || !isLoadingRef.current) return;
        //console.log("answer received, ", answer);
        await pc.current.setRemoteDescription({sdp : answer, type : "answer"});
    }

    async function onReceiveCandidate(data : any ){
        if(!pc.current) return;
        const candidate = data.candidate;
        const sdpMid = data.sdpMid;
        const sdpMLineIndex = data.sdpMLineIndex;
        console.log("candidate received, ", candidate);
        //console.log("sdpMid received, ", sdpMid);
        //console.log("sdpMLineIndex received, ", sdpMLineIndex);
        try{
        await pc.current.addIceCandidate({candidate : candidate, sdpMid : sdpMid, sdpMLineIndex : sdpMLineIndex});
        }
        catch(e){}
    }

    function onReceiveCCTVInfo(data : any ){
        // prevent duplicate server response
        if(!isLoadingRef.current || serverIdPairRef.current) return;
        const serverIdPair = data.serverIdPair;
        const clientId = data.clientId;
        const channelCount = data.channelCount;
        if(clientId !== id || channelCount < 1) return;
        if(channelRef.current > channelCount){
            channelRef.current = channelCount;
            setChannel(channelRef.current);
        }
        serverIdPairRef.current = serverIdPair;
        setChannelCount(channelCount);
        if(data.hasZeroChannel)
           setHasZeroChannel(true);
        else
           setHasZeroChannel(false);
        initializeCCTVConnection();
    }

    function onWebRTCConnected(){
        /*
        if(isLoadingRef.current){
            isLoadingRef.current = false;
            setIsLoading(isLoadingRef.current);
        }
        setIsConnected(true);
        */
    }

    function onWebRTCDisconnected(){
        if(isLoadingRef.current){
            isLoadingRef.current = false;
            setIsLoading(isLoadingRef.current);
        }
        serverIdPairRef.current = undefined;
        setIsConnected(false);
    }
    

    function play(dataChannel: RTCDataChannel, onDataChannelSet: () => void): Promise<void> {
        if (!id) Promise.resolve();
        try {
            if (jsPluginRef.current) {
                jsPluginRef.current.JS_StopRealPlayAll(); jsPluginRef.current.JS_DestroyWorker();
            }
            jsPluginRef.current = new window.JSPlugin2({
                "szId": "videoDiv",
                "iType": 1,
                "iWidth": "100%",
                "iHeight": "100%",
                "iCurrentSplit": 1,
                "iMaxSplit": 1,
                "szBasePath": ""
            });
            return jsPluginRef.current.JS_Play(dataChannel, onDataChannelSet, onMessageReceived, `ws://${"192.168.0.123"}:${"7681"}/101`, {
                "sessionID": "",
                "token": id
            }, 0);
        }
        catch (e) {
            return Promise.reject(e);
        }
    }

    function reconnect(){
        deinitialize();
        initialize(store);
    }

    function getChannelOptions(): JSX.Element[] {
        const options : JSX.Element[] = [];
        if(hasZeroChannel)
           options.push(<option key={0} value={0}> 통합(제로채널) </option>)
           
        for(let i=0; i<channelCount; i++){
            options.push(<option key={i+1} value={i+1}> 카메라{stringUtil.padZero((i+1).toString(), 2)} </option>)
        }
        return options;
    }

    function onChannelChange(newChannelString : string){
        try{
            const newChannel = Number.parseInt(newChannelString);
            if(newChannel < 0 || newChannel > channelCount) return;
            channelRef.current = newChannel;
            setChannel(channelRef.current);
            try{
                localStorage.setItem(channelKey, channelRef.current.toString());
              }
              catch(e) {}
            reconnect();
        }
        catch(e){
            console.log(e);
        }
    }

    return <div>
        <h4 className="mb-3">CCTV 영상보기</h4>
        <div className="mt-3" >
        {store ? (storeUtil.hasCCTVFeature(store.features) ?
        <>
        <ComponentToLoad isLoading={isLoading}>
            {isConnected ?
        <div className="d-flex">
        <div className="me-2">
        <OkButton onClick={()=> {jsPluginRef.current.JS_FullScreenSingle(0);}}  >전체화면</OkButton>
        </div>
        <div>
            <select className="form-select" value={channel} onChange={e => onChannelChange(e.currentTarget.value)}>
                {
                    getChannelOptions()
                }
            </select>
        </div>
        </div>
        :
        <>
        <div className="text-danger">
            ※ 서버 또는 CCTV에 연결을 할 수 없습니다. CCTV가 연결되어있는지 확인해주세요.
        </div>
        <OkButton onClick={() => reconnect()}>재연결</OkButton>
        </>
}
        </ComponentToLoad>
        <div className="ratio ratio-16x9">
        <div id="videoDiv" className="bg-gainsoboro mt-2"></div>
        </div>
        </>
        :
        <div className="text-danger">
          해당매장에 지원하지 않는 기능입니다.
    </div>)
        :
        <div>
            선택된 매장이 없습니다. 매장을 선택해주세요.
        </div>
    }
        </div>
    </div>
}
import Loading from "./Loading"

export default function ComponentToLoad(props : ComponentToLoadProps){
    return <div className={props.className || ""}>
        {props.isLoading ?
        <div className={"d-flex" + (props.loadingCenter ? " justify-content-center" : "")}>
          {props.smallIcon?
          <Loading width="30px" height="30px"/>
          :
          <Loading />
          } 
          </div>
          :
          props.children
        }
    </div> 
}

interface ComponentToLoadProps{
    children : React.ReactNode,
    isLoading : boolean,
    loadingCenter : boolean,
    className? : string,
    smallIcon : boolean
}

ComponentToLoad.defaultProps = {
    loadingCenter : false,
    smallIcon : false,
}


import { ErrorMessage, Field, useFormikContext } from "formik";
import { useContext, useState } from "react";
import DaumPostcodeEmbed, { Address, useDaumPostcodePopup } from "react-daum-postcode";
import DaumPostcode from "../components/DaumPostcode";
import SubButton from "../components/SubButton";

function AddressField(props : AddressFieldProps) {
    const {setFieldValue} = useFormikContext<any>();
    const [isDaumPopupOpen, setIsDaumPopupOpen] = useState<boolean>(false); 


    function handleDaumData(data :Address)  {
        let mainAddress ="";
        if(data.userSelectedType === 'R')
        mainAddress = data.roadAddress;
        else
        mainAddress = data.jibunAddress;
        setFieldValue(props.mainAddressFieldName, mainAddress);
        setFieldValue(props.zipCodeFieldName, data.zonecode);
        setIsDaumPopupOpen(false);
    }
    return <div className={props.className ?? ""}>
        <div className="mb-2">{props.display}</div>
        <div className="d-flex">
            <Field name={props.zipCodeFieldName} disabled={true} className="form-control me-2" style={{'width':'150px'}}/>
            <SubButton onClick={()=>{
                setIsDaumPopupOpen(!isDaumPopupOpen);
            }}>우편번호찾기</SubButton>
        </div>
        {isDaumPopupOpen&&
        <DaumPostcode handleDaumData={handleDaumData} />
        }
        <ErrorMessage name={props.zipCodeFieldName}>{msg =>
            <div className="text-danger">{msg}</div>
        }</ErrorMessage>
        <Field name={props.mainAddressFieldName} disabled={true} placeholder="우편번호찾기를 해주세요." className="form-control mt-2"></Field>
        <ErrorMessage name={props.mainAddressFieldName}>{msg =>
            <div className="text-danger">{msg}</div>
        }</ErrorMessage>
        <Field name={props.addressDetailFieldName} placeholder="상세주소를 입력해주세요." className="form-control mt-2"></Field>
        <ErrorMessage name={props.addressDetailFieldName}>{msg =>
            <div className="text-danger">{msg}</div>
        }</ErrorMessage>
    </div>
}

interface AddressFieldProps {
    display : string,
    mainAddressFieldName : string,
    zipCodeFieldName : string,
    addressDetailFieldName : string,
    className? : string
}

export default AddressField;
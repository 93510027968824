export default function KakaoButton(props : {className? : string, onClick? : () => void}){

    return <button type="button" className={`btn ${props.className || ""}`} onClick={() => props.onClick && props.onClick()} style={{background : "#FEE500"}}>
    <div className="d-flex justify-content-center">
        <div className="me-2">
<img src="/img/kakao_symbol.svg" className="pb-1" style={{width : "1.2rem"}}></img>
</div>
<div  >카카오 로그인</div>
</div>
</button>
}
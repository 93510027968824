import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RouteConfig from './route-config';
import HttpInterceptors from './utils/HttpInterceptors';
import { useEffect, useRef, useState } from 'react';
import { claim, claimCheck, Device } from './accounts/Accounts.Models';
import JWTHandler from './utils/JWTHandler';
import Authorization from './utils/Authorization';
import LoginPage from './accounts/LoginPage';
import AuthenticationContext from './accounts/AuthenticationContext';
import Authorized from './accounts/Authorized';
import ServerAPI from './ServerAPI';
import StoreSelectionHeader from './main/StoreSelectionHeader';
import { StoreInfo } from './stores/Store.Models';
import Dialog from './utils/Dialog';
import AppContext from './AppContext';
import HttpRequest from './utils/HttpRequest';
import { SocketManager } from './utils/SocketManager';
import Icons from './utils/Icons';
import ArrayUtil from './utils/ArrayUtil';
import SideBar from './main/SideBar';
import { wVirtualNetworkId } from './utils/wLibrary';
import MainTitle from './main/MainTitle';
import { app } from './utils/AppCommnunication';

HttpInterceptors.configureInterceptors();
(new Icons()).initialize();
const socketManager = new SocketManager();
const groupId = wVirtualNetworkId.generate()!.id;
const managerId = wVirtualNetworkId.generate()!.id;
socketManager.start(groupId, managerId);
console.log(`(groupID) managerID : (${groupId}) (${managerId})`);


function App() {
  const [claims, setClaims] = useState<claim[]>([]);
  const [stores, setStores] = useState<StoreInfo[]>([]);
  const [selectedStoreId, setSelectedStoreId] = useState<string>("");
  const [isTCPConnected, setIsTCPConnected] = useState(false);
  const isTCPConnectedRef = useRef(false);
  const [device, setDevice] = useState<Device>();
  const [defaultStoreId, setDefaultStoreId] = useState<string>("");
  const versionName = process.env.REACT_APP_VERSION_NAME;
  const versionCode = process.env.REACT_APP_VERSION_CODE;

  if(window.Kakao && !window.Kakao.isInitialized()){
    window.Kakao.init('4a950c75a97839986705a79e34568427');
  }

  useEffect(() => {
    versionCheck();
    const timer =setTimeout(() => versionCheck(), 300000);
    return () => {
      clearTimeout(timer);
    }
  }, []);
  

  useEffect(() => {
    const timer = setInterval(() => {
      const isConnected = socketManager.isConnectedToMainRouter();
      if (isConnected !== isTCPConnectedRef.current) {
        isTCPConnectedRef.current = isConnected;
        setIsTCPConnected(isTCPConnectedRef.current);
      }
    }, 1000);
    const handler = app.addListener(onAppMessage)
    app.send("deviceinfo");
    JWTHandler.getClaims().then(claims => {
      setClaims(claims);
    });

    return () => {
      clearInterval(timer);
      app.removeListener(handler);
    }
  }, []);

  useEffect(() => {
    const loginCheckTimer = setInterval(() => {
      Authorization.checkExpiration(claims, setClaims, device);
    }, 60000);
    return () => {
      clearInterval(loginCheckTimer);
    };
  }, [device, claims]);

  async function versionCheck(){
      if(!versionCode) return;
      try {
        const versionCodeNumber = Number.parseInt(versionCode);
        if(versionCodeNumber < 1) return;
        const latestVersionCode = await HttpRequest.request<Number>("get", ServerAPI.versionCodePath, undefined);
        if(versionCodeNumber >= latestVersionCode) return;
        if(window.location.pathname === RouteConfig.homePath){
          window.location.reload();
        }
      }
      catch(e){
        console.log(e);
        return;
      }
  }

  function onAppMessage(type: string, data: any) {
    try {
      if (type === "tokenGet")
        JWTHandler.onTokenGet(data.id, data.token);
      else if (type === "deviceinfo") {
        setDevice(data);
      }
    }
    catch (e) { console.log(e) }
  }

  function checkRoleAndClaims(roles?: string[], claimsToCheck?: claimCheck[]): boolean {
    if (!roles && !claimsToCheck) return true;
    let authorized = false;
    if (roles) {
      authorized = Authorization.hasOneOfRoles(claims, roles);
    }
    if (authorized)
      return true;
    else if (claimsToCheck) {
      for (let i = 0; i < claimsToCheck.length; i++) {
        if (Authorization.hasClaim(claims, claimsToCheck[i].name, claimsToCheck[i].value)) {
          return true;
        }
      }
    }
    return false;
  }

  async function getAccessibleStores(onSucess?: () => void, onFail?: (errorMsg: string) => void) {
    if (!Authorization.isAuthorized(claims)) return;
    try {
      const defaultStoreId = await HttpRequest.get<string>(ServerAPI.accountDefaultStoreGet);
      const stores = await HttpRequest.get<StoreInfo[]>(ServerAPI.storeGetAccessibleStores);
      ArrayUtil.sortAscending(stores, "name");
      setStores(stores);
      setDefaultStoreId(defaultStoreId);
      if (!stores.find(s => s._id === selectedStoreId)) {
        if (stores.length === 0)
          setSelectedStoreId("");
        else if(stores.find(s => s._id === defaultStoreId))
          setSelectedStoreId(defaultStoreId);
        else
          setSelectedStoreId(stores[0]._id);
      }

      if (onSucess)
        onSucess();
    }
    catch (error) {
      console.log(error);
      let errorMsg = "매장 목록을 가져오는데 실패했습니다.\n";
      if (error?.response?.data) {
        errorMsg += error.response.data;
      }
      setStores([]);
      if (onFail)
        onFail(errorMsg);
      else {
        Dialog.showError(errorMsg);
      }
    }
  }

  return (
    <BrowserRouter>
      <AuthenticationContext.Provider value={{ claims, update: setClaims }}>
        <AppContext.Provider value={{ stores, updateStores: getAccessibleStores, selectedStoreId: selectedStoreId, updateSelectedStoreId: setSelectedStoreId, socketManager: socketManager, 
          isSocketConnected: isTCPConnected, device: device, defaultStoreId : defaultStoreId, updateDefaultStoreId : setDefaultStoreId }}>
              <Routes>
                {RouteConfig.routes.map(route =>
                  <Route key={route.path} path={route.path} element={
                    <div id="outer-container">
            <div className='bg-main py-3 sticky-top'>
              <div className='container'>
                <div className='d-flex'>
                  {!route.noSideBar &&
                  <SideBar versionName={versionName} /> }
                  <div className='ms-0 ms-md-2'>
                    <MainTitle noHeaderLink={route.noHeaderLink ?? false} />
                  </div>
                </div>
              </div>
            </div>
            <div id="page-wrap">
                    {(!route.authorized || Authorization.isAuthorized(claims)) ?
                      (checkRoleAndClaims(route.role, route.claims) ?
                        <>
                          {!route.noStoreHeader &&
                            <div className='bg-main'>
                              <div className='container'>
                                <Authorized authorized={
                                  <StoreSelectionHeader />} />
                              </div>
                            </div>
                          }

                          <div className='my-4 container'>
                            <route.component />
                          </div>
                        </>
                        :
                        <div className='container my-4'>
                          <div className='text-danger'>
                            해당 페이지에 접근할 수 있는 권한이 없습니다.
                          </div>
                        </div>
                      )
                      :
                      <div className='container my-4'>
                        <LoginPage />
                      </div>
                  }
                      <div className='bg-main py-2'>
                <div className='container'>
                  <div className="row g-0 justify-content-between">
                  <div className='col-auto'>
                  Developed by 주식회사 엔에스엠 - nsm@nsmaker.co.kr.
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                      } >

                  </Route>
                )}
              </Routes>

        </AppContext.Provider>
      </AuthenticationContext.Provider>
    </BrowserRouter>
  );
}

export default App;

import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup'
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton";
import Timer from "../components/Timer";
import TextField from "../forms/TextField";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import EmailValidation from "../utils/EmailValidation";
import HttpRequest from "../utils/HttpRequest";

export default function EmailVerification(props : EmailVerificationProps) {
    const [errors, setErrors] = useState<string[]>([]);
    const [verificationErrors, setVerificationErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isVerificationLoading, setIsVerificationLoading] = useState(false);
    const emailRef = useRef<string>(props.email ?? "");
    const [codeNeeded, setCodeNeeded] = useState(false);
    const [timoutReset, setTimeoutReset] = useState(true);
    const navigate = useNavigate();

    async function onRequest(email : string) {
        setIsLoading(true);
        try {
            setErrors([]);
            setVerificationErrors([]);
            await HttpRequest.post(ServerAPI.accountRequestEmailVerificationPath, {email : email});
            emailRef.current = email;
            setCodeNeeded(true);
            setTimeoutReset(!timoutReset);
        }
        catch(error) {
            if(error?.response?.data){
                setErrors(error.response.data);
            }
            else{
                setErrors(["서버연결에 실패했습니다."]);
            }
            setCodeNeeded(false);

        }
        setIsLoading(false);
    }

    
    async function onVerification(code : string) {
        setIsVerificationLoading(true);
        try {
            setVerificationErrors([]);
            await HttpRequest.put(`${ServerAPI.accountCheckEmailVerificationPath}/${code}`, {email : emailRef.current});
            Dialog.showConfirmation("인증이 완료되었습니다. 로그인페이지로 이동합니다.")
            navigate(`${RouteConfig.accountLoginPath}/${emailRef.current}` );
        }
        catch(error) {
            if(error?.response?.data){
                setVerificationErrors(error.response.data);
            }
            else{
                setVerificationErrors(["서버연결에 실패했습니다."])
            }
        }
        setIsVerificationLoading(false);
    }

    return <div>
        <Formik initialValues={{email : emailRef.current}} onSubmit={value => onRequest(value.email)} 
                validationSchema={Yup.object({
                    email: Yup.string().required('이메일을 입력해주세요.').max(64, "이메일길이가 64글자를 초과합니다.")
                    .test("isValidEmail", "이메일 형식이 올바르지 않습니다.",
                        function (email) {
                            if(!email) return true;
                            return EmailValidation.isValidEmail(email);
                        }),
                })
                }>
                    {(formikProps) => 
                        <Form>
                            <div className="row g-0">
                                <TextField field='email' placeholder="이메일을 입력해주세요." className="col-lg-5" />
                                <div className="col-auto">
                                <ComponentToLoadSmallCenter className="ps-0 ps-lg-2 mt-2 mt-lg-0"  isLoading={isLoading}>
                                    <OkButton disabled={isLoading}  className="w-100" type='submit'>{codeNeeded ? "재인증요청" : "인증요청"}</OkButton>
                                    </ComponentToLoadSmallCenter>
                                    </div>
                                </div>
                               <DisplayErrors className="mt-2" errors={errors} />
                                    
                        </Form>
                    }
                </Formik>
                {codeNeeded && <Formik initialValues={{code : ""}} onSubmit={value => onVerification(value.code)} 
                validationSchema={Yup.object({
                    code: Yup.string().required('인증번호를 입력해주세요.').length(6, "인증번호는 6자리 숫자입니다.")
                })
                }>
                    {(formikProps) => 
                        <Form>
                            <div className="text-primary mb-1">
                                ※ "{emailRef.current}" 주소로 인증번호를 보냈습니다.  
                            </div>
                            <div className="text-primary">
                                ※ 인증만료 : <Timer minutes={3} seconds={0} reset={timoutReset} onTimeOut={function (): void {
                        setVerificationErrors(["인증시간이 만료되었습니다."]);
                    } }/> 
                            </div>
                            <div className="row g-0">
                                <TextField field='code' placeholder="인증번호 6자리를 입력해주세요." className="col-lg-5" />
                                <div className="col-auto">
                                <ComponentToLoadSmallCenter className="ps-0 ps-lg-2 mt-2 mt-lg-0" isLoading={isVerificationLoading}>
                                    <OkButton disabled={isVerificationLoading} type='submit' className='w-100'>인증완료</OkButton>
                                    </ComponentToLoadSmallCenter>
                                    </div>
                                </div>
                               <DisplayErrors className="mt-2" errors={verificationErrors} />
                                <div className='mt-3'>
                                    
                                    </div>                        
                        </Form>
                    }
                </Formik>}
    </div>
}

interface EmailVerificationProps{
    email : string | undefined
}
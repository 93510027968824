import { useEffect, useState } from "react";
import MyDateRangePicker from "./MyDateRangePicker";

export default function DateSelection(props : {defaultDateRange : string, startDate : Date, endDate : Date, setStartDate : (date :Date)=> void, setEndDate : (date : Date)=> void}) {
    const [dateRange, setDateRange] = useState<string>(props.defaultDateRange);

    useEffect(()=>{
        onDateRangeChange(props.defaultDateRange);
    }, [])

    function onDateRangeChange(newDateRange: string) {
        const today = new Date();
        if (newDateRange === "0") {
            props.setStartDate(today);
            props.setEndDate(today);
        }
        else if (newDateRange === "1") {
            const newDate = new Date();
            newDate.setDate(today.getDate() - 3)
            props.setStartDate(newDate);
            props.setEndDate(today);
        }
        else if (newDateRange === "2") {
            const newDate = new Date();
            newDate.setDate(today.getDate() - 7)
            props.setStartDate(newDate);
            props.setEndDate(today);
        }
        else if (newDateRange === "3") {
            const newDate = new Date();
            newDate.setMonth(today.getMonth() - 1)
            props.setStartDate(newDate);
            props.setEndDate(today);
        }
        else if (newDateRange !== "4") return;
        setDateRange(newDateRange);
    }
    
    return <>
        <div className="mb-2">조회기간</div>
        <select className="form-select mb-3" value={dateRange} onChange={e => onDateRangeChange(e.currentTarget.value)} >
            <option value="0">오늘</option>
            <option value="1">최근3일</option>
            <option value="2">최근1주일</option>
            <option value="3">최근1개월</option>
            <option value="4">직접입력</option>
        </select>
        {dateRange === "4" && <div className="text-primary mb-2">※ 검색기간이 1개월을 초과하지않게 선택해주세요.</div>}
        <MyDateRangePicker className="mb-3" disabled={dateRange !== "4"} startDate={props.startDate} endDate={props.endDate} onStartDateChange={props.setStartDate} onEndDateChange={props.setEndDate} />
    </>
}
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import ServerAPI from "../ServerAPI";

const serverDomains : string[] = ServerAPI.getServerDomains().map(d => `https://${d}:${ServerAPI.getServerPort()}`);

// Fisher-Yates shuffle
function shuffleServerDomains() {
    if (serverDomains.length <= 1) return;
    for (let index = serverDomains.length - 1; index > 0; index--) {
        const position = Math.floor(Math.random() * (index + 1));
        const temp = serverDomains[index];
        serverDomains[index] = serverDomains[position];
        serverDomains[position] = temp;
    }
}

class HttpRequest {
    static postJson<T=any>(path : string, data : string | undefined) : Promise<T>{
        return this.requestAPI("post", path, {
            headers: {'Content-Type': 'application/json'}
        }, data);
    }

    static get<T=any>(path : string, config? : AxiosRequestConfig<any> | undefined) : Promise<T>{
        return this.requestAPI("get", path, config);
    }

    static post<T=any>(path : string, data : any, config? : AxiosRequestConfig<any> | undefined) : Promise<T>{
        return this.requestAPI("post", path, config, data);
    }

    static put<T=any>(path : string, data : any, config? : AxiosRequestConfig<any> | undefined) : Promise<T>{
        return this.requestAPI("put", path, config, data);
    }

    static delete<T=any>(path : string, config? : AxiosRequestConfig<any> | undefined) : Promise<T>{
        return this.requestAPI("delete", path, config);
    }

    static async request<T=any>(type : "get" | "post" | "put" | "delete", path:string, config: AxiosRequestConfig<any> | undefined, body?:any) : Promise<T> {
        shuffleServerDomains();
        let finalError : any = undefined;
        for(let i = 0; i < serverDomains.length; i++){
            try{
                let response : AxiosResponse<T> | undefined = undefined;
                if(type === "get") {
                    response = await axios.get(`${serverDomains[i]}${path}`, config);
                }
                else if(type === "post") {
                    response = await axios.post(`${serverDomains[i]}${path}`, body, config);
                }
                else if(type === "put") {
                    response = await axios.put(`${serverDomains[i]}${path}`, body, config);
                }
                else if(type === "delete") {
                    response = await axios.delete(`${serverDomains[i]}${path}`, config);
                }
                if(response)
                    return response.data;
    
            }
            catch(error){
                console.log(`error server domain : ${serverDomains[i]}`);
                console.log(error);
                finalError = error;
            }
        }
        throw finalError;
    }

    static async requestAPI<T=any>(type : "get" | "post" | "put" | "delete", path:string, config: AxiosRequestConfig<any> | undefined, body?:any) : Promise<T> {
        return this.request<T>(type, `/api${path}`, config, body); 
   }

}

export default HttpRequest;


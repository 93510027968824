import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import OkButton from "./OkButton";

export default function DialogComponent(props : DialogComponentProps) {
    return  <Dialog open={props.isOpen} onClose={() => props.onClose()}>
    <DialogTitle>
        <div className="fw-bold">
        {props.title}
        </div>
   </DialogTitle>
    <DialogContent>
        {props.children}
    </DialogContent>
    <DialogActions className='mx-3 mb-2'>
    <OkButton onClick={()=>props.onOkButtonClick()}>확인</OkButton>
    </DialogActions>
    </Dialog>
} 

interface DialogComponentProps {
    isOpen : boolean,
    title : string,
    children : React.ReactNode,
    onClose() : void,
    onOkButtonClick() : void
}
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function SideBarCollapseMenu(props : SideBarCollapseMenuProps){
    const [menuOpen, setMenuOpen] = useState(false);
    return<> <div className='d-flex align-items-center'>
                 <button className='main-header' type="button" onClick={() => setMenuOpen(!menuOpen)}>
                  {props.display} 
                 {menuOpen ? <span className='ms-1'><FontAwesomeIcon icon="caret-left" /></span> : <span className='ms-1'><FontAwesomeIcon icon="caret-down" /></span>
                 }
                </button>
                </div>
                {menuOpen && props.children
                 }
                 </>
}

interface SideBarCollapseMenuProps {
    display : string,
    children : React.ReactNode
}
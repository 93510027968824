import axios, { AxiosResponse } from "axios";
import { ErrorMessage, useFormikContext } from "formik";
import { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { CompanyQueryDTO } from "../accounts/Accounts.Models";
import ServerAPI from "../ServerAPI";
import HttpRequest from "../utils/HttpRequest";
import DisplayErrors from "./DisplayErrors";

function CompanyTypeAhead(props : CompanyTypeAheadProps) {
    const [companies, setCompanies] = useState<CompanyQueryDTO[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const {setFieldValue} = useFormikContext<any>();

    async function handleSearch(query: string): Promise<void> {
        setIsLoading(true);
        setErrors([]);
        try {
            const companyAccounts = await HttpRequest.get<CompanyQueryDTO[]>(`${ServerAPI.accountGetCompaniesByNamePath}/${query}`);
            setCompanies(companyAccounts);
        }
        catch (error) {
            if (error?.response?.data) {
                setErrors(error.response.data);
            }
        }
        setIsLoading(false);
    }

    return(
    <div> 
          <ErrorMessage name={props.fieldName}>{msg =>
            <div className="text-danger">{msg}</div>
        }</ErrorMessage>
    <AsyncTypeahead
    id={props.fieldName}
        options={companies}
        onChange={companies => {
            if(companies.length > 0) {
              setFieldValue(props.fieldName, companies[0]._id);
            }
        }}
        caseSensitive={false}
        placeholder="소속업체를 검색해주세요."
        labelKey={option => option.name}
        emptyLabel={
            <div className="text-danger">
                일치하는 업체가 없습니다.
            </div>
        }
        minLength={1}
        onSearch={handleSearch}
        filterBy={() => true}
        isLoading={isLoading}
        renderMenuItemChildren={option => (
            <>
                <div className="fw-bold">{
                    option.name}</div>
                <div className="overflow-auto">{
                    option.addressFull}</div>
            </>
        )}
    >
    </AsyncTypeahead>
    <DisplayErrors errors={errors} />
  
    </div>);
}



interface CompanyTypeAheadProps {
    fieldName : string
}

export default CompanyTypeAhead;
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Authorized from "../accounts/Authorized";
import AppContext from "../AppContext";
import CancelButton from "../components/CancelButton";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import Authorization from "../utils/Authorization";
import Dialog from "../utils/Dialog";
import { PacketCommands } from "../utils/SocketManager";
import ConnectionComponent from "./ServerConnectionComponent";
import { Server, ServerState, StoreInfo } from "./Store.Models";

function ServerStateComponent(props: ServerStateComponentProps) {
    const [server, setServer] = useState<ServerState>({ ...props.server, version: "알 수 없음" });
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { selectedStoreId, socketManager, stores } = useContext(AppContext);
    const [store, setStore] = useState(stores.find(s => s._id === selectedStoreId));
    const [isConnected, setIsConnected] = useState(false);
    const connectionCount = useRef(0);
    const timeoutCount = 3;
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedStoreId)
            setStore(stores.find(s => s._id === selectedStoreId));
        socketManager?.registerServerCallBack<ServerState>(server._id, PacketCommands.Manager_Server_State, onReceive);
        const timer = setInterval(() => {
            if (connectionCount.current < timeoutCount) {
                connectionCount.current++;
            }
            else {
                if (isConnected)
                    setIsConnected(false);
            }

            socketManager?.requestServerState(selectedStoreId, server._id);
        }, 1000);
        return () => {
            clearInterval(timer);
            socketManager?.deregisterServerCallBack(server._id, PacketCommands.Manager_Server_State);
        }
    }, [server]);

    function onReceive(data: ServerState) {
        connectionCount.current = 0;
        if (!isConnected) setIsConnected(true);
        setServer({ ...data, name: server.name, bindIp: server.bindIp, _id: server._id, webIp: server.webIp, webPort: server.webPort });
    }

    function onRestart(){
        Dialog.showDialog("해당 서버프로그램을 재실행하시겠습니까?\n\n※ 서버연결이 잠시 끊긴 후 재연결됩니다.", async (result) => {
            if (!result) return;
            if(!selectedStoreId || !server._id) return;
            setIsConnected(false);
            socketManager?.sendServerRestart(selectedStoreId, server._id);
            Dialog.showConfirmation("서버프로그램이 종료 후 재실행되었습니다.");
        });
    }

    function onServerUpdate(isDebug : boolean){
        Dialog.showDialog(`해당 서버프로그램을 ${isDebug? "디버그버전" : "최신버전"}으로 업데이트하시겠습니까?\n\n※ 서버연결이 잠시 끊긴 후 재연결됩니다.`, async (result) => {
            if (!result) return;
            if(!selectedStoreId || !server._id) return;
            setIsConnected(false);
            socketManager?.sendServerUpdate(selectedStoreId, server._id, isDebug);
            Dialog.showConfirmation("업데이트 명령이 전달되었습니다. 재연결 후 서버버전을 확인해주세요.");
        });
    }

    return <div className="bg-gainsboro p-4 mb-3 rounded-4">
        <div className="row g-0 justify-content-between align-items-center mb-2">
            <div className="col text-break pe-2">
                <h4 className="text-violet fw-bold mb-0">{server.name}</h4>
            </div>
            <div className="col-auto d-flex justify-content-end">
                {store && Authorization.hasPermission(store.permission, "server", "update") &&
                    <div className="me-2">
                        <OkButton className="btn-sm" onClick={() => {
                            navigate(`${RouteConfig.storeServerPath}/${server._id}`);
                        }}>정보수정</OkButton>
                    </div>
                }
                {store && Authorization.hasPermission(store.permission, "server", "delete") &&
                    <div>
                        <ComponentToLoadSmallCenter isLoading={deleteLoading}>
                            <CancelButton className="btn-sm me-2" disabled={deleteLoading} onClick={() => {
                                Dialog.showDialog("해당 서버를 삭제하시겠습니까?", async (result) => {
                                    if (!result) return;
                                    setDeleteLoading(true);
                                    await props.onDelete(server._id);
                                    setDeleteLoading(false);
                                });
                            }}>삭제</CancelButton>
                        </ComponentToLoadSmallCenter>
                    </div>
                }
            </div>
        </div>
        <ConnectionComponent isConnected={isConnected}
            noConnectionElement={
                <div className="text-danger">서버가 연결되어 있지 않습니다. 해당서버에 인증키를 입력해 연결해주세요.</div>
            }
            connectionElement={
                <>서버가 연결되었습니다.</>
            }
        />

        <ul className="mt-2">
            <li>인증키 : {server._id}</li>
            {server.bindIp &&
                <li>바인딩 IP : {server.bindIp}</li>
            }
            {server.webIp && server.webPort &&
                <li>웹 IP 및 포트 : {server.webIp}:{server.webPort}</li>
            }
            {isConnected &&
                <>
                    <li>버전 : {server.version}</li>
                </>
            }
        </ul>

        <div className="row g-0 mt-2">
            <div className="col-auto pe-2">
                <OkButton className="btn-sm" onClick={onRestart}>재실행</OkButton>
            </div>
            <div className="col-auto pe-2">
                <OkButton className="btn-sm" onClick={() => {
                   onServerUpdate(false)
                }}>업데이트</OkButton>
            </div>
            <Authorized role={["admin"]} authorized={
            <div className="col-auto">
                <CancelButton className="btn-sm me-2" disabled={deleteLoading} onClick={() => onServerUpdate(true)}>디버그 업데이트</CancelButton>
            </div>
            }/>
        </div>
    </div>
}

interface ServerStateComponentProps {
    server: Server,
    onDelete(serverId: number): Promise<void>,
}

export default ServerStateComponent;
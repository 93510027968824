import LoginPage from "./accounts/LoginPage";
import RegisterPage from "./accounts/RegisterPage";
import PageNotFound from "./components/PageNotFound";
import IotListPage from "./iots/IotListPage";
import IotConnectionPage from "./iots/IotConnectionPage";
import Home from "./main/Home";
import ServerListPage from "./stores/ServerListPage";
import ServerPage from "./stores/ServerPage";
import StoreAddPage from "./stores/StoreAddPage";
import StoreUpdatePage from "./stores/StoreUpdatePage";
import IotUpdatePage from "./iots/IotUpdatePage";
import AppDownLoadPage from "./main/AppDownLoadPage";
import EmailVerificationPage from "./accounts/EmailVerificationPage";
import CompanyPage from "./companies/CompanyPage";
import CompanyAddPage from "./companies/CompanyAddPage";
import { ReactElement} from "react";
import { claimCheck } from "./accounts/Accounts.Models";
import AccountUpdatePage from "./accounts/AccountUpdatePage";
import PasswordChangePage from "./accounts/PasswordChangePage";
import EmployeeAddPage from "./companies/EmployeeAddPage";
import NotificationPage from "./settings/NotificationPage";
import IrisInfoPage from "./iris/IrisInfoPage";
import IrisSchedulePage from "./iris/IrisSchedulePage";
import IrisScheduleAddPage from "./iris/IrisScheduleAddPage";
import PermissionCheckPage from "./companies/PermissionCheckPage";
import CCTVListPage from "./cctv/CCTVListPage";
import CCTVPage from "./cctv/CCTVPage";
import CCTVViewPage from "./cctv/CCTVViewPage";
import IrisMemberListPage from "./iris/IrisMemberListPage";
import CCTVPlayListPage from "./cctv/CCTVPlayListPage";
import CCTVPlayPage from "./cctv/CCTVPlayPage";
import VideoCallConnectPage from "./videocall/VideoCallConnectPage";
import VideoCallPage from "./videocall/VideoCallPage";
import CallDeviceSetupPage from "./videocall/CallDeviceSetupPage";
import CallDeviceAddPage from "./videocall/CallDeviceAddPage";
import CallDeviceUpdatePage from "./videocall/CallDeviceUpdatePage";
import IrisLogPage from "./iris/IrisLogPage";
import IrisAgeVerificationTimePage from "./iris/IrisAgeVerificationTimePage";
import IotScheduleListPage from "./iots/IotScheduleListPage";
import IotScheduleAddPage from "./iots/IotScheduleAddPage";
import PrivacyTermsPage from "./accounts/PrivacyTermsPage";
import StoreLogPage from "./stores/StoreLogPage";
import InquiryPage from "./settings/InquiryPage";
import InquiryAddPage from "./settings/InquiryAddPage";
import KakaoLoginPage from "./accounts/KakaoLoginPage";
import NaverLoginPage from "./accounts/NaverLoginPage";
import NotificationHistoryPage from "./settings/NotificationHistoryPage";
import AppleLoginPage from "./accounts/AppleLoginPage";
import AccountCancelPage from "./accounts/AccountCancelPage";

class RouteConfig{
   static homePath ="/";
   static accountRegisterPath = "/account/register";
   static accountUpdatePath = "/account/update";
   static accountCancelPath = "/account/cancel";
   static accountPasswordChangePath = "/account/password";
   static accountEmailVerificationPath = "/account/emailverification";
   static accountLoginPath = "/account/login";
   static accountAppleLoginPath = "/account/applelogin";
   static accountKakaoLoginPath = "/account/kakaologin";
   static accountNaverLoginPath = "/account/naverlogin";
   static accountApprovalPath = "/account/approval";
   static accountPrivacyTermsPath = "/account/privacyterms";
   static companyPath = "/company";
   static companyAddPath = "/company/add";
   static permissionCheckPath = "/permissioncheck";
   static employeeAddPath = "/employee/add";
   static storeAddPath = "/store/add";
   static storeCallDeviceSetupPage = "/store/calldevicesetup"
   static storeCallDeviceAddPage = "/store/calldeviceadd"
   static storeCallDeviceUpdatePage = "/store/calldeviceupdate"
   static storeLogPath = "/store/log";
   static storeUpdatePath = "/store/update";
   static storeServerListPath = "/store/serverlist";
   static storeServerPath = "/store/server";
   static iotListPath = "/iots";
   static iotConnectPath = "/iot/connect";
   static iotSchedulePath = "/iot/schedule";
   static iotScheduleAddPath = "/iot/schedule/add";
   static iotUpdatePath = "/iots"; 
   static notificationPath = "/notification";
   static notificationHistoryPath = "/notification/history";
   static irisPath = "/iris";
   static irisAgeVerificationTimePath = "/irisageverification";
   static irisSchedulePath = "/irisschedule";
   static irisScheduleAddPath = "/irisschedule/add";
   static irisMemberListPath = "/irismember";
   static irisLogPath = "/irislog";
   static cctvListPath = "/cctvs";
   static cctvAddUpdatePath = "/cctv";
   static cctvViewPath = "/cctvview";
   static cctvPlayListPath = "/cctvvideolist";
   static cctvPlayPath = "/cctvvideoplay";
   static videoCallConnectPath = "/videocallconnect";
   static inquiryPath = "/inquiry";
   static inquiryAddPath = "/inquiry/add";

   static routes : routeElement[] = [
    {path : this.homePath, component: Home, authorized : true},
    {path : "/app", component: AppDownLoadPage},
    {path: this.accountCancelPath, component: AccountCancelPage, noStoreHeader : true},
    {path: this.accountRegisterPath, component: RegisterPage, noStoreHeader : true},
    {path: this.accountPrivacyTermsPath, component: PrivacyTermsPage, noStoreHeader : true},
    {path: this.accountUpdatePath, component: AccountUpdatePage, noStoreHeader : true, authorized :true},
    {path: this.accountPasswordChangePath, component: PasswordChangePage, noStoreHeader : true, authorized :true },
    {path: this.accountEmailVerificationPath, component: EmailVerificationPage},
    {path: `${this.accountEmailVerificationPath}/:email`, component: EmailVerificationPage},
    {path: this.accountLoginPath, component: LoginPage, noStoreHeader : true},
    {path: `${this.accountLoginPath}/:id`, component: LoginPage, noStoreHeader : true},
    {path: this.accountKakaoLoginPath, component: KakaoLoginPage, noStoreHeader : true},
    {path: this.accountNaverLoginPath, component: NaverLoginPage, noStoreHeader : true},
    {path: this.accountAppleLoginPath, component: AppleLoginPage, noStoreHeader : true},
    {path: "/videocall", component: VideoCallPage, noStoreHeader : true, noSideBar : true, noHeaderLink : true},
    {path: this.videoCallConnectPath, component: VideoCallConnectPage, noStoreHeader : true, noSideBar : true, noHeaderLink : true},
    {path: this.companyPath, component: CompanyPage, authorized : true, noStoreHeader : true},
    {path: this.companyAddPath, component: CompanyAddPage, authorized : true, noStoreHeader : true},
    {path: `${this.companyAddPath}/:companyId`, component: CompanyAddPage, authorized : true, noStoreHeader : true},
    {path: `${this.employeeAddPath}/:companyId`, component: EmployeeAddPage, authorized : true, noStoreHeader : true},
    {path: `${this.permissionCheckPath}/:companyId`, component: PermissionCheckPage, authorized : true, noStoreHeader : true},
    {path: `${this.permissionCheckPath}/:companyId/:email`, component: PermissionCheckPage, authorized : true, noStoreHeader : true},
    {path: this.storeAddPath, component: StoreAddPage, authorized : true, noStoreHeader : true},
    {path: this.storeCallDeviceSetupPage, component: CallDeviceSetupPage, authorized : true},
    {path: `${this.storeCallDeviceAddPage}/:deviceId`, component: CallDeviceAddPage, authorized : true},
    {path: `${this.storeCallDeviceUpdatePage}/:deviceId`, component: CallDeviceUpdatePage, authorized : true},
    {path: this.storeLogPath, component: StoreLogPage, authorized : true},
    {path: this.storeUpdatePath, component: StoreUpdatePage, authorized : true},
    {path: this.storeServerListPath, component: ServerListPage, authorized : true},
    {path: this.storeServerPath, component: ServerPage, authorized : true},
    {path: `${this.storeServerPath}/:serverId`, component: ServerPage, authorized : true},
    {path: this.iotListPath, component: IotListPage, authorized : true},
    {path: this.iotConnectPath, component: IotConnectionPage, authorized : true},
    {path: `${this.iotSchedulePath}/:clientId`, component: IotScheduleListPage, authorized : true},
    {path: `${this.iotScheduleAddPath}/:clientId`, component: IotScheduleAddPage, authorized : true},
    {path: `${this.iotScheduleAddPath}/:clientId/:scheduleId`, component: IotScheduleAddPage, authorized : true},
    {path: `${this.iotUpdatePath}/:id`, component: IotUpdatePage, authorized : true},
    {path: `${this.notificationPath}`, component: NotificationPage, authorized : true, noStoreHeader : true},
    {path: `${this.notificationHistoryPath}`, component: NotificationHistoryPage, authorized : true, noStoreHeader : true},
    {path: this.irisPath, component: IrisInfoPage, authorized : true},
    {path: `${this.irisPath}/:id`, component: IrisInfoPage, authorized : true},
    {path: `${this.irisAgeVerificationTimePath}/:id`, component: IrisAgeVerificationTimePage, authorized : true},
    {path: `${this.irisSchedulePath}/:clientId`, component: IrisSchedulePage, authorized : true},
    {path: `${this.irisScheduleAddPath}/:clientId`, component: IrisScheduleAddPage, authorized : true},
    {path: `${this.irisScheduleAddPath}/:clientId/:scheduleId`, component: IrisScheduleAddPage, authorized : true},
    {path: this.irisMemberListPath, component: IrisMemberListPage, authorized : true},
    {path: this.irisLogPath, component: IrisLogPage, authorized : true},
    {path: this.cctvListPath, component: CCTVListPage, authorized : true},
    {path: this.cctvAddUpdatePath, component: CCTVPage, authorized : true},
    {path: `${this.cctvAddUpdatePath}/:id`, component: CCTVPage, authorized : true},
    {path: `${this.cctvViewPath}/:id`, component: CCTVViewPage, authorized : true},
    {path: `${this.cctvPlayListPath}/:id`, component: CCTVPlayListPage, authorized : true},
    {path: `${this.cctvPlayPath}`, component: CCTVPlayPage},
    {path: `${this.inquiryPath}`, component: InquiryPage, authorized : true, noStoreHeader : true},
    {path: this.inquiryAddPath, component: InquiryAddPage, authorized : true, noStoreHeader : true},
    {path: `${this.inquiryAddPath}/:id`, component: InquiryAddPage, authorized : true, noStoreHeader : true},
    {path: '*', component: PageNotFound}  
   ];

   static getFullBaseURL() {
      return `${window.location.protocol}//${window.location.host}`;
   }

   static getFullURL() {
      return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
   }
}

interface routeElement {
   path : string,
   component() : ReactElement,
   noStoreHeader? : boolean,
   noSideBar? : boolean,
   noHeaderLink? : boolean,
   authorized? : boolean,
   role? : string[],
   claims? : claimCheck[],
}

export default RouteConfig;
export default class ArrayUtil {
    static sortAscending(arr : any[], propertyName : string){
        arr.sort((a : any, b :any) => {
            if(a[propertyName] > b[propertyName]) return 1;
            else if(a[propertyName] < b[propertyName]) return -1;
            else return 0;
        });
    }

    static sortDescending(arr : any[], propertyName : string){
        arr.sort((a : any, b :any) => {
            if(a[propertyName] < b[propertyName]) return 1;
            else if(a[propertyName] > b[propertyName]) return -1;
            else return 0;
        });
    }
}
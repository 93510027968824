import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../AppContext";
import ComponentToLoad from "../components/ComponentToLoad";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton"
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import { StoreInfo } from "../stores/Store.Models";
import ArrayUtil from "../utils/ArrayUtil";
import Dialog from "../utils/Dialog"
import HttpRequest from "../utils/HttpRequest";
import { storeUtil } from "../utils/StoreUtil";
import { cctvClient } from "./CCTV.Models";
import CCTVStateComponent from "./CCTVStateComponent";

export default function CCTVListPage() {
    const { selectedStoreId, stores, socketManager } = useContext(AppContext);
    const [store, setStore] = useState<StoreInfo>();
    const navigate = useNavigate();
    const [cctvs, setCCTVs] = useState<cctvClient[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);
    useEffect(() => {
        setStore(stores.find(s => s._id === selectedStoreId));
        updateCCTVList();
    }, [stores, selectedStoreId]);

 
    async function updateCCTVList() {
        if(!selectedStoreId) return;
        setIsLoading(true);
        setErrors([]);
        try {
            const cctvList = await HttpRequest.get<cctvClient[]>(`${ServerAPI.storeCCTVGetList}/${selectedStoreId}`);
            ArrayUtil.sortAscending(cctvList, "name");
            setCCTVs(cctvList);
        }
        catch (error) {
            console.log(error);
            if(cctvs)
                setCCTVs(undefined);
            if (error?.response?.data) {
                setErrors(error.response.data);
            }
            else {
                setErrors(["서버연결에 실패했습니다."])
            }
        }
        setIsLoading(false);
    }

    async function deleteCCTV(clientId: string) {
        try {
            if(!selectedStoreId) return;
            await HttpRequest.delete(`${ServerAPI.storeCCTVDelete}`, {params : {storeId : selectedStoreId, id : clientId}});
            Dialog.showConfirmation("CCTV 삭제가 완료되었습니다.");
            socketManager?.sendStoreUpdate(selectedStoreId);
            updateCCTVList();
        }
        catch(error) {
            let msg = "에러가 발생했습니다.\n"
            if(error?.response?.data){
                msg += error.response.data;
            }
            Dialog.showError(msg);
        }
    }

    return <div>
        <h3 >CCTV 목록</h3>
        <div className="mt-3">
            {store ? (storeUtil.hasCCTVFeature(store.features) ?
                <>
                    <OkButton className="btn-sm mb-2" onClick={() => {
                        navigate(RouteConfig.cctvAddUpdatePath);
                    }}>새 CCTV 등록</OkButton>

                    <ComponentToLoad className="mt-2" isLoading={isLoading}>
                        {cctvs ?
                            <div>
                                <div>
                                    총 {cctvs.length}개 CCTV가 등록되어 있습니다.
                                </div>
                               <div className="row">
                                <div className="col-lg-8">
                                { cctvs.map(s => 
              <CCTVStateComponent cctv={s} key={s._id} onDelete={deleteCCTV}  />
            )}
            </div>
            </div>
                            </div>
                            :
                            <DisplayErrors errors={errors} />}
                    </ComponentToLoad>
                </> :
                <div className="text-danger">
                  해당매장에 지원하지 않는 기능입니다.
            </div>)
                :
                <div>
                    선택된 매장이 없습니다. 매장을 선택해주세요.
                </div>
            }
        </div>
    </div>
}

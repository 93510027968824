import { useParams } from "react-router-dom";
import ScheduleAddPage from "../components/ScheduleAddPage";
import ServerAPI from "../ServerAPI";


export default function IrisScheduleAddPage() {
    const {clientId, scheduleId} = useParams();
    return <ScheduleAddPage clientId={clientId!} scheduleId={scheduleId} getScheduleURL={ServerAPI.storeIrisClientGetSchedule}
    addScheduleURL={ServerAPI.storeIrisClientAddSchedule} updateScheduleURL={ServerAPI.storeIrisClientUpdateSchedule} />
}

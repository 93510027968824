import { useFormikContext } from "formik";
import { useState } from "react";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import SubButton from "../components/SubButton";
import EmailValidation from "../utils/EmailValidation";
import { accountCreateDTO } from "./Accounts.Models";

function IdDuplicationCheck (props : IdDuplicationCheckProps){
   const [isLoading, setIsLoading] = useState(false);
   const {values} = useFormikContext<accountCreateDTO>();
   return ( 
   <div className={props.className}>
    <div className='d-flex align-items-center'>
      <ComponentToLoadSmallCenter isLoading={isLoading}>
   <SubButton disabled={values.idCheckButtonDisabled} onClick={async () =>
   {
      if(props.onCheckButtonClick){
      setIsLoading(true);
         await props.onCheckButtonClick();
      setIsLoading(false);
      }
   }  
      }>아이디 중복 확인</SubButton>
      </ComponentToLoadSmallCenter>
    {EmailValidation.isValidEmail(values.checkedEmail) && values.checkedEmail === values.emailFull ?
    <div className='text-primary ms-2'>※ 사용가능한 아이디입니다.</div> : null }
    </div>
   </div>);
}

interface IdDuplicationCheckProps {
    className? : string,
    onCheckButtonClick?() : Promise<void>,
 }

export default IdDuplicationCheck;
import React from "react";
import DaumPostcodeEmbed, { Address } from "react-daum-postcode";

class DaumPostcode extends React.Component<daumPostcodeProps> {
   render() {
    try{
    return <DaumPostcodeEmbed
    onComplete={this.props.handleDaumData} autoClose={false}></DaumPostcodeEmbed>
    }
    catch(e){
        return <div>aaa</div>
    }
  }
}

interface daumPostcodeProps {
    handleDaumData(data : Address) : void
}

export default DaumPostcode;
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../AppContext";
import ComponentToLoad from "../components/ComponentToLoad";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton"
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import ConnectionComponent from "../stores/ServerConnectionComponent";
import ArrayUtil from "../utils/ArrayUtil";
import Dialog from "../utils/Dialog"
import HttpRequest from "../utils/HttpRequest";
import { PacketCommands } from "../utils/SocketManager";
import { storeUtil } from "../utils/StoreUtil";
import { IoTClient } from "./Iots.Models";
import IotStateComponent from "./IoTStateComponent";

function IotListPage() {
    const { selectedStoreId, socketManager } = useContext(AppContext);
    const navigate = useNavigate();
    const [iots, setIots] = useState<IoTClient[]>();
    const [filteredIots, setFilteredIots] = useState<IoTClient[]>();
    const [type, setType] = useState("0");
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);
    const [isConnected, setIsConnected] = useState(false);
    const connectionCount = useRef(0);
    const timeoutCount = 3;
    const typeRef = useRef("0");

    useEffect(() => {
        updateIotList();
    }, [selectedStoreId]);

    useEffect(() => {
        let timer: any;
        if (iots && iots.length > 0) {
            socketManager?.registerCommandCallBack(PacketCommands.Manager_IoT_State, onReceive);
            timer = setInterval(() => {
                if (connectionCount.current < timeoutCount) {
                    connectionCount.current++;
                }
                else {
                    if (isConnected)
                        setIsConnected(false);
                }
                socketManager?.requestIoTState(selectedStoreId);
            }, 1000);
        }
        return () => {
            if (timer) {
                clearInterval(timer);
                socketManager?.deregisterCommandCallBack(PacketCommands.Manager_IoT_State);
            }
        }
    }, [selectedStoreId, iots]);

    function onReceive(data: IoTClient[]) {
        connectionCount.current = 0;
        if (!isConnected) setIsConnected(true);
        if (!iots || data.length === 0) return;
        const newIots: IoTClient[] = [];
        iots.forEach(s => {
            const iotState = data.find(i => i._id === s._id);
            let newIot = s;
            if (iotState) {
                newIot = { ...s, isConnected: iotState.isConnected, doorSensorStates: iotState.doorSensorStates, currentTemperature: iotState.currentTemperature, version: iotState.version };
            }
            newIots.push(newIot);
        });
        setIots(newIots);
        filterIotsByType(newIots, typeRef.current);
    }

    function filterIotsByType(iotList : IoTClient[] | undefined, newType : string){
        if(!iotList) {
            setFilteredIots(undefined);
            return;
        }
        switch(newType){
            case "1":
                setFilteredIots(iotList.filter(v=> storeUtil.hasDoorSensorFeature(v.features)));
                break;
            case "2":
                setFilteredIots(iotList.filter(v=> storeUtil.hasAirConditionerFeature(v.features)));
                break;
            default:
                setFilteredIots(iotList);
                break;
        }

    }

    async function updateIotList() {
        if (!selectedStoreId) return;
        setIsLoading(true);
        setErrors([]);
        try {

            const iotList = await HttpRequest.get<IoTClient[]>(`${ServerAPI.storeGetIots}/${selectedStoreId}`);
            ArrayUtil.sortAscending(iotList, "name");
            setIots(iotList);
            filterIotsByType(iotList, typeRef.current);
        }
        catch (error) {
            console.log(error);
            setIots(undefined);
            setFilteredIots(undefined);
            if (error?.response?.data) {
                setErrors(error.response.data);
            }
            else {
                setErrors(["서버연결에 실패했습니다."])
            }
        }
        setIsLoading(false);
    }

    async function deleteIot(clientId: string) {
        try {
            if (!selectedStoreId) {
                Dialog.showError("선택된 매장정보가 없습니다.")
                return;
            }
            await HttpRequest.delete(`${ServerAPI.storeIotDelete}`, { params: { storeId: selectedStoreId, id: clientId } });
            Dialog.showConfirmation("IoT 기기 삭제가 완료되었습니다.");
            socketManager?.sendStoreUpdate(selectedStoreId);
            updateIotList();
        }
        catch (error) {
            let msg = "에러가 발생했습니다.\n"
            if (error?.response?.data) {
                msg += error.response.data;
            }
            Dialog.showError(msg);
        }
    }

    function onChangeType(newType : string){
        typeRef.current = newType
        setType(typeRef.current);
        filterIotsByType(iots, newType);
    }

    function getTypeName(type : string){
        switch(type){
            case "1":
                return storeUtil.getStoreFeatureName(storeUtil.DoorSensor);
            case "2":
                return storeUtil.getStoreFeatureName(storeUtil.IrReceiver);
            default:
                return "전체";
        }
    }

    return <div>
        <h3 className="mb-3">IoT 기기 목록</h3>
        <div className="mt-3 row">
            <div className="col-lg-8">
            {selectedStoreId ?
                <>
                    <OkButton className="btn-sm mb-2" onClick={() => {
                        if (!window.ReactNativeWebView) {
                            Dialog.showError("해당 기능은 앱에서만 이용가능합니다.");
                            return;
                        }
                        navigate(RouteConfig.iotConnectPath);

                    }}>새 기기 등록</OkButton>

                    <ComponentToLoad className="mt-2" isLoading={isLoading}>
                        {iots && filteredIots ?
                            <div>
                                {iots.length > 0 &&
                                    <ConnectionComponent className="mb-2" isConnected={isConnected} noConnectionElement={
                                        <div className="text-danger">
                                            서버가 연결되어 있지 않아 기기의 일부 상태를 가져올 수 없습니다.
                                        </div>
                                    }
                                        connectionElement={
                                            <>서버가 연결되었습니다.</>
                                        }
                                    />
                                }
                                <div className="fw-bold">
                                    기기종류
                                </div>
                                <select className="form-select mb-2" value={type} onChange={(e) => onChangeType(e.currentTarget.value)}>
                                    <option value="0">전체</option>
                                    <option value="1">{getTypeName("1")}</option>
                                    <option value="2">{getTypeName("2")}</option>
                                </select>
                                
                                {iots.length === 0 &&
                                    <div className="text-primary">
                                        ※ 해당매장의 지원기능에 따라 등록된 기기가 보이지 않을수 있습니다.
                                    </div>
                                }
                                <div className="fw-bold">
                                    [{getTypeName(type)}] 총 {filteredIots.length}개 기기가 등록되어 있습니다.
                                </div>
                                
                                {filteredIots.map(s =>
                                    <IotStateComponent iot={s} key={s._id} storeId={selectedStoreId} onDelete={deleteIot} serverConnected={isConnected} />
                                )}
                            </div>
                            :
                            <DisplayErrors errors={errors} />}
                    </ComponentToLoad>
                </> :
                <div>
                    선택된 매장이 없습니다. 매장을 선택해주세요.
                </div>
            }
            </div>
        </div>

    </div>
}

export default IotListPage
import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import RouteConfig from "../route-config";
import { app } from "../utils/AppCommnunication";

export default function MainTitle(props : {noHeaderLink : boolean}) {
    const navigate = useNavigate();
    useEffect(() => {
        const handler = app.addListener(onAppMessage)
        return () => {
          app.removeListener(handler);
        }
      }, []);

      function onAppMessage(type: string, data: any) {
        if (type === "navigate") {
          navigate(data);
          }
      }
    function onTitleClick(){
      if(!props.noHeaderLink)
        navigate(RouteConfig.homePath)
    }

    return <span className="cursor-pointer" onClick={() => onTitleClick()}>
    NSM Manager (NSM 통합관리시스템)
    </span>
}
import { cctvVideo } from '../cctv/CCTV.Models';
import { IoTClient } from '../iots/Iots.Models';
import { IrisClientState, IrisLog, IrisMember } from '../iris/Iris.models';
import ServerAPI from '../ServerAPI';
import { ServerState } from '../stores/Store.Models';
import ArrayUtil from './ArrayUtil';
import { storeUtil } from './StoreUtil';
import stringUtil from './StringUtil';
import { wVirtualNetworkNode, wPacket, wVirtualNetworkId, wStream } from './wLibrary'

class SocketManager extends wVirtualNetworkNode {
    constructor() {
        const vnSign = new TextEncoder().encode("NSMVN");
        super(vnSign, "NSM", "wss", ServerAPI.getServerTCPDomains(), ServerAPI.getServerTCPPort(), "/Home/VnManager");
    }
    sign = new TextEncoder().encode("NSMVN");

    serverCallBack = new Map<string, (data: any) => void>();
    commandCallBack = new Map<number, (data: any) => void>();

    getServerGroupId(storeId: string): any {
        return wVirtualNetworkId.create(storeId.trim().toUpperCase());
    }

    getServerId(serverId: number | string): any {
        const id = stringUtil.padZero(serverId.toString().trim(), 8);
        return wVirtualNetworkId.create(id);
    }

    getClientId(clientId: string): any {
        return wVirtualNetworkId.create(clientId);
    }

    registerServerCallBack<T>(serverId: number, command: number, callback: (data: T) => void) {
        var key = [serverId.toString(), command.toString()].join(',');
        this.serverCallBack.set(key, callback);
    }

    deregisterServerCallBack(serverId: number, command: number) {
        var key = [serverId.toString(), command.toString()].join(',');
        this.serverCallBack.delete(key);
    }

    registerCommandCallBack(command: number, callback: (data: any) => void) {
        this.commandCallBack.set(command, callback);
    }

    deregisterCommandCallBack(command: number) {
        this.serverCallBack.delete(command.toString());
    }

    getServerCallBack(serverId: number, command: number) {
        var key = [serverId.toString(), command.toString()].join(',');
        return this.serverCallBack.get(key);
    }

    requestCCTVInfo(storeId: string, clientId: string) {
        if (!storeId || !clientId) return;
        const payload = new wPacket(this.sign, PacketCommands.Manager_CCTVInfo_Version, PacketCommands.Manager_CCTVInfo);
        payload.writeStringByteLength(clientId);
        this.sendPayloadToOtherSubRouters(this.getServerGroupId(storeId), payload.getBytes());
    }

    requestCCTVList(storeId: string, serverSocketId: string, clientId: string, channel : number) {
        const payload = new wPacket(this.sign, PacketCommands.Manager_CCTVPlaybackListRequest_Version, PacketCommands.Manager_CCTVPlaybackListRequest);
        payload.writeStringByteLength(clientId);
        payload.writeByte(channel);
        this.sendPayloadToOne(this.getServerGroupId(storeId), this.getServerId(serverSocketId), payload.getBytes());
    }

    requestCCTVPlay(storeId: string, serverSocketId: string, clientId: string, channel : number, fileName: string) {
        const payload = new wPacket(this.sign, PacketCommands.Manager_CCTVPlayRequest_Version, PacketCommands.Manager_CCTVPlayRequest);
        payload.writeStringByteLength(clientId);
        payload.writeByte(channel);
        payload.writeStringUShortLength(fileName);
        this.sendPayloadToOne(this.getServerGroupId(storeId), this.getServerId(serverSocketId), payload.getBytes());
    }

    requestServerState(storeId: string, serverId: number) {
        if (!storeId || !serverId) return;
        const payload = new wPacket(this.sign, PacketCommands.Manager_Server_State_Version, PacketCommands.Manager_Server_State);
        payload.writeUInt(serverId);
        this.sendPayloadToOne(this.getServerGroupId(storeId), this.getServerId(serverId), payload.getBytes());
    }

    requestServerStateSimple(storeId: string, serverId: number) {
        if (!storeId || !serverId) return;
        const payload = new wPacket(this.sign, PacketCommands.Manager_Server_State_Simple_Version, PacketCommands.Manager_Server_State_Simple);
        payload.writeUInt(serverId);
        this.sendPayloadToOne(this.getServerGroupId(storeId), this.getServerId(serverId), payload.getBytes());
    }

    requestIoTState(storeId: string) {
        if (!storeId) return;
        const payload = new wPacket(this.sign, PacketCommands.Manager_IoT_State_Version, PacketCommands.Manager_IoT_State);
        this.sendPayloadToOtherSubRouters(this.getServerGroupId(storeId), payload.getBytes());
    }

    requestIrisOpenDoor(storeId: string, clientId: string) {
        if (!storeId) return;
        const payload = new wPacket(this.sign, PacketCommands.Manager_Iris_Open_Door_Version, PacketCommands.Manager_Iris_Open_Door);
        this.sendPayloadToOne(this.getServerGroupId(storeId), this.getClientId(clientId), payload.getBytes());
    }



    onReceive_Manager_Server_State(packet: wPacket) {
        let stateString = packet.readBytesUShortLength();
        if (!stateString) return;
        let stateStringUnzipped = stringUtil.decompress(stateString);
        if (!stateStringUnzipped) {
            console.log("failed to unzip data");
            return;
        }
        const serverState: ServerState = JSON.parse(stateStringUnzipped);
        let serverCallBack = this.getServerCallBack(serverState._id, PacketCommands.Manager_Server_State);
        if (serverCallBack)
            serverCallBack(serverState);
    }

    onReceive_Manager_Server_State_Simple(packet: wPacket) {
        const serverId = packet.readUInt();
        let callBack = this.getServerCallBack(serverId, PacketCommands.Manager_Server_State_Simple);
        if (callBack) {
            callBack(undefined);
        }
    }

    onReceive_Manager_IoT_State(packet: wPacket) {
        const stateBytes = packet.readBytesUShortLength();
        if (!stateBytes) return;
        const stateStringUnzipped2 = stringUtil.decompress(stateBytes);
        if (!stateStringUnzipped2) {
            console.log("failed to unzip data");
            return;
        }
        const iotState: IoTClient[] = JSON.parse(stateStringUnzipped2);
        const commandCallBack = this.commandCallBack.get(packet.id);
        if (commandCallBack)
            commandCallBack(iotState);
    }

    onReceive_Manager_Iris_Open_Door(packet: wPacket) {
        const isSuccess = packet.readBool();
        const commandCallBack = this.commandCallBack.get(packet.id);
        if (commandCallBack)
            commandCallBack(isSuccess);
    }

    onReceive_Manager_Offer(packet: wPacket) {
        try {
            const answerBytes = packet.readBytesUShortLength();
            const answer = stringUtil.decompress(answerBytes!);
            const commandCallBack = this.commandCallBack.get(packet.id);
            if (commandCallBack)
                commandCallBack(answer);
        }
        catch (e) { console.log(e); }
    }

    onReceive_Manager_Candidate(packet: wPacket) {
        try {
            const candidateBytes = packet.readBytesUShortLength();
            const candidate = stringUtil.decompress(candidateBytes!);
            const sdpMid = packet.readStringByteLength();
            const sdpMLineIndex = packet.readInt();
            const commandCallBack = this.commandCallBack.get(packet.id);
            if (commandCallBack)
                commandCallBack({ candidate, sdpMid, sdpMLineIndex });
        }
        catch (e) { console.log(e); }
    }

    onReceive_Manager_CCTV_Info(serverIdPair: any, packet: wPacket) {
        try {
            const clientId = packet.readStringByteLength();
            const channelCount = packet.readByte();
            const hasZeroChannel = packet.readBool();
            const commandCallBack = this.commandCallBack.get(packet.id);
            if (commandCallBack)
                commandCallBack({ serverIdPair, clientId, channelCount, hasZeroChannel });
        }
        catch (e) { console.log(e); }
    }

    onReceive_Manager_CCTV_PlaybackList_Response(packet: wPacket) {
        try {
            const streamUnziped = packet.readBytesIntLength();
            const streamBytes = stringUtil.decompressToBytes(streamUnziped!);
            const stream = new wStream();
            stream.set(streamBytes);
            const count = stream.readInt();
            const cctvVideos: cctvVideo[] = [];
            for (let i = 0; i < count; i++) {
                const fileName = stream.readStringByteLength();
                stream.readLong();
                const cctvVideo = storeUtil.getCctvVideoFromFileName(fileName!);
                if (!cctvVideo) continue;
                cctvVideos.push(cctvVideo);
            }
            ArrayUtil.sortDescending(cctvVideos, "displayName");
            const commandCallBack = this.commandCallBack.get(packet.id);
            if (commandCallBack)
                commandCallBack(cctvVideos);
        }
        catch (e) { console.log(e); }
    }

    onReceive_Manager_CCTV_Play_Response(serverSocketId: string, packet: wPacket) {
        try {
            const path = packet.readStringByteLength();
            if (path === null) return;
            const commandCallBack = this.commandCallBack.get(packet.id);
            if (commandCallBack)
                commandCallBack({ serverSocketId, path });
        }
        catch (e) { console.log(e); }
    }

    onReceive_Manager_Iris_Get_MemberList(packet: wPacket) {
        const clientId = packet.readStringByteLength();
        const memberCount = packet.readInt();
        const memberListBytes = packet.readBytesUShortLength();
        if (!memberListBytes) return;
        const memberListString = stringUtil.decompress(memberListBytes);
        if (!memberListString) {
            console.log("failed to unzip data");
            return;
        }
        const memberList: IrisMember[] = JSON.parse(memberListString);
        const commandCallBack = this.commandCallBack.get(packet.id);
        if (commandCallBack)
            commandCallBack({ clientId, memberCount, memberList });
    }

    onReceive_Manager_IrisLog(packet: wPacket) {
        const clientId = packet.readStringByteLength();
        const count = packet.readUInt();
        const logListBytes = packet.readBytesIntLength();
        if (!logListBytes) return;
        const logListString = stringUtil.decompress(logListBytes);
        if (!logListString) {
            console.log("failed to unzip data");
            return;
        }
        const logList: IrisLog[] = JSON.parse(logListString);
        const commandCallBack = this.commandCallBack.get(packet.id);
        if (commandCallBack)
            commandCallBack({ clientId, count, logList });
    }

    onReceive_Manager_Iris_State(serverIdPair: any, packet: wPacket) {
        const stateBytes = packet.readBytesUShortLength();
        if (!stateBytes) return;
        const stateStringUnzipped = stringUtil.decompress(stateBytes);
        if (!stateStringUnzipped) {
            console.log("failed to unzip data");
            return;
        }
        const irisState: IrisClientState = JSON.parse(stateStringUnzipped);
        const commandCallBack = this.commandCallBack.get(packet.id);
        if (commandCallBack)
            commandCallBack({ serverIdPair: serverIdPair, state: irisState });
    }

    onReceive_Manager_IrisVideoCallOfferRequest(packet: wPacket) {
        const clientId = packet.readStringByteLength();
        const offerDataBytes = packet.readBytesUShortLength();
        if (!offerDataBytes) return;
        const offerDataString = stringUtil.decompress(offerDataBytes);
        if (!offerDataString) {
            console.log("failed to unzip data");
            return;
        }
        const offerData = JSON.parse(offerDataString);
        if (!offerData) return;
        const offer: any = JSON.parse(stringUtil.atob(offerData.offer));
        const candidates: any = JSON.parse(stringUtil.atob(offerData.candidates));
        if (!offer || !candidates) return;
        const commandCallBack = this.commandCallBack.get(packet.id);
        if (commandCallBack)
            commandCallBack({ clientId, offer, candidates });
    }

    onReceive_Manager_IrisVideoCallEnd(packet: wPacket) {
        const clientId = packet.readStringByteLength();
        const state = packet.readInt();
        const commandCallBack = this.commandCallBack.get(packet.id);
        if (commandCallBack)
            commandCallBack({ clientId, state });
    }

    onReceivedPayload(payload: any): void {
        const packet = wPacket.createFromBytes(this.sign, payload.payload);
        if (!packet) return;
        try {
            switch (packet.id) {
                case PacketCommands.Manager_Server_State: this.onReceive_Manager_Server_State(packet); break;
                case PacketCommands.Manager_Server_State_Simple: this.onReceive_Manager_Server_State_Simple(packet); break;
                case PacketCommands.Manager_IoT_State: this.onReceive_Manager_IoT_State(packet); break;
                case PacketCommands.Manager_Iris_State: this.onReceive_Manager_Iris_State(payload.srcIdPair, packet); break;
                case PacketCommands.Manager_IrisVideoCallOfferRequest: this.onReceive_Manager_IrisVideoCallOfferRequest(packet); break;
                case PacketCommands.Manager_IrisVideoCallEnd: this.onReceive_Manager_IrisVideoCallEnd(packet); break;
                case PacketCommands.Manager_Iris_Open_Door: this.onReceive_Manager_Iris_Open_Door(packet); break;
                case PacketCommands.Manager_IrisLog: this.onReceive_Manager_IrisLog(packet); break;
                case PacketCommands.Manager_Iris_Get_Member_List: this.onReceive_Manager_Iris_Get_MemberList(packet); break;
                case PacketCommands.Manager_WebRTCOffer: this.onReceive_Manager_Offer(packet); break;
                case PacketCommands.Manager_WebRTCCandidate: this.onReceive_Manager_Candidate(packet); break;
                case PacketCommands.Manager_CCTVInfo: this.onReceive_Manager_CCTV_Info(payload.srcIdPair, packet); break;
                case PacketCommands.Manager_CCTVPlaybackListResponse: this.onReceive_Manager_CCTV_PlaybackList_Response(packet); break;
                case PacketCommands.Manager_CCTVPlayResponse: this.onReceive_Manager_CCTV_Play_Response(payload.srcIdPair.socketId.toString(), packet); break;
            }
        }
        catch (e) { console.log(e); }
    }

    sendCandidate(serverIdPair: any, candidate: string, sdpMid: string, sdpMLineIndex: number) {
        const payload = new wPacket(this.sign, PacketCommands.Manager_WebRTCCandidate_Version, PacketCommands.Manager_WebRTCCandidate);
        const candidateBytes = stringUtil.compress(candidate);
        payload.writeBytesUShortLength(candidateBytes);
        payload.writeStringByteLength(sdpMid);
        payload.writeInt(sdpMLineIndex);
        this.sendPayloadToOne(serverIdPair.groupId, serverIdPair.socketId, payload.getBytes());
        console.log("candidate sent, ", candidate);
    }

    sendIoTUpdate(storeId: string, clientId: string) {
        if (!storeId) return;
        const payload = new wPacket(this.sign, PacketCommands.Manager_IoTUpdate_Version, PacketCommands.Manager_IoTUpdate);
        payload.writeStringByteLength(clientId);
        this.sendPayloadToOtherSubRouters(this.getServerGroupId(storeId), payload.getBytes());
    }

    sendIrisGetMemberList(serverIdPair: any, clientId: string, pageNum: number, nameQuery: string) {
        if (!serverIdPair) return;
        const payload = new wPacket(this.sign, PacketCommands.Manager_Iris_Get_Member_List_Version, PacketCommands.Manager_Iris_Get_Member_List);
        payload.writeStringByteLength(clientId);
        payload.writeUShort(pageNum);
        payload.writeStringByteLength(nameQuery);
        this.sendPayloadToOne(serverIdPair.groupId, serverIdPair.socketId, payload.getBytes());
    }

    sendIrisLog(serverIdPair: any, clientId: string, startDate: string, endDate: string, page: number) {
        if (!serverIdPair) return;
        const payload = new wPacket(this.sign, PacketCommands.Manager_IrisLog_Version, PacketCommands.Manager_IrisLog);
        payload.writeStringByteLength(clientId);
        payload.writeStringByteLength(startDate);
        payload.writeStringByteLength(endDate);
        payload.writeUShort(page);
        this.sendPayloadToOne(serverIdPair.groupId, serverIdPair.socketId, payload.getBytes());
    }

    sendIrisState(storeId: string, clientId: string) {
        if (!storeId) return;
        const payload = new wPacket(this.sign, PacketCommands.Manager_Iris_State_Version, PacketCommands.Manager_Iris_State);
        payload.writeStringByteLength(clientId);
        this.sendPayloadToOtherSubRouters(this.getServerGroupId(storeId), payload.getBytes());
    }

    sendIrisVideoCallAnswer(storeId: string, serverId: string, clientId: string, deviceId: string, answerString: string, candidatesString: string) {
        const webrtcData = {
            webRTCAnswer: answerString,
            candidates: candidatesString,
        };
        const webrtcDataBytes = stringUtil.compress(JSON.stringify(webrtcData));
        const payload = new wPacket(this.sign, PacketCommands.Manager_IrisVideoCallAnswer_Version, PacketCommands.Manager_IrisVideoCallAnswer);
        payload.writeStringByteLength(clientId);
        payload.writeStringByteLength(deviceId);
        payload.writeBytesUShortLength(webrtcDataBytes);
        this.sendPayloadToOne(this.getServerGroupId(storeId), this.getServerId(serverId), payload.getBytes());
    }

    sendIrisVideoCallConnected(storeId: string, serverId: string, clientId: string, deviceId: string) {
        const payload = new wPacket(this.sign, PacketCommands.Manager_IrisVideoCallConnected_Version, PacketCommands.Manager_IrisVideoCallConnected);
        payload.writeStringByteLength(clientId);
        payload.writeStringByteLength(deviceId);
        this.sendPayloadToOne(this.getServerGroupId(storeId), this.getServerId(serverId), payload.getBytes());
    }

    sendIrisVideoCallConnectionState(storeId: string, serverId: string, clientId: string, deviceId: string) {
        const payload = new wPacket(this.sign, PacketCommands.Manager_IrisVideoCallConnectionState_Version, PacketCommands.Manager_IrisVideoCallConnectionState);
        payload.writeStringByteLength(clientId);
        payload.writeStringByteLength(deviceId);
        this.sendPayloadToOne(this.getServerGroupId(storeId), this.getServerId(serverId), payload.getBytes());
    }

    sendIrisVideoCallEnd(storeId: string, serverId: string, clientId: string, deviceId: string) {
        const payload = new wPacket(this.sign, PacketCommands.Manager_IrisVideoCallEnd_Version, PacketCommands.Manager_IrisVideoCallEnd);
        payload.writeStringByteLength(clientId);
        payload.writeStringByteLength(deviceId);
        this.sendPayloadToOne(this.getServerGroupId(storeId), this.getServerId(serverId), payload.getBytes());
    }

    sendIrisVideoCallOffer(storeId: string, serverId: string, clientId: string, deviceId: string) {
        const payload = new wPacket(this.sign, PacketCommands.Manager_IrisVideoCallOfferRequest_Version, PacketCommands.Manager_IrisVideoCallOfferRequest);
        payload.writeStringByteLength(clientId);
        payload.writeStringByteLength(deviceId);
        this.sendPayloadToOne(this.getServerGroupId(storeId), this.getServerId(serverId), payload.getBytes());
    }

    sendIRTransmitterMode(storeId: string, clientId: string, mode: number) {
        const payload = new wPacket(this.sign, PacketCommands.Manager_IrTransmitterMode_Version, PacketCommands.Manager_IrTransmitterMode);
        payload.writeStringByteLength(clientId);
        payload.writeByte(mode);
        this.sendPayloadToOtherSubRouters(this.getServerGroupId(storeId), payload.getBytes());
    }

    sendIRTransmitterPower(storeId: string, clientId: string, powerOn: boolean) {
        const payload = new wPacket(this.sign, PacketCommands.Manager_IrTransmitterPower_Version, PacketCommands.Manager_IrTransmitterPower);
        payload.writeStringByteLength(clientId);
        payload.writeBool(powerOn);
        this.sendPayloadToOtherSubRouters(this.getServerGroupId(storeId), payload.getBytes());
    }

    sendIRTransmitterRequest(storeId: string, clientId: string, powerOn: boolean, mode : number, temperature : number , fanSpeed : number) {
        const payload = new wPacket(this.sign, PacketCommands.Manager_IrTransmitterRequest_Version, PacketCommands.Manager_IrTransmitterRequest);
        payload.writeStringByteLength(clientId);
        payload.writeBool(powerOn);
        payload.writeByte(mode);
        payload.writeByte(temperature);
        payload.writeByte(fanSpeed);
        this.sendPayloadToOtherSubRouters(this.getServerGroupId(storeId), payload.getBytes());
    }

    sendIRTransmitterTemperature(storeId: string, clientId: string, temperature: number) {
        const payload = new wPacket(this.sign, PacketCommands.Manager_IrTransmitterTemperature_Version, PacketCommands.Manager_IrTransmitterTemperature);
        payload.writeStringByteLength(clientId);
        payload.writeByte(temperature);
        this.sendPayloadToOtherSubRouters(this.getServerGroupId(storeId), payload.getBytes());
    }


    sendOffer(serverIdPair: any, clientId: string, offer: string) {
        const payload = new wPacket(this.sign, PacketCommands.Manager_WebRTCOffer_Version, PacketCommands.Manager_WebRTCOffer);
        payload.writeStringByteLength(clientId);
        const offerBytes = stringUtil.compress(offer);
        payload.writeBytesUShortLength(offerBytes);
        this.sendPayloadToOne(serverIdPair.groupId, serverIdPair.socketId, payload.getBytes());
        console.log("offer sent, ", offerBytes.length);
    }

    sendServerRestart(storeId: string, serverId: number) {
        if (!storeId || !serverId) return;
        const payload = new wPacket(this.sign, PacketCommands.ServerRestart_Version, PacketCommands.ServerRestart);
        this.sendPayloadToOne(this.getServerGroupId(storeId), this.getServerId(serverId), payload.getBytes());
    }

    sendServerUpdate(storeId: string, serverId: number, isDebug : boolean) {
        if (!storeId || !serverId) return;
        const payload = new wPacket(this.sign, PacketCommands.ServerUpdateRequest_Version, PacketCommands.ServerUpdateRequest);
        payload.writeBool(isDebug);
        this.sendPayloadToOne(this.getServerGroupId(storeId), this.getServerId(serverId), payload.getBytes());
    }

    sendStoreUpdate(storeId: string) {
        if (!storeId) return;
        const payload = new wPacket(this.sign, PacketCommands.Manager_Store_Update_Version, PacketCommands.Manager_Store_Update);
        this.sendPayloadToOtherSubRouters(this.getServerGroupId(storeId), payload.getBytes());
    }
}

class PacketCommands {
    // manager -> server : [serverId : uint]
    // server -> manager : [serverStateString : string]
    static Manager_Server_State = 0x80;
    static Manager_Server_State_Version = 0x01;
    // manager -> server : [serverId : uint]
    // server -> manager : [serverId : uint]
    static Manager_Server_State_Simple = 0x81;
    static Manager_Server_State_Simple_Version = 0x01;
    // manager -> server : []
    // server -> manager : []
    static Manager_IoT_State = 0x82;
    static Manager_IoT_State_Version = 0x01;
    // manager -> server : [client id]
    // server -> manager : [IrisClientState]
    static Manager_Iris_State = 0x87;
    static Manager_Iris_State_Version = 0x01;
    // manager -> server : []
    static Manager_Store_Update = 0x88;
    static Manager_Store_Update_Version = 0x01;
    // manager -> Iris client : []
    static Manager_Iris_Open_Door = 0x89;
    static Manager_Iris_Open_Door_Version = 0x01;
    // manager -> server [clinetId : string]
    static Manager_CCTVPlaybackListRequest = 0x8A;
    static Manager_CCTVPlaybackListRequest_Version = 0x01;
    // manager <- server
    static Manager_CCTVPlaybackListResponse = 0x8B;
    static Manager_CCTVPlaybackListResponse_Version = 0x01;
    // manager -> Iris client : [clientId : string, pagenum : ushort, nameQuery : string]
    static Manager_Iris_Get_Member_List = 0x8C;
    static Manager_Iris_Get_Member_List_Version = 0x01;
    // manager <-> server : [clientId : string, offser sdp : string]
    static Manager_WebRTCOffer = 0x8D;
    static Manager_WebRTCOffer_Version = 0x01;
    // manager <-> server
    static Manager_WebRTCCandidate = 0x8E;
    static Manager_WebRTCCandidate_Version = 0x01;
    // manager <-> server [clinetId : string]
    static Manager_CCTVInfo = 0x8F;
    static Manager_CCTVInfo_Version = 0x01;
    // manager -> server [clinetId : string]
    static Manager_CCTVPlayRequest = 0x90;
    static Manager_CCTVPlayRequest_Version = 0x01;
    // manager <- server
    static Manager_CCTVPlayResponse = 0x91;
    static Manager_CCTVPlayResponse_Version = 0x01;
    // manager -> server
    static Manager_IrisVideoCallOfferRequest = 0x92;
    static Manager_IrisVideoCallOfferRequest_Version = 0x01;
    // manager -> server
    static Manager_IrisVideoCallAnswer = 0x93;
    static Manager_IrisVideoCallAnswer_Version = 0x01;
    // manager -> server
    static Manager_IrisVideoCallCancel = 0x94;
    static Manager_IrisVideoCallCancel_Version = 0x01;
    // manager -> server
    static Manager_IrisVideoCallConnected = 0x95;
    static Manager_IrisVideoCallConnected_Version = 0x01;
    // manager <-> server
    static Manager_IrisVideoCallEnd = 0x96;
    static Manager_IrisVideoCallEnd_Version = 0x01;
    // manager <-> server
    static Manager_IrisVideoCallConnectionState = 0x97;
    static Manager_IrisVideoCallConnectionState_Version = 0x01;
    // manager <-> server
    static Manager_IrisLog = 0x98;
    static Manager_IrisLog_Version = 0x01;
    // manager -> server
    static Manager_IrTransmitterMode = 0x99;
    static Manager_IrTransmitterMode_Version = 0x01;
    // manager -> server
    static Manager_IrTransmitterTemperature = 0x9A;
    static Manager_IrTransmitterTemperature_Version = 0x01;
    // manager -> server
    static Manager_IrTransmitterPower = 0x9B;
    static Manager_IrTransmitterPower_Version = 0x01;

    // manager -> server
    static Manager_IoTUpdate = 0x9C;
    static Manager_IoTUpdate_Version = 0x01;

    // manager -> server
    static Manager_IrTransmitterRequest = 0x9D;
    static Manager_IrTransmitterRequest_Version = 0x01;

    static ServerRestart = 0xC2;
    static ServerRestart_Version = 0x01;

    static ServerUpdateRequest = 0xC3;
    static ServerUpdateRequest_Version = 0x01;
}

export { SocketManager, PacketCommands };
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CancelButton from "../components/CancelButton";
import DisplayErrors from "../components/DisplayErrors";
import Loading from "../components/Loading";
import OkButton from "../components/OkButton";
import RouteConfig from "../route-config";
import ServerAPI from "../ServerAPI";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import { Server, StoreInfo } from "./Store.Models";
import AppContext from "../AppContext";
import ServerStateComponent from "./ServerStateComponent";
import ArrayUtil from "../utils/ArrayUtil";
import Authorization from "../utils/Authorization";

function ServerListPage() {
    const {selectedStoreId, stores} = useContext(AppContext);
    const navigate = useNavigate();
    const [servers, setServers] = useState<Server[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);
    const [store, setStore] = useState(stores.find(s=> s._id ===selectedStoreId));

    useEffect(() => {
        updateServerList();        
    }, [selectedStoreId, stores]); 

    async function updateServerList(){
        if(!selectedStoreId) return;
        setStore(stores.find(s=> s._id ===selectedStoreId));
        setIsLoading(true);
        setErrors([]);
        try {

            const servers = await HttpRequest.get<Server[]>(`${ServerAPI.storeGetServers}/${selectedStoreId}`);
            ArrayUtil.sortAscending(servers, "name");
            setServers(servers);
        }
        catch (error) {
            console.log(error);
            if(servers) setServers(undefined);
            if (error?.response?.data) {
                setErrors(error.response.data);
            }
            else {
                setErrors(["서버연결에 실패했습니다."])
            }
        }
        setIsLoading(false);
    }

    async function deleteServer(serverId : number) {
        try {
            if(!selectedStoreId) {
                Dialog.showError("선택된 매장정보가 없습니다.")
                return;
            }
            await HttpRequest.delete(`${ServerAPI.storeServerDelete}`, {params : {storeId : selectedStoreId, serverId : serverId}});
            Dialog.showConfirmation("서버삭제가 완료되었습니다.");
            updateServerList();
        }
        catch(error) {
            let msg = "에러가 발생했습니다.\n"
            if(error?.response?.data){
                msg += error.response.data;
            }
            Dialog.showError(msg);
        }
    }

    return <div>
        <h3 className='mb-3'>서버 목록</h3>
        { selectedStoreId ?
        <>
        {
         store && Authorization.hasPermission(store.permission, "server", "add") &&
        <OkButton className="btn-sm mb-3" onClick={() => navigate(RouteConfig.storeServerPath)}>새 서버 등록</OkButton>
}
        { isLoading ? <div><Loading /> </div> :
            servers?
            
            <div className="row g-0">
                <div className="col-lg-8"> 
                <div>
                    총 {servers.length}개 서버가 등록되어 있습니다.
                </div>
            {servers.map(s => 
              <ServerStateComponent server={s} key={s._id} onDelete={deleteServer} />
            )} 
            </div>
            </div>
            :
            <DisplayErrors errors={errors}/>
        }
        </> :
        <div>
            선택된 매장이 없습니다. 매장을 선택해주세요.
            </div>
        }
    </div>
}

export default ServerListPage;
import { Pagination } from "@mui/material";
import { Form, Formik } from "formik";
import { start } from "node:repl";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../AppContext";
import ComponentToLoad from "../components/ComponentToLoad";
import ComponentToLoadSmallCenter from "../components/ComponentToLoadSmallCenter";
import DateSelection from "../components/DateSelection";
import DisplayErrors from "../components/DisplayErrors";
import OkButton from "../components/OkButton"
import SelectionField from "../forms/SelectionField";
import ServerAPI from "../ServerAPI";
import { StoreLog, StoreLogSearch, StoreLogSearchOutput } from "../stores/Store.Models";
import { dateUtil } from "../utils/DateUtil";
import Dialog from "../utils/Dialog";
import HttpRequest from "../utils/HttpRequest";
import { logUtil } from "../utils/LogUtil";
import { storeUtil } from "../utils/StoreUtil";
import stringUtil from "../utils/StringUtil";
import { NotificationLog, NotificationLogSearch } from "./Settings.Models";

export default function NotificationHistoryPage() {
    const startDateInit = new Date();
    startDateInit.setDate(startDateInit.getDate()-7);
    const [logSearch, setLogSearch] = useState<NotificationLogSearch>({
        startDate: startDateInit,
        endDate: new Date(),
    });
    const totalLogsRef = useRef<NotificationLog[]>();
    const [logs, setLogs] = useState<NotificationLog[]>();
    const [types, setTypes] = useState<Set<number>>();
    const [selectedType, setSelectedType] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<Date>(startDateInit);
    const [endDate, setEndDate] = useState<Date>(new Date());
    

    useEffect(() => {
        onLogSearch(logSearch);
    }, []);

    async function onLogSearch(logSearch: NotificationLogSearch) {
        if (startDate.getTime() > endDate.getTime()) {
            Dialog.showError("종료날짜가 시작날짜보다 이전입니다.");
            return;
        }
        else if (!dateUtil.isInOneMonth(startDate, endDate)) {
            Dialog.showError("검색기간이 1개월을 초과할 수 없습니다.");
            return;
        }
        setIsLoading(true);
        setErrors([]);
        try {
            const startDateString = dateUtil.toDateISOStringWithoutTime(logSearch.startDate);
            const endDate1DayAfter = new Date(logSearch.endDate.toDateString());
            endDate1DayAfter.setDate(endDate1DayAfter.getDate() + 1);
            const endDateString = endDate1DayAfter.toISOString();
            totalLogsRef.current = await HttpRequest.get<NotificationLog[]>(ServerAPI.accountGetNotificationLogs, { params: { startDate: startDateString, endDate: endDateString } });
            const newTypes = new Set<number>();
            totalLogsRef.current.forEach(l => newTypes.add(l.type));
            setTypes(newTypes);
            onTypeChange("0");
        }
        catch (error) {
            setLogs(undefined);
            setTypes(undefined);
            setSelectedType(0);
            if (Array.isArray(error?.response?.data)) {
                setErrors(error.response.data);
            }
            else {
                setErrors(["검색에 실패했습니다."])
            }
        }
        finally {
            setIsLoading(false);
        }
    }

    function getTypeOptions() : JSX.Element {
        if(!types) return <></>;
        const options : JSX.Element[] = [];
        types.forEach(t =>
            options.push(<option key={t} value={t}>{logUtil.getNotificationTypeName(t)}</option> ) 
        );
        return <>{options}</>;
    }

    function onTypeChange(value: string) {
        try {
            const numberValue = Number.parseInt(value)
            if (numberValue === 0)
                setLogs(totalLogsRef.current);
            else
                setLogs(totalLogsRef.current?.filter(p => p.type === numberValue));
            setSelectedType(numberValue);
        }
        catch (e) {
            console.log(e);
        }
    }

    return <div>
        <h3>알림내역</h3>
        <div className="mt-3">
            <div className="row g-0">
                <div className="col-lg-6" >
                    <div className="border border-primary p-3 rounded">
                        <Formik initialValues={logSearch} onSubmit={v => onLogSearch(v)}>
                            {(formikProps) =>
                                <Form>
                                    <DateSelection startDate={formikProps.values.startDate} endDate={formikProps.values.endDate} setStartDate={(date: Date) => formikProps.setFieldValue("startDate", date)} setEndDate={(date: Date) => formikProps.setFieldValue("endDate", date)} defaultDateRange="2" />
                                    <ComponentToLoadSmallCenter className="mb-2 mt-3" isLoading={isLoading}>
                                        <OkButton disabled={isLoading} type="submit" className="w-100" >검색</OkButton>
                                    </ComponentToLoadSmallCenter>
                                </Form>
                            }
                        </Formik>
                    </div>
                    <ComponentToLoad className="mt-2" isLoading={isLoading}>
                        {
                            logs ?
                                <>
                                    <div className="fw-bold mt-2">
                                        총 {logs.length}개 내역이 검색되었습니다.
                                    </div>
                                    {types &&<div className="my-1">
                                        <select className="form-select" value={selectedType} onChange={e => onTypeChange(e.currentTarget.value)}>
                                        <option value="0">전체</option>
                                            {getTypeOptions()}
                                        </select>

                                    </div>
                                    }

                                    <div className="row g-0">
                                        {logs.map(c => <div key={c.registerDate} className="py-2 px-4 bg-gainsboro my-1 rounded">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-auto me-2 fw-bold"> {stringUtil.toStringFullDateTime(new Date(c.registerDate))} </div>
                                                <div className="col-auto">
                                                    <div className={`badge bg-success`}>{logUtil.getNotificationTypeName(c.type)}</div>
                                                </div>

                                            </div>
                                            <div className="d-flex mt-2">
                                                <ul>
                                                    <li>제목 : {c.title}</li>
                                                    <li>내용 : {c.contents}</li>
                                                    <li>전송기기 : {c.deviceName}</li>
                                                </ul>
                                            </div>
                                        </div>

                                        )}
                                    </div>
                                </>
                                :
                                <DisplayErrors errors={errors} />}
                    </ComponentToLoad>
                </div>
            </div>
        </div>
    </div>
}

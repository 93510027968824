import { useParams } from "react-router-dom";
import EmailVerification from "./EmailVerification";

export default function EmailVerificationPage() {
    const {email} = useParams();

    return <div className='row g-0'>
    <div className='col-lg-8'>
    <h3 className="mb-3">이메일 인증</h3>
      <EmailVerification email={email}/>
    </div>
    </div>
}